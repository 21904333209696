<template>
  <div class="organization-seat-type-table-cell">
    <span v-if="billingType !== 'SiteLicense'">
      {{ usedSeats }}/{{ maxSeats }} ({{ maxSeats - usedSeats }} available)
    </span>
    <span v-else>N/A</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import type { Organization } from '@models';

/**
 * `TableCellOrganizationSeats` component properties.
 */
export interface Props {
  /** ... */
  item: Organization;
}

const props = defineProps<Props>();

const usedSeats = computed(() => props.item.usedSeats);
const maxSeats = computed(() => props.item.maxSeats);
const billingType = computed(() => props.item.billingType);
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellOrganizationSeats: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellOrganizationSeats' };
</script>

<style lang="scss" scoped>
.organization-seat-type-table-cell {
  // text-align: right;
  white-space: nowrap;
}
</style>
