<script lang="ts">
import { ref, computed, watch, watchEffect } from 'vue';
import find from 'lodash/find';

import { faArrowLeft } from '@icons/regular/faArrowLeft';
import { faArrowRight } from '@icons/regular/faArrowRight';

import SelectionGroup from './SelectionGroup.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    SelectionPanel: ComponentWithProps<Props>;
  }
}

export interface ListItem<T = unknown> {
  id: number;
  item: T;
}

export default { name: 'SelectionPanel' };
</script>

<script setup lang="ts">
/**
 * `SelectionPanel` component properties.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Props<T = any> {
  options: T[];
  selection: T[];
  optionsLabel?: string;
  selectionLabel?: string;
  text: (item: T) => string;
  subtext: (item: T) => string;
}

export type Emits<T = unknown> = (
  event: 'selection-updated',
  value: T[],
) => void;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

// const items = ref< ListItem<T>[]>
const listItems = ref<ListItem[]>([]);
const selectionIds = ref<number[]>([]);

watch(selectionIds, (ids) => {
  const value: unknown[] = [];

  for (const id of ids) {
    const item = find(listItems.value, { id })?.item;

    if (!item) {
      throw new Error(
        '[SelectionPanel:onSelectionChanged] could not find an internally tacked item by its ID.',
      );
    }

    value.push(item);
  }

  emit('selection-updated', value);
});

const groups = computed(() => {
  const left: ListItem[] = [];
  const right: ListItem[] = [];

  for (const item of listItems.value) {
    const group = selectionIds.value.includes(item.id) ? right : left;

    group.push(item);
  }

  return { left, right };
});

function add(id: number) {
  selectionIds.value = [...selectionIds.value, id];
}

function remove(id: number) {
  selectionIds.value = selectionIds.value.filter((_id) => _id !== id);
}

function addAll() {
  selectionIds.value = listItems.value.map(({ id }) => id);
}

function removeAll() {
  selectionIds.value = [];
}

// /**
//  * ...
//  *
//  * @returns
//  */
// function generateLists() {
//   const items: ListItem[] = [];
//   const ids: number[] = [];

//   let count = 0;

//   for (const item of props.options) {
//     items.push({ id: count++, item });
//   }

//   for (const item of props.selection) {
//     const id = count++;

//     items.push({ id, item });
//     ids.push(id);
//   }

//   listItems.value = items;
//   selectionIds.value = ids;
// }

watchEffect(() => {
  const items: ListItem[] = [];
  const ids: number[] = [];

  let count = 0;

  for (const item of props.options) {
    items.push({ id: count++, item });
  }

  for (const item of props.selection) {
    const id = count++;

    items.push({ id, item });
    ids.push(id);
  }

  listItems.value = items;
  selectionIds.value = ids;
});
</script>

<template>
  <div class="selector-panel">
    <SelectionGroup
      :label="optionsLabel || 'Options'"
      :items="groups.left"
      :text="text"
      :subtext="subtext"
      @removed="add"
    />

    <div class="selector-center-col">
      <b-button class="mt-2" :disabled="!groups.left.length" @click="addAll">
        Add All
      </b-button>

      <Icon :icon="faArrowRight" />
      <Icon :icon="faArrowLeft" />

      <b-button
        class="mt-2"
        :disabled="!groups.right.length"
        @click="removeAll"
      >
        Remove All
      </b-button>
    </div>

    <SelectionGroup
      :label="selectionLabel || 'Selection'"
      :items="groups.right"
      :text="text"
      :subtext="subtext"
      @removed="remove"
    />
  </div>
</template>

<style scoped lang="scss">
.selector-panel {
  display: flex;
  flex-grow: 1;
}

.selector-group {
  // flex-basis: 40%;
  flex-grow: 1;
}

.selector-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
</style>
