<script lang="ts">
import { computed, useSlots } from 'vue';

declare module 'vue/types/vue' {
  export interface Vue {
    ModalFormSection: ComponentWithProps<Props>;
  }
}

export default { name: 'ModalFormSection' };
</script>

<script setup lang="ts">
/**
 * `ModalFormSection` component properties.
 */
export interface Props {
  title?: string;
  description?: string;
  noPadding?: boolean;
  disabled?: boolean;
  variant?: 'danger';
}

const props = defineProps<Props>();

const slots = useSlots();

const hasHeaderContent = computed(() => {
  return 'header' in slots;
});

const showHeader = computed(() => {
  return !!props.title || !!props.description || hasHeaderContent.value;
});
</script>

<template>
  <div :class="['modal-form-section', { disabled }]">
    <div v-if="showHeader" class="modal-form-section-header">
      <div v-if="title || description" class="flex-grow-1">
        <div v-if="title" class="h6 mb-0">{{ title }}</div>

        <small v-if="description" class="small text-muted">
          {{ description }}
        </small>
      </div>

      <div
        v-if="hasHeaderContent"
        class="d-flex justify-content-end flex-grow-1"
      >
        <slot name="header" />
      </div>
    </div>

    <div
      :class="[
        variant && variant === 'danger'
          ? 'modal-form-section-content-danger'
          : 'modal-form-section-content',
        { 'p-0': noPadding },
      ]"
    >
      <div class="position-relative">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-form-section {
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.modal-form-section-header {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.modal-form-section-content-danger {
  position: relative;
  padding: 20px;
  margin: 0 -20px;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid;

    pointer-events: none;

    @include app-theme-light {
      border-color: #00000026;
      box-shadow: 0 0 1px 1px #aa0000,
        0 0 15px rgba(200, 000, 000, 0.5215686275);
      background-color: #330000;
    }

    @include app-theme-dark {
      border-color: #313c4f59;
      box-shadow: 0 0 1px 1px #aa0000,
        0 0 15px rgba(200, 000, 000, 0.5215686275);
      background-color: #330000;
    }
  }

  .form-group {
    opacity: 1 !important;

    &.disabled,
    &[disabled],
    &[disabled='disabled'] {
      > :deep(*) {
        opacity: 0.25;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid white;
      padding-bottom: 1rem;

      @include app-theme-light {
        border-color: #00000026;
      }

      @include app-theme-dark {
        border-color: #313c4f59;
      }
    }
  }
}

.modal-form-section-content {
  position: relative;
  padding: 20px;
  margin: 0 -20px;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid;
    border-radius: 1rem;
    pointer-events: none;

    @include app-theme-light {
      border-color: #00000026;
      background-color: #00000008;
    }

    @include app-theme-dark {
      border-color: #313c4f59;
      background-color: #ffffff05;
    }
  }

  .form-group {
    opacity: 1 !important;

    &.disabled,
    &[disabled],
    &[disabled='disabled'] {
      > :deep(*) {
        opacity: 0.25;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid white;
      padding-bottom: 1rem;

      @include app-theme-light {
        border-color: #00000026;
      }

      @include app-theme-dark {
        border-color: #313c4f59;
      }
    }
  }
}
</style>
