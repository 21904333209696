<script lang="ts">
import { Product } from '@models';
import { alert } from '@services/alert';
import { useStore, useCommit } from '@store';
import { ensureError } from '@tools/ensure-error';

import PackOptionCard, { Pack, Feature } from '@components/PackOptionCard.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    PackOptionCards: ComponentWithProps;
  }
}

const FEATURE_NAMES = {
  tutorial: 'Tutorial',
  communityPark: 'Community Park',
  basicTraining: 'Basic Training',
  yard: 'Yard',
  towerInspection: 'Tower Inspection',
  skipFredericks: 'Skip Fredericks',
  obstacleCourse: 'Obstacle Course',
  theHill: 'The Hill',
  race: 'Race',
  parkingLot: 'Parking Lot',
  flightPathPractice: 'Flight Path Practice',
  // bridgeInspection: 'Bridge Inspection',
  // nistOpenLaneTest: 'NIST Open Lane Test',
  // firstResponder: 'First Responder (Upcoming)',
} as const;

type FeatureKey = keyof typeof FEATURE_NAMES;

interface CreateFeaturesListOptions {
  include?: FeatureKey[];
  exclude?: FeatureKey[];
}

function createFeaturesList({
  include,
  exclude,
}: CreateFeaturesListOptions = {}) {
  return Object.entries(FEATURE_NAMES).map(([key, name]) => {
    // ...
    const included = !include ? true : include.some((k) => k === key);
    const excluded = !exclude ? false : exclude.some((k) => k === key);

    return { key, name, isIncluded: !excluded && included };
  }) as Feature[];
}

const PACKS: Pack[] = [
  {
    key: 'FREE-TRIAL',
    title: 'Beginner',
    description: 'Curated for the curious drone enthusiast',
    price: 0,
    features: createFeaturesList({ include: ['tutorial', 'communityPark'] }),
    personalUseOnly: true,
  },
  {
    key: 'HOBBYIST-PACK',
    title: 'Hobbyist',
    description: 'Great for hobbyists who want to get their feet wet',
    price: 9.99,
    badge: 'Most Popular',
    features: createFeaturesList({
      include: ['tutorial', 'communityPark', 'basicTraining', 'yard'],
    }),
    oneTimePurchase: true,
    personalUseOnly: true,
  },
  {
    key: 'ENTHUSIAST-PACK',
    title: 'Enthusiast',
    description: 'For the hobbyist or enthusiast seeking enhanced training',
    price: 49.99,
    badge: 'Best Value',
    features: createFeaturesList(),
    oneTimePurchase: true,
    personalUseOnly: true,
  },
];

export default { name: 'PackOptionCards' };
</script>

<script setup lang="ts">
const store = useStore();

const cartHasItem = store.getters['cart/hasItem'];
const getProduct = store.getters['shop/getProduct'];

const addItem = useCommit('cart/ADD_ITEM');
const removeItem = useCommit('cart/REMOVE_ITEM');

function onSelection(productId: Product['id']) {
  const item = getProduct(productId);

  if (!item) {
    return alert.warning(`A product with the ID ${productId} was not found.`);
  }

  for (const { key } of PACKS) {
    if (key === productId) continue;

    if (cartHasItem(key)) removeItem({ itemId: key });
  }

  let error: ensureError.Error | null = null;

  try {
    addItem({ item });
  } catch (err) {
    error = ensureError(err);
  }

  if (!error) return;

  alert.warning(error.message);
}
</script>

<template>
  <b-container class="pack-option-cards">
    <b-row>
      <b-col v-for="(pack, index) in PACKS" :key="pack.key" lg="4">
        <PackOptionCard v-bind="{ index, pack }" @selected="onSelection" />
      </b-col>
    </b-row>
  </b-container>
</template>
