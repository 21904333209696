<script lang="ts">
import { defineComponent } from 'vue';
import { BFormInput } from 'bootstrap-vue';

declare module 'vue/types/vue' {
  export interface Vue {
    FormInput: ComponentWithProps<Props>;
  }
}

/**
 * `FormInput` component properties.
 */
export interface Props extends BFormInput.Props {
  label: string;
}

export default defineComponent({
  name: 'FormInput',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <FloatingLabel v-bind="{ label }" class="form-input">
    <b-form-input :placeholder="label" v-bind="$attrs" v-on="$listeners" />
  </FloatingLabel>
</template>
