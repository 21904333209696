<script lang="ts">
const INTERVAL_DURATION = 15_000;

export default { name: 'SectionPromotions' };
</script>

<script setup lang="ts">
import { computed } from 'vue';

import { useAutoCycleList } from '@composables';
import { Promotion as PromotionData } from '@interfaces';

/** `SectionPromotions` component properties. */
export interface Props {
  promotions: PromotionData[];
}

const props = defineProps<Props>();

const { state, paused, start, stop, select } = useAutoCycleList(
  props.promotions,
  INTERVAL_DURATION,
);

const animationDuration = (INTERVAL_DURATION * 0.001).toString() + 's';

const videoUrl = computed(() => {
  const videoId = state.value?.youtubeVideoId;

  return videoId && typeof videoId === 'string'
    ? `https://www.youtube.com/embed/${videoId}`
    : null;
});
</script>

<template>
  <b-container class="section-promotions" @mouseover="stop" @mouseleave="start">
    <b-row class="mb-5 justify-content-md-center">
      <b-row v-if="state" class="justify-content-center mb-4">
        <b-col lg="12" class="promo-container">
          <div class="promo-title">
            <h2>{{ state.title }}</h2>
          </div>

          <div :key="state.id" class="promo-body">
            <div
              v-if="state.image"
              class="promo-image"
              :style="{
                backgroundImage: `url(${state.image})`,
              }"
            />

            <div
              :class="[
                state.textAlign ? state.textAlign : 'text-center',
                'promo-content',
              ]"
            >
              <pre>{{ state.body }}</pre>

              <div v-if="videoUrl" class="promo-video-container">
                <div class="promo-video">
                  <iframe
                    width="560"
                    height="315"
                    :src="videoUrl"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>

            <div v-if="state.callToAction" class="promo-call-to-action">
              <b-button
                :variant="state.callToAction.buttonVariant"
                @click="state.callToAction.fn"
              >
                {{ state.callToAction.text }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>

    <b-row>
      <b-col>
        <div class="section-footer">
          <div class="pagination-btns">
            <div
              v-for="(promo, i) in promotions"
              :key="promo.id"
              :class="['pagination-btn', { active: promo === state }]"
              @click="select(i)"
            ></div>
          </div>

          <div
            v-if="!paused"
            :key="state.id"
            class="progress-bar"
            :style="{ animationDuration }"
          ></div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
@keyframes promoContent {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-footer {
  position: relative;
  display: inline-flex;
  margin: 0 auto;
}

.progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.15);
  margin: 0 10px;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: gray;
    animation: progressBar linear 15s;
  }
}

.promotion-panel {
  display: flex;
  align-items: stretch;
  margin-bottom: 2rem;
  opacity: 1;
  transform: translateY(0);
  transition: 1s;

  &.v-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  &.v-leave-active {
    transition: 0;
    display: none;
  }
}

.promo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.promo-title {
  flex: 0 0 100%;
  padding: 1rem;
}

.promo-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  border-radius: 40px;
  box-shadow: var(--panel-box-shadow);
  background-color: rgba(0, 0, 0, 0.8);
  min-height: 700px;
}

.promo-image {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 0;
  }
}

.promo-content {
  position: relative;
  z-index: 1000;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;

    iframe {
      width: 100%;
    }
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: 'Rubik';
    color: inherit;
    font-size: 1rem;
    padding: 1rem;
  }
}

.promo-call-to-action {
  position: relative;
  margin-top: 1rem;
  z-index: 10;
}

.promo-footer {
  display: flex;
  align-items: center;
}

.pagination-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  @media (max-width: 1000px) {
    width: 100px;
  }
}

.pagination-btn {
  width: 100px;
  height: 10px;
  background: #ffd400;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  opacity: 0.25;

  &.active {
    opacity: 1;
  }
}
</style>
