<script setup lang="ts">
import { computed } from 'vue';

import Icon, { IconDefinition } from '@components/Icon.vue';

import { faCheck } from '@icons/solid/faCheck';
import { faTimes } from '@icons/solid/faTimes';
import { faQuestion } from '@icons/solid/faQuestion';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellSuccess: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellSuccess` component properties.
 */
export interface Props {
  /** ... */
  value: unknown;
}

const props = defineProps<Props>();

const status = computed(() => {
  let tag: string;
  let icon: IconDefinition;

  if (props.value === true) {
    tag = 'success';
    icon = faCheck;
  } else if (props.value === false) {
    tag = 'fail';
    icon = faTimes;
  } else {
    tag = 'unknown';
    icon = faQuestion;
  }

  return { tag, icon };
});
</script>

<template>
  <div :class="['table-cell-success', status.tag]">
    <Icon size="1rem" :icon="status.icon" />
  </div>
</template>

<style scoped lang="scss">
// .table-cell-success {
//   text-align: center;
// }

.success {
  color: var(--success);
}

.fail {
  color: var(--danger);
}

.unknown {
  color: var(--warning);
}
</style>
