<script lang="ts">
export default { name: 'SiteDocument' };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import uniqueId from 'lodash/uniqueId';

import Icon from '@components/Icon.vue';

import { faLightbulbOn } from '@icons/duotone/faLightbulbOn';

export interface Line {
  text: string;
  // subText?: string;
  tag?: string;
  classList?: string[];
  style?: Partial<CSSStyleDeclaration>;
}

export type Paragraph = string | Line | string[];

export interface Section {
  title?: string;
  paragraphs: Paragraph[];
  subText?: string;
}

/** `SiteDocument` component properties. */
export interface Props {
  sections: Section[];
}

const props = defineProps<Props>();

const items = computed(() => {
  return mapSections(props.sections);
});

const hasSubText = computed(() => {
  return items.value.some(({ subText }) => subText);
});

//#region Helper Functions

/**
 * Normalize the paragraphs of a section.
 *
 * @param paragraphs Paragraphs to normalize.
 * @returns Normalized paragraphs.
 */
function normalizeSectionParagraphs(paragraphs: Paragraph[]) {
  return paragraphs.map((value) => {
    if (Array.isArray(value)) return value;

    const line: Line =
      typeof value !== 'string' ? { ...value } : { text: value };

    line.tag = line.tag ?? 'p';
    line.classList = line.classList ?? [];
    line.style = line.style ?? {};

    return line;
  });
}

interface KeyedSection extends Section {
  key: string;
  paragraphs: (string[] | Line)[];
}

/**
 * Map sections to keyed sections.
 *
 * @param sections Sections to map.
 * @returns Keyed sections.
 */
function mapSections(sections: Section[]) {
  return sections.map(({ title, paragraphs, subText }) => {
    const config: KeyedSection = {
      key: uniqueId(),
      paragraphs: normalizeSectionParagraphs(paragraphs),
    };

    if (title) {
      config.title = title;
    }

    if (subText) {
      config.subText = subText;
    }

    return config;
  });
}

//#endregion Helper Functions
</script>

<template>
  <div class="site-document">
    <div
      v-for="({ title, paragraphs, subText }, index) in items"
      :class="{ 'mb-4': index < items.length - 1 }"
    >
      <b-row v-if="title" class="mb-2">
        <b-col>
          <h2 class="doc-section-title h4 mb-0 mr-4">{{ title }}</h2>
        </b-col>
      </b-row>

      <b-row
        align-h="between"
        :style="{ textAlign: hasSubText ? 'justify' : 'unset' }"
      >
        <b-col lg="12">
          <template v-for="paragraph in paragraphs">
            <ul v-if="Array.isArray(paragraph)">
              <li v-for="item in paragraph" v-html="item"></li>
            </ul>

            <component
              v-else
              :is="paragraph.tag"
              :class="paragraph.classList"
              :style="paragraph.style"
              v-html="paragraph.text"
            />
          </template>
        </b-col>
      </b-row>

      <b-row v-if="subText">
        <b-col>
          <div class="simple-info-card">
            <div class="h6 mb-2">
              <Icon :icon="faLightbulbOn" />
              <span>Plain and Simple</span>
            </div>

            <p v-html="subText"></p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul {
  padding-left: 40px;
}

.doc-section-title {
  font-weight: 500 !important;
  flex-shrink: 0;
}

.simple-info-card {
  background-color: #ffe2ad;
  padding: 30px;
  text-align: left;
  border-radius: 10px;
  color: #4e3825;

  .fa-icon {
    font-size: 1.2em;
  }

  > div {
    color: #4e1a00;
    font-weight: 500;
  }

  p {
    line-break: loose;
    margin: 0;
  }
}
</style>
