import { reactive, watch } from 'vue';
import * as auth0 from '@auth0/auth0-spa-js';
// import * as auth0 from './foo';
import { some, uniq } from 'lodash';

import { api, ApiRequestError } from '@api';
import { Role } from '@models';
import { router } from '@router';
import { alert } from '@services/alert';
import { store, Me } from '@store';
import { defer, Deferred } from '@tools/defer';
import { delay } from '@tools/delay';
import { ensureError } from '@tools/ensure-error';
import { isBoolean, isString, isObject, isUndefined } from '@tools/type-guards';
import { RoleId } from '@values/roles';

import { useIdleMonitor, IdleMonitor } from './idle-monitor';
import * as tabs from './tab-manager';

import {
  User,
  AccessCredential,
  AccessPrivilege,
  AccessDescription,
  AuthState,
  GreetingOptions,
  RedirectReturnRoute,
  AuthGreeting,
  LoginOptions,
  RedirectRegistrationOptions,
  RedirectLoginOptions,
  PopupLoginOptions,
  LinkAccountOptions,
  UnlinkAccountOptions,
  LogoutOptions,
  GetAccessTokenOptions,
  RedirectStateInfo,
  RedirectInfo,
  RedirectResult,
} from './types';
import ls from '@services/ls';

const DOMAIN = process.env.AUTH0_DOMAIN;
const CLIENT_ID = process.env.AUTH0_CLIENT_ID;
const AUDIENCE = process.env.AUTH0_AUDIENCE_URI;
const AUTH_REDIRECT_URI = `${window.location.origin}/login/callback`;

const auth0Client = new auth0.Auth0Client({
  domain: DOMAIN,
  clientId: CLIENT_ID,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: AUDIENCE,
    redirect_uri: AUTH_REDIRECT_URI,
  },
});

export class AuthClient {
  #initialization: Deferred<void> = defer();
  #userRequest: Deferred<Me.State> | null = null;
  #redirectResult: RedirectResult | null = null;
  #idleMonitor: IdleMonitor;

  #state = reactive({
    initialized: false,
    state: null as AuthState | null,
  });

  /**
   * Whether or not the service has been initialized
   */
  get initialized() {
    return this.#state.initialized;
  }

  /** ... */
  get redirectInfo() {
    return !this.#redirectResult || this.#redirectResult instanceof Error
      ? null
      : this.#redirectResult;
  }

  /**
   * Current user object -- an alias of `store.state.me`.
   */
  get user() {
    return store.state.me.id ? (store.state.me as User) : null;
  }

  /**
   * Current user roles.
   */
  get roles() {
    return this.user?.roles ?? [];
  }

  /**
   * Active role of current user, if logged in.
   */
  get activeRole() {
    return this.user?.selectedRole ?? null;
  }
  /**
   * Active role ID of current user, if logged in.
   */
  get activeRoleId() {
    return this.activeRole?.roleId ?? null;
  }

  /** ... */
  get isLoggingIn() {
    return (
      this.#state.state === 'logging-in-password' ||
      this.#state.state === 'logging-in-redirect' ||
      this.#state.state === 'logging-in-popup'
    );
  }

  /** ... */
  get isLoggingOut() {
    return this.#state.state === 'logging-out';
  }

  /** ... */
  get isLinkingAccount() {
    return this.#state.state === 'linking-account';
  }

  /**
   * `true` if it is detected that the site is currently in an unhandled (Auth0)
   * redirect state, otherwise `false`.
   */
  get hasUnhandledRedirect() {
    const params = new URLSearchParams(window.location.href);

    return params.has('state');
  }

  get auth0Client() {
    return auth0Client;
  }

  /**
   * `true` if the page this auth service instance is running in was redirected to
   * via Auth0's universal login page.
   */
  get isFromRedirect() {
    return !!this.#redirectResult;
  }

  constructor() {
    this.#idleMonitor = useIdleMonitor({
      limitReachedCallback: () => void this.logout(),
    });
  }

  /**
   * Initialize the Auth service.
   */
  async init() {
    if (this.#state.initialized) {
      throw new Error('Auth service has already been initialized.');
    }

    watch(
      () => !!this.user?.id,
      (authenticated) => {
        if (authenticated) {
          this.#idleMonitor.start();
        } else {
          this.#idleMonitor.end();
        }
      },
    );

    // ...

    if (isAuthRedirect()) {
      try {
        this.#redirectResult = await this.#processAuthRedirect();
      } catch (err) {
        this.#redirectResult = ensureError(err);
      }
    }

    // Check current Auth0 session followed by an authentication check.

    await auth0Client.checkSession();
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (!isAuthenticated) {
      checkForTabManagerLogoutFlag();
    } else if (!tabs.hasMembers()) {
      // If the tab manager has no current members, meaning all tabs opened to
      // the site were previously closed before this one was create, log the
      // user out in the event it was there intent to close out of there
      // active session.
      setTabManagerLogoutFlag();

      await this.logout();
    } else {
      // If reached, the user is complete authenticated, so run post-auth
      // handling.

      // try {
      //   await this.#getUser();
      // } catch {
      //   spawnUnexpectedGetUserFailureAlert();
      // }

      // NOTE: It is possible, in a deployed context, that a request to `getMe`
      // could fail here due to a "cold start" of the backend runtime that
      // hosts the Zephyr API. A better solution would be to mitigate this
      // possibility at its source, but in the meantime, the following
      // implementation will make a few spaced-out attempts to fetch Zephyr user
      // data before alerting the user that there is an issue.

      let requestAttempts = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        let error: ErrorOrNullish = null;

        try {
          await this.#getUser();
        } catch (err) {
          error = ensureError(err);
        }

        // If the request was successful, break from the reattempt loop.
        if (!error) break;

        // If the error is an instance of an `ApiRequestError`, it is safe to
        // assume the failure was due to an unexpected, but authentic error
        // response from the API server, and not due to it being inaccessible.
        if (error instanceof ApiRequestError || ++requestAttempts > 3) {
          spawnUnexpectedGetUserFailureAlert();

          break;
        }

        await delay(1_000);
      }
    }

    // Ensure current tab is registered with manager.
    tabs.registerInstance();

    this.#state.initialized = true;
    this.#initialization.resolve();
  }

  /**
   * Returns a `Promise` that resolves once this auth service has finished
   * initializing.
   */
  async whenReady() {
    await this.#initialization.promise;
  }

  /**
   * ...
   */
  async checkSession() {
    await auth0Client.checkSession();
  }

  /**
   * Get current session's ID token.
   *
   * @returns A promise that will resolve to access token of the current
   * session, if one exists, and `null` if one does not.
   */
  async getAccessToken(options?: GetAccessTokenOptions) {
    return await auth0Client.getTokenSilently(options).catch(() => null);
  }

  /**
   * Returns Auth0 user information if available (decoded from the id_token).
   */
  async getAuth0User() {
    await this.whenReady();

    return (await auth0Client.getUser()) ?? null;
  }

  /**
   * Internal call to fetch the user from the server. The call is wrapped in a
   * deferred promise that is automatically returned on all subsequent calls
   * to `#getUser` until the request is resolved, at which point the deferrer
   * is discarded. This prevents wasteful stacking of the same request to fetch
   * user data should others occur while one is already in progress.
   */
  #getUser = async () => {
    if (!this.#userRequest) {
      this.#userRequest = defer();

      fetchAuthenticatedUser()
        .then((res) => {
          this.#userRequest?.resolve(res);
        })
        .catch((err) => {
          this.#userRequest?.reject(err);
        })
        .finally(() => {
          // Set back to `null` so that the next call to `#getUser` starts a new
          // request.
          this.#userRequest = null;
        });
    }

    return await this.#userRequest.promise;
  };

  /**
   * Fetch current user from server.
   */
  async getUser() {
    try {
      return await this.#getUser();
    } catch {
      return null;
    }
  }

  /**
   * Refresh logged-in user.
   */
  async refreshUser() {
    await this.getUser();
  }

  /**
   * Check if a user is logged in
   *
   * @returns `true` if the user is logged in, otherwise `false`.
   */
  async isLoggedIn() {
    await this.whenReady();

    if (!(await auth0Client.isAuthenticated())) return false;

    return !!(await this.getUser())?.id;
  }

  /**
   * Register a new user account via a redirect.
   */
  async registerWithRedirect(options?: RedirectRegistrationOptions) {
    await this.whenReady();

    const authorizationParams: auth0.AuthorizationParams = {
      prompt: 'login',
      screen_hint: 'signup',
      redirect_uri: AUTH_REDIRECT_URI,
    };

    if (options?.orderId) {
      authorizationParams['orderId'] = options.orderId;
    }

    if (options?.freeTrial) {
      authorizationParams['freeTrial'] = true;
    }

    if (options?.userEmail) {
      authorizationParams.login_hint = options?.userEmail;
    }

    const redirectInfo: RedirectStateInfo = {
      authFlow: 'signup',
    };

    if (options?.returnTo) {
      redirectInfo.redirectRoute = normalizeRedirectRouteParams(
        options.returnTo,
      );
    }

    if (options?.greeting) {
      redirectInfo.greeting = options.greeting;
    }

    if (options?.appState) {
      redirectInfo.appState = options.appState;
    }

    const registrationOptions: auth0.RedirectLoginOptions = {
      authorizationParams,
      appState: redirectInfo,
    };

    await auth0Client.loginWithRedirect(registrationOptions);
  }

  /**
   * Login to user account via a redirect.
   */
  async loginWithRedirect(options?: RedirectLoginOptions) {
    await this.whenReady();

    const authorizationParams: auth0.AuthorizationParams = {
      redirect_uri: AUTH_REDIRECT_URI,
    };

    if (options?.prompt) {
      authorizationParams.prompt = options.prompt;
    }

    if (options?.userEmail) {
      authorizationParams.login_hint = options?.userEmail;
    }

    if (options?.screenHint) {
      authorizationParams.screen_hint = options?.screenHint;
    }

    if (options?.organization) {
      authorizationParams.organization = options.organization;
    }

    if (options?.invitation) {
      authorizationParams.invitation = options.invitation;
    }

    if (options?.provider) {
      authorizationParams.connection = options.provider;
    }

    const redirectInfo: RedirectStateInfo = {
      authFlow: 'login',
    };

    if (options?.returnTo) {
      redirectInfo.redirectRoute = normalizeRedirectRouteParams(
        options.returnTo,
      );
    }

    if (options?.greeting) {
      redirectInfo.greeting = options.greeting;
    }

    if (options?.appState) {
      redirectInfo.appState = options.appState;
    }

    const loginOptions: auth0.RedirectLoginOptions = {
      authorizationParams,
      appState: redirectInfo,
    };

    this.#state.state = 'logging-in-redirect';

    await auth0Client.loginWithRedirect(loginOptions);
  }

  /**
   * Login to user account via a separate popup window.
   *
   * @returns The logged-in user.
   */
  async loginWithPopup(options?: PopupLoginOptions) {
    await this.whenReady();

    const authorizationParams: auth0.AuthorizationParams = {
      prompt: 'login',
      redirect_uri: AUTH_REDIRECT_URI,
    };

    if (options?.userEmail) {
      authorizationParams.login_hint = options?.userEmail;
    }

    const loginOptions: auth0.PopupLoginOptions = {
      authorizationParams,
    };

    if (options?.provider) {
      authorizationParams.connection = options.provider;
    }

    let error: ErrorOrNullish = null;

    this.#state.state = 'logging-in-popup';

    try {
      await auth0Client.loginWithPopup(loginOptions).then(this.#getUser);
    } catch (err) {
      error = ensureError(err);
    }

    this.#state.state = null;

    if (error) {
      throw error;
    }

    void this.#handlePostAuth(options);

    return this.user as User;
  }

  /**
   * Handle a redirect coming from Auth0's Universal Login. This includes
   * checking for and establishing local user authentication, as well as
   * fetching any app state data stored prior login redirect.
   */
  async handleAuthRedirect() {
    await this.whenReady();

    // ...
    if (this.#redirectResult === null) {
      throw new Error(
        'An attempt to handle an authorization redirect was made despite none having been detected.',
      );
    }

    // ...
    if (this.#redirectResult instanceof Error) {
      throw this.#redirectResult;
    }

    // ...
    if (!(await auth0Client.isAuthenticated())) {
      throw new Error('Unable to authenticate after redirect.');
    }

    // ...
    if (!(await this.getUser())) {
      throw new Error('Could not retrieve user data.');
    }

    await this.#handlePostAuth(this.#redirectResult.loginOptions);

    return this.#redirectResult;
  }

  /**
   * Spawn successful-login greeting alert. Providing no greeting value will
   * result in the default "Welcome to Zephyr..." greeting.
   *
   * @param greeting Alert greeting value.
   */
  async spawnLoginGreetingAlert(greeting: AuthGreeting = true) {
    if (greeting === false) return;

    // Fetch Zephyr user data if not already done.
    const user = this.user ?? (await this.#getUser());

    let userMessage = '';
    let includeWelcome = false;

    if (greeting === true) {
      includeWelcome = true;
    } else if (isString(greeting)) {
      userMessage = greeting;
    } else {
      userMessage = greeting.text;
      includeWelcome = !!greeting.appendToWelcome;
    }

    if (includeWelcome) {
      let welcomeMessage = 'Welcome to Zephyr';

      if (user.firstName) {
        welcomeMessage += `, ${user.firstName}`;
      }

      userMessage = userMessage
        ? `${welcomeMessage}\n\n${userMessage}`
        : welcomeMessage;
    }

    alert.success(userMessage);
  }

  /**
   * Link an existing user account (under a list of supported providers) to the
   * currently active user account. This will allow a user to log in to their
   * Zephyr account through linked account's respective provider.
   */
  async linkAccount(options: LinkAccountOptions) {
    await this.whenReady();

    let error: ErrorOrNullish = null;

    this.#state.state = 'linking-account';

    try {
      const { id: userId } = this.getAssertedAuthUser();

      const { __raw: idToken } = await authenticateSecondaryUser(
        options.connection,
      );

      await api.users.linkAccount({
        userId,
        accountIdToken: idToken,
      });
    } catch (err) {
      error = ensureError(err);
    }

    this.#state.state = null;

    if (error) {
      throw error;
    }

    await this.refreshUser();
  }

  /**
   * Unlink an previously-linked user account.
   */
  async unlinkAccount(options: UnlinkAccountOptions) {
    await this.whenReady();

    const { id: userId } = this.getAssertedAuthUser();

    await api.users.unlinkAccount({ userId, ...options });

    await this.refreshUser();
  }

  /**
   * ...
   */
  async #processAuthRedirect() {
    const { appState } = await auth0Client.handleRedirectCallback();

    const loginOptions: LoginOptions = {};

    if (!isRedirectStateInfo(appState)) {
      throw new Error('Invalid redirect state data.');
    }

    if (appState.redirectRoute) {
      loginOptions.returnTo = appState.redirectRoute;
    }

    if (appState.greeting) {
      loginOptions.greeting = appState.greeting;
    }

    const customAppState = appState.appState ?? null;

    // ...
    return {
      authFlow: appState.authFlow,
      loginOptions,
      appState: customAppState,
    } as RedirectInfo;
  }

  /**
   * Internal method to be called directly after successful authentication has
   * occurred.
   */
  async #handlePostAuth(options?: LoginOptions) {
    // Fetch Zephyr user data if not already done.
    if (!this.user) await this.#getUser();

    // Start idle monitor so that automatic logout for forgotten user sessions
    // can occur.
    this.#idleMonitor.start();

    // Navigate to an "authentication-successful" route, if specified.
    if (options?.returnTo) {
      await router.push(options.returnTo);
    }

    // Have the default behavior for the login greeting be to show the standard
    // "Welcome to Zephyr, {userFirstName}"
    const greeting = options?.greeting ?? true;

    if (greeting !== false) {
      await this.spawnLoginGreetingAlert(greeting);
    }
  }

  /**
   * Delete access token and user info.
   */
  async logout(options?: LogoutOptions) {
    const redirect = options?.redirect ?? true;
    const federated = options?.federated ?? true;

    const logoutOptions: auth0.LogoutOptions = {
      logoutParams: { federated },
    };

    if (!redirect) {
      logoutOptions.openUrl = false;
    }

    this.#state.state = 'logging-out';

    // If redirecting, add small buffer time before logout to allow for visual
    // logout feedback.
    if (redirect) await delay(750);

    const promise = auth0Client.logout(logoutOptions);

    if (redirect) return await promise;

    await Promise.all([promise, delay(750)]);

    // Ensure the user does not remain on an authenticated route.

    const accessDescription = this.getRouteAccessDescription();

    if (accessDescription && accessDescription.credentials !== 'none') {
      await router.push({ name: 'main' });
    }

    //  Clear the user state.
    store.commit('me/CLEAR');

    this.#state.state = null;

    alert.success('Successfully logged out', { clear: true });
  }

  /**
   * Set logged-in user's role.
   *
   * @param role The role or role ID to set as active for the current logged-in
   * user.
   */
  async setRole(role: string | Role) {
    await this.#setUserRole(isString(role) ? role : role.id);
  }

  /**
   * Check if a user has admin privileges.
   *
   * @returns `true` if the user has admin privileges, otherwise `false`.
   */
  isAdmin() {
    return some(this.roles, { roleId: RoleId.LasAdmin });
  }

  /**
   * Check if a user is currently active as the given roleId.
   *
   * @param roleIds... Role ID(s) to test.
   * @returns `true` if at least one of the roles matches, otherwise `false`.
   */
  isActiveRole(...roleIds: RoleId[]) {
    return !this.activeRoleId
      ? false
      : roleIds.some((id) => id === this.activeRoleId);
  }

  /**
   * ...
   *
   * @param records ...
   * @returns ...
   */
  getRouteAccessDescription(records = router.currentRoute.matched) {
    return records.reduce((previous: AccessDescription | null, { meta }) => {
      const current = meta.accessDescription ?? null;

      if (!previous) return current;

      if (!current) return previous;

      let credentials: AccessCredential | null = null;

      if (!previous.credentials) {
        credentials = current.credentials ?? null;
      } else if (!current.credentials) {
        credentials = previous.credentials;
      } else if (previous.credentials === current.credentials) {
        credentials = previous.credentials;
      } else if (previous.credentials === 'none') {
        throw new Error(
          "[getRouteAccessDescription] a route who's access is configured to require that a user be logged in was found to be a child of a route that requires the opposite.",
        );
      } else if (current.credentials === 'none') {
        throw new Error(
          "[getRouteAccessDescription] a route who's access is configured to require that a user be logged out was found to be a child of a route that requires the opposite.",
        );
      } else if (previous.credentials === true) {
        credentials = current.credentials;
      } else if (current.credentials === true) {
        credentials = previous.credentials;
      } else {
        credentials = uniq([...previous.credentials, ...current.credentials]);
      }

      let privileges: AccessPrivilege[] | null = null;

      // ...
      if (previous.privileges || current.privileges) {
        privileges = [];

        if (previous.privileges) privileges.push(...previous.privileges);
        if (current.privileges) privileges.push(...current.privileges);

        privileges = uniq(privileges);
      }

      if (!credentials && !privileges) return null;

      const description: AccessDescription = {};

      if (credentials) {
        description.credentials = credentials;
      }

      if (privileges) {
        description.privileges = privileges;
      }

      return description;
    }, null);
  }

  /**
   * Check to see if the current authentication state meets the requirements
   * described by the provided {@link AccessDescription access description}.
   *
   * @param description The access description to check against.
   * @returns `true` if the current authentication state meets the requirements,
   * otherwise `false`.
   */
  meetsAccessRequirement(description: AccessDescription) {
    // Check against description credentials statement.
    if (description.credentials) {
      let checkFailed = false;

      if (description.credentials === 'none') {
        // Check fails if credentials statement is `'none'` and user is
        // authenticated.
        checkFailed = !!this.user;
      } else if (description.credentials === true) {
        // Check fails if credentials statement is `true` and user is not
        // authenticated.
        checkFailed = !this.user;
      } else {
        // Check fails if credentials statement is an array of role IDs and the
        // current active role, if any, is not one of them.
        checkFailed = !this.isActiveRole(...description.credentials);
      }

      if (checkFailed) return false;
    }

    // Check against description privileges statement.
    if (description.privileges) {
      let checkFailed = false;

      // Check fails if `'checkout'` privilege is present in privileges
      // statement and the user belongs to a K-12 organization.
      if (description.privileges.includes('checkout')) {
        checkFailed = !!this.user && !!this.user.isK12;
      }

      if (checkFailed) return false;
    }

    return true;
  }

  //#region Assertion Getters

  /**
   * Will assert the existence of a currently-authenticated user and return it
   * if it passes.
   *
   * @returns The authenticated user (if the assertion passes).
   */
  getAssertedAuthUser() {
    if (!this.user) {
      throw new Error(
        '[auth.getAssertedAuthUser] no authenticated user is currently set.',
      );
    }

    return this.user;
  }

  /**
   * Will assert the existence of an active user role and return it if it passes.
   *
   * @returns The active user role (if the assertion passes).
   */
  getAssertedActiveRole() {
    const selectedRole = this.user?.selectedRole;

    if (!selectedRole) {
      throw new Error(
        '[auth.getAssertedActiveRole] no active user role is currently set.',
      );
    }

    return selectedRole;
  }

  //#endregion Assertion Getters

  /**
   * Handle an authorization error (an error that occurs during login) by
   * attempting to interpret it and produce a client-facing alert to notify
   * the user.
   *
   * @see https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
   *
   * @param reason Value representing the issue, be it an `Error` or error
   * message.
   */
  handleAuthError(reason: unknown) {
    const error = ensureError(reason);

    let userMessage = '';

    if (error instanceof auth0.GenericError) {
      userMessage = error.error_description;
    } else if (isString(error.cause)) {
      userMessage = error.cause;
    } else if (error.cause instanceof Error) {
      userMessage = error.cause.message;
    } else {
      userMessage = error.message;
    }

    userMessage =
      userMessage ||
      'Login failed. Please try again or contact us for assistance at support@littlearms.com.';

    if (
      userMessage === 'User did not authorize the request' ||
      userMessage === 'Popup closed'
    ) {
      return alert.warning(userMessage);
    }

    /* eslint-disable-next-line no-console */
    console.error(error);

    alert.error(userMessage);
  }

  /**
   * ...
   *
   * @param roleId ...
   */
  async #setUserRole(roleId?: string) {
    if (isString(roleId)) {
      await store.dispatch('me/setRole', { roleId });
    } else {
      await store.dispatch('me/setRole');
    }
  }
}

//#region Helper Functions

async function fetchAuthenticatedUser() {
  // ...
  if (!(await auth0Client.isAuthenticated())) {
    throw new Error('User is not authenticated.');
  }

  return await store.dispatch('me/get');
}

async function authenticateSecondaryUser(connection: string) {
  const a0 = new auth0.Auth0Client({
    domain: DOMAIN,
    clientId: CLIENT_ID,
  });

  await a0.loginWithPopup({
    authorizationParams: {
      prompt: 'consent',
      redirect_uri: AUTH_REDIRECT_URI,
      max_age: 0,
      scope: 'openid',
      connection,
      dashboard_linking: 'true',
    },
  });

  const claims = await a0.getIdTokenClaims();

  if (!claims) {
    throw new Error(
      'No claim data from secondary account authorization was found.',
    );
  }

  return claims;
}

/**
 * Determine if the current page is an Auth0 redirect page.
 */
function isAuthRedirect() {
  return (
    `${window.location.origin}${window.location.pathname}` === AUTH_REDIRECT_URI
  );
}

/**
 * ...
 */
function normalizeRedirectRouteParams(returnTo: RedirectReturnRoute) {
  if (returnTo !== 'current') return { ...returnTo };

  const { path, params, query } = router.currentRoute;

  return { path, params, query };
}

/**
 * Determine if a value is a valid `GreetingOptions` object.
 *
 * @param value The value to check.
 * @return `true` if the value is a valid `GreetingOptions` object, otherwise
 * `false`.
 */
function isValidGreetingOptions(value: unknown): value is GreetingOptions {
  return (
    isObject(value) &&
    isString(value['text']) &&
    (isUndefined(value['appendToWelcome']) ||
      isBoolean(value['appendToWelcome']))
  );
}

/**
 * ...
 */
function isRedirectStateInfo(value: unknown): value is RedirectStateInfo {
  if (!isObject(value)) return false;

  const authFlow = value['authFlow'];

  if (authFlow !== 'login' && authFlow !== 'signup') {
    return false;
  }

  const redirectRoute = value['redirectRoute'];

  if (
    redirectRoute !== undefined &&
    !isString(redirectRoute) &&
    !isObject(redirectRoute)
  ) {
    return false;
  }

  const greeting = value['greeting'];

  if (
    greeting !== undefined &&
    !isBoolean(greeting) &&
    !isString(greeting) &&
    !isValidGreetingOptions(greeting)
  ) {
    return false;
  }

  return true;
}

function setTabManagerLogoutFlag() {
  ls.set('zephyr.tabManagerLogoutTriggered', 'true');
}

function checkForTabManagerLogoutFlag() {
  const didTabManagerLogoutOccur =
    ls.get('zephyr.tabManagerLogoutTriggered') === 'true';

  if (!didTabManagerLogoutOccur) return;

  ls.remove('zephyr.tabManagerLogoutTriggered');

  // TODO:
  // `VueNotifications` is not yet configured by the time this function is called.
  // Should re-implement in some way so that `alert` functions can be called
  // without concern of this.
  setTimeout(() => {
    alert.warning(
      'The user was automatically logged out due to all previously opened window(s) and tab(s) being closed.',
    );
  }, 1);
}

function spawnUnexpectedGetUserFailureAlert() {
  alert.warning(
    `Unable to fetch user information from the server due to an unexpected error. You may refresh the page to try again. If you continue to experience this issue, please contact us at <a href="mailto:${process.env.SUPPORT_EMAIL}.">${process.env.SUPPORT_EMAIL}</a>.`,
    { clear: true, persist: true },
  );
}

//#endregion Helper Functions
