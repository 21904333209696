var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.LineChart,
    _vm._b(
      { ref: "chart", staticClass: "chart-line" },
      "LineChart",
      { height: _vm.height },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }