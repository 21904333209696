<script lang="ts">
import { modals } from '@modals';

import DisplayTextNumber from '@components/Display/DisplayTextNumber.vue';
import Icon from '@components/Icon.vue';

import { faInfoCircle } from '@icons/duotone/faInfoCircle';

declare module 'vue/types/vue' {
  export interface Vue {
    CardStat: ComponentWithProps<Props>;
  }
}

export default { name: 'CardStat' };
</script>

<script setup lang="ts">
/**
 * `CardStat` component properties.
 */
export interface Props {
  title: string;
  subtitle?: string | null;
  value: number | null;
  info?: string | null;
  color?: string;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  subtitle: null,
  info: null,
  color: 'inherit',
  loading: false,
});

function viewInfo() {
  const title = props.title.toUpperCase() + ' STATISTICS';
  const text = props.info ?? '';

  void modals.util.generalInfo({ title, text });
}
</script>

<template>
  <CardLoadable v-bind="{ loading: !!loading }" no-body>
    <b-card-body>
      <b-card-title>
        <div class="h6">
          <span class="stat-card-title">{{ title }}</span>

          <span v-if="subtitle" class="small">{{ subtitle }}</span>

          <span v-if="info" class="icon-wrapper" @click="viewInfo">
            <Icon :icon="faInfoCircle" />
          </span>
        </div>

        <div>
          <slot name="header" />
        </div>
      </b-card-title>

      <span class="value-display">
        <DisplayTextNumber
          v-if="typeof value === 'number'"
          class="h1 m-0"
          :style="{ color }"
          :value="value"
        />

        <span v-else class="h1 m-0" :style="{ color }">N/A</span>

        <slot />
      </span>
    </b-card-body>

    <b-card-footer v-if="$scopedSlots['footer']">
      <slot name="footer" />
    </b-card-footer>
  </CardLoadable>
</template>

<style lang="scss" scoped>
.card-title {
  display: flex;
  justify-content: space-between;
}

.stat-card-title {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.icon-wrapper {
  cursor: pointer;
}

.value-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-grow: 1;

  .h1 {
    font-size: 4rem;
    font-weight: 300;
  }
}

.card-footer {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
}
</style>
