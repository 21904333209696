<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { RouterView } from 'vue-router';
import { ModalRoot } from 'uib-modals';

import { auth } from '@auth';
import { useRoute } from '@router';
import { deviceInfo } from '@services/device-info';
import { useStore, useCommit, useDispatch, watchStore } from '@store';

import AppHeader from '@components/AppHeader';
import AppFooter from '@components/AppFooter.vue';
import AppCookiesNotice from '@components/AppCookiesNotice.vue';
import KeyboardShortcutsUtil from '@components/KeyboardShortcutsUtil.vue';
import NotificationsRoot from '@components/NotificationsRoot.vue';
import Overlay from '@components/Overlay.vue';
import PrivacyPolicyUpdateNotice from './components/PrivacyPolicyUpdateNotice.vue';

const route = useRoute();
const store = useStore();

const setWindowSize = useCommit('SET_WINDOW_SIZE');
const setInitialNavigation = useCommit('SET_INITIAL_NAVIGATION');
const getLauncherLinks = useDispatch('getLauncherLinks');
const listAnnouncements = useDispatch('announcements/list');

/** ... */
const onClickListeners = ref<((el: HTMLElement) => void)[]>([]);
/** ... */
const refreshUserDataIntervalId = ref<number | null>(null);

/** Is the app currently on the landing page. */
const onLandingPage = computed(() => {
  return route.value.name === 'main';
});

/** Is the app currently on the checkout page. */
const onCheckout = computed(() => {
  return (route.value.name ?? '').startsWith('checkout');
});

const rootClassList = computed(() => {
  const classList: string[] = [];

  if (onLandingPage.value) classList.push('on-landing-page');
  if (onCheckout.value) classList.push('on-checkout-view');
  if (auth.isLoggingOut) classList.push('signing-out');

  return classList;
});

const pageClassList = computed(() => {
  return onCheckout.value
    ? ['state-checkout']
    : route.value.name
    ? [`state-${route.value.name}`]
    : [];
});

const busyOverlay = computed(() => {
  let message = '';

  if (auth.isLoggingIn) {
    message = 'Logging in';
  } else if (auth.isLoggingOut) {
    message = 'Logging out';
  } else if (auth.isLinkingAccount) {
    message = 'Linking account';
  }

  return message ? { message } : null;
});

// Active app route name watcher.
watch(
  () => route.value.name,
  (name) => {
    setRootAttribute('route', name ?? null);

    if (!store.state.initialNavigationCompleted) {
      setInitialNavigation();
    }
  },
);

// Active app theme watcher.
watchStore(
  (state) => state.theme,
  (value) => setRootAttribute('theme', value),
  { immediate: true },
);

// Active dashboard mode watcher.
watchStore(
  (state) => state.dashboardMode,
  (value) => setRootAttribute('dashboard-mode', value),
  { immediate: true },
);

// Active app display mode watcher.
watchStore(
  (state) => state.displayMode,
  (mode) => setRootAttribute('display-mode', mode),
  { immediate: true },
);

// Active app user watcher.
watchStore(
  (state) => state.me.id,
  (userId) => {
    if (userId) {
      handleUserLogin();
    } else {
      handleUserLogout();
    }
  },
  { immediate: true },
);

/**
 * ...
 */
function handleUserLogin() {
  // ...
  void getLauncherLinks();
  // ...
  void listAnnouncements();

  // ...
  if (refreshUserDataIntervalId.value) return;

  refreshUserDataIntervalId.value = window.setInterval(
    () => void listAnnouncements(),
    300_000, // 5 minutes.
  );
}

/**
 * ...
 */
function handleUserLogout() {
  if (!refreshUserDataIntervalId.value) return;

  clearInterval(refreshUserDataIntervalId.value);

  refreshUserDataIntervalId.value = null;
}

// ...
setWindowSize();

// ...
window.addEventListener('resize', () => setWindowSize());

// Set the "data-browser" root attribute for browser-based styling.
setRootAttribute('browser', deviceInfo.browser);

if (!auth.initialized) void auth.init();

//#region Helper Functions

/**
 * ...
 *
 * @param name ...
 * @param value ...
 */
function setRootAttribute(name: string, value: string | null) {
  const qualifiedName = `data-${name}`;

  if (value !== null) {
    document.documentElement.setAttribute(qualifiedName, value);
  } else {
    document.documentElement.removeAttribute(qualifiedName);
  }
}

//#endregion Helper Functions

//#region Event Listeners

/**
 * A list of callback function for handling site-wide click events. Functions
 * added to the list can execute conditionally based on the clicked element.
 */
function onAppClicked({ target }: MouseEvent) {
  if (target) {
    onClickListeners.value.forEach((item) => item(target as HTMLElement));
  }
}

//#endregion Event Listeners
</script>

<template>
  <div id="app" :class="rootClassList">
    <AppHeader />

    <div class="content" @click="onAppClicked">
      <Transition>
        <RouterView :class="['view-wrapper', ...pageClassList]" />
      </Transition>
    </div>

    <AppFooter />

    <ModalRoot />

    <NotificationsRoot />

    <PrivacyPolicyUpdateNotice />

    <AppCookiesNotice />

    <Overlay v-if="busyOverlay" class="busy-overlay" fullscreen>
      <Spinner class="mr-4" size="3" />
      <span class="h2">{{ busyOverlay.message }}</span>
    </Overlay>

    <KeyboardShortcutsUtil />
  </div>
</template>

<style scoped lang="scss">
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.content {
  flex: 1 0 auto;
}

.view-wrapper {
  opacity: 1;
  transition: 0.1s;
  min-height: 100vh;

  @include v-from {
    opacity: 0;
  }

  // &.state-checkout {
  //   position: relative;
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 1000;
  //   margin-top: -75px;
  //   margin-top: 0;
  //   box-shadow: 0px 60px 180px #00000026;
  //   // transform: translateX(0);
  //   transition: transform 0.5s cubic-bezier(0.42, 0.01, 0.25, 1.01);

  //   @include app-theme-light {
  //     color: #667381;
  //     background-color: #f8f9fe;
  //   }

  //   @include app-theme-dark {
  //     // color: #a7a7a7;
  //     background-color: var(--body-bg);
  //   }

  //   &.v-enter,
  //   &.v-enter-active {
  //     transform: translateX(0);
  //   }

  //   &.v-enter,
  //   &.v-leave-active {
  //     transform: translateX(100vw);
  //   }
  // }

  // #app.on-checkout-view &:not(.state-checkout) {
  //   transition: 1s;

  //   .v-leave-active {
  //     opacity: 0.5;
  //   }
  // }
}

.busy-overlay {
  z-index: 999999;
  pointer-events: none;
  // transition-duration: 0.1s;

  @include app-theme-light {
    background: #b7b7b7f2;
  }

  @include app-theme-dark {
    background: #212530f2;
  }
}
</style>
