<script lang="ts">
import { ref, computed, watch } from 'vue';
import type { StyleValue } from 'vue/types/jsx';
import uniqueId from 'lodash/uniqueId';

declare module 'vue/types/vue' {
  export interface Vue {
    Overlay: ComponentWithProps<Props>;
  }
}

// Disabled scrolling when at lease one fullscreen overlay is displayed.
//
// TODO: Determine whether not this is an OK practice -- using a non-setup
// scripts to track global values internally to a component.

const fullscreenOverlays = ref<string[]>([]);

watch(fullscreenOverlays, (ids) => {
  document.body.classList.toggle('fullscreen-overlay', ids.length > 0);
});

export default { name: 'Overlay' };
</script>

<script setup lang="ts">
/**
 * `Overlay` component properties.
 */
export interface Props {
  /** ... */
  color?: string;
  /** ... */
  blur?: string;
  /** Background color of the overlay. */
  // eslint-disable-next-line @typescript-eslint/ban-types
  background?: 'none' | (string & {});
  /** ... */
  fullscreen?: boolean;
  /** ... */
  transition?: string | number;
}

/** ... */
const uid = uniqueId();

const props = defineProps<Props>();

/** ... */
const style = computed(() => {
  const style: StyleValue = {};

  if (props.color) {
    style.color = props.color;
  }

  if (props.blur) {
    style.backdropFilter = `blur(${props.blur})`;
  }

  if (props.background) {
    style.background =
      props.background === 'none' ? 'transparent' : props.background;
  }

  if (props.fullscreen) {
    style.position = 'fixed';
    style.inset = '0';
  }

  if (props.transition) {
    style.transition = `opacity ${props.transition}s`;
  }

  return style;
});

function onBeforeEnter() {
  if (props.fullscreen) {
    fullscreenOverlays.value = [...fullscreenOverlays.value, uid];
  }
}

function onBeforeLeave() {
  if (props.fullscreen) {
    fullscreenOverlays.value = fullscreenOverlays.value.filter(
      (id) => id !== uid,
    );
  }
}
</script>

<template>
  <Transition
    appear
    @before-enter="onBeforeEnter"
    @before-leave="onBeforeLeave"
  >
    <div class="overlay" v-bind="{ style }">
      <slot />
    </div>
  </Transition>
</template>

<style lang="scss">
body.fullscreen-overlay .view-wrapper {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b7b7b7d9;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.5s;
  // backdrop-filter: blur(10px);

  @include app-mobile {
    flex-direction: column;
    font-size: 1.5em;
    text-align: center;
  }

  @include app-theme-dark {
    background: #222630d9;
  }

  &.v-enter,
  &.v-leave-active {
    opacity: 0;
  }

  &.v-leave-active {
    pointer-events: none;
  }
}
</style>
