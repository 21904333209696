<template>
  <div :class="['zephyr-logo', { animate }]" v-bind="{ style }">
    <!--  -->
    <svg
      id="logo"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 295.4 80.8"
      :style="svgStyle"
      xml:space="preserve"
    >
      <defs>
        <linearGradient
          :id="gradientId"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            v-for="{ key, color, offset } in gradient"
            :key="key"
            :offset="`${offset}%`"
            :stop-color="color"
          />
        </linearGradient>
      </defs>

      <!--  -->
      <g id="logo-body" :fill="`url(#${gradientId})`">
        <g class="logo-text">
          <path
            id="logo-r"
            d="M273.4,32.6c0.3-1.2,1-2.3,1.9-3.1c1.1-0.9,2.4-1.4,3.8-1.7c2.1-0.4,4.2-0.6,6.3-0.5h5.4l-2,4h-3.1
    c-1.3,0-2.7,0.1-4,0.2c-0.9,0.1-1.7,0.4-2.5,0.9c-0.6,0.5-1.1,1.1-1.2,1.9c-0.3,1-0.4,2.1-0.3,3.1v20.4h-4.8V37.5
    C272.9,35.9,273.1,34.2,273.4,32.6z"
          />

          <path
            id="logo-y"
            d="M264,27.3h5.7l-11.4,32.3c-0.5,1.5-1.1,3-1.6,4.3c-0.5,1.2-1.2,2.4-2,3.4s-1.7,1.7-2.9,2.3
    c-1.4,0.6-2.9,0.9-4.4,0.8v-3.7c1,0,2-0.3,2.8-0.9c0.7-0.6,1.3-1.4,1.8-2.3c0.5-0.9,0.8-1.9,1.1-2.9s0.5-2,0.7-2.9l-10.6-30.4h5.8
    l7.3,24.2l2.7-9.2L264,27.3z"
          />

          <path
            id="logo-h"
            d="M240.8,57.9H236v-21c0-0.9-0.1-1.9-0.4-2.8c-0.3-0.8-0.7-1.4-1.4-1.9c-0.8-0.6-1.7-1-2.6-1.1
    c-1.4-0.3-2.7-0.4-4.1-0.4h-4.3v27.2h-4.8v-43h4.8v11.8h4.4c2.2,0,4.3,0.2,6.4,0.6c1.5,0.3,2.9,1,4.1,1.9c1,0.8,1.7,1.9,2.1,3.1
    c0.4,1.4,0.6,2.9,0.6,4.4V57.9z"
          />

          <path
            id="logo-p"
            d="M194.2,28.5c3-1.1,6.1-1.7,9.3-1.8c3.3,0,5.9,0.9,7.8,2.6c1.8,1.8,2.8,4.4,2.8,7.8v8.3c0,1.7-0.1,3.4-0.3,5.2
    c-0.2,1.5-0.7,2.9-1.5,4.1c-0.8,1.2-2,2.2-3.4,2.7c-1.9,0.7-4,1.1-6.1,1c-1.2,0-2.5-0.1-3.7-0.3c-1-0.2-1.9-0.5-2.8-0.9v13h-4.8
    V29.5L194.2,28.5z M202.7,54.5c1.3,0.1,2.5-0.2,3.7-0.7c0.8-0.4,1.5-1.1,1.9-1.9C208.7,51,209,50,209,49c0.1-1.1,0.1-2.3,0.1-3.6
    v-8.2c0.1-1.8-0.5-3.5-1.6-4.9c-1.1-1.2-2.7-1.9-5-1.9c-2.1,0.1-4.2,0.5-6.2,1.2v15.5c0,1,0.1,1.9,0.2,2.9c0.1,0.8,0.4,1.6,0.8,2.3
    c0.5,0.7,1.2,1.3,2,1.6C200.4,54.3,201.6,54.5,202.7,54.5L202.7,54.5z"
          />

          <path
            id="logo-e"
            d="M169.8,43.8v1.5c0,1.3,0,2.5,0.1,3.6c0.1,1,0.3,2,0.7,2.8c0.4,0.9,1,1.6,1.9,2c1.2,0.5,2.5,0.7,3.7,0.7
    c2.4,0,4.1-0.5,5-1.5c1-1.2,1.5-2.7,1.4-4.2h1h1.3c0.4,0,0.9,0,1.4,0h1.2c0,1.3-0.2,2.7-0.5,4c-0.3,1.2-0.9,2.2-1.8,3.1
    c-1,0.9-2.2,1.6-3.4,2c-1.8,0.5-3.6,0.8-5.5,0.7c-2.1,0.1-4.1-0.3-6.1-1c-1.4-0.6-2.5-1.5-3.4-2.7c-0.8-1.2-1.3-2.6-1.5-4.1
    c-0.2-1.7-0.3-3.5-0.3-5.2v-5.7c0-1.7,0.1-3.5,0.3-5.2c0.2-1.5,0.7-2.9,1.5-4.2c0.8-1.2,2-2.2,3.4-2.8c1.9-0.7,4-1.1,6.1-1
    c2-0.1,3.9,0.3,5.8,1c1.4,0.6,2.6,1.5,3.4,2.7c0.8,1.3,1.4,2.7,1.6,4.2c0.3,1.8,0.4,3.6,0.4,5.5v3.8
    C187.5,43.8,169.8,43.8,169.8,43.8z M176.2,30.6c-1.3-0.1-2.6,0.2-3.7,0.7c-0.8,0.4-1.5,1.1-1.9,1.9c-0.4,0.9-0.7,1.8-0.7,2.8
    c-0.1,1.1-0.1,2.2-0.1,3.4V40h12.9c0-1.4-0.1-2.8-0.2-4.1c-0.1-1-0.4-2-0.9-2.9c-0.4-0.8-1.1-1.4-1.9-1.7
    C178.6,30.8,177.4,30.6,176.2,30.6L176.2,30.6z"
          />

          <path
            id="logo-z"
            d="M152.5,14.9h3.9c1.2-0.1,2.5,0.3,3.5,1c0.9,0.7,1.3,1.8,1.3,2.9c0,1-0.3,2.1-0.7,3c-0.6,1.3-1.3,2.6-2.2,3.8
    L139,52.5h20.5l2.2,5.4h-23.9c-1.2,0.1-2.3-0.4-3.1-1.2c-0.8-0.9-1.1-2-1.1-3.2c0-1.1,0.2-2.1,0.6-3.1c0.5-1.2,1.2-2.4,2.1-3.4
    l18.9-26.6h-19.5l-2.2-5.4h19V14.9z"
          />
        </g>

        <!--  -->

        <g class="logo-symbol">
          <path
            id="logo-base"
            d="M66.7,10.7c-17.6,0-31.9,14.3-31.9,31.9s14.3,31.9,31.9,31.9s31.9-14.3,31.9-31.9l0,0
    C98.6,25,84.3,10.7,66.7,10.7L66.7,10.7z M75.9,46.7L75.9,46.7c0.3-0.1,0.5-0.2,0.8-0.2l0.2-0.1l0.6-0.2l0.3-0.1
    c0.2,0,0.4-0.1,0.6-0.1h0.5h0.3h0.4c5.1,0,9.3,4.2,9.3,9.3c0,3.1-1.5,5.9-4.1,7.7c-1.5,1-3.3,1.6-5.2,1.6c-5.1,0-9.3-4.2-9.3-9.3
    c0-0.8,0.1-1.5,0.3-2.2V53c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c-1.6-1.2-3.5-2.3-4.1-2.3l0,0
    c-0.6,0-2.4,1.1-3.8,2.3c1.7,4.1,0.3,8.8-3.3,11.3c-4.3,2.9-10,1.8-12.9-2.5s-1.8-10,2.5-12.9c1.5-1,3.4-1.6,5.2-1.6H55h0.2
    c0.6,0,1.1,0.1,1.6,0.3l0,0c0.5,0.1,1,0.3,1.4,0.5c1.2-1.6,2.3-3.5,2.3-4.1l0,0c0-0.6-1.1-2.4-2.3-3.9C53.5,40.9,48,38.7,45.9,34
    s0.1-10.2,4.8-12.3S61,21.6,63,26.4c1,2.4,1,5.1,0,7.4c1.5,1.2,3.2,2.3,3.8,2.3l0,0c0.7,0,2.5-1.1,4.1-2.3v-0.2l-0.1-0.3
    c-0.1-0.2-0.2-0.5-0.2-0.7l-0.1-0.2c-0.2-0.7-0.3-1.5-0.3-2.2c0-5.1,4.2-9.3,9.3-9.3c5.1,0,9.3,4.2,9.3,9.3s-4.2,9.3-9.3,9.3
    c-0.7,0-1.5-0.1-2.2-0.3l-0.2-0.1l-0.7-0.2l-0.3-0.1l-0.2-0.1c-1.2,1.5-2.3,3.2-2.3,3.9l0,0C73.6,43.2,74.7,45.1,75.9,46.7
    L75.9,46.7z"
          />
          <path
            id="logo-rotor-br"
            d="M79.6,62.6c4.1,0,7.5-3.3,7.5-7.5s-3.3-7.5-7.5-7.5c-4.1,0-7.5,3.3-7.5,7.5l0,0
    C72.1,59.3,75.4,62.6,79.6,62.6C79.5,62.6,79.6,62.6,79.6,62.6z"
          />
          <path
            id="logo-rotor-bl"
            d="M54.5,47.7c-4.1,0-7.4,3.4-7.4,7.5s3.4,7.4,7.5,7.4s7.4-3.4,7.4-7.5S58.7,47.7,54.5,47.7
    C54.6,47.7,54.5,47.7,54.5,47.7z"
          />
          <path
            id="logo-rotor-tr"
            d="M79.6,37.6c4.1,0,7.4-3.3,7.4-7.4s-3.3-7.4-7.4-7.4s-7.4,3.3-7.4,7.4c0,2,0.8,3.9,2.2,5.3
    C75.7,36.8,77.6,37.6,79.6,37.6z"
          />
          <path
            id="logo-rotor-tl"
            d="M59.8,24.8c-2.9-2.9-7.6-2.9-10.5,0.1s-2.9,7.6,0.1,10.5s7.6,2.9,10.5-0.1
    c1.4-1.4,2.2-3.3,2.2-5.3C62,28.1,61.2,26.2,59.8,24.8z"
          />
          <path
            id="logo-drone-body"
            d="M74.3,37.7c-0.3-0.2-0.5-0.4-0.8-0.6L73.4,37l-0.2-0.2L73,36.7l0,0c-0.4-0.4-0.7-0.8-1-1.2
    c-1.4,1-3.7,2.5-5,2.5l0,0c-1.3,0-3.4-1.5-4.7-2.5c-0.6,0.9-1.4,1.7-2.3,2.3c1,1.3,2.5,3.4,2.5,4.8l0,0c0,1.4-1.5,3.7-2.5,5
    c0.9,0.6,1.7,1.4,2.3,2.3c1.3-1,3.4-2.5,4.7-2.5l0,0c1.4,0,3.7,1.5,5,2.5c0.6-0.9,1.4-1.7,2.3-2.3c-1-1.4-2.5-3.7-2.5-5l0,0
    C71.8,41.2,73.3,39,74.3,37.7z"
          />
          <path
            id="logo-outer-ring"
            d="M66.7,78.4c19.8,0,35.9-16.1,35.9-35.9S86.5,6.6,66.7,6.6S30.8,22.7,30.8,42.5l0,0
    C30.9,62.4,46.9,78.4,66.7,78.4z M66.7,8.4c18.9,0,34.2,15.3,34.2,34.2S85.6,76.8,66.7,76.8S32.5,61.5,32.5,42.6l0,0
    C32.5,23.7,47.8,8.4,66.7,8.4z"
          />
          <path
            id="logo-right-wing"
            d="M133.4,0l-30.9,18.4l0,0l-3.9,2.4l0.5,0.8l0.6,1l0.7,1.2c0.5,0.9,1.1,2.1,1.7,3.5c0.3,0.7,0.6,1.4,0.9,2.2
    s0.6,1.6,0.8,2.5c0.5,1.9,0.9,3.9,1.1,5.9c1.3,12.4-3.4,24.6-12.7,32.9c-1.7,1.6-3.6,2.9-5.6,4.2c-1.8,1.1-3.6,2-5.6,2.9
    c-2.9,1.2-5.9,2.1-9,2.6c-1.1,0.2-2,0.3-2.6,0.3l-0.9,0.1h0.9c0.6,0,1.5-0.1,2.7-0.2c13.1-1.4,24.7-9.1,31.1-20.6
    c2.3-4.1,3.8-8.5,4.6-13.2h5.9l3.5-8.4h-8.8c0-0.3,0-0.5,0-0.8c0-0.5-0.1-1-0.1-1.4l10.4-1.3l5.4-12.8l-17.3,6.3c0-0.1,0-0.1,0-0.2
    c-0.2-0.8-0.5-1.6-0.8-2.3l20-8.5L133.4,0z"
          />
          <path
            id="logo-left-wing"
            d="M0,0l30.9,18.4l0,0l4,2.4l-0.5,0.8l-0.6,1L33,23.7c-0.6,1.1-1.2,2.3-1.7,3.5c-0.3,0.7-0.6,1.4-0.9,2.2
    s-0.6,1.6-0.8,2.5c-0.5,1.9-0.9,3.9-1.1,5.9c-0.2,2.2-0.3,4.5-0.1,6.8c0.7,10,5.3,19.4,12.8,26.1c1.7,1.6,3.6,2.9,5.6,4.2
    c1.8,1.1,3.6,2,5.5,2.9c2.9,1.2,5.9,2.1,9,2.6c1.1,0.2,2,0.3,2.6,0.3l0.9,0.1h-0.9c-0.6,0-1.5-0.1-2.7-0.2
    c-5.3-0.5-10.4-2.1-15.1-4.7c-2.1-1.1-4.1-2.5-6-4c-2-1.6-3.9-3.4-5.5-5.4c-4.8-5.6-7.9-12.4-9.2-19.7h-5.9L16,38.4h9
    c0-0.3,0-0.5,0-0.8c0-0.5,0.1-1,0.1-1.4l-10.5-1.3L9.3,22.1l17.3,6.3c0-0.1,0-0.1,0-0.2c0.3-0.8,0.5-1.6,0.8-2.3l-20-8.5L0,0z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { StyleValue } from 'vue/types/jsx';
import { uniqueId } from 'lodash';

import { isString, isNotNullish } from '@tools/type-guards';

/**
 * ...
 */
export interface DropShadow {
  size: number;
  xOffset?: number;
  yOffset?: number;
  color?: string;
}

/**
 * `ZephyrLogo` component properties.
 */
export interface Props {
  /** ... */
  width?: string | number | null;
  /** ... */
  colors?: string | string[] | null;
  /** ... */
  shadow?: DropShadow | DropShadow[] | null;
  /** ... */
  animate?: boolean;
}

/** Default gradient of the Zephyr logo. */
const DEFAULT_LOGO_GRADIENT = ['#FBAF40', '#F15A29'];

const props = withDefaults(defineProps<Props>(), {
  width: null,
  colors: null,
  shadow: null,
  animate: false,
});

/** ... */
const id = uniqueId();

/** ... */
const gradientId = computed(() => {
  return `logo-${id}-gradient-overlay`;
});

/** ... */
const style = computed(() => {
  const style: StyleValue = {};

  if (isNotNullish(props.width)) {
    style.width = isString(props.width) ? props.width : `${props.width}px`;
  }

  return style;
});

/** ... */
const svgStyle = computed(() => {
  const style: StyleValue = {};

  // ...
  if (props.shadow) {
    style.filter = createDropShadowFilterStyle(props.shadow);
  }

  return style;
});

/** ... */
const gradient = computed(() => {
  const colors = !props.colors
    ? DEFAULT_LOGO_GRADIENT
    : Array.isArray(props.colors)
    ? props.colors
    : [props.colors];

  return colors.map((color, index) => ({
    key: index,
    color,
    offset: Math.round(100 * ((index === 0 ? 0 : index + 1) / colors.length)),
  }));
});

//#region Helper Functions

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
function createDropShadowFilterStyle(options: DropShadow | DropShadow[]) {
  const shadows = Array.isArray(options) ? options : [options];

  return shadows
    .map(({ xOffset, yOffset, size, color }) => {
      const values: (string | number)[] = [];

      values.push((xOffset ?? 0).toString() + 'px');
      values.push((yOffset ?? 0).toString() + 'px');
      values.push(size.toString() + 'px');
      values.push(color ?? 'black');

      return `drop-shadow(${values.join(' ')})`;
    })
    .join(' ');
}

//#endregion Helper Functions
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ZephyrLogo: ComponentWithProps<Props>;
  }
}

export default { name: 'ZephyrLogo' };
</script>

<style lang="scss" scoped>
@keyframes logoPop {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes logoLetter {
  0% {
    opacity: 0;
    // transform: scale(0.5);
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    // transform: scale(1);
    transform: translateY(0);
  }
}

@keyframes logoLeftWing {
  0% {
    opacity: 0;
    // transform: scale(0.5) rotateZ(-20deg);
    transform: rotateZ(-40deg);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes logoRightWing {
  0% {
    opacity: 0;
    // transform: scale(0.5) rotateZ(20deg);
    transform: rotateZ(40deg);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.zephyr-logo {
  display: inline-block;

  &.animate {
    .logo-text path {
      animation-name: logoLetter;
    }

    .logo-symbol path {
      animation-name: logoPop;
    }

    #logo-z {
      animation-delay: 0.75s;
    }

    #logo-e {
      animation-delay: 0.85s;
    }

    #logo-p {
      animation-delay: 0.95s;
    }

    #logo-h {
      animation-delay: 1.05s;
    }

    #logo-y {
      animation-delay: 1.15s;
    }

    #logo-r {
      animation-delay: 1.25s;
    }

    #logo-rotor-tr {
      animation-delay: 0s;
    }

    #logo-rotor-br {
      animation-delay: 0.1s;
    }

    #logo-rotor-bl {
      animation-delay: 0.2s;
    }

    #logo-rotor-tl {
      animation-delay: 0.3s;
    }

    #logo-drone-body {
      animation-delay: 0.4s;
    }

    #logo-base {
      animation-delay: 0.5s;
    }

    #logo-outer-ring {
      animation-delay: 0.55s;
    }

    #logo-right-wing {
      transform-origin: 0% 100%;
      animation-name: logoRightWing;
      animation-duration: 0.75s;
      animation-delay: 0.5s;
    }

    #logo-left-wing {
      transform-origin: 100% 100%;
      animation-name: logoLeftWing;
      animation-duration: 0.75s;
      animation-delay: 0.5s;
      //
      // animation-duration: 2s;
      // animation-iteration-count: infinite;
    }
  }
}

svg {
  width: 100%;
}

path {
  transform-box: fill-box;
  transform-origin: center;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
</style>
