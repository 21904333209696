<script lang="ts">
import { computed, defineComponent } from 'vue';
import { isValidPhoneNumber } from 'libphonenumber-js';
import type { BaseValidation } from '@vuelidate/core';

import { isString, isNotNullish } from '@tools/type-guards';

import FormPhone, {
  type Props as FormPhoneProps,
} from '@components/Form/FormPhone.vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormFieldPhone: ComponentWithProps<FormPhoneProps>;
  }
}

/**
 * ...
 */
interface FieldError {
  key: string;
  message: string;
}

export default defineComponent({ name: 'FormFieldPhone' });
</script>

<script setup lang="ts">
/** ... */
export type Field = BaseValidation<string | null>;

/**
 * `FormFieldPhone` component properties.
 */
export interface Props extends FormPhoneProps {
  field: Field;
  validate?: Nullable<boolean | 'valid-only'>;
}

const props = defineProps<Props>();

/** ... */
const hasValue = computed(() => isNotNullish(props.field.$model));

/** ... */
const state = computed(() => {
  // ...
  if (!props.validate || (!hasValue.value && !props.field.$dirty)) {
    return null;
  }

  // if (!props.field.$invalid) return true;
  if (isString(props.field.$model) && isValidPhoneNumber(props.field.$model)) {
    return true;
  }

  return props.validate === 'valid-only' ? null : false;
});

/** ... */
const errors = computed(() => {
  return props.field.$errors.map(({ $validator, $message }) => ({
    key: $validator,
    message: isString($message) ? $message : $message.value,
  })) as FieldError[];
});

/** ... */
const errorMessage = computed(() => {
  return errors.value[0]?.message ?? null;
});

/** ... */
const showErrorMessage = computed(() => {
  return props.validate === true && !!errorMessage.value;
});
</script>

<template>
  <div class="form-field-phone">
    <!-- eslint-disable vue/no-mutating-props -->

    <FormPhone
      v-model="field.$model"
      v-bind="{ ...$attrs, state }"
      v-on="$listeners"
    />

    <!-- eslint-enable vue/no-mutating-props -->

    <b-form-invalid-feedback v-if="showErrorMessage" :state="false">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </div>
</template>
