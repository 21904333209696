<script lang="ts">
import { ref, computed, watch } from 'vue';
import sortBy from 'lodash/sortBy';

import { api } from '@api';
import { Report } from '@models';
import { formatReportDistance, formatReportSpeed } from '@utils/reports';

// import RadialProgressBar from 'vue-radial-progress';
import RadialProgressBar from '@components/RadialProgressBar';
import CardLoadable from '@components/Cards/CardLoadable.vue';
import DisplayTextDate from '@components/Display/DisplayTextDate.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    CardMostRecentFlightInfo: ComponentWithProps<Props>;
  }
}

export default { name: 'CardMostRecentFlightInfo' };
</script>

<script setup lang="ts">
/**
 * `CardMostRecentFlightInfo` component properties.
 */
export interface Props {
  loading: boolean;
  reports: Report[];
}

const props = defineProps<Props>();

// loading = true;
const loadingReport = ref(true);
const report = ref<Report | null>(null);

watch(
  () => props.reports,
  (reports) => {
    report.value = null;

    const reportId = sortBy(reports, ['createdAt']).pop()?.id;

    if (reportId) {
      void loadReports(reportId);
    } else {
      loadingReport.value = false;
    }
  },
  { immediate: true },
);

/** ... */
const topSpeedDisplay = computed(() => {
  return report.value ? formatReportSpeed(report.value.topSpeed) : null;
});

/** ... */
const averageSpeedDisplay = computed(() => {
  return report.value ? formatReportSpeed(report.value.averageSpeed) : null;
});

/** ... */
const maxAltitudeDisplay = computed(() => {
  return report.value ? formatReportDistance(report.value.maxAltitude) : null;
});

/** ... */
const averageAltitudeDisplay = computed(() => {
  return report.value
    ? formatReportDistance(report.value.averageAltitude)
    : null;
});

/** ... */
const items = computed(() => {
  if (!report.value) return [];

  return [
    {
      key: 'topSpeed',
      label: 'Top Speed',
      value: topSpeedDisplay.value,
    },
    {
      key: 'averageSpeed',
      label: 'Average Speed',
      value: averageSpeedDisplay.value,
    },
    {
      key: 'maxAltitude',
      label: 'Max Altitude',
      value: maxAltitudeDisplay.value,
    },
    {
      key: 'averageAltitude',
      label: 'Average Altitude',
      value: averageAltitudeDisplay.value,
    },
  ];
});

/** ... */
const displayModuleName = computed(() => {
  return report.value?.module?.name ?? '--';
});

/** ... */
const displaySceneName = computed(() => {
  return report.value?.scene?.name ?? '--';
});

/**
 * ...
 *
 * @param reportId ...
 */
async function loadReports(reportId: Report['id']) {
  loadingReport.value = true;

  try {
    report.value = await api.reports.get({ reportId });
  } catch {
    //
  }

  loadingReport.value = false;
}
</script>

<template>
  <CardLoadable :loading="loading || loadingReport" no-body>
    <b-card-body>
      <!--  -->

      <b-row class="align-items-center">
        <b-col>
          <div class="h6 mb-4">Most Recent Flight</div>
        </b-col>
      </b-row>

      <b-row v-if="report" class="align-items-center">
        <b-col>
          <div class="h5">
            <span>{{ displayModuleName }}</span>
            <span class="text-muted mx-1">on</span>
            <span>{{ displaySceneName }}</span>
          </div>

          <div class="stat mb-1">
            <span>Completed:</span>
            <DisplayTextDate
              class="text-muted"
              :value="report.moduleStartTime"
              format="MM/dd/yyyy"
            />
          </div>

          <div class="stat mb-1">
            <span>Top speed:</span>
            <span class="text-muted">{{ topSpeedDisplay }}</span>
          </div>

          <div class="stat mb-1">
            <span>Average speed:</span>
            <span class="text-muted">{{ averageSpeedDisplay }}</span>
          </div>

          <div class="stat mb-1">
            <span>Max Altitude:</span>
            <span class="text-muted">{{ maxAltitudeDisplay }}</span>
          </div>

          <div class="stat mb-1">
            <span>Average Altitude:</span>
            <span class="text-muted">{{ averageAltitudeDisplay }}</span>
          </div>
        </b-col>

        <b-col cols="auto">
          <div class="score-panel">
            <RadialProgressBar
              :diameter="180"
              :completed-steps="report.score"
              :total-steps="report.possibleScore"
              start-color="#f35c23"
              stop-color="#f35c23"
              inner-stroke-color="#ffffff29"
              :inner-stroke-width="8"
              :animate-speed="1000"
            />
            <span class="score-breakdown">
              <!-- <span class="score-numerator">{{ points.scored }}</span>
            <span class="score-divider"></span>
            <span class="score-denominator">{{ points.total }}</span> -->
              <span class="score-numerator">{{ report.score }}</span>
              <small>out of {{ report.possibleScore }} point(s)</small>
            </span>
            <!-- <span class="grade-indicator">{{ report.grade }}</span> -->
          </div>
          <!-- <div
          class="score-info d-flex justify-content-center align-items-center w-100"
        >
          <RadialProgressBar
            :diameter="150"
            :completed-steps="report.score || 200"
            :total-steps="report.possibleScore"
            start-color="white"
            stop-color="white"
            inner-stroke-color="#ffffff29"
            :inner-stroke-width="5"
          />
          <span class="h2 mb-0 mr-4">
            <span class="score-numerator">{{ report.score }}</span>
            <span class="text-muted">/</span>
            <span class="score-denominator">{{ report.possibleScore }}</span>
          </span>
          <span class="grade-indicator">{{ report.grade }}</span>
        </div> -->
        </b-col>
      </b-row>

      <b-row v-else class="align-items-center">
        <b-col>
          <div class="h1 mb-0">N/A</div>
        </b-col>
      </b-row>

      <!-- <hr /> -->
    </b-card-body>

    <b-card-body v-if="false" class="additional-info">
      <b-row>
        <b-col>
          <div
            v-for="{ key, label, value } in items"
            :key="key"
            class="px-0 py-1 d-flex justify-content-between align-items-center"
          >
            <strong class="mr-2">{{ label }}</strong>
            <span>{{ value }}</span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </CardLoadable>
</template>

<style scoped lang="scss">
.h1,
.h5,
.h6 {
  color: white;
}

.card {
  font-size: 0.8rem;
  // color: white;
  // background: linear-gradient(#511692, #2b6392) !important;
  // background: linear-gradient(45deg, #401692, #004b8a) !important;
  // background: linear-gradient(360deg, #56b7e4, #56b7e4, #0049ce) !important;
  // background: linear-gradient(50deg, #ffb835, #e47b21, #ef4e4e) !important;
  // background: linear-gradient(180deg, #e68a00, #f35c23) !important;
  border: none;
  overflow: hidden;
}

hr {
  background: currentColor;
  opacity: 0.25;
}

.card-header {
  background: transparent;
  border: none;
}

.card-body {
  padding: 3rem;
  background: linear-gradient(45deg, #ff521e, #ffaa61);
  color: #fff;
}

.score-numerator {
  display: inline-block;
  font-size: 2.5rem;
  line-height: 1em;
  // transform: translate(-1.5rem, 0);
}

.score-denominator {
  display: inline-block;
  font-size: 1.2rem;
  // transform: translate(2rem, 0);
}

.stat {
  font-size: 0.8rem;
}

.score-divider {
  display: inline-block;
  margin: 0.3rem 0;
  width: 100%;
  height: 1px;
  border-radius: 6px;
  background: white;
  opacity: 0.75;
  // transform: rotateZ(-15deg);
}

.grade-indicator {
  font-size: 2rem;
  width: 1.8em;
  height: 1.8em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // font-size: 5em;
  font-weight: 500;
  border-radius: 100%;
  background: #ffffff30;
  color: #fff;

  &.grade-f {
    background: #f56767bd;
    color: #672828;
  }
}

.additional-info {
  // background: #ffffff12;
  // background: #ffffff47;
  // color: #1c6bb1;
  background: #2f00225e;
  // backdrop-filter: blur(10px);
}

.score-panel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-0.5rem);

  .grade-indicator {
    position: absolute;
  }
}

.score-breakdown {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // transform: translateY(0.3rem);
}
</style>
