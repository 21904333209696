<script lang="ts">
import { ref, computed } from 'vue';

import { useStore } from '@store';

import DisplayPrice from '@components/Display/DisplayPrice.vue';
import Icon from '@components/Icon.vue';

import { faCheckCircle } from '@icons/regular/faCheckCircle';
import { faTimes } from '@icons/regular/faTimes';

declare module 'vue/types/vue' {
  export interface Vue {
    PackOptionCard: ComponentWithProps<Props>;
  }
}

/**
 * Option card pack feature info.
 */
export interface Feature {
  key: string;
  name: string;
  isIncluded: boolean;
}

/**
 * Option card pack info.
 */
export interface Pack {
  key: string;
  title: string;
  description: string;
  price: string | number;
  badge?: string;
  features: Feature[];
  oneTimePurchase?: boolean;
  personalUseOnly?: boolean;
}

export default { name: 'PackOptionCard' };
</script>

<script setup lang="ts">
/**
 * `PackOptionCard` component properties.
 */
export interface Props {
  index: number;
  pack: Pack;
}

/**
 * `PackOptionCard` component emits.
 */
export type Emits = (event: 'selected', key: string) => void;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const store = useStore();

const cartHasItem = store.getters['cart/hasItem'];

const entering = ref(false);

/** `true` if the option card's product is in the cart, otherwise `false`. */
const selected = computed(() => cartHasItem(props.pack.key));

/**
 * Listener to be called when the option card is selected.
 */
function onAfterEnter() {
  entering.value = false;
}
</script>

<template>
  <Transition appear @after-enter="onAfterEnter">
    <div
      :class="['pack-option-card', { entering, selected }]"
      :style="{ transitionDelay: `${0.03 * index}s` }"
    >
      <div class="card-content">
        <div v-if="pack.badge" class="pack-badge">
          <span>{{ pack.badge }}</span>
        </div>

        <h3 class="text-center">{{ pack.title }}</h3>

        <div class="text-center">{{ pack.description }}</div>

        <div class="pack-price">
          <DisplayPrice size="4rem" :price="pack.price" />
        </div>

        <p
          v-if="pack.personalUseOnly"
          class="disclaimer"
          :style="{ marginBottom: pack.oneTimePurchase ? '0' : '16px' }"
        >
          <i>Personal, Individual Use Only</i>
        </p>

        <p v-if="pack.oneTimePurchase" class="disclaimer">
          <i>One-time fee</i>
        </p>

        <ul class="pack-feature-list">
          <li
            v-for="feature in pack.features"
            :key="feature.key"
            :class="{ included: feature.isIncluded }"
          >
            <Icon :icon="feature.isIncluded ? faCheckCircle : faTimes" />
            <span>{{ feature.name }}</span>
          </li>
        </ul>

        <b-button
          class="option-select-btn"
          variant="primary"
          :disabled="selected"
          block
          @click="emit('selected', pack.key)"
        >
          {{ selected ? 'Selected' : 'Select' }}
        </b-button>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
// Transition for when card is entering.
$entering-transition: 0.5s;
// Transition for when card is selected.
// $selection-transition: 0.5s cubic-bezier(0, 3.66, 0.68, 0.9);
$selection-transition: 0.5s;
// Transition for when card is unselected.
$unselection-transition: 0.3s;

.pack-option-card {
  opacity: 1;
  transform: translateY(0);
  transition: $unselection-transition;

  &::before {
    content: '';
    position: absolute;
    border-radius: 14px;
    inset: 1px;
    background-color: #009aff;
    opacity: 0;
    z-index: 0;
    transition: $unselection-transition;
  }

  &.selected {
    transform: scale(1.02);
    transition: $selection-transition;
  }

  &.selected::before {
    inset: -8px;
    opacity: 1;
  }

  &.entering,
  &.entering::before {
    transition: $entering-transition;
  }

  @include v-from {
    opacity: 0;
    transform: translateY(10vh);
  }
}

.card-content {
  z-index: 1;
  border-radius: 10px;
  padding: 3.5rem 3rem;
  position: relative;
  box-shadow: var(--panel-box-shadow);

  .disclaimer {
    text-align: center;
  }

  @include app-theme-light {
    background-color: white;
  }

  @include app-theme-dark {
    background-color: #21252c;
  }
}

.pack-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 70px;
  height: 80px;
  background: linear-gradient(#fff800, #ffc200);
  text-align: center;
  padding: 10px;
  color: #5c0000;
  font-size: 0.9rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2em;
  font-weight: 500;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 80%, 0% 100%);

  > span {
    margin-top: -20%;
  }
}

.pack-price {
  color: #ff7400;
  font-size: 4rem;
  font-weight: 400;
  margin: 2rem 0;
  text-align: center;
}

.pack-feature-list {
  min-height: 200px;
  list-style: none;
  margin: 0 0 3rem;
  padding: 0;
  text-align: left;

  .fa-icon {
    margin-right: 1rem;
  }

  li {
    padding: 0.25rem 0;

    &:not(.included) {
      opacity: 0.5;
    }
  }
}

.option-select-btn {
  &[disabled],
  &[disabled='disabled'] {
    opacity: 1;
    background-color: #007bfe29 !important;
    border-color: transparent !important;
    color: #007bff !important;
    font-weight: 600;
  }
}
</style>
