<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellListItemsAsBadges: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellListItemsAsBadges` component properties.
 */
export interface Props {
  /** ... */
  value: unknown[];
}

const props = defineProps<Props>();

const items = computed(() => {
  return props.value.map((item) => ({ key: uniqueId(), item }));
});
</script>

<template>
  <div>
    <b-badge v-for="{ key, item } in items" :key="key" class="mx-1">{{
      item
    }}</b-badge>
  </div>
</template>
