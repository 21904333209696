<template>
  <b-card-group deck>
    <CardMetric v-for="{ key, ...card } in cards" v-bind="card" :key="key" />
  </b-card-group>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import { useGetter, Metrics } from '@store';
import { aggregateReports, metrics } from '@tools/simulator';
import { isNumber } from '@tools/type-guards';
import { scoreInfo } from '@values';

import CardMetric from './CardMetric.vue';

/**
 * `CardGroupMetrics` component properties.
 */
export interface Props {
  results: aggregateReports.Results | null;
  loading?: boolean;
}

/**
 * ...
 */
interface CardInfo {
  key: Metrics.Catogory;
  title: string;
  subtitle?: string;
  info: string;
}

/** ... */
const CARD_CONFIGS: CardInfo[] = [
  {
    key: 'reportsWithHighScore',
    title: 'Modules Completed',
    subtitle: '(Score 80% or Above)',
    info: scoreInfo.completed,
  },
  {
    key: 'reportsWithViolations',
    title: 'Violations',
    info: scoreInfo.violations,
  },
  {
    key: 'reportsWithCrashes',
    title: 'Crashes',
    info: scoreInfo.crashes,
  },
];

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  results: null,
});

/** ... */
const globalBounds = useGetter('metrics/bounds');

/** ... */
const cards = computed(() =>
  CARD_CONFIGS.map((card) => {
    const fraction = props.results?.[card.key].fraction;
    const bounds = globalBounds.value?.[card.key];

    return {
      ...card,
      loading: props.loading,
      ...computePercentAndRating(fraction, bounds),
    } as CardMetric.Props & { key: string };
  }),
);

//#region Helper Functions

/**
 * ...
 *
 * @param fraction ...
 * @param bounds ...
 * @return ...
 */
function computePercentAndRating(
  fraction: Nullable<aggregateReports.Tally['fraction']>,
  bounds: Nullable<metrics.Bounds>,
) {
  let percent: number | null = null;
  let rating: string | null = null;

  if (isNumber(fraction) && bounds) {
    percent = 100 * fraction;
    rating = metrics.rate(fraction, bounds);
  }

  return { percent, rating };
}

//#endregion Helper Functions
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardGroupMetrics: ComponentWithProps<Props>;
  }
}

export default { name: 'CardGroupMetrics' };
</script>
