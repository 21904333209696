<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    AddOnsRackCard: ComponentWithProps<Props>;
  }
}

export default { name: 'AddOnsRackCard' };
</script>

<script setup lang="ts">
import { computed } from 'vue';

import { modals } from '@modals';
import { alert } from '@services/alert';
import { useGetter, useCommit, Shop } from '@store';
import { ensureError } from '@tools/ensure-error';
import * as currencies from '@utils/currencies';

import DisplayPrice from '@components/Display/DisplayPrice.vue';

import { faInfo } from '@icons/solid/faInfo';

/**
 * `AddOnsRackCard` component properties.
 */
export interface Props {
  product: Shop.Item;
}

const props = defineProps<Props>();

const containsItemWithScenes = useGetter('cart/containsItemWithScenes');
const addItem = useCommit('cart/ADD_ITEM');

/** Product ID. */
const id = computed(() => {
  return props.product.id;
});

/** Product title. */
const title = computed(() => {
  return props.product.name;
});

/** Product description. */
const description = computed(() => {
  return props.product.description;
});

/** Product price. */
const price = computed(() => {
  // NOTE: Price currently assumed to be first `price` in the `prices` array
  // with a currency in USD.
  return currencies.getProductPrice(props.product);
});

/** Primary product image. */
const image = computed(() => {
  return props.product.images[0];
});

/** Whether or not the product can be added to the cart. */
const isAddable = computed(() => {
  return (
    // !props.product.owned &&
    !containsItemWithScenes.value(props.product.scenes ?? [])
  );
});

/** Is a certification. */
const isCertification = computed(() => {
  return !!props.product.tags?.some(
    (tag) => tag.toLowerCase() === 'certification',
  );
});

/** Whether or not there are any product tags to display.  */
const productTagsPresent = computed(() => {
  return (
    props.product.recommended || props.product.onSale || props.product.owned
  );
});

/**
 * Summon product details modal.
 */
function showDetails() {
  void modals.shop.productDetails({ productId: id.value });
}

/**
 * Add product to the shopping cart.
 */
function addToCart() {
  let error: ensureError.Error | null = null;

  try {
    addItem({ item: props.product });
  } catch (err) {
    error = ensureError(err);
  }

  if (!error) return;

  if (error['code'] === 'DUPLICATE_ITEM_SCENES') {
    return alert.warning(error.message);
  }

  if (error['code'] === 'DUPLICATE_ITEM_SCENES') {
    return alert.warning(error.message);
  }
}
</script>

<template>
  <b-card class="add-on-card" @click="showDetails">
    <b-card-text
      class="text-center d-flex justify-content-between align-items-center"
    >
      <DisplayPrice size="2.5rem" v-bind="{ price }" />

      <span>
        <b-button variant="secondary" @click.stop="showDetails">
          <Icon :icon="faInfo" />
        </b-button>

        <b-button
          variant="primary"
          :disabled="!isAddable"
          @click.stop="addToCart"
        >
          Add
        </b-button>
      </span>
    </b-card-text>

    <p v-if="price > 0 && !isCertification" class="disclaimer">
      <i>One-time fee</i>
    </p>

    <div
      class="add-on-image"
      :style="{ backgroundImage: `url('${image}')` }"
    ></div>

    <b-card-title class="text-center">
      {{ title }}
    </b-card-title>

    <b-card-text class="text-center">
      <span class="pre-wrap" v-html="description"></span>
    </b-card-text>

    <!-- #region Tags -->

    <div v-if="productTagsPresent" class="product-tags-container">
      <span v-if="product.recommended" class="recommended-tag">
        RECOMMENDED
      </span>

      <span v-if="product.onSale" class="sale-tag"> ON SALE! </span>

      <span v-if="product.owned" class="owned-tag"> OWNED </span>
    </div>

    <!-- #endregion Tags -->
  </b-card>
</template>

<style scoped lang="scss">
.add-on-card {
  height: 100%;
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.25s;
  cursor: default !important;

  &:hover {
    transform: scale(1.05);
  }

  .card-body {
    padding: 2rem;
  }

  .btn-secondary {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    font-size: 0.8rem;
    line-height: 0;
    margin: 0;
    text-align: center;
  }

  .disclaimer {
    font-size: 0.8em;
    margin-bottom: 0;
  }
}

.add-on-price {
  font-size: 2rem;
  color: #ff7400;
}

.add-on-image {
  // width: 100%;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #00000012;
  // margin-bottom: 1rem;
  margin: 0 -2rem 1rem;
}

.card-text {
  // max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.product-tags-container {
  grid-column-start: span 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;

  > span {
    margin: 0 5px;
  }
}

.owned-tag {
  position: absolute;
  top: 8px;
  right: -48px;
  left: auto;
  width: 150px;
  color: #f0f0f0;
  line-height: 50px;
  letter-spacing: 1px;
  text-align: center;
  background: #00ad8f;
  transform: rotate(45deg);
}

.sale-tag {
  padding: 5px 10px;
  border-radius: 5px;
  color: $dark-grey;
  background-color: $dark-orange;
  white-space: nowrap;

  @include box-shadow(0 1px 3px 0 $black);
}

.recommended-tag {
  padding: 5px 10px;
  border-radius: 5px;
  color: $dark-grey;
  background-color: $dark-orange;

  @include box-shadow(0 1px 3px 0 $black);
}
</style>
