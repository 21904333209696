<script setup lang="ts">
import { computed } from 'vue';

declare module 'vue/types/vue' {
  export interface Vue {
    Separator: ComponentWithProps<Props>;
  }
}

/**
 * `Separator` component properties.
 */
export interface Props {
  /** Text to be displayed within the center of the separator.  */
  text?: string;
  /** If `true`, will orient the separator vertically rather in horizontally. */
  vertical?: boolean;
}

const props = defineProps<Props>();

const classList = computed(() => {
  const listItems: string[] = [];

  if (props.vertical) {
    listItems.push('vertical');
  } else {
    listItems.push('horizontal');
  }

  if (props.text) listItems.push('has-text');

  return listItems;
});
</script>

<template>
  <div :class="['separator', ...classList]">
    <template v-if="text"> <span></span>{{ text }}<span></span> </template>

    <span v-else></span>
  </div>
</template>

<style scoped lang="scss">
.separator {
  display: flex;
  align-items: center;
  margin: 5px 0;

  &.vertical {
    flex-direction: column;
    margin: 0 5px;
  }
}

span {
  display: inline-block;
  width: 100%;
  height: 1px;
  border-radius: 2px;

  @include app-theme-light {
    background-color: #0013561f;
  }

  @include app-theme-dark {
    background-color: #ffffff30;
  }

  .separator.vertical & {
    width: 1px;
    height: 100%;
  }

  .separator.horizontal.has-text &:first-of-type {
    margin-right: 20px;
  }

  .separator.horizontal.has-text &:last-of-type {
    margin-left: 20px;
  }

  .separator.vertical.has-text &:first-of-type {
    margin-bottom: 20px;
  }

  .separator.vertical.has-text &:last-of-type {
    margin-top: 20px;
  }
}
</style>
