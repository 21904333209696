<script lang="ts">
import { computed } from 'vue';
import type { StyleValue } from 'vue/types/jsx';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/vue-fontawesome';

export type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

declare module 'vue/types/vue' {
  export interface Vue {
    Icon: ComponentWithProps<Props>;
  }
}

export default { name: 'Icon' };
</script>

<script setup lang="ts">
/**
 * `Icon` component properties.
 */
export interface Props extends FontAwesomeIconProps {
  size?: string;
  color?: string;
  opacity?: string | number;
}

const props = defineProps<Props>();

const style = computed(() => {
  const styles: StyleValue = {};

  if (props.size) {
    styles.fontSize = props.size;
  }

  if (props.color) {
    styles.color = props.color;
  }

  if (props.opacity) {
    styles.opacity = props.opacity;
  }

  return styles;
});
</script>

<template>
  <span class="fa-icon" v-bind="{ style }">
    <FontAwesomeIcon v-bind="$attrs" />
  </span>
</template>

<style scoped lang="scss">
.fa-icon {
  pointer-events: none;
  height: 1em;
  line-height: 0;
  display: inline-block;
}
</style>
