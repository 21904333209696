<script lang="ts">
import { computed } from 'vue';

import { userLabelFilter, type UserInfo } from '@filters/user-label';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayTextUserLabel: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayTextUserLabel' };
</script>

<script lang="ts" setup>
/**
 * `DisplayTextUserLabel` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: UserInfo;
  /**
   * If `true`, the user's email will be included in the label.
   */
  email?: boolean;
  /**
   * If `true`, the user's last name will be placed before their first name in
   * the label.
   */
  lastNameFirst?: boolean;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  email: false,
  lastNameFirst: false,
});

const displayValue = computed(() => {
  return userLabelFilter(props.value, props.email, props.lastNameFirst);
});
</script>

<template>
  <component :is="tag">{{ displayValue }}</component>
</template>
