<script lang="ts">
import { computed } from 'vue';

import { clamp } from '@tools/math';

interface CardLine {
  key: number;
  width: string;
}

interface CardInstance {
  key: number;
  transitionDelay: string;
  lines: CardLine[];
}

declare module 'vue/types/vue' {
  export interface Vue {
    CardLoadPlaceholder: ComponentWithProps<Props>;
  }
}

export default { name: 'CardLoadPlaceholder' };
</script>

<script setup lang="ts">
/**
 * `CardLoadPlaceholder` component properties.
 */
export interface Props {
  cards: number;
  lines: number;
}

const props = defineProps<Props>();

function createInstances(cardCount: number, lineCount: number) {
  const instances: CardInstance[] = [];

  for (let n = 0; n < cardCount; n++) {
    const transitionDelay = `${n * 0.1}s`;
    const lines = createLines(lineCount);

    instances.push({ key: n, transitionDelay, lines });
  }

  return instances;
}

function createLines(lineCount: number) {
  const lines: CardLine[] = [];

  for (let n = 0; n < lineCount; n++) {
    const w = clamp(Math.random(), 0.2, 0.8) * 100;

    lines.push({ key: n, width: `${w}%` });
  }

  return lines;
}

const instances = computed(() => {
  return createInstances(props.cards, props.lines);
});
</script>

<template>
  <TransitionGroup tag="div" appear>
    <b-card
      v-for="inst in instances"
      :key="inst.key"
      :style="{ transitionDelay: inst.transitionDelay }"
      class="card-load-placeholder"
    >
      <b-skeleton
        v-for="line in inst.lines"
        :key="line.key"
        :width="line.width"
      />
    </b-card>
  </TransitionGroup>
</template>

<style scoped lang="scss">
.card-load-placeholder {
  padding: 2rem;
  background: #0000000a;
  border: 0;
  box-shadow: none;
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.5s, transform 0.5s;

  @include v-from {
    transform: translateY(20px);
    opacity: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}
</style>
