<template>
  <b-card class="text-white">
    <div :class="[hasExpired ? 'danger' : 'success']">
      <span>Student Account Expires: </span>
      <strong>{{ displayExpirationDate }}</strong>
    </div>

    <div class="pb-2">
      <template v-if="!hasExpired">
        <span>Days Left: </span>
        <span>{{ daysRemaining ?? '--' }}</span>
      </template>

      <template v-else>
        <Icon :icon="icon" />
        <span>Your Student Account Has Expired</span>
      </template>
    </div>

    <!-- TODO Implement upgrade process from new discount routes -->
    <!-- <b-button variant="primary" @click="upgradeAccount">
      Upgrade to Independent License
    </b-button> -->
  </b-card>
</template>

<script lang="ts">
import { ref, computed } from 'vue';

import { faExclamationTriangle } from '@icons/regular/faExclamationTriangle';

import { dateFilter } from '@filters/date';
import { Organization, License } from '@models';
import { dates } from '@utils/dates';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardStudentLicenseInfo: ComponentWithProps<Props>;
  }
}

export default { name: 'CardStudentLicenseInfo' };
</script>

<script setup lang="ts">
/** ... */
export interface Props {
  license: License;
  organization: Organization;
}

/** ... */
const props = defineProps<Props>();

/** ... */
const icon = ref(faExclamationTriangle);

/** ... */
const expiration = computed(() => props.license.expiration);

/** ... */
const displayExpirationDate = computed(() =>
  expiration.value
    ? (dateFilter(expiration.value, 'MM/dd/yyyy') as string)
    : '--',
);

/** ... */
const daysRemaining = computed(() => {
  if (!expiration.value) return null;

  const currentDateTime = Date.now();

  const value = dates.differenceInDays(expiration.value, currentDateTime);

  return value < 0 ? 0 : value;
});

/** ... */
const hasExpired = computed(() => (daysRemaining.value ?? Infinity) <= 0);
</script>
