<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellListItems: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellListItems` component properties.
 */
export interface Props {
  /** ... */
  value: unknown[];
}

const props = defineProps<Props>();

const items = computed(() => {
  return props.value.map((item) => ({ key: uniqueId(), item }));
});
</script>

<template>
  <div class="table-cell-list-items">
    <div v-for="{ key, item } in items" :key="key">• {{ item }}</div>
  </div>
</template>
