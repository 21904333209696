<template>
  <Transition appear>
    <div v-if="display" id="app-cookies-notice">
      <div class="notice-content">
        <p>
          Notice. Zephyr-Sim.com uses cookies to provide necessary website
          functionality, improve your experience and analyze our traffic. By
          using our website, you agree to our
          <RouterLink to="/privacy-policy">Privacy Statement</RouterLink> and
          our <RouterLink to="/cookies">Cookies Policy</RouterLink>.
        </p>

        <b-button variant="primary" @click="dismiss">Dismiss</b-button>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from '@vue';

import { ls } from '@services/ls';

/** Name of key to be used to track user acknowlegment in local storage. */
const LS_KEY = 'zephyrCookiesNotificationDismissed';

const display = ref(ls.get(LS_KEY) !== 'true');

function dismiss() {
  ls.set(LS_KEY, 'true');

  display.value = false;
}
</script>

<script lang="ts">
export default { name: 'AppCookiesNotice' };
</script>

<style lang="scss" scoped>
#app-cookies-notice {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  border-radius: 1rem;
  display: flex;
  background-color: #ff7700;
  color: white;
  justify-content: center;
  padding: 30px 20px;
  z-index: 100;
  box-shadow: var(--panel-box-shadow);
  transform: translateY(0);
  transition: transform 0.25s;

  @include v-from {
    transform: translateY(100%);
  }
}

.notice-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 1000px;

  a {
    color: var(--blue);
    font-weight: 500;
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    margin-left: 20px;
  }
}
</style>
