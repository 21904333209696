<script lang="ts">
import { computed, Component } from 'vue';

import { Order } from '@models';
import { useStore } from '@store';
import * as currencies from '@utils/currencies';

import DisplayAddress from '@components/Display/DisplayAddress.vue';
import DisplayText from '@components/Display/DisplayText.vue';
import Spacer from '@components/Spacer.vue';

interface ItemDetail {
  id: string;
  label: string;
  value: unknown;
  component: Component;
}

export default { name: 'OrderCardItem' };
</script>

<script setup lang="ts">
/**
 * `OrderCardItem` component properties.
 */
export interface Props {
  cartItem: Order.CartItem;
  currency: currencies.Currency;
  /**
   * Note: possibly depreciated, revisit once shipping information is finalized.
   */
  status?: Order.Status;
  shippingInfo?: ZephyrWeb.AddressInfo | null;
}

const props = defineProps<Props>();

const store = useStore();

const imageUrl = computed(() => {
  const thumbnail =
    props.cartItem.images?.[0] ??
    store.state.shop.products[props.cartItem.id]?.images[0];

  let value: string;

  if (thumbnail) {
    value = thumbnail.replace(/ /g, '%20');
  } else {
    value = '';
  }

  return value;
});

const displayPrice = computed(() => {
  let value: string;

  const { total, quantity } = props.cartItem;

  if (total !== 0) {
    value = currencies.format({
      amount: total / quantity, // NOTE: cart item `price` may have this value.
      currency: props.currency,
      unitType: 'smallest',
    });
  } else {
    value = 'Free';
  }

  return value;
});

const displayStatus = computed(() => {
  return props.shippingInfo ? props.status : 'Activated';
});

const itemDetails = computed(() => {
  const items: ItemDetail[] = [
    {
      id: 'quantity',
      label: 'Quantity',
      value: props.cartItem.quantity,
      component: DisplayText,
    },
    {
      id: 'price',
      label: 'Price',
      value: displayPrice.value,
      component: DisplayText,
    },
    // {
    //   id: 'status',
    //   label: 'Status',
    //   value: displayStatus,
    //   component: DisplayText,
    // },
  ];

  if (props.shippingInfo) {
    items.push({
      id: 'ship-to',
      label: 'Ship To',
      value: displayStatus,
      component: DisplayAddress,
    });
  }

  return items;
});
</script>

<template>
  <b-container class="order-card-item">
    <b-row class="py-3">
      <b-col cols="auto">
        <div
          class="item-thumbnail"
          :style="{ backgroundImage: `url(${imageUrl})` }"
        ></div>
      </b-col>

      <b-col>
        <div class="h6">{{ cartItem.name }}</div>

        <div v-for="item in itemDetails" :key="item.id" class="item-detail">
          <div class="detail-label">{{ item.label }}</div>
          <div class="detail-spacer"><Spacer /></div>
          <div class="detail-value">
            <component :is="item.component" v-bind="{ value: item.value }" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.print-address-line {
  text-align: right;
}

.item-thumbnail {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #00000029;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.item-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 40px;
}

.item-detail {
  display: flex;
  align-items: flex-start;
  padding: 0.2rem 0;
  font-size: 0.8rem;
}

.detail-label {
  line-height: 1em;
  font-weight: 500;
}

.detail-spacer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 1em;
}

.detail-value {
  line-height: 1em;
}
</style>
