<script lang="ts">
import { defineComponent } from 'vue';
import { BFormSelect } from 'bootstrap-vue';

declare module 'vue/types/vue' {
  export interface Vue {
    FormSelect: ComponentWithProps<Props>;
  }
}

/**
 * `FormSelect` component properties.
 */
export interface Props extends BFormSelect.Props {
  label: string;
}

export default defineComponent({
  name: 'FormSelect',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <FloatingLabel v-bind="{ label }" class="form-select">
    <b-form-select :placeholder="label" v-bind="$attrs" v-on="$listeners">
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </b-form-select>
  </FloatingLabel>
</template>
