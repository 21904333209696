<script lang="ts">
import { ref, computed, watch, watchEffect, defineComponent } from 'vue';
import { BFormInput } from 'bootstrap-vue';
import uniqueId from 'lodash/uniqueId';

import { useEventListener } from '@composables';
import { ls } from '@services/ls';

export interface ResultItem {
  text: string;
  value: string | number;
}

export type Querier = (queryText: string) => Promise<ResultItem[]>;

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormQuerySelect: ComponentWithProps<Props>;
  }
}

const FIELD_ID_PREFIX = `form-query-field`;

export default defineComponent({ name: 'FormQuerySelect' });
</script>

<script setup lang="ts">
/**
 * `FormQuerySelect` component properties.
 */
export interface Props extends BFormInput.Props {
  value?: number | string | null;
  querier: Querier;
  /** ... */
  fieldId?: string;
  placeholder?: string;
  clearable?: boolean;
}

/** `FormQuerySelect` component emits. */
export type Emits = (event: 'input', value: number | string | null) => void;

const props = withDefaults(defineProps<Props>(), {
  value: null,
  fieldId: '',
  placeholder: '',
  clearable: true,
});

const emit = defineEmits<Emits>();

const queryField = ref<BFormInput | null>(null);

const popoverShow = ref(false);
const loading = ref(false);
const queryText = ref<string | null>(null);
const selectedResult = ref<ResultItem | null>(null);
const results = ref<ResultItem[]>([]);

const elemId = ref('');

watchEffect(() => {
  const id = props.fieldId || uniqueId();

  if (props.fieldId) {
    // Attempt to get cached result item value.
    const data = ls.getObject(props.fieldId) as ResultItem | null;

    if (data && props.value && data.value === props.value) {
      selectedResult.value = data;
    }
  }

  elemId.value = `${FIELD_ID_PREFIX}-${id}`;
});

// Close the popup if the user clicks outside its element.
useEventListener('click', ({ target }) => {
  if (!popoverShow.value || !target) return;

  const elem = document.querySelector(
    `[data-form-query-select-id="${elemId.value}"]`,
  );

  if (!elem) return;

  if (elem !== target && !elem.contains(target as Node)) {
    popoverShow.value = false;
  }
});

const displayValue = computed(() => {
  let value;

  if (props.value !== null) {
    value = selectedResult.value?.text ?? '';
  } else {
    value = props.placeholder ?? 'Select an option';
  }

  return value;
});

watch(queryText, async (value) => {
  let items: ResultItem[] = [];

  if (value !== null) {
    loading.value = true;

    try {
      items = await props.querier(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    loading.value = false;
  }

  results.value = items;
});

watch(popoverShow, (value) => {
  if (value) {
    window.setTimeout(() => {
      queryField.value?.focus();
    }, 1);
  } else {
    // queryText.value = null;
  }
});

function setValue(value: number | string | null) {
  emit('input', value);

  selectedResult.value =
    results.value.find((item) => item.value === value) ?? null;

  if (props.fieldId) {
    ls.setObject(props.fieldId, selectedResult.value);
  }

  popoverShow.value = false;
}

function clearValue() {
  setValue(null);
}

function clearQueryText() {
  queryText.value = null;
}

function close() {
  popoverShow.value = false;
}
</script>

<template>
  <div class="form-query-select">
    <div class="field-wrapper">
      <div :id="elemId" class="input-wrapper">
        <b-form-input
          :value="displayValue"
          :class="{ focused: popoverShow }"
          v-on="$listeners"
        />
      </div>

      <b-button v-if="clearable" @click="clearValue"> Clear </b-button>
    </div>

    <b-popover
      :data-form-query-select-id="elemId"
      :target="elemId"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottom"
    >
      <div class="popover-search-form">
        <b-input-group class="mr-2" size="sm" prepend="Search">
          <b-form-input
            ref="queryField"
            v-model="queryText"
            :debounce="500"
            class="m-0"
            size="sm"
            spellcheck="false"
          />

          <b-input-group-append>
            <b-button variant="secondary" @click="clearQueryText">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-button variant="danger" size="sm" @click="close"> Close </b-button>
      </div>

      <hr />

      <div class="popover-search-results">
        <template v-if="loading">
          <div class="popover-overlay">
            <Spinner size="1.5" class="mr-2" />Loading
          </div>
        </template>

        <template v-else-if="!results.length && queryText">
          <div class="popover-overlay">
            <span class="h6 m-0 text-muted"> No results </span>
          </div>
        </template>

        <template v-else-if="!results.length && !queryText">
          <div class="popover-overlay">
            <span class="h6 m-0 text-muted"> Results will appear here </span>
          </div>
        </template>

        <div v-else class="popover-search-results-inner">
          <div
            v-for="result in results"
            :key="result.value"
            class="result-item"
            @click="setValue(result.value)"
          >
            {{ result.text }}
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<style scoped lang="scss">
hr {
  margin: 0;
  background: var(--popover-border-color);
}

.form-query-select {
  .input-group > & {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group
    > &:not(:first-child)
    .field-wrapper
    > .input-wrapper
    > .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group
    > &:not(:last-child)
    .field-wrapper
    > .input-wrapper:last-child
    > .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > &:not(:last-child) .field-wrapper > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.field-wrapper {
  display: flex;

  & .input-wrapper > .form-control {
    pointer-events: none;
  }

  & .input-wrapper:not(:last-child) > .form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-wrapper {
  flex: 1 1 auto;
  user-select: none;
}

.form-control {
  cursor: default;

  &.focused {
    color: var(--input-color);
    background-color: var(--input-bg);
    border-color: #009eff;
    outline: 0;
    box-shadow: 0 0 1px 1px #009eff, 0 0 10px rgba(0, 158, 255, 0.5215686275);
  }
}

.popover {
  max-width: 800px;
}

.popover:deep(.popover-body) {
  padding: 0;
}

.popover-search-form {
  padding: 1.5rem;
  display: flex;
}

.popover-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  user-select: none;
}

.popover-search-results {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100px;
  user-select: none;
}

.popover-search-results-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem 0;
  overflow: auto;
  max-height: calc(100vh - 800px);
}

.result-item {
  flex-wrap: nowrap;
  padding: 0.25rem 1.5rem;
  cursor: pointer;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: var(--blue);
  }
}
</style>
