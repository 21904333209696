<script lang="ts">
import Icon from '@components/Icon.vue';

import { faInfoCircle as faInfoRegular } from '@icons/regular/faInfoCircle';
import { faInfoCircle as faInfoSolid } from '@icons/solid/faInfoCircle';

declare module 'vue/types/vue' {
  export interface Vue {
    InfoCircle: ComponentWithProps<Props>;
  }
}

export default { name: 'InfoCircle' };
</script>

<script setup lang="ts">
/**
 * `InfoCircle` component properties.
 */
export interface Props {
  text: string;
  /**
   * Applies one of the Bootstrap theme color variants to the component.
   */
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
}

defineProps<Props>();
</script>

<template>
  <span
    v-b-tooltip.top="text"
    :class="['info-circle', variant ? `info-circle-${variant}` : '']"
  >
    <Icon class="icon-regular" :icon="faInfoRegular" />
    <Icon class="icon-solid" :icon="faInfoSolid" />
  </span>
</template>

<style scoped lang="scss">
$circle-size: 2em;

.info-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  position: relative;
  width: 1em;
  height: 1em;

  &.info-circle-primary {
    color: var(--primary);
  }

  &.info-circle-secondary {
    color: var(--secondary);
  }

  &.info-circle-success {
    color: var(--success);
  }

  &.info-circle-danger {
    color: var(--danger);
  }

  &.info-circle-warning {
    color: var(--warning);
  }

  &.info-circle-info {
    color: var(--info);
  }
}

.fa-icon {
  font-size: 1em;
  position: absolute;
}

.info-circle > .fa-icon.icon-regular {
  opacity: 1;
}

.info-circle:hover > .fa-icon.icon-regular {
  opacity: 0;
}

.info-circle > .fa-icon.icon-solid {
  opacity: 0;
}

.info-circle:hover > .fa-icon.icon-solid {
  opacity: 1;
}
</style>
