<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows';

/**
 * `TableCellOperatingSystem` component properties.
 */
export interface Props {
  /** ... */
  value: 'mac' | 'win';
}

const props = defineProps<Props>();
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellOperatingSystem: ComponentWithProps<Props>;
  }
}
export default { name: 'TableCellOperatingSystem' };
</script>

<template>
  <div>
    <icon v-if="props.value === 'mac'" :icon="faApple" />
    <icon v-if="props.value === 'win'" :icon="faWindows" />
  </div>
</template>
