<script lang="ts">
import { ref, watch } from 'vue';

import { delay } from '@tools/delay';

import DisplayTextCurrency from '@components/Display/DisplayTextCurrency.vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TotalLine: ComponentWithProps<Props>;
  }
}

export default { name: 'TotalLine' };
</script>

<script setup lang="ts">
/**
 * `TotalLine` component properties.
 */
export interface Props {
  /** Line label text. */
  label: string;
  /** Amount value to display. */
  value?: number;
  /** If `true`, the line will be rendered with a slightly larger font. */
  large?: boolean;
  /** If `true`, a loading indicator will display instead of the value. */
  loading?: boolean;
  /** If `true`, the text "Pending" will display instead of the value. */
  pending?: boolean;
}

const props = defineProps<Props>();

const valueChanged = ref(false);

async function onValueChanged() {
  valueChanged.value = true;

  await delay(150);

  valueChanged.value = false;
}

watch(() => props.value, onValueChanged);
</script>

<template>
  <div :class="['total-line', { large }]">
    <p class="total-line-label">{{ label }}</p>

    <Spacer />

    <p v-if="pending" class="total-line-value status">Pending</p>

    <p v-else-if="loading" class="total-line-value status">
      <Spinner class="mr-2" /> Calculating
    </p>

    <p v-else :class="['total-line-value', { changed: valueChanged }]">
      <DisplayTextCurrency v-bind="{ value }" />
    </p>
  </div>
</template>

<style scoped lang="scss">
.total-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  &.large {
    font-size: 25px;
  }

  p {
    display: inline-flex;
    align-items: center;
    margin: 0;
  }
}

.total-line-label {
  font-size: inherit;
  margin: 0;
  user-select: none;
}

.total-line-dotted-line {
  flex-grow: 1;
  height: 1px;
  border-top: 2px dotted currentColor;
  margin: 0 20px -1px;
  opacity: 0.1;
}

.total-line-value {
  font-size: inherit;
  font-weight: 400;
  transform: scale(1);
  transition: transform 0.15s;

  &.status {
    opacity: 0.5;
  }

  &.changed {
    transform: scale(1.25);
  }
}
</style>
