<template>
  <div class="badge-count">
    <div>{{ displayCount }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

/**
 * ...
 */
export interface Props {
  value: number;
}

/** ... */
const props = defineProps<Props>();

/** ... */
const count = computed(() => Math.max(0, props.value));
/** ... */
const displayCount = computed(() =>
  count.value >= 100 ? '99+' : count.value.toString(),
);
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    BadgeCount: ComponentWithProps<Props>;
  }
}

export default { name: 'BadgeCount' };
</script>

<style lang="scss" scoped>
$badge-size: 1.5rem;

.badge-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  min-width: $badge-size;
  height: $badge-size;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  background-color: #009eff;
  // darker color below for ADA compliance
  // background-color: #00588f;
  border-radius: $badge-size;
  user-select: none;

  > div {
    padding: 0 0.5rem;
  }
}
</style>
