<script lang="ts">
import { computed } from 'vue';
import type { StyleValue } from 'vue/types/jsx';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayPrice: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayPrice' };
</script>

<script setup lang="ts">
/**
 * `DisplayPrice` component properties.
 */
export interface Props {
  size: string | null;
  price: string | number;
  currency?: 'usd' | 'eur';
}

const props = withDefaults(defineProps<Props>(), {
  currency: 'usd',
});

const style = computed(() => {
  const style: StyleValue = {};

  if (props.size) {
    style.fontSize = props.size;
  }

  return style;
});

const amount = computed(() => {
  const amount = parseFloat(props.price.toString());

  if (isNaN(amount) || amount < 0) {
    throw new Error('[]');
  }

  return amount;
});

const isFree = computed(() => {
  return amount.value === 0;
});

const displayPrice = computed(() => {
  const [dollars, change] = amount.value
    .toLocaleString('en-US', {
      style: 'currency',
      currency: props.currency,
    })
    .split('.');

  return { dollars, change };
});
</script>

<template>
  <div class="display-price" :style="style">
    <template v-if="isFree">
      <span>Free</span>
    </template>

    <template v-else>
      <span>{{ displayPrice.dollars }}</span>
      <span class="amount-change">.{{ displayPrice.change }}</span>
    </template>
  </div>
</template>

<style scoped lang="scss">
.display-price {
  position: relative;
  color: #ff682b;
  font-size: 2rem;
  font-weight: 600;
  display: inline-flex;
  line-height: 1em;
}

.amount-change {
  font-size: 0.6em;
  height: 100%;
  margin-top: -0.2rem;
}
</style>
