<script lang="ts">
import { ref, watch } from 'vue';
import { languages, highlightAllUnder } from 'prismjs';

import { alert } from '@services/alert';
import { ensure } from '@services/ensure';
import { isString } from '@tools/type-guards';

declare module 'vue/types/vue' {
  export interface Vue {
    JsonView: ComponentWithProps<Props>;
  }
}

Object.assign(languages, {
  json: {
    comment: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
    property: { pattern: /"(?:\\.|[^\\"\r\n])*"(?=\s*:)/, greedy: !0 },
    string: { pattern: /"(?:\\.|[^\\"\r\n])*"(?!\s*:)/, greedy: !0 },
    number: /-?\d+\.?\d*(e[+-]?\d+)?/i,
    punctuation: /[{}[\],]/,
    operator: /:/,
    boolean: /\b(?:true|false)\b/,
    null: /\bnull\b/,
  },
});

export default { name: 'JsonView' };
</script>

<script setup lang="ts">
/**
 * `JsonView` component properties.
 */
export interface Props {
  source: string | object | unknown[];
}

const props = defineProps<Props>();

const preEl = ref<HTMLPreElement | null>(null);

const markup = ref<string | null>(null);

watch(
  () => props.source,
  (source) => {
    let obj: object = {};
    let error: ErrorLike | null = null;

    try {
      obj = isString(source) ? JSON.parse(source) : source;
    } catch (err) {
      error = ensure.error(err);
    }

    if (error) {
      alert.warning(
        'JSON view: the value provided was not a valid object or JSON string',
      );
    }

    markup.value = JSON.stringify(obj, null, 2);

    setTimeout(() => {
      if (!preEl.value) return;

      preEl.value.innerHTML = '';

      const codeEl = document.createElement('code');
      codeEl.classList.add('language-json');
      codeEl.textContent = markup.value ?? 'null';

      preEl.value.appendChild(codeEl);

      highlightAllUnder(preEl.value);
    });
  },
  { immediate: true },
);
</script>

<template>
  <pre ref="preEl" class="json-view">
    <!-- <code class="language-json">{{ markup }}</code> -->
  </pre>
</template>

<style lang="scss">
.json-view {
  // $red: #98c379;
  $red: #f15392;
  // $green: #e06c75;
  $green: #2bcca0;
  // $yellow: hsl(29, 54%, 61%);
  $yellow: #cca545;
  // $gray: #abb2bf;
  // $gray: #7f9cd2;
  $gray: #5f759d;

  margin: 0;
  padding: 40px;
  border: none;
  text-align: left;

  @include app-theme-light {
    background-color: #f1f5ff;

    ::selection {
      background: #c4c8d3;
    }
  }

  @include app-theme-dark {
    background-color: #14161a;

    ::selection {
      background: #404872;
    }
  }

  code {
    display: block;
    white-space: pre;
  }

  span {
    font-family: inherit;
    user-select: text;

    &.token {
      color: $gray;

      &.operator {
        color: #abb2bf;
      }

      &.cdata,
      &.comment,
      &.doctype,
      &.prolog {
        color: hsl(220, 10%, 40%);
      }

      &.boolean,
      &.deleted,
      &.number,
      &.tag {
        color: $yellow;
      }

      &.null {
        color: #d19a66;
      }

      &.builtin,
      &.constant,
      &.keyword,
      &.property,
      &.selector,
      &.symbol {
        color: $red;
      }

      &.attr-name,
      &.attr-value,
      &.char,
      &.entity,
      &.inserted,
      &.string,
      &.url,
      &.variable {
        color: $green;
      }
    }
  }
}
</style>
