<script lang="ts">
import { ref, computed, watch, watchEffect, defineComponent } from 'vue';
import { BFormInput } from 'bootstrap-vue';
import find from 'lodash/find';
import uniqueId from 'lodash/uniqueId';

import { useEventListener } from '@composables';

export interface ListItem {
  text: string;
  value: number | string;
  disabled?: boolean;
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormListSelect: ComponentWithProps<Props>;
  }
}

export default defineComponent({ name: 'FormListSelect' });
</script>

<script setup lang="ts">
/**
 * `FormListSelect` component properties.
 */
export interface Props extends BFormInput.Props {
  value?: number | string | null;
  items: ListItem[];
  placeholder?: string;
  clearable?: boolean;
}

/** `FormQuerySelect` component emits. */
export type Emits = (event: 'input', value: number | string | null) => void;

const props = withDefaults(defineProps<Props>(), {
  value: null,
  placeholder: '',
  clearable: true,
});

const emit = defineEmits<Emits>();

const fieldId = `form-query-field-${uniqueId()}`;
const queryField = ref<BFormInput | null>(null);

const popoverShow = ref(false);
const queryText = ref<string | null>(null);

// Close the popup if the user clicks outside its element.
useEventListener('click', ({ target }) => {
  if (!popoverShow.value || !target) return;

  const elem = document.querySelector(
    `[data-form-list-select-id="${fieldId}"]`,
  );

  if (!elem) return;

  if (elem !== target && !elem.contains(target as Node)) {
    popoverShow.value = false;
  }
});

const filteredItems = ref<ListItem[]>([]);

watchEffect(() => {
  const text = queryText.value?.toLowerCase();

  const items: ListItem[] = [];

  for (const item of props.items) {
    if (!text || item.text.toLowerCase().includes(text)) {
      items.push(item);
    }
  }

  filteredItems.value = items;
});

const selectedItem = computed(() => {
  let item;

  if (props.value) {
    item = find(filteredItems.value, { value: props.value }) ?? null;
  } else {
    item = null;
  }

  return item;
});

const displayValue = computed(() => {
  let value;

  if (props.value !== null) {
    value = selectedItem.value?.text ?? '';
  } else {
    value = props.placeholder ?? 'Select an option';
  }

  return value;
});

watch(popoverShow, (value) => {
  if (!value) return;

  window.setTimeout(() => {
    queryField.value?.focus();
  }, 1);
});

function setValue(value: number | string | null) {
  emit('input', value);

  popoverShow.value = false;
}

function clearValue() {
  setValue(null);
}

function clearQueryText() {
  queryText.value = null;
}

function close() {
  popoverShow.value = false;
}

function listInnerHeight() {
  const vH = window.innerHeight;

  const elem = document.getElementById(fieldId);

  if (!elem) return `${vH - 800}px`;

  const posY = elem?.getBoundingClientRect().y;

  return `${vH - posY - 200}px`;
}
</script>

<template>
  <div class="form-list-select">
    <div class="field-wrapper">
      <div :id="fieldId" class="input-wrapper">
        <b-form-input
          :value="displayValue"
          :class="{ focused: popoverShow }"
          v-on="$listeners"
        />
      </div>

      <b-button v-if="clearable" @click="clearValue"> Clear </b-button>
    </div>

    <b-popover
      :data-form-list-select-id="fieldId"
      :target="fieldId"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottom"
    >
      <div class="popover-search-form">
        <b-input-group class="mr-2" size="sm" prepend="Search">
          <b-form-input
            ref="queryField"
            v-model="queryText"
            class="m-0"
            size="sm"
            spellcheck="false"
          />

          <b-input-group-append>
            <b-button variant="secondary" @click="clearQueryText">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-button variant="danger" size="sm" @click="close"> Close </b-button>
      </div>

      <hr />

      <div class="popover-list-items">
        <template v-if="!filteredItems.length && !queryText">
          <div class="popover-overlay">
            <span class="h6 m-0 text-muted"> No items </span>
          </div>
        </template>

        <template v-else-if="!filteredItems.length && queryText">
          <div class="popover-overlay">
            <span class="h6 m-0 text-muted"> No results </span>
          </div>
        </template>

        <div
          v-else
          class="popover-list-items-inner"
          :style="{ maxHeight: listInnerHeight() }"
        >
          <div
            v-for="item in filteredItems"
            :key="item.value"
            :class="['list-item', { selected: item.value === value }]"
            :disabled="item.disabled"
            @click="setValue(item.value)"
          >
            <span> {{ item.text }}</span>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<style scoped lang="scss">
hr {
  margin: 0;
  background: var(--popover-border-color);
}

.form-list-select {
  .input-group > & {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group
    > &:not(:first-child)
    .field-wrapper
    > .input-wrapper
    > .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group
    > &:not(:last-child)
    .field-wrapper
    > .input-wrapper:last-child
    > .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group > &:not(:last-child) .field-wrapper > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.field-wrapper {
  display: flex;

  & .input-wrapper > .form-control {
    pointer-events: none;
  }

  & .input-wrapper:not(:last-child) > .form-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.input-wrapper {
  flex: 1 1 auto;
  user-select: none;
}

.form-control {
  cursor: default;

  &.focused {
    color: var(--input-color);
    background-color: var(--input-bg);
    border-color: #009eff;
    outline: 0;
    box-shadow: 0 0 1px 1px #009eff, 0 0 10px rgba(0, 158, 255, 0.5215686275);
  }
}

.popover {
  max-width: 800px;
}

.popover:deep(.popover-body) {
  padding: 0;
}

.popover-search-form {
  padding: 1.5rem;
  display: flex;
}

.popover-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  user-select: none;
}

.popover-list-items {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 100px;
  user-select: none;
}

.popover-list-items-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5rem 0;
  overflow: auto;
  // max-height: calc(100vh - 800px);
}

.list-item {
  flex-wrap: nowrap;
  padding: 0.25rem 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: var(--blue);
  }

  &.selected {
    color: white;
    text-decoration: none;
    background-color: var(--blue);
    pointer-events: none;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.25;
  }
}
</style>
