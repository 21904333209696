<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    DisplayValueText: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayValueText' };
</script>

<script setup lang="ts">
/**
 * `DisplayValueText` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value?: unknown;
  /**
   * Text label that should accompany the displayed value.
   */
  label: string;
}

defineProps<Props>();
</script>

<template>
  <div class="display-value-text my-2">
    <label class="h6 m-0 mb-2">{{ label }}</label>

    <pre>{{ value }}</pre>
  </div>
</template>

<style scoped lang="scss">
.display-value-text {
  display: flex;
  flex-direction: column;
}

pre {
  margin: 0;
  white-space: break-spaces;
  color: var(--text-color);
  padding: 1rem;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border-color);
  border-radius: 0.5rem;
}
</style>
