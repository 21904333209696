<script setup lang="ts">
import { UnityCatalog } from '@client/models/unity-catalog';

/**
 * `TableCellBaseApp` component properties.
 */
export interface Props {
  /** ... */
  value: UnityCatalog.BaseApp;
}

const props = defineProps<Props>();
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellBaseApp: ComponentWithProps<Props>;
  }
}
export default { name: 'TableCellBaseApp' };
</script>

<template>
  <div>
    <span>{{ value.name }} | {{ value.version }}</span>
  </div>
</template>
