<script setup lang="ts">
import { computed } from 'vue';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { Certification } from '@models';

import { faBadgeCheck } from '@icons/solid/faBadgeCheck';
import { faBan } from '@icons/solid/faBan';
import { faClock } from '@icons/solid/faClock';
import { faTimes } from '@icons/solid/faTimes';

/**
 * `PopoverVerificationInfo` component properties.
 */
export interface Props {
  target: string;
  status: Certification.Status;
}

interface StatusInfo {
  text: string;
  color: string;
  icon: IconDefinition | null;
}

const props = defineProps<Props>();

const info = computed(() => {
  let value: StatusInfo | null = null;

  if (props.status === 'CURRENT') {
    value = { text: 'Active', color: '#15d246', icon: faBadgeCheck };
  } else if (props.status === 'EXPIRED') {
    value = { text: 'Expired', color: '#ff004f', icon: faClock };
  } else if (props.status === 'SUSPENDED') {
    value = { text: 'Suspended', color: '#ff004f', icon: faTimes };
  } else if (props.status === 'REVOKED') {
    value = { text: 'Revoked', color: '#ff004f', icon: faBan };
  } else {
    value = { text: '', color: 'currentColor', icon: null };
  }

  return value;
});
</script>

<template>
  <b-popover v-bind="{ target }" triggers="hover" placement="bottom">
    <div
      class="d-flex justify-content-center align-items-center flex-column p-3"
    >
      <div class="h5 mb-3 d-flex align-items-center">
        <Icon :icon="info.icon" :style="{ color: info.color }" />
        <span>{{ info.text }} Certificate</span>
      </div>

      <p class="m-0">by Zephyr Verification Service<br /></p>

      <b-button :to="{ name: 'certifications' }" variant="link" target="_blank">
        Learn more
      </b-button>
    </div>
  </b-popover>
</template>

<style lang="scss" scoped>
.popover {
  text-align: center;
  .fa-icon {
    margin-right: 0.75rem;
  }

  .btn {
    height: 1em;
    padding: 0 !important;
  }
}
</style>
