<script setup lang="ts">
import { ref, computed } from 'vue';

import { modals } from '@modals';
import type { Faq } from '@models';

import { faStar } from '@icons/solid/faStar';
import { faTag } from '@icons/solid/faTag';
import { faPlus } from '@icons/regular/faPlus';
import { faMinus } from '@icons/regular/faMinus';

const SUPPORT_MODAL_CONFIG = {
  title: 'SUPPORT',
  text: 'You can email us at support@littlearms.com with your support question. We will respond as soon as we receive your message.',
};

/**
 * `FaqCard` component properties.
 */
export interface Props {
  id: string;
  title: string;
  body: Faq.BodyItem[];
  subjects: string[];
  showChat: boolean;
  tags: string[];
  topFaq: boolean;
  expanded: boolean;
}

/** `FaqCard` component emits. */
export type Emits = (event: 'toggled', id: string) => void;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const internalExpanded = ref(false);

const expandedSet = computed(() => props.expanded);

const open = computed({
  get: () => {
    return expandedSet.value ? props.expanded : internalExpanded.value;
  },
  set: (value) => {
    if (!expandedSet.value) {
      internalExpanded.value = value;
    }
  },
});

const elements = computed(() => {
  return props.body.map((item) => {
    if (item.type === 'video') {
      item.content = `https://www.youtube.com/embed/${item.content}`;

      return item;
    }

    if (item.type !== 'text') return item;

    const getFaqIdRegex = /\[(.*?)\]/;
    const [, faqId] = item.content.match(getFaqIdRegex) ?? [];

    if (!faqId) return item;

    const text = item.content.replace(
      `<a href="#" [${faqId}]">`,
      `<a href="#" data-faq-id="${faqId}" onclick="dispatchEvent.value(new Event('linked', { bubbles: true }));">`,
    );

    return { ...item, content: text };
  });
});

function toggle() {
  if (expandedSet.value) {
    emit('toggled', props.id);
  } else {
    internalExpanded.value = !internalExpanded.value;
  }
}

function contactSupport() {
  void modals.util.generalInfo(SUPPORT_MODAL_CONFIG);
}
</script>

<template>
  <div v-bind="{ id }" class="faq-card my-4">
    <div class="faq-title" @click="toggle">
      <span>
        <span class="faq-marker-container">
          <Icon v-if="topFaq" :icon="faStar" />
        </span>

        <span class="title">{{ title }}</span>
      </span>

      <Icon :icon="open ? faMinus : faPlus" />
    </div>

    <div class="faq-tags">
      <div class="tag">
        <Icon :icon="faTag" />
      </div>

      <div v-for="tag in tags" :key="tag" class="tag">{{ tag }}</div>
    </div>

    <b-collapse v-model="open" role="tabpanel">
      <div class="faq-body-container">
        <div v-if="subjects && subjects.length" class="faq-subjects">
          <div v-for="subject in subjects" :key="subject" class="subject">
            {{ subject }}
          </div>
        </div>

        <div class="faq-body">
          <div
            v-for="({ type, content }, index) in elements"
            :key="index"
            class="faq-body-content"
          >
            <div v-if="type === 'text'" ref="text" v-html="content"></div>

            <div v-else-if="type === 'video'">
              <iframe
                width="560"
                height="315"
                :src="content"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div v-else-if="type === 'image'">
              <img :src="content" />
            </div>
          </div>

          <div
            v-if="showChat"
            class="contact-support-prompt"
            @click="contactSupport"
          >
            Still not finding what you are looking for? Contact Us
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style lang="scss">
.faq-body {
  ul,
  ol {
    padding-left: 25px;
  }

  iframe {
    display: block;
    margin: 0 auto;
  }
}
</style>

<style scoped lang="scss">
.faq-card {
  overflow: hidden;
  background-color: var(--panel-bg) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 20px;
  box-shadow: var(--panel-box-shadow);
}

.faq-title {
  cursor: pointer;
  padding: 1.5rem;
  font-size: 1rem;
  color: var(--heading-color);
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  > span {
    position: relative;
    display: flex;
    align-items: center;
  }

  .title {
    white-space: pre-wrap;
  }
}

.faq-marker-container {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  height: 100%;
  margin-right: 10px;
  color: var(--primary);

  .fa-icon {
    margin-top: -2px;
  }
}

.faq-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 10px;
  font-size: 0.8em;
  background-color: #051a4a14;

  .tag {
    margin: 5px;
  }
}

.faq-subjects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.8em;

  .subject {
    background-color: #f08080;
    color: #232323;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 6px;
    box-shadow: var(--panel-box-shadow);
  }
}

.faq-body-container {
  padding: 1.5rem;
  // border: 1px solid #363636;
}

.faq-body {
  margin-top: 10px;
}

.faq-body-container {
  border-top: 1px solid var(--border-color);
}

.faq-body-content {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.contact-support-prompt {
  padding: 10px;
  color: #ad3f13;
  font-weight: bold;

  &:hover {
    color: #e96330;
    cursor: pointer;
  }
}
</style>
