<script lang="ts">
// import Notifications, { SlotProps } from 'vue-notifications/Notifications.vue';
import Notifications from 'vue-notifications/Notifications.vue';

import Icon from '@components/Icon.vue';

import { faTimes } from '@icons/solid/faTimes';

declare module 'vue/types/vue' {
  export interface Vue {
    NotificationsRoot: ComponentWithProps;
  }
}

export default { name: 'NotificationsRoot' };
</script>

<script setup lang="ts"></script>

<template>
  <!-- TODO: After migrating to Vue 3, type slot with `SlotProps`. -->
  <!-- ex: v-slot="{ item, close }: SlotProps" -->
  <Notifications
    v-slot="{ item, close }"
    group="reg"
    classes="app-notification"
    width="480px"
    position="top center"
  >
    <div :class="['regular-notification', `type-${item.type}`]" @click="close">
      <div class="notification-panel">
        <button class="btn-dismiss">
          <Icon :icon="faTimes" />
        </button>

        <!-- eslint-disable vue/no-v-html -->
        <h3 v-if="item.title" v-html="item.title" />
        <pre class="message" v-html="item.text" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </Notifications>
</template>

<style scoped lang="scss">
.vue-notification-group {
  top: 90px !important;

  &:deep(.vue-notification-wrapper) {
    margin: 10px 0;
  }
}

.regular-notification {
  position: relative;
  color: #fff;
  transform: scale(1);
  transition: all ease 0.15s;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) -4px 9px 25px -6px;
    z-index: -1;
    transition: all ease 0.15s;
  }

  &:hover {
    transform: scale(1.05);

    &::after {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px -7px;
    }
  }

  &:active {
    transform: scale(1);
  }

  a {
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
  }
}

.notification-panel {
  position: relative;
  width: 100%;
  padding: 2rem;
  border-radius: 10px;
  background: #337ab7;
  z-index: 1;

  .type-info & {
    background: var(--blue);
  }

  .type-success & {
    background: #13ac44;
  }

  .type-warn & {
    background: var(--yellow);
    color: #000;
  }

  .type-error & {
    background: var(--red);
  }

  > h3 {
    display: block;
    margin: 10px 10px 0 10px;
    padding: 0 0 5px 0;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.btn-dismiss {
  color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  font-size: 16px;
  background: transparent !important;
  outline: none !important;

  .fa {
    margin: 0;
  }
}

.message {
  font-size: 1rem;
  font-family: 'Rubik';
  color: inherit;
  margin: 0;
  white-space: pre-wrap;

  &:deep(a) {
    color: currentColor !important;
    font-weight: 600;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
