<script lang="ts">
import { ref, computed, watchEffect } from 'vue';
import { kebabCase, startCase, sortBy } from 'lodash';

import { useStore, TablePanels } from '@store';
import { ls } from '@services/ls';
import { isString } from '@tools/type-guards';

import Icon from '@components/Icon.vue';

import { faList } from '@icons/solid/faList';
import { faGrid2 } from '@icons/solid/faGrid2';

import GroupItem from './CardTableGroupItem.vue';
import GroupView from './CardTableGroupView.vue';

const CACHE_ID_PREFIX = 'zephyr.cardTableGroup';

function titleCase(value: string) {
  return startCase(kebabCase(value).replace(/-/g, ' '));
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardTableGroup: ComponentWithProps<Props>;
  }
}

export default { name: 'CardTableGroup' };
</script>

<script setup lang="ts">
export interface PanelConfig {
  tableKey: TablePanels.ModuleKey;
  loaderOptions?: Nullable<GenericObject>;
}

/** `CardTableGroup` component properties. */
export interface Props {
  /**
   * A mixed array of either table panel module keys, and/or {@link PanelConfig}
   * objects.
   */
  items: (TablePanels.ModuleKey | PanelConfig)[];
  /** ... */
  id?: string;
}

const props = defineProps<Props>();

const store = useStore();

/**
 * Filtered list of table panel configs based upon whether or not they're
 * enabled.
 */
const configs = computed(() => {
  const configs: PanelConfig[] = [];

  for (const item of props.items) {
    const config = isString(item) ? { tableKey: item } : item;

    if (!isPanelEnabled(config.tableKey)) continue;

    configs.push(config);
  }

  return configs;
});

/**
 * ...
 *
 * @param panel ...
 */
function isPanelEnabled(panel: TablePanels.ModuleKey) {
  return (
    (store.getters as unknown as GenericObject)[
      `tablePanels/${panel}/enabled`
    ] !== false
  );
}

type ViewMode = 'single' | 'multi';

const viewModeCacheId = computed(() => {
  return props.id ? `${CACHE_ID_PREFIX}.${props.id}.viewMode` : null;
});

const viewMode = ref<ViewMode>(getViewMode());

const viewOptions = [
  { label: 'Single Table View', icon: faGrid2, value: 'single' },
  { label: 'Multi Table View', icon: faList, value: 'multi' },
] as const;

watchEffect(() => {
  if (viewModeCacheId.value) {
    ls.set(viewModeCacheId.value, viewMode.value);
  }
});

const isAdmin = computed(() => {
  return store.state.me.selectedRole?.roleId === 9;
});

const tableCacheId = computed(() => {
  return props.id ? `${CACHE_ID_PREFIX}.${props.id}.table` : null;
});

const selectedTable = ref(getLastSelectedTable());

watchEffect(() => {
  if (tableCacheId.value && selectedTable.value) {
    ls.set(tableCacheId.value, selectedTable.value);
  }
});

const tableOptions = computed(() => {
  return sortBy(configs.value, 'tableKey').map(({ tableKey }) => ({
    text: titleCase(tableKey),
    value: tableKey,
  }));
});

//#region Helper Functions

function isTableKey(value: unknown): value is TablePanels.ModuleKey {
  return isString(value) && value in TablePanels.modules;
}

function getLastSelectedTable() {
  let value: unknown = null;

  if (tableCacheId.value) {
    value = ls.get(tableCacheId.value);
  } else {
    value = configs.value[0]?.tableKey;
  }

  return isTableKey(value) ? value : null;
}

function getViewMode() {
  let value: string | null = null;

  if (viewModeCacheId.value) {
    value = ls.get(viewModeCacheId.value);
  }

  return (
    value === 'single' || value === 'multi' ? value : 'multi'
  ) as ViewMode;
}

//#endregion Helper Functions
</script>

<template>
  <div class="card-table-group accordion" role="tablist">
    <b-nav v-if="isAdmin" class="mb-4" align="right" pills>
      <b-nav-item
        v-for="option in viewOptions"
        :key="option.value"
        v-b-tooltip.hover.bottom="option.label"
        :active="option.value === viewMode"
        @click="viewMode = option.value"
      >
        <Icon :icon="option.icon" />
      </b-nav-item>
    </b-nav>

    <TransitionGroup v-if="!isAdmin || viewMode === 'multi'" tag="div" appear>
      <GroupItem
        v-for="(config, index) in configs"
        :key="config.tableKey"
        v-transition-delay.group="{ index }"
        v-bind="config"
      />
    </TransitionGroup>

    <b-card v-else class="card-table-group-item" no-body>
      <b-card-header
        header-tag="header"
        class="p-3 d-flex justify-content-between"
        role="tab"
      >
        <b-form-group
          class="mb-0"
          label-cols="3"
          label="Table"
          label-align="left"
        >
          <b-form-select v-model="selectedTable" :options="tableOptions" />
        </b-form-group>
      </b-card-header>

      <GroupView
        v-if="selectedTable"
        :key="selectedTable"
        :table-key="selectedTable"
      />

      <!-- <b-card-body class="p-0">
        <component :is="tableComponent" :table="selectedTable" v-bind="table" />
      </b-card-body> -->
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.nav:deep(a) {
  color: var(--text-color);
}

.nav-item {
  cursor: pointer;
}

.card-table-group-item {
  transition: opacity 0.75s, transform 0.5s;

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

<!-- <template>
  <div class="card-table-group accordion">
    <Draggable
      v-model="configs"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <TransitionGroup
        tag="div"
        type="transition"
        :name="!drag ? 'card-tables' : null"
      >
        <CardTableGroupItem
          v-for="(config, index) in configs"
          :key="config.tableKey"
          v-transition-delay.group="{ index }"
          v-bind="config"
        />
      </TransitionGroup>
    </Draggable>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from '@vue';
import Draggable from 'vuedraggable';

import { store, TablePanels } from '@store';
import { isString } from '@tools/type-guards';

export interface PanelConfig {
  tableKey: TablePanels.ModuleKey;
  loaderOptions?: Nullable<GenericObject>;
}

/** `CardTableGroup` component properties. */
export interface Props {
  /**
   * A mixed array of either table panel module keys, and/or
   * {@link PanelConfig} objects.
   */
  items: (TablePanels.ModuleKey | PanelConfig)[];
}

const props = defineProps<Props>();

const drag = ref(false);

const dragOptions = ref({
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost',
});

/**
 * Filtered list of table panel configs based upon whether or not they're
 * enabled.
 */
const configs = ref<PanelConfig[]>([]);

for (const item of props.items) {
  const config = isString(item) ? { tableKey: item } : item;

  if (!isPanelEnabled(config.tableKey)) continue;

  configs.value.push(config);
}

/**
 * ...
 *
 * @param panel ...
 */
function isPanelEnabled(panel: TablePanels.ModuleKey) {
  return (
    (store.getters as unknown as GenericObject)[
      `tablePanels/${panel}/enabled`
    ] !== false
  );
}
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardTableGroup: ComponentWithProps<Props>;
  }
}

export default { name: 'CardTableGroup' };
</script>

<style lang="scss" scoped>
.card-tables-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.card.ghost {
  opacity: 0.5;
  // border: 3px white dashed !important;
  border: 3px #3f4350 dashed !important;
  background: transparent !important;
  box-shadow: none !important;

  &:deep(*) {
    opacity: 0 !important;
  }
}

.card.card-table-group-item {
  // transition: opacity 0.75s, transform 0.5s;
  // cursor: move;

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: translateY(0);
  }
}
</style> -->
