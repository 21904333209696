<script lang="ts">
import { reactive, computed, defineComponent } from 'vue';
import type { StyleValue } from 'vue/types/jsx';

import { isString, isNumber } from '@tools/type-guards';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ParallaxLayer: ComponentWithProps<Props>;
  }
}

export default defineComponent({ name: 'ParallaxLayer' });
</script>

<script setup lang="ts">
/**
 * `ParallaxLayer` component properties.
 */
export interface Props {
  index: string | number;
  depth: string | number;
  bgSize: string | null;
  bgImage?: string | null;
  bgPos?: string | null;
  bgColor?: string | null;
  yOffset?: string | number | null;
}

const props = withDefaults(defineProps<Props>(), {
  bgImage: null,
  bgPos: null,
  bgColor: null,
  yOffset: null,
});

/** ... */
const pos = reactive({ x: 0, y: 0 });

/** ... */
const transform = computed(() => {
  const yOffset = isNumber(props.yOffset)
    ? props.yOffset
    : isString(props.yOffset)
    ? parseFloat(props.yOffset)
    : 0;

  return `translate(${pos.x}px, ${yOffset + pos.y}px)`;
});

/** ... */
const style = computed(() => {
  const style: StyleValue = {
    zIndex: props.index.toString(),
  };

  if (props.bgImage || props.bgColor) {
    if (props.bgImage) style.backgroundImage = `url(${props.bgImage})`;
    else if (props.bgColor) style.backgroundImage = props.bgColor;

    if (props.bgSize) style.backgroundSize = props.bgSize;
    if (props.bgPos) style.backgroundPosition = props.bgPos;
  }

  return style;
});

/**
 * Call externally at any point to update the parallaxing of element.value.
 */
function updatePosition() {
  const depth = isNumber(props.depth) ? props.depth : parseFloat(props.depth);

  const movement = -(window.scrollY * depth);

  pos.y = movement;
}

defineExpose({ updatePosition });
</script>

<template>
  <Transition appear>
    <div class="parallax-layer" :style="{ ...style, transform }">
      <slot />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  will-change: transform;

  @include app-mobile {
    position: absolute;
    transform: none !important;
    background-position: 50% 100%;
  }
}
</style>
