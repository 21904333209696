<script setup lang="ts">
import { computed, reactive, defineComponent } from 'vue';

import { ensureDate } from '@tools/ensure-date';
import { isString, isNotNullish } from '@tools/type-guards';

/**
 * `FormDatepicker` component properties.
 */
export interface Props {
  value?: Dateish | null;
  state?: boolean | null;
  initialDate?: Dateish;
  min?: 'now' | string | number | Date;
  max?: 'now' | string | number | Date;
  valueAs?: 'string' | 'number' | 'date' | 'auto';
  datepicker?: boolean;
  vertical?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
  state: null,
  initialDate: () => getCurrentDate(),
  min: (o) => addYears(o?.initialDate ?? getCurrentDate(), -50),
  max: (o) => addYears(o?.initialDate ?? getCurrentDate(), 50),
  valueAs: 'auto',
  vertical: false,
});

const emit = defineEmits(['input']);

/** ... */
const valueType = computed(() => {
  // ...
  if (props.valueAs !== 'auto') return props.valueAs;

  // ...
  if (props.value === null) return 'string';

  const type = typeof props.value;

  return type === 'string' ? 'string' : type === 'number' ? 'number' : 'date';
});

/** ... */
const date = computed(() => parseDate(props.value));

/** ... */
const minDate = computed(() =>
  props.min === 'now' ? getCurrentDate() : ensureDate(props.min),
);
/** ... */
const maxDate = computed(() =>
  props.max === 'now' ? getCurrentDate() : ensureDate(props.max),
);

/** ... */
const dayOptions = computed(() => {
  return date.value.month
    ? generateDayOptions(date.value.month, date.value.year)
    : DAY_OPTIONS;
});

/** ... */
const yearOptions = computed(() => {
  const minYear = minDate.value.getFullYear();
  const maxYear = maxDate.value.getFullYear();

  const span = maxYear - minYear;

  return generateNumberOptions(span + 1, minYear).reverse();
});

/** ... */
const datePickerValue = computed(() => {
  const day = date.value.day;
  const month = date.value.month;
  const year = date.value.year;

  return day && month && year ? `${year}-${month}-${day}` : null;
});

const fields = computed(
  () =>
    [
      { key: 'day', label: 'Day', options: dayOptions.value },
      { key: 'month', label: 'Month', options: MONTH_OPTIONS },
      { key: 'year', label: 'Year', options: yearOptions.value },
    ] as const,
);

/**
 * Data object for keeping track of most-recent field values.
 *
 * TODO: Find better/more-efficient way of managing this.
 */
const fieldValues = reactive({
  day: date.value.day,
  month: date.value.month,
  year: date.value.year,
});

/**
 * ...
 *
 * @param params ...
 */
function emitUpdatedValue(params: Partial<DateParams>) {
  const newDateValues = { ...date.value, ...params };

  const day = newDateValues.day ?? fieldValues.day;
  const month = newDateValues.month ?? fieldValues.month;
  const year = newDateValues.year ?? fieldValues.year;

  fieldValues.day = day;
  fieldValues.month = month;
  fieldValues.year = year;

  const newDate = day && month && year ? new Date(year, month - 1, day) : null;

  if (!newDate) return emit('input', null);

  let newValue: Dateish;

  if (valueType.value === 'string') {
    newValue = newDate.toString();
  } else if (valueType.value === 'number') {
    newValue = newDate.getTime();
  } else {
    newValue = newDate;
  }

  emit('input', newValue);
}

/**
 * ...
 *
 * @param key ...
 * @param value ...
 */
function onFieldUpdated(key: 'day' | 'month' | 'year', value: number | null) {
  emitUpdatedValue({ [key]: value });
}

/**
 * ...
 *
 * @param value ...
 */
function onDatePickerInput(value: string) {
  const [y, m, d] = value.split('-');

  const day = d ? parseInt(d) : null;
  const month = m ? parseInt(m) : null;
  const year = y ? parseInt(y) : null;

  emitUpdatedValue({ day, month, year });
}
</script>

<script lang="ts">
/** ... */
export type Dateish = string | number | Date;

/**
 * ...
 */
interface DateParams {
  day: number | null;
  month: number | null;
  year: number | null;
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormDatepicker: ComponentWithProps<Props>;
  }
}

/** ... */
const DATE_PATTERN = /^\d+-\d\d?-\d\d?(?:\s|T|$)/;
/** ... */
const DATE_SPLIT_PATTERN = /-|\s|T/;

/** ... */
const MONTH_OPTIONS = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
];

/** ... */
const DAY_OPTIONS = generateNumberOptions(31);

/**
 * ...
 *
 * @param year ...
 * @return ...
 */
function isALeapYear(year: number) {
  return new Date(year, 1, 29).getMonth() === 1;
}

/**
 * ...
 */
function getCurrentDate() {
  return new Date();
}

/**
 * ...
 *
 * @param fromDate ...
 * @param years ...
 * @returns ...
 */
function addYears(fromDate: Dateish, years: number) {
  const date = new Date();

  date.setFullYear(ensureDate(fromDate).getFullYear() + years);

  return date;
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function parseDate(value: Dateish | null) {
  let day: number | null = null;
  let month: number | null = null;
  let year: number | null = null;

  if (isString(value) && DATE_PATTERN.test(value)) {
    const [y, m, d] = value.split(DATE_SPLIT_PATTERN).map((v) => parseInt(v));

    day = isNotNullish(d) ? d : null;
    month = isNotNullish(m) ? m : null;
    year = isNotNullish(y) ? y : null;
  } else if (isNotNullish(value)) {
    const date = new Date(value);

    day = date.getDate();
    month = date.getMonth() + 1;
    year = date.getFullYear();
  }

  return { day, month, year } as DateParams;
}

/**
 * ...
 *
 * @param count ...
 * @return ...
 */
function generateNumberOptions(count: number, from = 1) {
  const options: ZephyrWeb.OptionItem<number>[] = [];

  for (let n = from; n < from + count; n++) {
    options.push({ text: n.toString(), value: n });
  }

  return options;
}

/**
 * ...
 *
 * @param month ...
 * @param year ...
 * @return ...
 */
function generateDayOptions(month: number, year?: number | null) {
  // Create variable to hold new number of days to inject.
  let numberOfDays: number;

  if (month === 2) {
    // If month is February, calculate whether it is a leap year or not.
    numberOfDays = !year ? 28 : isALeapYear(year) ? 29 : 28;
  } else if (
    month === 4 || // April
    month === 6 || // June
    month === 9 || // September
    month === 11 // November
  ) {
    numberOfDays = 30;
  } else {
    numberOfDays = 31;
  }

  return generateNumberOptions(numberOfDays);
}

export default defineComponent({ name: 'FormDatepicker' });
</script>

<template>
  <div :class="['form-datepicker', { vertical }]">
    <FloatingLabel
      v-for="field in fields"
      :key="field.key"
      :class="`${field.key}-field`"
      :label="field.label"
    >
      <b-input-group v-if="field.key === 'year' && datepicker">
        <b-form-select
          :value="date[field.key]"
          :placeholder="field.label"
          :state="state ?? null"
          :options="field.options"
          @input="onFieldUpdated(field.key, $event)"
        />

        <b-input-group-append>
          <b-form-datepicker
            class="ml-2"
            :value="datePickerValue"
            button-only
            @input="onDatePickerInput"
          />
        </b-input-group-append>
      </b-input-group>

      <b-form-select
        v-else
        :value="date[field.key]"
        :placeholder="field.label"
        :state="state ?? null"
        :options="field.options"
        @input="onFieldUpdated(field.key, $event)"
      />
    </FloatingLabel>
  </div>
</template>

<style scoped lang="scss">
.form-datepicker {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &.vertical {
    flex-direction: column;
    gap: 1rem;
  }

  &:deep(select) {
    border-radius: 0.5rem !important;
  }

  &:deep(.b-form-datepicker > button) {
    border-radius: 0.5rem !important;
  }
}

.form-group {
  margin-bottom: 0;
  min-width: 150px;
}

.day-field,
.month-field,
.year-field {
  flex: 1 0 0;
}
</style>
