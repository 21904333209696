<script lang="ts">
import {
  ref,
  computed,
  reactive,
  inject,
  provide,
  onErrorCaptured,
  defineComponent,
  type Component,
  type PropType,
} from 'vue';

import { alert } from '@services/alert';
import { ensure } from '@services/ensure';
import { isBoolean } from '@tools/type-guards';

import type { ModalStack } from './stack';
import type { EmitModalEventFunction } from './ModalWindow.vue';

/**
 * Default error message that should be displayed to the user in the event a
 * modal is killed.
 */
const ERROR_MESSAGE = `The modal encountered an error and needs to close. If you continue to experience this issue, please contact us at <a href="mailto:${process.env.SUPPORT_EMAIL}.">${process.env.SUPPORT_EMAIL}</a>.`;

/**
 * Structure of the modal instance state interface object returned by the
 * {@link useState} composable.
 */
export type ModalState = ReturnType<typeof useState>;

/**
 * Structure used for defining buttons to be rendered in a modal's footer.
 */
export interface ModalButton {
  classes: string;
  text: string;
  click: (e: unknown) => unknown;
}

/** `ModalDialog` component properties. */
export interface Props {
  title?: string;
  dismissible?: boolean;
  size?: string;
  buttons?: ModalButton[];
  modalComponent: ModalStack.ComponentValue;
}

type UseStateProps = Required<Omit<Props, 'modalComponent'>>;

class ModalError extends Error {
  modalTitle: string;

  constructor(modalTitle: string, reason: unknown) {
    const cause = ensure.error(reason);

    const errorMessage = typeof reason === 'string' ? reason : ERROR_MESSAGE;

    super(errorMessage, { cause });

    this.modalTitle = modalTitle;
  }
}

/**
 * Composable function that constructs an interface for managing the state of
 * a given modal instance.
 */
function useState(props: UseStateProps) {
  const modalEvent = inject('modalEvent') as EmitModalEventFunction;

  const isFullscreen = ref(false);

  const title = computed(() => props.title);
  const dismissible = computed(() => props.dismissible);
  const size = computed(() => props.size);
  const buttons = computed(() => props.buttons);
  const fullscreen = computed(() => isFullscreen.value);

  /** Toggle modal's fullscreen view mode on and off. */
  const toggleFullscreen = (force?: boolean) => {
    isFullscreen.value = isBoolean(force) ? force : !isFullscreen.value;
  };

  /** Close the modal. */
  const close = (result?: unknown) => {
    void modalEvent('close', result);
  };

  /** Dismiss the modal. */
  const dismiss = (reason?: unknown) => {
    void modalEvent('dismiss', reason);
  };

  /** Kill the modal. */
  const kill = (reason?: unknown) => {
    const modalError = new ModalError(title.value, reason);
    void modalEvent('dismiss', reason).finally(() => alert.error(modalError));
  };

  const modal = {
    title,
    dismissible,
    size,
    fullscreen,
    buttons,
    toggleFullscreen,
    close,
    dismiss,
    kill,
  };

  // For backwards compatibility with old modal system.

  provide('$title', title);
  provide('$dismissible', dismissible);
  provide('$size', size);
  provide('$fullscreen', fullscreen);
  provide('$buttons', buttons);
  provide('$toggleFullscreen', toggleFullscreen);
  provide('$close', close);
  provide('$dismiss', dismiss);
  provide('$kill', kill);

  provide('modal', { ...modal });

  return modal;
}

export default defineComponent({
  name: 'ModalDialog',
  inheritAttrs: false,
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    dismissible: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    size: {
      type: String as PropType<string>,
      default: '',
    },
    buttons: {
      type: Array as PropType<ModalButton[]>,
      default: () => [],
    },
    modalComponent: {
      type: [Object, Function] as PropType<ModalStack.ComponentValue>,
      required: true,
    },
  },
  setup: (props) => {
    const modal = reactive(useState(props));

    onErrorCaptured((err) => modal.kill(err));

    const classList = computed(() => {
      const classList: string[] = ['uib-modal-dialog'];

      if (modal.size) classList.push(`modal-${modal.size}`);

      if (modal.fullscreen) classList.push('modal-fullscreen');

      return classList;
    });

    const component = ref<Component | null>(null);

    /** ... */
    const loadComponent = async () => {
      // ...
      if (typeof props.modalComponent !== 'function') {
        component.value = props.modalComponent;

        return;
      }

      const value = await (
        props.modalComponent as ModalStack.ComponentLoader
      )();

      component.value = 'default' in value ? value.default : value;
    };

    void loadComponent();

    return { component, classList };
  },
});
</script>

<template>
  <div :class="classList">
    <div v-if="component" class="uib-modal-content" @click.stop>
      <component :is="component" v-bind="$attrs" v-on="$listeners" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.uib-modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  max-width: calc(100vw - 20px);

  .uib-modal.v-enter &,
  .uib-modal.v-leave-active & {
    transform: translate(0, -25%);
  }

  &.modal-sm-md {
    width: 400px;
  }

  &.modal-lg {
    width: 900px;
  }

  &.modal-xl {
    width: 1200px;
  }

  &.modal-auto {
    width: unset;
  }

  @media (min-width: 768px) {
    width: 600px;
    margin: 30px auto;

    &.modal-xl {
      width: 90%;
      max-width: 1200px;
    }
  }

  &.modal-fullscreen {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 100vw !important;
    height: 100vh !important;
  }
}

.uib-modal-content {
  position: relative;
  background-clip: padding-box;
  outline: 0;
  overflow: hidden;
  border: 1px solid var(--modal-border-color);
  border-radius: 20px;
  // background-color: var(--modal-bg-2);
  background-color: var(--modal-bg);
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  .modal-fullscreen & {
    border-radius: 0 !important;

    > div {
      width: 100vw !important;
      height: 100vh !important;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
