<script lang="ts">
import { computed } from 'vue';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayTextNumber: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayTextNumber' };
</script>

<script setup lang="ts">
/**
 * `DisplayTextNumber` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: number;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
});

const displayValue = computed(() => {
  return props.value.toLocaleString('en', { useGrouping: true });
});
</script>

<template>
  <component :is="tag" class="display-text-number">
    {{ displayValue }}
  </component>
</template>
