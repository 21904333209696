<script lang="ts">
export default { name: 'FormPhone' };
</script>

<script setup lang="ts">
import { reactive, computed, watch } from 'vue';
import {
  parsePhoneNumber,
  AsYouType,
  CountryCode,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

import { countryInfo } from '@values/countries';

import CountryFlag from '@components/CountryFlag.vue';

import FormInput from './FormInput.vue';
import FormSelect from './FormSelect.vue';

/**
 * `FormPhone` component properties.
 */
export interface Props {
  value?: string | null;
  label?: string;
  state?: boolean | null;
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
  label: 'Phone Number',
  state: null,
});

const emit = defineEmits(['input']);

interface CountryCodeInfo {
  countryCode: string;
  dialCode: string;
  flag: string;
}

const countryCodeOptions: ZephyrWeb.OptionItem<CountryCodeInfo>[] =
  Object.values(countryInfo).map((item) => ({
    text: `${item.dialCode} ${item.code}`,
    value: {
      countryCode: item.code,
      dialCode: item.dialCode,
      flag: item.flag,
    },
  }));

const fieldData = reactive(initializeFieldData(props.value));

const countryCode = computed(() => {
  return fieldData.country?.countryCode ?? null;
});

const parsedNumber = computed(() => {
  return parseNumber(
    (fieldData.country?.dialCode ?? '') + (fieldData.number ?? ''),
  );
});

watch(parsedNumber, (value) => {
  emit('input', value?.number ?? null);
});

//#region Helper Functions

function getCountry(code: string) {
  return (
    countryCodeOptions.find(({ value }) => value.countryCode === code)?.value ??
    countryCodeOptions[0]?.value ??
    null
  );
}

function parseNumber(value: string) {
  let parsedNumber;

  try {
    parsedNumber = parsePhoneNumber(value);
  } catch {
    // ...
  }

  return parsedNumber ?? null;
}

function asYouType(value: string) {
  if (!fieldData.country) return;

  if (
    validatePhoneNumberLength(
      value,
      fieldData.country.countryCode as CountryCode,
    ) === 'TOO_LONG'
  ) {
    // Stop the input if possible?
    return;
  }

  try {
    fieldData.number = new AsYouType(
      fieldData.country.countryCode as CountryCode,
    ).input(value);
  } catch {
    //
  }
}

/**
 * ...
 */
function initializeFieldData(value: string | null) {
  let country = getCountry('US');

  let number: string | null = null;

  const parsedNumber = parseNumber(value ?? '');

  if (parsedNumber?.country) {
    country = getCountry(parsedNumber.country);
    number = parsedNumber.nationalNumber;
  }

  return { country, number };
}

//#endregion Helper Functions
</script>

<template>
  <div class="form-phone">
    <div class="d-flex align-items-center">
      <CountryFlag v-if="countryCode" class="ml-2 mt-3" :flag="countryCode" />

      <div class="country-code-field">
        <FormSelect
          v-model="fieldData.country"
          class="select-input"
          label="Country Code"
          :state="state ?? null"
          :options="countryCodeOptions"
        />

        <FormSelect
          v-model="fieldData.country"
          class="select-input-mask"
          label="Country Code"
        >
          <template #first>
            <option :value="fieldData.country">
              {{ fieldData.country?.countryCode }}&nbsp;&nbsp;{{
                fieldData.country?.dialCode
              }}
            </option>
          </template>
        </FormSelect>
      </div>

      <FormInput
        v-model="fieldData.number"
        class="phone-number-field"
        type="tel"
        label="Phone Number"
        :state="state ?? null"
        @input="asYouType"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
// @use 'bootstrap/scss/bootstrap';

@import '@styles/bootstrap/custom-vars.scss';
@import 'bootstrap/scss/bootstrap';

.form-phone {
  display: block;
  width: 100%;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  overflow: hidden;

  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Customize the `:focus` state to imitate native WebKit styles.
  // @include form-control-focus($ignore-warning: true);

  &:has(input:focus) {
    color: var(--input-color);
    background-color: var(--input-bg);
    border-color: #009eff;
    outline: 0;
    box-shadow: 0 0 1px 1px #009eff, 0 0 10px rgb(0 158 255 / 52%);
  }

  &:has(input.is-valid) {
    color: var(--input-color);
    background-color: var(--input-bg);
    border-color: #2fd8b9;
    outline: 0;
    // box-shadow: 0 0 1px 1px #2fd8b9;
    box-shadow: 0 0 1px 1px #2fd8b9, 0 0 10px #2fd8b963 !important;
  }

  // // Placeholder
  // &::placeholder {
  //   color: $input-placeholder-color;
  //   // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  //   opacity: 1;
  // }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  > div {
    display: flex;
  }

  &:deep(.form-group) {
    margin: 0;
  }

  &:deep(.country-code-field) {
    width: 130px;

    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      // ...
      border: 0;
      border-radius: 0;
      box-shadow: none;
      border-right: $input-border-width solid $input-border-color !important;
    }
  }

  &:deep(.phone-number-field) {
    flex-grow: 1;

    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      // ...
      border: 0;
      border-radius: 0;
      box-shadow: none !important;
    }
  }
}

.country-code-field {
  position: relative;
}

.select-input-mask {
  position: relative;
  pointer-events: none;

  &:deep(select) {
    // font-size: 1.25rem !important;
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
    padding-bottom: 0.15rem !important;
  }
}

.select-input {
  position: absolute !important;
  inset: 0;
  opacity: 0;
  z-index: 1;
}
</style>
