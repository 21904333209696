<script lang="ts">
import { ref, computed } from 'vue';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

import { auth, RoleId } from '@auth';
import { secToHmsFilter } from '@filters/sec-to-hms';
import { modals } from '@modals';
import type { Assignment, Report, Certification } from '@models';
import { useStore, Certifications } from '@store';
import { dates } from '@utils/dates';

import CardLoadPlaceholder from '@components/Cards/CardLoadPlaceholder.vue';
import LabelCertificationStatus from '@components/LabelCertificationStatus.vue';

interface BaseItem {
  id: string;
  name: string;
  buttonText: string;
  fn: () => void;
}

interface AssignmentItem extends BaseItem {
  type: 'assignment';
  item: Assignment;
}

interface CertificationItem extends BaseItem {
  type: 'certification';
  item: Certification;
  status: Certification.Status;
}

interface LatestReportItem extends BaseItem {
  type: 'latest report';
  item: Report;
}

declare module 'vue/types/vue' {
  export interface Vue {
    CardRecentActivity: ComponentWithProps<Props>;
  }
}

export default { name: 'CardRecentActivity' };
</script>

<script setup lang="ts">
/**
 * `CardRecentActivity` component properties.
 */
export interface Props {
  assignments?: Assignment[];
  reports?: Report[];
}

/** ... */
type ActivityItem = AssignmentItem | CertificationItem | LatestReportItem;

const store = useStore();

const props = defineProps<Props>();

const loading = ref(false);

const me = computed(() => store.state.me);

// const roleId = computed(() => {
//   return store.state.me.selectedRole?.roleId;
// });

const listMyCertifications: Certifications['listMine'] = () => {
  return store.dispatch('certifications/listMine');
};

const listAvailableCertifications: Certifications['listAvailable'] = () => {
  return store.dispatch('certifications/listAvailable');
};

/** ... */
const items = computed(() => {
  const activityItems: ActivityItem[] = [];

  for (const cert of store.state.certifications.items) {
    if (cert.user.id === me.value.id) {
      // My Certifications
      activityItems.push(createOwnedCertificationItem(cert));
    } else if (
      cert.status === 'AWAITING_REVIEW' ||
      (cert.status === 'REVIEWING' && cert.reviewer?.id === me.value.id)
    ) {
      // Available Certifications for Review or Being Reviewed by Me
      // TODO (Alex) make sure once a cert is reviewed that it's removed from this list
      activityItems.push(createReviewerCertificationItem(cert));
    }
  }

  // Assignments?
  for (const a of props.assignments ?? []) {
    if (dates.isBefore(new Date(), a.dueDate)) {
      activityItems.push(createAssignmentItem(a));
    }
  }

  // Reports?

  // ...
  const latestReport = sortBy(props.reports ?? [], ['createdAt']).pop();

  if (latestReport) {
    activityItems.push(createLatestReportItem(latestReport));
  }

  return activityItems;
});

async function load() {
  loading.value = true;

  const promises: Promise<unknown>[] = [listMyCertifications()];

  if (auth.isActiveRole(RoleId.Reviewer))
    promises.push(listAvailableCertifications());

  await Promise.all(promises);

  loading.value = false;
}

void load();

//#region Helper Functions

/**
 * ...
 *
 * @param cert ...
 * @returns ...
 */
function createOwnedCertificationItem(cert: Certification) {
  const buttonText =
    cert.status === 'NOT_STARTED' ||
    cert.status === 'AWAITING_ATTEMPT' ||
    cert.status === 'AWAITING_REATTEMPT'
      ? 'Submit'
      : 'View';

  const fn = () => {
    if (
      cert.status === 'NOT_STARTED' ||
      cert.status === 'AWAITING_ATTEMPT' ||
      cert.status === 'AWAITING_REATTEMPT'
    ) {
      void modals.certifications.submitAttempt({ certificationId: cert.id });
    } else if (cert.status === 'DENIED') {
      const text = `Denied At: ${
        cert.deniedAt
          ? dates.format(cert.deniedAt, 'MM/dd/yyyy hh:mm:ss a')
          : 'N/A'
      }\n\nNotes: ${cert.notes ? cert.notes : 'Notes Not Found'}`;

      const options = { title: 'REVIEW NOTES', text };

      void modals.util.generalInfo(options);
    } else {
      void modals.certifications.view({ certificationId: cert.id });
    }
  };

  const item: CertificationItem = {
    id: cert.id,
    item: cert,
    name: cert.product.name,
    status: cert.status,
    buttonText,
    type: 'certification',
    fn,
  };

  return item;
}

/**
 * ...
 *
 * @param cert ...
 * @returns ...
 */
function createReviewerCertificationItem(cert: Certification) {
  const name = `${cert.product.name} | ${cert.user.lastName}, ${cert.user.firstName}`;

  const fn = () => {
    void modals.certifications.reviewAttempt({ certificationId: cert.id });
  };

  const item: CertificationItem = {
    id: cert.id,
    item: cert,
    name,
    status: cert.status,
    buttonText: 'Review',
    type: 'certification',
    fn,
  };

  return item;
}

/**
 * ...
 *
 * @param a ...
 * @returns ...
 */
function createAssignmentItem(a: Assignment) {
  const name = `Due: ${dates.format(a.dueDate, 'MM/DD/YYYY')} | ${
    a.scene.name
  }/${a.module.name}`;

  const fn = () => {
    const text = `Scene: ${a.scene.name}\nModule: ${
      a.module.name
    }\nDate Range: ${
      a.startDate ? dates.format(a.startDate, 'MM/DD/YYYY') : 'N/A'
    } - ${
      a.endDate ? dates.format(a.endDate, 'MM/DD/YYYY') : 'N/A'
    }\nMinimum Score: ${
      a.minimumScore ? a.minimumScore : 'N/A'
    }\nTotal Time (hh:mm:ss): ${
      a.totalTime ? secToHmsFilter(a.totalTime) : 'N/A'
    }\nDetails: ${a.details ? a.details : 'N/A'}`;

    const options = {
      title: 'ASSIGNMENT DETAILS',
      text,
    };

    // TODO (Alex) calculate if student has completed assignment

    void modals.util.generalInfo(options);
  };

  const item: AssignmentItem = {
    id: a.id,
    item: a,
    name,
    type: 'assignment',
    buttonText: 'View',
    fn,
  };

  return item;
}

/**
 * ...
 *
 * @param report ...
 * @returns ...
 */
function createLatestReportItem(report: Report) {
  const name = `${dates.format(report.createdAt, 'MM/DD/YYYY HH:mm')} | ${
    report.scene.name
  } | ${report.module.name}`;

  const fn = () => {
    void modals.report.viewReport({ report });
  };

  const item: LatestReportItem = {
    id: uniqueId(),
    item: report,
    name,
    buttonText: 'View',
    type: 'latest report',
    fn,
  };

  return item;
}

//#endregion Helper Functions
</script>

<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center"
      ><h6 class="mb-0">Recent Activity</h6></b-card-header
    >
    <b-card-body class="p-3">
      <CardLoadPlaceholder v-if="loading" :cards="1" :lines="2" />

      <div v-else-if="items.length" class="recent-activity-items-container">
        <div v-for="item in items" :key="item.id" class="recent-activity-item">
          <div class="activity-info-wrapper">
            <div :class="['status', item.type]">{{ item.type }}</div>

            <span>{{ item.name }}</span>

            <span v-if="item.type === 'certification'">
              <LabelCertificationStatus :status="item.status" />
            </span>
          </div>

          <b-button class="action-btn" variant="primary" @click="item.fn">
            {{ item.buttonText }}
          </b-button>
        </div>
      </div>

      <div v-else>
        <p><i>No Activity Detected</i></p>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped lang="scss">
.status {
  padding: 2px 5px;
  margin-right: 10px;
  width: 90px;
  text-align: center;
  border-radius: 5px;
  color: $dark-grey;
  border: 0;
  font-size: 0.8em;

  &.report {
    background-color: $brand-warning;
  }

  &.certification {
    background-color: $sales-blue-secondary;
  }

  &.assignment {
    background-color: $orange-red;
  }
}

.recent-activity-items-container {
  .recent-activity-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    &:first-child {
      border: 0;
    }

    .label-certification-status {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 0.8em;
      padding: 2px 8px;
      border-radius: 5px;
    }
  }
}

.activity-info-wrapper {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}

.action-btn {
  width: 76px;
  flex-shrink: 0;
  margin-left: 10px;
}
</style>
