var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "site-document" },
    _vm._l(_setup.items, function ({ title, paragraphs, subText }, index) {
      return _c(
        "div",
        { class: { "mb-4": index < _setup.items.length - 1 } },
        [
          title
            ? _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c("b-col", [
                    _c(
                      "h2",
                      { staticClass: "doc-section-title h4 mb-0 mr-4" },
                      [_vm._v(_vm._s(title))]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-row",
            {
              style: { textAlign: _setup.hasSubText ? "justify" : "unset" },
              attrs: { "align-h": "between" },
            },
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _vm._l(paragraphs, function (paragraph) {
                    return [
                      Array.isArray(paragraph)
                        ? _c(
                            "ul",
                            _vm._l(paragraph, function (item) {
                              return _c("li", {
                                domProps: { innerHTML: _vm._s(item) },
                              })
                            }),
                            0
                          )
                        : _c(paragraph.tag, {
                            tag: "component",
                            class: paragraph.classList,
                            style: paragraph.style,
                            domProps: { innerHTML: _vm._s(paragraph.text) },
                          }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          subText
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "simple-info-card" }, [
                      _c(
                        "div",
                        { staticClass: "h6 mb-2" },
                        [
                          _c(_setup.Icon, {
                            attrs: { icon: _setup.faLightbulbOn },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Plain and Simple")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { domProps: { innerHTML: _vm._s(subText) } }),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }