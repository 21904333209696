<script lang="ts">
import { computed } from 'vue';

import { isNumber } from '@tools/type-guards';
import * as currencies from '@utils/currencies';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayTextCurrency: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayTextCurrency' };
</script>

<script setup lang="ts">
/**
 * `DisplayTextCurrency` component properties.
 */
export interface Props {
  /**
   * The currency amount to be displayed.
   */
  value: Nullable<number>;
  /**
   * The currency of the provided amount. Defaults to `usd`.
   */
  currency?: currencies.Currency;
  /**
   * The unit type of the provided amount. Defaults to `primary`.
   */
  unit?: currencies.UnitType;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  currency: 'usd',
  unit: 'primary',
  tag: 'span',
});

const displayValue = computed(() => {
  if (!isNumber(props.value)) return '--';

  let amount: number;

  if (props.unit === 'smallest') {
    amount = currencies.convertUnit(props.value, props.currency, 'primary');
  } else {
    amount = props.value;
  }

  return currencies.format(amount, props.currency);
});
</script>

<template>
  <component :is="tag">{{ displayValue }}</component>
</template>
