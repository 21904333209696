<script lang="ts">
import { faXmark } from '@icons/solid/faXmark';
import { faArrowDownLeftAndArrowUpRightToCenter } from '@icons/solid/faArrowDownLeftAndArrowUpRightToCenter';
import { faArrowUpRightAndArrowDownLeftFromCenter } from '@icons/solid/faArrowUpRightAndArrowDownLeftFromCenter';

import { useModal } from './modal-state';

export default { name: 'ModalHeader' };
</script>

<script setup lang="ts">
/** `ModalHeader` component properties. */
export interface Props {
  minimal?: boolean;
  noTitle?: boolean;
}

defineProps<Props>();

const modal = useModal();
</script>

<template>
  <div :class="['modal-header', { minimal, fullscreen: modal.fullscreen }]">
    <h4 v-if="modal.title && !noTitle" class="flex-grow-1 h6 m-0">
      {{ modal.title }}
    </h4>

    <template v-if="$env.isDevelopment">
      <button
        v-if="!modal.fullscreen"
        title="Maximize modal"
        @click="modal.toggleFullscreen()"
      >
        <Icon :icon="faArrowUpRightAndArrowDownLeftFromCenter" />
      </button>

      <button v-else title="Minimize modal" @click="modal.toggleFullscreen()">
        <Icon :icon="faArrowDownLeftAndArrowUpRightToCenter" />
      </button>
    </template>

    <button v-if="modal.dismissible" title="Close modal" @click="modal.dismiss">
      <Icon :icon="faXmark" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.modal-header {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 15px;
  background-color: var(--modal-header-bg);
  border-color: var(--modal-header-border-color);
  z-index: 2;

  &.minimal {
    background: none !important;
    border: none !important;
  }
}

button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  line-height: 1em;
  padding: 0;
  background: none;
  color: var(--text-color);
  opacity: 1;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }
}
</style>
