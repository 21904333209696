<script setup lang="ts">
import { computed } from 'vue';

import { useModal } from './modal-state';

const modal = useModal();

const buttonList = computed(() => {
  return modal.buttons.map((button, key) => ({ key, ...button }));
});
</script>

<template>
  <div class="modal-footer">
    <div class="modal-footer-group group-left">
      <b-button
        v-for="{ key, classes, text, click } in buttonList"
        :key="key"
        :class="classes"
        @click="click"
      >
        {{ text }}
      </b-button>
      <slot name="group-left" />
    </div>

    <div class="modal-footer-group group-right">
      <slot name="group-right" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-footer {
  display: flex;
  align-items: center;
  text-align: right;
  padding: 15px !important;
  flex-wrap: nowrap !important;
  background-color: var(--modal-header-bg);
  border-top: 1px solid var(--modal-footer-border-color);
  z-index: 2;
}

.modal-footer-group {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 50%;

  &.group-left {
    justify-content: flex-start;
  }

  &.group-right {
    justify-content: flex-end;
  }

  .btn {
    margin: 0 2px;
  }
}
</style>
