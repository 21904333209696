<script lang="ts">
import { modals } from '@modals';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayValue: ComponentWithProps<Props>;
  }
}

function openUrl(link: string) {
  void modals.confirm.externalLinkNotice({ link });
}

export default { name: 'DisplayValue' };
</script>

<script setup lang="ts">
import { computed } from 'vue';

import { stringify } from '@tools/stringify';

import { faArrowUpRightFromSquare } from '@icons/solid/faArrowUpRightFromSquare';

/**
 * `DisplayValue` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value?: unknown;
  /**
   * Text label that should accompany the displayed value.
   */
  label: string;
  /**
   * If `true`, the rendered text will be bolded.
   */
  bold?: boolean;
  /**
   * Target URL the displayed value should link to if clicked.
   */
  href?: string;
  /**
   * If `true`, capitalize "value"
   */
  capitalize?: boolean;
}

const props = defineProps<Props>();

const displayValue = computed(() => {
  let value = stringify(props.value ?? '');

  if (props.capitalize) {
    value = value.toUpperCase();
  }

  return value;
});
</script>

<template>
  <div :class="['display-value', { bold }]">
    <label class="value-label">
      <span class="label-text">{{ label }}</span>

      <slot name="after-label" />
    </label>

    <Spacer />

    <slot>
      <div v-if="href">
        <span class="link" @click="openUrl(href)">
          {{ displayValue }}
          <Icon :icon="faArrowUpRightFromSquare" />
        </span>
      </div>

      <div v-else>{{ displayValue }}</div>
    </slot>
  </div>
</template>

<style scoped lang="scss">
.display-value {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin: 0.5rem 0;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
}

.display-value.bold > :not(.spacer) {
  color: var(--heading-color);
  font-weight: 500;
}

.value-label {
  display: flex;
  align-items: center;
  text-align: start;
  margin: 0;
}

.label-text {
  white-space: nowrap;
}
</style>
