<script lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

import { useStore } from '@store';

declare module 'vue/types/vue' {
  export interface Vue {
    ParallaxWrapper: ComponentWithProps<Props>;
  }
}

export default { name: 'ParallaxWrapper' };
</script>

<script setup lang="ts">
/**
 * `ParallaxWrapper` component properties.
 */
export interface Props {
  factor: number;
}

const props = defineProps<Props>();

const store = useStore();

const el = ref<HTMLDivElement | null>(null);
const offset = ref(0);

onMounted(() => {
  window.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

const transform = computed(() => {
  if (disabled.value) return '';

  const finalOffset = props.factor * offset.value;

  return `translateY(${finalOffset}px)`;
});

const disabled = computed(() => {
  return store.state.displayMode === 'mobile';
});

function onScroll() {
  if (disabled.value || !el.value) return;

  const winCenter = 0.5 * window.outerHeight;

  const { top, height } = el.value.getBoundingClientRect();

  const center = top + height;

  offset.value = Math.round(
    Math.min(winCenter, Math.max(-winCenter, center - winCenter)),
  );
}
</script>

<template>
  <div ref="el" class="parallax-wrapper" :style="{ transform }">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.parallax-wrapper {
  transition: transform 4s;
  transition-timing-function: cubic-bezier(0, 0.83, 0.56, 1.06);
}
</style>
