<template>
  <Transition appear>
    <div class="sidebar-mobile">
      <div class="sidebar-main">
        <SidebarNav />

        <hr />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import SidebarNav from './SidebarNav.vue';
</script>

<script lang="ts">
export default { name: 'SidebarMobile' };
</script>

<style lang="scss" scoped>
.sidebar-mobile {
  transform: translateX(0);
  z-index: 100;
  background-color: transparent;
  transition: transform 0.75s cubic-bezier(0, 1.15, 0.28, 1.02);

  &.v-enter {
    transform: translateX(-300px);
  }

  &.v-leave-active {
    display: none;
  }
}

.sidebar-main {
  padding: 0 20px;
}
</style>
