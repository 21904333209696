<template>
  <div class="email-key-table-cell">
    <span v-b-tooltip.hover.right="'Click to copy'" @click="copyKey">
      {{ email }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import type { User } from '@models';
import { clipboard } from '@services/clipboard';
/**
 * `TableCellUserEmail` component properties.
 */
export interface Props {
  item: User;
}

const props = defineProps<Props>();

const email = computed(() => props.item.email);

/**
 * Copy email to clipboard.
 */
async function copyKey() {
  await clipboard.copy(email.value);
}
</script>
<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellUserEmail: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellUserEmail' };
</script>

<style scoped lang="scss">
.email-key-table-cell {
  white-space: nowrap;
  cursor: pointer;
  color: var(--blue);
  transition: color 0.5s;

  &:hover {
    color: $orange;
  }
}
</style>
