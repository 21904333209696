<script lang="ts">
import usiLogo from '@assets/images/partner-logos/USI_logo.png';
import usiLogo2x from '@assets/images/partner-logos/USI_logo-2x.png';

import skiducLogo from '@assets/images/partner-logos/skiduc-logo.png';
import skiducLogo2x from '@assets/images/partner-logos/skiduc-logo-2x.png';

import skyopLogo from '@assets/images/partner-logos/skyop-logo.png';
import skyopLogo2x from '@assets/images/partner-logos/skyop-logo-2x.png';

import dronePilotGroundSchoolLogo from '@assets/images/partner-logos/drone-pilot-ground-school-logo.png';
import dronePilotGroundSchoolLogo2x from '@assets/images/partner-logos/drone-pilot-ground-school-logo-2x.png';

import droneHiveIncLogo from '@assets/images/partner-logos/drone-hive-inc-logo.png';
import droneHiveIncLogo2x from '@assets/images/partner-logos/drone-hive-inc-logo-2x.png';

import itcLogo from '@assets/images/partner-logos/itc-logo.png';
import itcLogo2x from '@assets/images/partner-logos/itc-logo-2x.png';

import avisightDroneAcademyLogo from '@assets/images/partner-logos/avisight-drone-academy-logo.png';
import avisightDroneAcademyLogo2x from '@assets/images/partner-logos/avisight-drone-academy-logo-2x.png';

import fpvAustraliaLogo from '@assets/images/partner-logos/fpv-australia-logo.png';
import fpvAustraliaLogo2x from '@assets/images/partner-logos/fpv-australia-logo-2x.png';

import cloudCountyCommunityCollegeLogo from '@assets/images/partner-logos/cloud-county-community-college-logo.png';
import cloudCountyCommunityCollegeLogo2x from '@assets/images/partner-logos/cloud-county-community-college-logo-2x.png';

import aviationMaintenanceLogo from '@assets/images/partner-logos/aviation-maintenance-logo.png';
import aviationMaintenanceLogo2x from '@assets/images/partner-logos/aviation-maintenance-logo-2x.png';

import sdstateLogo from '@assets/images/partner-logos/sdstate-logo.png';
import sdstateLogo2x from '@assets/images/partner-logos/sdstate-logo-2x.png';

import creightonUniversityLogo from '@assets/images/partner-logos/creighton-university-logo.png';
import creightonUniversityLogo2x from '@assets/images/partner-logos/creighton-university-logo-2x.png';

import unmannedExpertsLogo from '@assets/images/partner-logos/unmanned-experts-logo.png';
import unmannedExpertsLogo2x from '@assets/images/partner-logos/unmanned-experts-logo-2x.png';

import heatspringLogo from '@assets/images/partner-logos/heatspring-logo.png';
import heatspringLogo2x from '@assets/images/partner-logos/heatspring-logo-2x.png';

import womenAndDronesLogo from '@assets/images/partner-logos/women-and-drones-logo.png';
import womenAndDronesLogo2x from '@assets/images/partner-logos/women-and-drones-logo-2x.png';

import usasLogo from '@assets/images/partner-logos/usas-logo.png';
import usasLogo2x from '@assets/images/partner-logos/usas-logo-2x.png';

interface PartnerConfig {
  id: string;
  link: string;
  color: string;
  image: string;
  image2x: string;
}

interface Partner {
  id: string;
  link: string;
  color: string;
  logo: string;
}

export const PARTNERS: PartnerConfig[] = [
  {
    id: 'usi',
    link: 'https://www.flyusi.org/',
    color: '#b3f2ff',
    image: usiLogo,
    image2x: usiLogo2x,
  },
  {
    id: 'skiduc',
    link: 'https://www.sciduc.org/',
    color: 'white',
    image: skiducLogo,
    image2x: skiducLogo2x,
  },
  {
    id: 'skyop',
    link: 'https://www.skyop.com',
    color: '#f37e00',
    image: skyopLogo,
    image2x: skyopLogo2x,
  },
  {
    id: 'drone-pilot-ground-school',
    link: 'http://dronepilotgroundschool.com/?affcode=23044_vpplcfhs',
    color: '#09587f',
    image: dronePilotGroundSchoolLogo,
    image2x: dronePilotGroundSchoolLogo2x,
  },
  {
    id: 'drone-hive-inc',
    link: 'http://www.dronehiveinc.com',
    color: '#287753',
    image: droneHiveIncLogo,
    image2x: droneHiveIncLogo2x,
  },
  {
    id: 'itc',
    link: 'http://www.infraredtraining.com/',
    color: '#732020',
    image: itcLogo,
    image2x: itcLogo2x,
  },
  {
    id: 'avisight-drone-academy',
    link: 'https://www.avisightdroneacademy.com',
    color: '#4254de',
    image: avisightDroneAcademyLogo,
    image2x: avisightDroneAcademyLogo2x,
  },
  {
    id: 'fpv-australia',
    link: 'http://fpvaustralia.com.au/',
    color: '#9c6500',
    image: fpvAustraliaLogo,
    image2x: fpvAustraliaLogo2x,
  },
  {
    id: 'cloud-county-community-college',
    link: 'http://www.cloud.edu',
    color: '#e4cb00',
    image: cloudCountyCommunityCollegeLogo,
    image2x: cloudCountyCommunityCollegeLogo2x,
  },
  {
    id: 'aviation-maintenance',
    link: 'http://www.aviationmaintenance.edu',
    color: '#00065c',
    image: aviationMaintenanceLogo,
    image2x: aviationMaintenanceLogo2x,
  },
  {
    id: 'sdstate',
    link: 'https://www.sdstate.edu',
    color: '#0095e0',
    image: sdstateLogo,
    image2x: sdstateLogo2x,
  },
  {
    id: 'creighton-university',
    link: 'https://www.creighton.edu',
    color: '#e6e6e6',
    image: creightonUniversityLogo,
    image2x: creightonUniversityLogo2x,
  },
  {
    id: 'unmanned-experts',
    link: 'https://www.unmannedexperts.com',
    color: 'white',
    image: unmannedExpertsLogo,
    image2x: unmannedExpertsLogo2x,
  },
  {
    id: 'heatspring',
    link: 'https://www.heatspring.com',
    color: '#f2f4f3',
    image: heatspringLogo,
    image2x: heatspringLogo2x,
  },
  {
    id: 'women-and-drones',
    link: 'https://womenanddrones.com',
    color: '#c7f7ff',
    image: womenAndDronesLogo,
    image2x: womenAndDronesLogo2x,
  },
  {
    id: 'usas',
    link: 'https://www.unmannedsafetyinstitute.org',
    color: '#81b7ff',
    image: usasLogo,
    image2x: usasLogo2x,
  },
];

export default { name: 'SectionProudPartners' };
</script>

<script setup lang="ts">
const partners: Partner[] = PARTNERS.map(({ image, image2x, ...config }) => ({
  ...config,
  logo: `-webkit-image-set(url('${image}') 1x, url('${image2x}') 2x)`,
}));
</script>

<template>
  <b-container class="section-proud-partners">
    <b-row class="mb-5">
      <b-col lg="8" class="mx-auto">
        <h2>Proud Partners</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="partner-cards-wrapper">
          <div
            v-for="{ id, ...partner } in partners"
            :key="id"
            class="logo-image-container"
            data-anim-name="slideInUp"
            data-anim-group="1"
          >
            <a
              target="_blank"
              :href="partner.link"
              :style="{ backgroundColor: partner.color }"
            >
            </a>

            <div
              class="logo-image"
              :style="{ backgroundImage: partner.logo }"
            ></div>
          </div>
        </div>

        <div class="partner-cards-bg"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.partner-cards-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  background: transparent;
}

.logo-image-container {
  position: relative;

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 200px;
    margin: 10px;
    padding: 20px;
    border-radius: 40px;
    box-shadow: var(--panel-box-shadow);
    backface-visibility: hidden;
    transform: scale(1);
    transition: 0.25s;
    z-index: 1;

    &:not(:hover) {
      // background-color: white !important;
    }

    &:hover {
      transform: scale(1.05);
    }

    @include app-mobile {
      width: 150px;
      height: 120px;
    }
  }

  a:hover + .logo-image {
    transform: scale(1.1);
    filter: grayscale(0) drop-shadow(0 3px 3px rgba(0, 0, 0, 0.25));
  }
}

.logo-image {
  position: absolute;
  inset: 0;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none;
  transform: scale(0.8);
  // filter: grayscale(1) drop-shadow(0 2px 5px rgba(0, 0, 0, 0.25));
  transition: 0.25s;
  z-index: 9999;
}

.partner-cards-bg {
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    // inset: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    // background: linear-gradient(-45deg, #ff3d00, #ff9e2c);
    // background: linear-gradient(-45deg, #ffca00, #fb8319);
    background: linear-gradient(-45deg, #ffca00, #ff3d00);
    content: '';
    clip-path: polygon(0% 20%, 100% 0%, 100% 100%, 0% 80%);
  }
}
</style>
