<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    ModalSplitView: ComponentWithProps<Props>;
  }
}

export default { name: 'ModalSplitView' };
</script>

<script setup lang="ts">
import { ref, computed, watch, watchEffect } from 'vue';

import { useModal } from '@modals/modal-state';

import { useStore } from '@store';

import AnimatedSuccess from '@components/AnimatedSuccess.vue';
import AnimatedError from '@components/AnimatedError.vue';
import Icon, { type IconDefinition } from '@components/Icon.vue';
import Overlay from '@components/Overlay.vue';
import Spinner from '@components/Spinner.vue';

import { faChevronLeft } from '@icons/solid/faChevronLeft';
import { faXmark } from '@icons/solid/faXmark';

export interface ViewPane {
  id: string;
  label: string;
  icon: IconDefinition;
  description: string;
}

/**
 * `ModalSplitView` component properties.
 */
export interface Props {
  panes: ViewPane[];
  /**
   * If `true`, a "loading" overlay will display instead of the modal body
   * content.
   */
  loading?: boolean;
  /**
   * If `true`, a "processing" overlay will display over the modal body content.
   */
  processing?: boolean;
  /**
   * If `true`, a green checkmark overlay will display over the modal body content.
   */
  success?: boolean;
  /**
   * If `true`, a red X overlay will display over the modal body content.
   */
  errored?: boolean;
}

export type Emits = (event: 'panel-changed', paneId: string | null) => void;

export interface ContentSlotProps {
  activePanel: ViewPane | null;
}

export interface Slot {
  default(props: ContentSlotProps): unknown;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  processing: false,
  success: false,
  errored: false,
});

const emit = defineEmits<Emits>();

const store = useStore();

const modal = useModal();

const activePaneId = ref<string | null>(null);

watch(activePaneId, (value) => {
  emit('panel-changed', value);
});

const isMobile = computed(() => store.state.displayMode === 'mobile');

watchEffect(() => {
  // If the display mode changed from 'mobile' and no active pane ID is set, set
  // it to the first pane.
  if (!isMobile.value && !activePaneId.value) {
    activePaneId.value = props.panes[0]?.id ?? null;
  }
});

const activePanel = computed(() => {
  return props.panes.find((pane) => pane.id === activePaneId.value) ?? null;
});

const classList = computed(() => {
  const items: string[] = ['modal-split-view'];

  if (props.processing) {
    items.push('processing');
  }

  if (activePaneId.value) {
    items.push('panel-set');
  }

  return items;
});

function back() {
  activePaneId.value = null;
}

function dismiss() {
  modal.close();
}
</script>

<template>
  <div :class="classList">
    <Overlay v-if="processing" background="transparent">
      <Spinner v-if="!success && !errored" :size="3" unit="rem" />

      <AnimatedSuccess v-if="success" />
      <AnimatedError v-if="errored" />
    </Overlay>

    <div class="nav-pane">
      <div class="nav-pane-side-panel">
        <div v-if="isMobile" class="nav-pane-header">
          <button class="pane-header-btn dismiss-btn" @click="dismiss">
            <Icon :icon="faXmark" />
          </button>
        </div>

        <div
          v-for="pane in panes"
          :key="pane.id"
          :class="['side-panel-item', { active: pane.id === activePaneId }]"
          @click="activePaneId = pane.id"
        >
          <Icon class="mr-3" :icon="pane.icon" />
          <span>{{ pane.label }}</span>
        </div>
      </div>

      <div class="nav-pane-main-panel">
        <div class="nav-pane-header">
          <button v-if="isMobile" class="pane-header-btn" @click="back">
            <Icon class="mr-2" :icon="faChevronLeft" />
            <span>Back</span>
          </button>

          <button class="pane-header-btn dismiss-btn" @click="dismiss">
            <Icon :icon="faXmark" />
          </button>
        </div>

        <div class="main-panel-content">
          <slot v-if="activePanel" v-bind="{ activePanel }" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-split-view:deep() {
  .modal-body-content {
    padding: 40px;
    width: 100%;
    position: relative !important;
  }
}

.nav-pane-header {
  text-align: center;
  //
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3rem;
  padding: 0 1rem;

  @include app-theme-dark {
    color: #e8e8e8;
  }

  @include app-theme-light {
    color: #252525;
  }
}

.pane-header-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  user-select: none;
  opacity: 1;
  transition: opacity 0.25s;

  @include app-theme-dark {
    color: #d4d4d4;
  }

  @include app-theme-light {
    color: #292929;
  }

  &:hover {
    opacity: 0.5;
  }
}

.dismiss-btn {
  font-size: 1.2em;
  margin-left: auto;
}

.nav-pane {
  display: flex;
  min-height: 90vh;
  height: 100%;
}

.nav-pane-side-panel {
  width: 300px;
  flex-shrink: 0;
  border-color: rgba(255, 255, 255, 0.0117647059);
  border-right: 1px solid var(--modal-header-border-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @include app-mobile {
    width: 100%;
    padding-top: 3rem;
    border-right: none;
  }
}

.nav-pane-main-panel {
  flex-grow: 1;
  position: relative;

  @include app-mobile {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 100%;
    background-color: var(--modal-bg);
    transition: left 0.25s;
  }
}

.main-panel-content {
  position: absolute;
  inset: 0;
  top: 4rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modal-split-view.panel-set .nav-pane-main-panel {
  @include app-mobile {
    left: 0 !important;
  }
}

.side-panel-item {
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.25s;

  &:hover {
    background-color: #00000026;
  }

  &.active {
    cursor: default;

    @include app-desktop {
      background-color: #007bff !important;
      color: white;
    }
  }

  .fa-icon {
    width: 20px;
    text-align: center;
  }
}
</style>
