<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    CardOrqaController: ComponentWithProps<Props>;
  }
}

const CARD_BODY_TEXT =
  'The Orqa FPV controller is now available for purchase through Zephyr!\n\nFunctionalities:\n- Built-in rechargeable battery\n- USB-C for connectivity and charging\n\nLook & Feel:\n- Gamepad style ergonomics\n- Perfect fit for small or big hands\n- Robust and solid feel\n- Programmable buttons\n\nDedicated FPV.Ctrl Mobile App to Manage your settings. Search "FPV.Ctrl" on iOS or Android app stores.\n\n';

export default { name: 'CardOrqaController' };
</script>

<script setup lang="ts">
import { computed } from 'vue';

import { modals } from '@modals';
import * as models from '@models';

import CardLoadable from './CardLoadable.vue';

/**
 * `CardOrqaController` component properties.
 */
export interface Props {
  orqaProduct: models.Product | null;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), { loading: false });

const title = computed(() => props?.orqaProduct?.name ?? []);

function openOrqaModal() {
  if (!props?.orqaProduct?.id) return;

  return void modals.shop.productDetails({ productId: props?.orqaProduct?.id });
}
</script>

<template>
  <CardLoadable :loading="!!loading" no-body>
    <b-card-header class="bg-transparent">
      <h4 class="mb-0 text-center">{{ title }}</h4>
    </b-card-header>

    <template v-if="!loading">
      <b-card-body class="orqa-bg">
        <div class="overlay"></div>

        <b-row class="justify-content-center align-items-center">
          <b-col class="col-auto">
            <div>
              <span class="pre-wrap" v-html="CARD_BODY_TEXT"> </span>
            </div>
          </b-col>
        </b-row>

        <div class="flex-row justify-content-center">
          <b-button variant="primary" @click="openOrqaModal()">
            Buy Now
          </b-button>
        </div>
      </b-card-body>
    </template>
  </CardLoadable>
</template>

<style scoped lang="scss">
.orqa-bg {
  background-image: url('@assets/images/orqa-promo.png');
  background-position: center;
  background-size: cover;
  border-radius: 0 0 15px 15px;
  background-color: #191919;
  position: relative;
  color: white;
  text-align: center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #1919197b;
    border-radius: 0 0 15px 15px;
  }
}
</style>
