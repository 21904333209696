<script lang="ts">
import {
  ref,
  computed,
  provide,
  onMounted,
  onUnmounted,
  defineComponent,
  type Component,
} from 'vue';

import { delay } from '@tools/delay';

import { stack, type ModalStack } from './stack';
import { TABBABLE_SELECTOR } from './ModalRoot.vue';
import ModalDialog, { type Props as ModalDialogProps } from './ModalDialog.vue';

export type Instance = InstanceType<typeof import('./ModalWindow.vue').default>;

export default defineComponent({ name: 'ModalWindow' });
</script>

<script setup lang="ts">
/**
 * `ModalWindow` component properties.
 */
export interface Props {
  index: number;
  size?: string;
  classList: string[];
  animation: boolean;
  modalInstance: ModalStack.ModalInstance;
  modalComponent: Component;
  modalProps: object;
  onModalMounted: ModalEventCallback;
  onModalRendered: ModalEventCallback;
  onModalClosed: ModalEventCallback;
}

/** ... */
export interface ModalEventData {
  modalInstance: ModalStack.ModalInstance;
  modalElement: HTMLDivElement;
  modalWindow: ModalStack.WindowData;
}

/** ... */
export type ModalEventCallback = (data: ModalEventData) => void;

/** ... */
export type EmitModalEventFunction = (
  event: 'close' | 'dismiss',
  data: unknown,
) => Promise<void>;

const props = withDefaults(defineProps<Props>(), {
  size: '',
});

const modalWindow = stack.get(props.modalInstance)?.value;

if (!modalWindow) {
  throw new Error(
    '[ModalWindow] modal window data could not be found for the provided modal instance.',
  );
}

const root = ref<HTMLDivElement | null>(null);
const topModalIndex = ref(props.index);

/** ... */
const hasMounted = ref(false);
/** ... */
const destructionScheduled = ref(false);

/** ... */
const zIndex = computed(() => 1_050 + topModalIndex.value * 10);
/** ... */
const transitionDuration = computed(() => (props.animation ? '0.15s' : '0'));

const modalDialogProps = computed(() => {
  const data: ModalDialogProps = {
    modalComponent: props.modalComponent,
    ...props.modalProps,
  };

  if (props.size) {
    data.size = props.size;
  }

  return data;
});

onMounted(() => {
  hasMounted.value = true;
});

onUnmounted(() => {
  if (!destructionScheduled.value) {
    dismiss('$uibUnscheduledDestruction');
  }
});

/**
 * ...
 */
function onBeforeEnter(el: HTMLDivElement) {
  props.onModalMounted(createEventData(el));
}

/**
 * ...
 */
function onAfterEnter(el: HTMLDivElement) {
  props.onModalRendered(createEventData(el));
}

/**
 * ...
 */
function onAfterLeave(el: HTMLDivElement) {
  props.onModalClosed(createEventData(el));
}

/**
 * ...
 *
 * @param result ...
 */
function close(result: unknown) {
  const modal = stack.top;

  if (modal) stack.emit('close', modal.key, result);
}

/**
 * ...
 *
 * @param reason ...
 */
function dismiss(reason?: string) {
  const modal = stack.top;

  if (modal) stack.emit('dismiss', modal.key, reason);
}

/**
 * ...
 *
 * @param event ...
 * @param data ...
 */

const modalEvent: EmitModalEventFunction = async (event, data) => {
  // NOTE: waiting for Vue's next tick prevents errors from occurring in the
  // event the modal is scheduled for destruction immediately upon
  // instantiation. Also add a slight pause (if modal has not mounted yet)
  // so that the user can see the modal briefly before it closes.

  if (!hasMounted.value) {
    await delay(500);
  }

  if (event === 'close') {
    close(data);
  } else if (typeof data === 'string') {
    dismiss(data);
  } else {
    dismiss();
  }
};

provide('modalEvent', modalEvent);

/**
 * ...
 *
 * @param e ...
 */
function backdropClicked(e: MouseEvent) {
  const modal = stack.top;

  if (
    modal &&
    modal.value.backdrop !== 'static' &&
    e.target === e.currentTarget
  ) {
    e.preventDefault();
    e.stopPropagation();

    stack.emit('dismiss', modal.key, 'backdrop click');
  }
}

/**
 * ...
 */
function scheduleDestruction() {
  // modal.value?.scheduleDestruction();
  destructionScheduled.value = true;
}

/**
 * ...
 */
function getFocusableElements() {
  return [
    ...(root.value?.querySelectorAll<HTMLElement>(TABBABLE_SELECTOR) ?? []),
  ].filter(isVisible);
}

defineExpose({
  getFocusableElements,
  scheduleDestruction,
});

//#region Helper Functions

/**
 * ...
 */
function createEventData(modalElement: HTMLDivElement) {
  return {
    modalElement,
    modalInstance: props.modalInstance,
    modalWindow,
  } as ModalEventData;
}

/**
 * ...
 *
 * @param element ...
 * @return ...
 */
function isVisible(element: HTMLElement) {
  return !!(
    element.offsetWidth ||
    element.offsetHeight ||
    element.getClientRects().length
  );
}

//#endregion Helper Functions
</script>

<template>
  <Transition
    appear
    @before-enter="onBeforeEnter"
    @after-enter="onAfterEnter"
    @after-leave="onAfterLeave"
  >
    <div
      ref="root"
      :class="['uib-modal', ...classList]"
      :style="{ transitionDuration, zIndex }"
      role="dialog"
      tabindex="-1"
      @click="backdropClicked"
    >
      <ModalDialog
        v-bind="modalDialogProps"
        @close="close"
        @dismiss="dismiss"
      />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.uib-modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  //
  display: flex;
  justify-content: center;
  align-items: center;

  &.v-enter,
  &.v-leave-active {
    opacity: 0;
  }
}
</style>
