<template>
  <div class="site-documentV2">
    <div v-if="loading"><Spinner /></div>
    <div v-else>
      <div
        v-for="(
          { key, type, text, raw, classList, depth, style, htmlParsed, tabs },
          index
        ) in items"
        :key="key"
      >
        <b-row v-if="type === 'heading'" :class="classList">
          <b-col>
            <h1
              v-if="depth === 1"
              :class="classList ?? ['doc-section-title', 'h1', 'mt-3']"
            >
              {{ text }}
            </h1>
            <h2
              v-if="depth === 2"
              :class="classList ?? ['doc-section-title', 'h2', 'mt-3']"
            >
              {{ text }}
            </h2>
            <h3
              v-if="depth === 3"
              :class="classList ?? ['doc-section-title', 'h3', 'mt-3']"
            >
              {{ text }}
            </h3>
            <h4
              v-if="depth === 4"
              :class="classList ?? ['doc-section-title', 'h4', 'mt-3']"
            >
              {{ text }}
            </h4>
          </b-col>
        </b-row>

        <b-row
          align-h="between"
          :style="{ textAlign: type === 'paragraph' ? 'justify' : 'unset' }"
        >
          <b-col lg="12">
            <div v-if="type === 'list'" class="site-document-list-container">
              <template v-if="type === 'list'">
                <ul v-html="text"></ul>
              </template>
            </div>
            <template v-if="type === 'paragraph'">
              <p :class="[classList ?? ['m-1']]" v-html="text" />
            </template>
          </b-col>
        </b-row>

        <b-row v-if="type === 'tabs'">
          <div
            :class="[
              classList ?? ['site-document-tabs-container', 'mt-3', 'w-full'],
            ]"
          >
            <b-tabs
              active-nav-item-class="font-weight-bold site-document-tabs"
              content-class=" w-full mx-auto"
            >
              <b-tab v-for="{ title, tabParsed } in tabs" :key="key + title">
                <template #title>
                  <div v-if="title.includes('🪟')">
                    <Icon
                      :icon="faWindows"
                      style="font-size: 2em; color: var(--primary)"
                    />
                  </div>
                  <div v-else-if="title.includes('🍎')">
                    <Icon
                      :icon="faApple"
                      style="font-size: 2em; color: lightgray"
                    />
                  </div>
                  <div v-else>{{ title }}</div>
                </template>
                <div v-html="tabParsed"></div>
              </b-tab>
            </b-tabs>
          </div>
        </b-row>

        <table
          v-if="type === 'table'"
          :class="[classList ?? ['table']]"
          v-html="htmlParsed"
        />

        <b-row v-if="type === 'hint-info'">
          <b-col>
            <div class="hint-info-card mb-3 mt-3">
              <div style="display: flex">
                <Icon :icon="faCircleInfo" style="padding-right: 10px" />
                <div :class="classList" v-html="text"></div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="type === 'hint-warning'">
          <b-col>
            <div class="hint-warning-card mb-3 mt-3">
              <div style="display: flex">
                <Icon :icon="faLightbulbOn" style="padding-right: 10px" />
                <div :class="classList" v-html="text"></div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="type === 'hint-danger'">
          <b-col>
            <div class="hint-danger-card mb-3 mt-3">
              <div style="display: flex">
                <Icon
                  :icon="faTriangleExclamation"
                  style="padding-right: 10px"
                />
                <div :class="classList" v-html="text"></div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { uniqueId } from 'lodash';

import { faLightbulbOn } from '@icons/duotone/faLightbulbOn';
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faTriangleExclamation } from '@icons/duotone/faTriangleExclamation';
import { faCircleInfo } from '@icons/duotone/faCircleInfo';
import { marked, TokensList } from 'marked';

/**
 * ...
 */
export type TokenCallback = (token: Token) => Token;
/**
 * ...
 */
export interface Props {
  tokens: string | Token[];
  callback?: TokenCallback;
}

const props = defineProps<Props>();

const loading = ref(false);

const items = ref([] as KeyedToken[]);

watch(
  () => props.tokens,
  async (value) => {
    let list: KeyedToken[] = [];

    if (typeof value === 'string' && value.startsWith('https://')) {
      const pageData = await (await fetch(value)).text();
      list = await returnTokensFromMarkdown(pageData);
    } else if (typeof value === `string`) {
      loading.value = true;
      list = await returnTokensFromMarkdown(value);
      loading.value = false;
    } else {
      list = value.map((item) => ({ key: uniqueId(), ...item }));
    }
    items.value = list;
  },
  { immediate: true },
);

interface HeaderToken {
  type: 'text';
  raw: string;
  text: string;
}

interface Header {
  text: string;
  tokens: HeaderToken[];
}
interface Row {
  text: string;
  token: any[];
}

interface TabHolder {
  text: string;
  htmlParsed: string;
  tabs: Tab[];
}

interface Tab {
  title: string;
  tabParsed: string;
}

/**
 * ...
 */
/**
 * ...
 */
export interface Token {
  type:
    | 'heading'
    | 'paragraph'
    | 'list'
    | 'hint-warning'
    | 'hint-info'
    | 'hint-danger'
    | 'tabs'
    | 'table';
  text: string;
  raw: string;
  depth?: number;
  classList?: string[];
  style?: Partial<CSSStyleDeclaration>;
  header?: Header;
  rows?: Row[];
  htmlParsed?: string;
  tabs?: Tab[];
}

interface KeyedToken extends Token {
  key: string;
}

// region Helper Functions
async function returnTokensFromMarkdown(markdown: string) {
  const tokens = marked.lexer(markdown);
  console.log(tokens);
  return await returnSectionsFromTokens(tokens);
}

const TAB_REGEX = /\{%\s*tab\s*title\s*=\s*"[^"]*"\s*%\}/g;
const TAB_TITLE_REGEX = /\btitle\s*=\s*"([^"]*)"/;

async function returnSectionsFromTokens(tokens: TokensList) {
  const sections: KeyedToken[] = [];
  const hintObjectHolder = {
    type: '' as 'hint-info' | 'hint-warning' | 'hint-danger',
    text: '',
  };

  const tabHolder: TabHolder = {
    text: '',
    htmlParsed: '',
    tabs: [],
  };

  let tabCount = 0;
  for (const token of tokens) {
    /* 
    Styling Color Replacements for our theme
    */
    // Replace color blue styling with the primary color
    token.raw = token.raw.replace(/color:blue/g, 'color:var(--primary)');

    /* 
    Local Page Replacements
    */
    token.raw = token.raw.replace(
      'supported-controllers.md',
      '/supported-controllers',
    );

    let section: Token = {
      type: 'paragraph',
      text: '',
      raw: '',
    };

    if (token.type === 'paragraph') {
      // We're in a tab group
      if (!token.raw.search(/{% tabs %}/)) {
        console.log('started tabs');
        let replaceThis = token.raw.replace(/{% tabs %}/gs, '');

        if (token.raw.includes('{% tab title')) {
          tabCount++;
          console.log('in tab title');
          //grab the title
          const grabTitle = TAB_TITLE_REGEX.exec(token.raw);
          // replace the template text
          replaceThis = replaceThis.replace(TAB_REGEX, '');
          console.log('pushing tab');
          tabHolder.tabs.push({
            title: grabTitle ? grabTitle[1] : 'Title',
            tabParsed: await marked.parse(replaceThis),
          });
          continue;
        }

        tabHolder.text = await marked.parse(replaceThis);
      }

      if (token.raw.includes('{% tab title')) {
        let replaceThis = token.raw;
        tabCount++;
        console.log('in tab title');
        //grab the title
        const grabTitle = TAB_TITLE_REGEX.exec(token.raw);
        // replace the template text
        replaceThis = replaceThis.replace(TAB_REGEX, '');
        console.log('pushing tab');
        tabHolder.tabs.push({
          title: grabTitle ? grabTitle[1] : 'Title',
          tabParsed: await marked.parse(replaceThis),
        });
        continue;
      }

      if (token.raw.includes('{% endtab %}')) {
        console.log('in end tab');
        tabHolder.tabs[tabCount - 1].tabParsed += await marked.parse(
          token.raw.replace('{% endtab %}', ''),
        );
        continue;
      }

      if (token.raw.includes('{% endtabs %}')) {
        console.log('in end tabs');
        // const replaceThis = token.raw.replace('{% endtabs %}', '');
        // tabHolder.text += await marked.parse(replaceThis);
        sections.push({
          key: uniqueId(),
          type: 'tabs',
          tabs: tabHolder.tabs,
          text: tabHolder.text,
          raw: tabHolder.htmlParsed,
        });
        tabHolder.text = '';
        tabHolder.tabs = [];
        tabHolder.htmlParsed = '';
        tabCount = 0;
        continue;
      }

      // Hint Warning Started
      if (!token.raw.search(/{% hint style="warning" %}/)) {
        hintObjectHolder.type = 'hint-warning';
        let replaceThis = token.raw.replace(/{% hint style="warning" %}/gs, '');

        if (token.raw.includes('{% endhint %}')) {
          replaceThis = replaceThis.replace('{% endhint %}', '');
          sections.push({
            key: uniqueId(),
            type: 'hint-warning',
            text: await marked.parse(replaceThis),
            raw: replaceThis,
            classList: ['hint-warning'],
          });
          continue;
        }
        hintObjectHolder.text = await marked.parse(replaceThis);
        //Hint Info Started
      } else if (!token.raw.search(/{% hint style="info" %}/)) {
        hintObjectHolder.type = 'hint-info';
        let replaceThis = token.raw.replace(/{% hint style="info" %}/gs, '');

        if (token.raw.includes('{% endhint %}')) {
          replaceThis = replaceThis.replace('{% endhint %}', '');
          sections.push({
            key: uniqueId(),
            type: 'hint-info',
            text: await marked.parse(replaceThis),
            raw: replaceThis,
            classList: ['hint-info'],
          });
          continue;
        }
        hintObjectHolder.text = await marked.parse(replaceThis);
        //Hint Danger Started
      } else if (!token.raw.search(/{% hint style="danger" %}/)) {
        hintObjectHolder.type = 'hint-danger';
        let replaceThis = token.raw.replace(/{% hint style="danger" %}/gs, '');

        //TODO Eric replace mark tags in here

        // if (replaceThis.includes(`color:red;`))
        //   replaceThis = replaceThis.replace('color:red;', 'color:#770000;');

        if (token.raw.includes('{% endhint %}')) {
          replaceThis = replaceThis.replace('{% endhint %}', '');
          // console.log(await marked.parse(replaceThis));

          sections.push({
            key: uniqueId(),
            type: 'hint-danger',
            text: await marked.parse(replaceThis),
            raw: replaceThis,
            classList: ['hint-danger'],
          });

          continue;
        }

        hintObjectHolder.text = await marked.parse(replaceThis);
        // Closed Hint
      } else if (token.raw.includes('{% endhint %}')) {
        const replaceThis = token.raw.replace('{% endhint %}', '');
        hintObjectHolder.text += await marked.parse(replaceThis);

        section = {
          type: `${hintObjectHolder.type}`,
          text: `${hintObjectHolder.text}`,
          raw: `${hintObjectHolder.text}`,
          classList: [`${hintObjectHolder.type}`],
        };

        //   hintOBject.text = '';
      } else {
        section = {
          type: 'paragraph',
          text: await marked.parse(token.raw),
          raw: token.raw,
          classList: [],
        };
      }
    }

    if (token.type === 'table') {
      console.log(token.raw);
      if (token.raw.includes('{% tab title')) {
        console.log('[TABLE] has tab');
        const grabTitle = TAB_TITLE_REGEX.exec(token.raw);

        const replaceThis = token.raw.replace(TAB_REGEX, '');
        tabCount++;

        //grab the title

        // replace the template text

        console.log(`${grabTitle[1]} pushing tab [Table]`);
        tabHolder.tabs.push({
          title: grabTitle ? grabTitle[1] : 'Title',
          tabParsed: await marked.parse(replaceThis),
        });
      }
      if (
        token.raw.includes('{% endtab %}') &&
        !token.raw.includes('{% endtabs %}')
      ) {
        console.log('in end tab [TABLE]');
        console.log(tabHolder.tabs);

        console.log(tabCount);
        tabHolder.tabs[tabCount - 1].tabParsed += await marked.parse(
          token.raw.replace('{% endtab %}', ''),
        );
        continue;
      } else if (token.raw.includes('{% endtabs %}')) {
        console.log('in end tabs [TABLE]');
        tabHolder.tabs[tabCount - 1].tabParsed += await marked.parse(
          token.raw.replace('{% endtab %}', '').replace('{% endtabs %}', ''),
        );
        // const replaceThis = token.raw.replace('{% endtabs %}', '');
        // tabHolder.text += await marked.parse(replaceThis);
        sections.push({
          key: uniqueId(),
          type: 'tabs',
          tabs: tabHolder.tabs,
          text: tabHolder.text,
          raw: tabHolder.htmlParsed,
          // classList: ['site-document-tabs'],
        });
        tabCount = 0;
        tabHolder.text = '';
        tabHolder.tabs = [];
        tabHolder.htmlParsed = '';

        continue;
      }

      section = {
        type: 'table',
        htmlParsed: await marked.parse(token.raw),
        raw: token.raw,
        text: token.text,
      };
    }

    if (token.type === 'heading') {
      if (token.depth === 2) {
        section = {
          type: 'heading',
          text: token.text,
          raw: token.raw,
          depth: token.depth,
        };
      }
      if (token.depth === 3) {
        section = {
          type: 'heading',
          text: token.text,
          raw: token.raw,
          depth: token.depth,
        };
      }
      if (token.depth === 4) {
        section = {
          type: 'heading',
          text: token.text,
          raw: token.raw,
          depth: token.depth,
        };
      }
    }
    if (token.type === 'list') {
      if (token.raw.includes('{% endhint %}')) {
        const replaceThis = token.raw.replace('{% endhint %}', '');
        hintObjectHolder.text += await marked.parse(replaceThis);

        section = {
          type: `${hintObjectHolder.type}`,
          text: `${hintObjectHolder.text}`,
          raw: `${hintObjectHolder.text}`,
          classList: [`${hintObjectHolder.type}`],
        };
      } else {
        section = {
          type: 'list',
          raw: token.raw,
          text: await marked.parse(token.raw),
        };
      }
    }

    if (props.callback) {
      section = props.callback(section);
    }

    sections.push({
      key: uniqueId(),
      ...section,
    });
  }
  console.log(sections);
  return sections;
}

// endregion Helper Functions
</script>

<style lang="scss">
.site-documentV2 {
  mark {
  }
}

.hint-info {
  a {
    color: var(--primary) !important;
    &:hover {
      color: $orange-red !important;
      text-decoration: none;
      transition-duration: 0.25s;
    }
  }

  mark {
    background-color: #33000000;
  }
}
.hint-warning {
  a {
    color: var(--primary) !important;
    &:hover {
      color: $orange-red !important;
      text-decoration: none;
      transition-duration: 0.25s;
    }
  }

  mark {
    background-color: #33000000;
  }
}
.hint-danger {
  a {
    color: var(--primary) !important;
    &:hover {
      color: $orange-red !important;
      text-decoration: none;
      transition-duration: 0.25s;
    }
  }

  mark {
    background-color: #33000000;
  }
}
.site-document-tabs-container {
  .tabs {
    width: 100%;

    // border-left: 1px solid;
    // border-right: 1px solid;
    // margin-bottom: 10px;
  }

  .nav-tabs {
    @include app-theme-dark {
      border-bottom: 1px solid #dee2e633;
    }
    @include app-theme-light {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .nav-tabs .nav-link.active {
    @include app-theme-dark {
      border-color: #dee2e633 #dee2e633 var(--body-bg);
    }
  }

  .nav-tabs .nav-link:hover {
    @include app-theme-dark {
      border-color: #dee2e633;
    }
  }

  .tab-content {
    // max-width: 50rem;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;

    @include app-theme-dark {
      border-left: 1px solid #dee2e633;
      border-right: 1px solid #dee2e633;
      border-bottom: 1px solid #dee2e633;
    }
    @include app-theme-light {
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
    }
  }
  table {
    grid-area: 1/1;
    table-layout: auto;
    width: 100%;
    text-indent: 0;
    // border-color: var(--light-gray);
    border-collapse: collapse;
    display: table;
    // border: 0 solid #e5e7eb;
    box-sizing: border-box;
  }
  table tr {
    display: table-row;
  }

  table tbody {
    display: table-row-group;
    vertical-align: middle;
  }

  table tbody tr {
    display: table-row;
    vertical-align: inherit;
    padding-left: 1rem;
    border-bottom: 1px solid;
  }

  table tbody tr:last-child {
    border-bottom: none;
  }

  table tbody tr td + td {
    border-left: 1px solid;
    padding-left: 10px;
  }

  table tbody tr td {
    vertical-align: middle;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    min-width: 8rem;
    display: table-cell;

    // border: 1px solid;
  }

  table tr th {
    padding-top: 10px;
    padding-bottom: 0.25rem;
    font-weight: bold;
    display: table-cell;
    border-bottom-width: 1px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid;
  }
  mark {
    background-color: #33000000;
  }
}
.site-document-tabs {
  color: var(--primary) !important;
}

.site-document-list-container {
  ul {
    margin-left: 5px;
    // padding-left: 10px;
  }
  // li {
  //   padding-left: 10px;
  // }
  li {
    margin-left: 15px;
    // padding-left: 10px;
  }
}
</style>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.doc-section-title {
  // color: #1b1e21;
  font-weight: 500 !important;
  flex-shrink: 0;
}

.hint-info-card {
  padding: 30px 30px 20px 30px;
  text-align: left;
  border-radius: 10px;
  //   color: #4e3825;
  // font-size: 0.8rem;

  @include app-theme-dark {
    // background-color: #bdd1f144;
    background-color: #5bc0de22;
  }
  @include app-theme-light {
    background-color: #bdd1f1;
  }

  .fa-icon {
    font-size: 1.2em;
  }

  > div {
    @include app-theme-dark {
      color: rgb(222 232 248);
    }
    @include app-theme-light {
      color: rgb(34 40 48);
    }
  }

  p {
    line-break: loose;
    margin: 0;
  }
}

.hint-warning-card {
  // background-color: #f0ad4e;
  // background-color: #f4e28d22;
  padding: 30px 30px 20px 30px;
  text-align: left;
  border-radius: 10px;
  // color: #4e3825;
  // color: rgb(251, 243, 209);
  // font-size: 0.8rem;

  @include app-theme-dark {
    background-color: #f4e28d22;
  }
  @include app-theme-light {
    background-color: #f4e28d66;
  }

  .fa-icon {
    font-size: 1.2em;
  }

  > div {
    @include app-theme-dark {
      color: rgb(251, 243, 209);
    }
    @include app-theme-light {
      color: #4e3825;
    }
  }

  p {
    line-break: loose;
    margin: 0;
  }
}

.hint-danger-card {
  // background-color: #d9534f;

  padding: 30px 30px 20px 30px;
  text-align: left;
  border-radius: 10px;
  //   color: #4e3825;
  // font-size: 0.8rem;

  @include app-theme-dark {
    background-color: #33000055;
  }

  @include app-theme-light {
    background-color: #91372355;
  }

  .fa-icon {
    font-size: 1.2em;
  }

  > div {
    // color: #f57c61;
    @include app-theme-dark {
      color: rgb(252, 222, 216);
    }
    @include app-theme-light {
      color: rgb(48 18 12);
    }
  }

  p {
    line-break: loose;
    margin: 0;
  }
}
</style>
