<template>
  <ul class="sidebar-list">
    <li
      v-for="item in items"
      :key="item.key"
      class="sidebar-list-item"
      :class="[{ 'coming-soon': item.comingSoon }]"
      v-b-tooltip.left="navItemTooltip(item)"
    >
      <Transition appear>
        <RouterLink
          v-bind="item.link"
          class="sidebar-link"
          exact-active-class="active"
          :class="[{ disabled: item.comingSoon }]"
        >
          <span class="icon-wrapper">
            <Icon :icon="item.icon" />
          </span>

          <span>{{ item.label }}</span>
        </RouterLink>
      </Transition>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import { useGetter } from '@store';

import { DashboardMenuItem } from '@values/dashboard-menus';

const menu = useGetter('dashboardMenu');
const activeRole = useGetter('activeRole');

const items = computed(() => {
  const keyPrefix = activeRole.value?.id ?? 0;

  return menu.value
    .filter(({ sub }) => !sub)
    .map(({ id, label, route, icon, comingSoon }, i) => {
      // Nav item key.
      const key = `${keyPrefix}-${id}`;
      // Nav item target.
      const to = { name: route };
      // Nav item style.
      const style = { transitionDelay: `${0.04 * i}s` };

      const link = { key, to, style };

      return { key, label, icon, link, comingSoon };
    });
});

const navItemTooltip = (item: DashboardMenuItem) => {
  return item.comingSoon ? 'Coming Soon!' : null;
};
</script>

<script lang="ts">
export default { name: 'SidebarNav' };
</script>
