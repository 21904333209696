<template>
  <div ref="grid" class="layout-masonry">
    <slot v-bind="{ onItemMounted }" @item-mounted="onItemMounted" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const grid = ref<HTMLDivElement | null>(null);

/**
 * ...
 *
 * @param item ...
 */
function onItemMounted(item: HTMLDivElement) {
  if (!grid.value) return;

  const rowHeight = parseInt(
    window.getComputedStyle(grid.value).getPropertyValue('grid-auto-rows'),
  );

  const rowGap = parseInt(
    window.getComputedStyle(grid.value).getPropertyValue('grid-row-gap'),
  );

  const itemInner = item.querySelector(':scope > div');

  if (!itemInner) return;

  const rowSpan = Math.ceil(
    (itemInner.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap),
  );

  // console.log({ rowHeight, rowGap, rowSpan });

  item.style.gridRowEnd = `span ${rowSpan}`;
}
</script>

<style scoped lang="scss">
.layout-masonry {
  gap: 0 60px;
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: 20px;
}
</style>
