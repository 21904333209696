<template>
  <CardLoadable :loading="!!loading" no-body>
    <b-card-header>
      <h5 class="mb-0">Assignments</h5>
    </b-card-header>

    <b-card-body class="p-0">
      <b-table
        class="m-0"
        :items="assignments"
        :fields="FIELDS"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        empty-text="No Assignments"
        empty-filtered-text="No Assignments Found"
      />

      <b-container>
        <b-row class="p-3">
          <b-col col>
            <b-pagination
              v-model="currentPage"
              size="sm"
              class="m-0"
              :total-rows="rows"
              :per-page="perPage"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card-body>
  </CardLoadable>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import type { BvTableFieldArray } from 'bootstrap-vue';

import CardLoadable from '@components/Cards/CardLoadable.vue';

import { dateFilter } from '@filters/date';
import { secToHmsFilter } from '@filters/sec-to-hms';
import { ScoredAssignment } from '@utils/reports';

/**
 * `CardAssignments` component properties.
 */
export interface Props {
  assignments: ScoredAssignment[];
  loading?: boolean;
}

const FIELDS: BvTableFieldArray = [
  {
    key: 'dueDate',
    label: 'Due Date',
    sortable: true,
    formatter: (value: string) => {
      return dateFilter(value, 'MM/dd/yyyy');
    },
  },
  {
    key: 'dateRange',
    label: 'Date Range',
    formatter: (_value, _key, { startDate, endDate }: ScoredAssignment) => {
      return dateRangeFormatter(startDate, endDate);
    },
  },
  {
    key: 'minimumScore',
    label: 'Minimum Score',
    formatter: (value: ScoredAssignment['minimumScore']) => {
      return value ? `${value}` : 'N/A';
    },
  },
  {
    key: 'totalTime',
    label: 'Total Time (hh:mm:ss)',
    formatter: (totalTime: ScoredAssignment['totalTime']) => {
      return totalTime ? secToHmsFilter(totalTime) : 'N/A';
    },
  },
  {
    key: 'module',
    label: 'Scene/Module',
    formatter: (_value, _key, { module, scene }: ScoredAssignment) => {
      return `${scene.name} | ${module.name}`;
    },
    sortable: true,
  },
  {
    key: 'details',
    label: 'Details',
    sortable: true,
  },
  {
    key: 'report',
    label: 'Completed',
    sortable: true,
    formatter: (value: ScoredAssignment['report']) => {
      return `${value.completed ? 'Yes' : 'No'}`;
    },
  },
];

const props = defineProps<Props>();

const perPage = ref(5);
const currentPage = ref(1);

/** ... */
const rows = computed(() => {
  return props.assignments.length;
});

//#region Helper Functions

/**
 * ...
 *
 * @param startDate ...
 * @param endDate ...
 * @return ...
 */
function dateRangeFormatter(startDate: unknown, endDate: unknown) {
  if (typeof startDate !== 'string' || typeof endDate !== 'string') {
    return 'N/A';
  }

  const formattedStartDate = dateFilter(startDate, 'MM/dd/yyyy').toString();
  const formatteEndDate = dateFilter(endDate, 'MM/dd/yyyy').toString();

  return `${formattedStartDate} - ${formatteEndDate}`;
}

//#endregion Helper Functions
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardAssignments: ComponentWithProps<Props>;
  }
}

export default { name: 'CardAssignments' };
</script>

<style lang="scss" scoped>
.card {
  font-size: 0.8rem;
}

.card-header {
  border: none !important;
}

.container {
  border-top: 1px solid var(--border-color);
}
</style>
