<script lang="ts">
import { computed } from 'vue';

import type { Invoice } from '@models';
import { clipboard } from '@services/clipboard';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellInvoiceNumber: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellInvoiceNumber' };
</script>

<script setup lang="ts">
/**
 * `TableCellInvoiceNumber` component properties.
 */
export interface Props {
  item: Invoice;
}

const props = defineProps<Props>();

const invoiceNumber = computed(() =>
  props.item.invoiceNumber ? props.item.invoiceNumber : props.item.id,
);

/**
 * Copy invoiceNumber to clipboard.
 */
async function copyKey() {
  await clipboard.copy(invoiceNumber.value);
}
</script>

<template>
  <div class="invoice-number-cell">
    <span v-b-tooltip.hover.right="'Click to copy'" @click="copyKey">
      {{ invoiceNumber }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.invoice-number-cell {
  white-space: nowrap;
  cursor: pointer;
  color: var(--blue);
  transition: color 0.5s;

  &:hover {
    color: $orange;
  }
}
</style>
