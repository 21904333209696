<script lang="ts">
import { computed } from 'vue';

import { dateFilter } from '@filters/date';
import * as models from '@models';
import { useGetter } from '@store';
import { nameFormat } from '@utils/name-format';

import { faInfoCircle } from '@icons/duotone/faInfoCircle';

import CardLoadable from './CardLoadable.vue';

declare module 'vue/types/vue' {
  interface Vue {
    CardCurrentCourse: ComponentWithProps<Props>;
  }
}

export default { name: 'CardCurrentCourse' };
</script>

<script setup lang="ts">
/**
 * `CardCurrentCourse` component properties.
 */
export interface Props {
  organization: models.Organization | null;
  course: models.Course | null;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), { loading: false });

const getCourseStatus = useGetter('courses/getCourseStatus').value;

const status = computed(() => {
  const label = props.course ? getCourseStatus(props.course.id) : '--';

  let className = '';

  if (label === 'IN_PROGRESS') {
    className = 'success';
  } else if (label === 'UPCOMING') {
    className = 'warning';
  } else if (label === 'ENDED') {
    className = 'negative';
  }

  return { label, className };
});

const startDate = computed(() => props.course?.startDate ?? null);
const endDate = computed(() => props.course?.endDate ?? null);

const displayCourseName = computed(() => props.course?.name ?? '--');

const displayDateRange = computed(() => {
  if (!startDate.value || !endDate.value) return '--';

  return (
    dateFilter(startDate.value, 'MM/dd/yyyy').toString() +
    ' - ' +
    dateFilter(endDate.value, 'MM/dd/yyyy').toString()
  );
});

const displayTeacherInfo = computed(() => {
  return props.course ? nameFormat(props.course.teacher) : '--';
});

const displayAssistantInfo = computed(() => {
  return props.course?.teacherAssistant
    ? nameFormat(props.course.teacherAssistant)
    : '--';
});
</script>

<template>
  <CardLoadable :loading="!!loading" no-body>
    <b-card-header class="bg-transparent">
      <h6 class="mb-0">Course</h6>
    </b-card-header>

    <template v-if="!loading">
      <OverlayRenderIssue v-if="!organization || !course" />

      <b-card-body>
        <b-card-title>{{ displayCourseName }}</b-card-title>

        <b-card-sub-title class="mb-2">
          <span>{{ displayDateRange }}</span>
          <span>
            <Icon :icon="faInfoCircle" :class="[status.className]" />
            <span :class="[status.className]">{{ status.label }}</span>
          </span>
        </b-card-sub-title>

        <p class="mb-1">Instructor: {{ displayTeacherInfo }}</p>
        <p class="mb-1">Instructor Assistant: {{ displayAssistantInfo }}</p>
      </b-card-body>
    </template>
  </CardLoadable>
</template>

<style scoped lang="scss">
.card {
  font-size: 0.8rem;
}
</style>
