<script setup lang="ts">
import { computed } from 'vue';
import { sortBy } from 'lodash';

import type { User } from '@models';
import { useStore } from '@store';

/**
 * `TableCellUserRoles` component properties.
 */
export interface Props {
  /** ... */
  item: User;
}

const store = useStore();

const props = defineProps<Props>();

const roleId = computed(() => {
  return store.state.me.selectedRole?.roleId;
});

const organizationId = computed(() => {
  return store.state.me.selectedRole?.organization?.id;
});

/** ... */
const roles = computed(() => {
  let items = props.item.roles ?? [];

  if (roleId.value !== 9 && organizationId.value) {
    items = items.filter(
      ({ organization }) => organization?.id === organizationId.value,
    );
  }

  return sortBy(items, ['id']).reverse();
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellUserRoles: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellUserRoles' };
</script>

<template>
  <div class="user-roles-table-cell">
    <template v-if="roles.length">
      <div v-for="role in roles" :key="role.id">
        <span>•</span>
        <span class="role-name pr-1">{{ role.name }}</span>

        <span v-if="role.organization?.name" class="role-institution">
          <span class="sub-label">Institution:</span>
          <span class="sub-value">{{ role.organization.name }}</span>
        </span>

        <span v-if="role.course?.name">, </span>

        <span v-if="role.course?.name" class="role-course">
          <span class="sub-label">Course:</span>
          <span class="sub-value">{{ role.course.name }}</span>
        </span>
      </div>
    </template>

    <span v-else> ... </span>
  </div>
</template>

<style scoped lang="scss">
.user-roles-table-cell {
  white-space: nowrap;
}

.role-institution {
  font-size: 0.8em;
}

.role-course {
  font-size: 0.8em;
}

.sub-label {
  color: #ff8935;
}

.sub-value {
  opacity: 0.75;
}
</style>
