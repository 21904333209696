<script setup lang="ts">
import { ref, computed } from 'vue';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { faSortCircle as faSortCircleRegular } from '@icons/regular/faSortCircle';
import { faSortCircle as faSortCircleSolid } from '@icons/solid/faSortCircle';

import Overlay from '@components/Overlay.vue';
import { dateFilter } from '@filters/date';
import { modals } from '@modals';
import { Announcements } from '@store';

import NavDropdown from './NavDropdown.vue';

/**
 * `AnnouncementsDropdown` component properties.
 */
export interface Props {
  announcements: Announcements.UserAnnouncement[];
}

const props = defineProps<Props>();

const dropdown = ref<InstanceType<typeof NavDropdown> | null>(null);

const sortByUnread = ref(false);

/** Announcement list items. */
const items = computed(() => {
  let announcements = sortBy(uniqBy(props.announcements, 'id'), [
    'createdAt',
  ]).reverse();

  if (sortByUnread.value) {
    announcements = sortBy(announcements, ['read']);
  }

  return announcements.map((o) => {
    const date = dateFilter(o.createdAt, 'MM/dd/yyyy') as string;
    const time = dateFilter(o.createdAt, 'h:mm') as string;
    const createdAt = `${date} at ${time}`;

    const message = generateMessagePreview(o.message);

    return { ...o, createdAt, message };
  });
});

const sortBtnIcon = computed(() => {
  return sortByUnread.value ? faSortCircleSolid : faSortCircleRegular;
});

const sortBtnTooltip = computed(() => {
  return sortByUnread.value ? 'Sort by date.' : 'Sort by unread.';
});

/**
 * ...
 */
function toggle() {
  dropdown?.value?.toggle();
}

defineExpose({ toggle });

/**
 * Toggle sort mode.
 */
function toggleSortMode() {
  sortByUnread.value = !sortByUnread.value;
}

/**
 * View a specific announcement.
 *
 * @param announcement The announcement to display.
 */
function view(announcement: Announcements.UserAnnouncement) {
  close();

  void modals.announcement.view({ announcement });
}

//#region Helper Functions

/**
 * ...
 *
 * @param source ...
 * @return ...
 */
function generateMessagePreview(source: string) {
  const el = document.createElement('div');
  el.innerHTML = source;

  const preview = el.innerText;

  el.remove();

  return preview;
}

//#endregion Helper Functions
</script>

<template>
  <NavDropdown ref="dropdown" width="450px" height="50vh" no-body-padding>
    <template #header>
      <div
        v-b-tooltip="sortBtnTooltip"
        :class="['sort-btn', { active: sortByUnread }]"
        @click="toggleSortMode"
      >
        <Icon :icon="sortBtnIcon" />
      </div>
    </template>

    <Overlay v-if="!announcements.length" background="transparent">
      <div class="h4 text-center text-muted">
        You don't have any announcements.
      </div>
    </Overlay>

    <div v-else class="announcement-list-items">
      <ul>
        <li
          v-for="item in items"
          :key="item.id"
          :class="['list-item', { unread: !item.read }]"
          @click="view(item)"
        >
          <div class="item-header">
            <div class="item-title">
              <span>{{ item.title }}</span>
            </div>

            <div class="item-date">{{ item.createdAt }}</div>
          </div>

          <div class="item-content-preview">
            <div v-html="item.message"></div>
            <span class="ellipsis">...</span>
          </div>
        </li>
      </ul>
    </div>
  </NavDropdown>
</template>

<style scoped lang="scss">
.sort-btn {
  background-color: transparent;
  padding: 0.25rem 1rem;
  transition: background-color 0.25s;
  border-radius: 0.25rem;

  &:hover {
    background-color: #00000017;
  }

  &.active {
    color: dodgerblue;
  }

  .fa-icon {
    pointer-events: none;
  }
}

.announcement-list-items {
  position: absolute;
  inset: 0;
  overflow: auto;
}

ul {
  padding: 1rem;
  margin: 0;
  list-style: none;
  // width: 450px;
  // height: 50vh;
  overflow: scroll;
}

.list-item {
  position: relative;
  padding: 1rem 1rem 1rem 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  &:hover {
    @include app-theme-light {
      background-color: #e8e8e8;
    }

    @include app-theme-dark {
      background-color: #22242b;
    }
  }

  .list-item:not(:hover):not(:active) + & {
    &:not(:first-of-type):not(:hover):not(:active) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-top: 1px solid #00000026;
      }
    }
  }

  &.unread::before {
    content: '';
    position: absolute;
    font-size: 0.5rem;
    width: 1em;
    height: 1em;
    background-color: var(--blue);
    top: calc(50% - (0.5 * 1em));
    left: calc(1rem - (0.5 * 1em));
    border-radius: 100%;
  }
}

.item-header {
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.item-title {
  font-size: 1rem;
  flex-grow: 1;
  color: inherit;
  position: relative;
  height: 1.2em;

  > span {
    line-height: 1.2em;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-date {
  font-size: 0.7rem;
  font-weight: 500;
  opacity: 0.8;
  flex-shrink: 0;
  padding-left: 10px;
}

.item-content-preview {
  position: relative;
  font-size: 0.7rem;
  font-weight: 400;
  width: 100%;

  > div {
    width: 100%;
    height: 2.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1rem;
  }
}

.ellipsis {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 0.5rem;
  background: linear-gradient(90deg, transparent, var(--card-bg) 60%);

  .list-item:hover & {
    @include app-theme-light {
      background: linear-gradient(90deg, transparent, #e8e8e8 60%);
    }

    @include app-theme-dark {
      background: linear-gradient(90deg, transparent, #22242b 60%);
    }
  }
}
</style>
