<script lang="ts">
import { computed } from 'vue';
import type { TablePanel } from 'table-panel';

import { dateFilter } from '@filters/date';
import { stringify } from '@tools/stringify';
import { isBoolean, isString, isNumber } from '@tools/type-guards';
import * as currencies from '@utils/currencies';

declare module 'vue' {
  export interface Vue {
    TablePanelCell: ComponentWithProps<Props>;
  }
}

export default { name: 'TablePanelCell' };
</script>

<script setup lang="ts">
/**
 * `TablePanelCell` component properties.
 */
export interface Props<T = unknown> {
  /** ... */
  column: TablePanel.Column<T>;
  /** ... */
  row: TablePanel.Row<T>;
}

interface Cell {
  value: string;
  classList: string[];
}

const props = defineProps<Props>();

const blank = computed(() => !props.row);
const value = computed(() => props.row.values?.[props.column.key]);

const placeholder = computed(() => {
  let value: unknown;

  if (isString(props.column.placeholder)) {
    value = props.column.placeholder;
  } else if (props.column.placeholder) {
    value = props.column.placeholder(props.row);
  }

  return value ? stringify(value) : '--';
});

const cell = computed(() => {
  const cell: Cell = {
    value: '',
    classList: [`cell-${props.column.type ?? 'default'}`],
  };

  if (blank.value) return cell;

  if (props.column.type === 'currency' && isNumber(value.value)) {
    cell.value = currencies.format(value.value);
  } else if (props.column.type === 'date' && value.value) {
    cell.value = stringify(dateFilter(value.value as string, 'MM/dd/yyyy'));
  } else if (props.column.type === 'boolean' && isBoolean(value.value)) {
    cell.value = value.value ? 'Yes' : 'No';
  } else {
    cell.value = stringify(value.value);
  }

  if (props.column.type === 'percent' && cell.value) {
    cell.value += '%';
  }

  if (!cell.value) {
    cell.value = placeholder.value;
    cell.classList.push('cell-no-entry');
  }

  return cell;
});
</script>

<template>
  <span class="table-panel-cell">
    <pre :class="['cell-value', cell.classList]">{{ cell.value }}</pre>
  </span>
</template>

<style scoped lang="scss">
pre {
  font-family: inherit;
  display: block;
  padding: 0;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  word-break: break-all;
  word-wrap: break-word;
  color: inherit;
  background: none;
  border: none;
  border-radius: 0;
}

.table-panel-cell {
  font-weight: 400;
}

.cell-value {
  //
}

.cell-number {
  // color: #0082c1;
}

.cell-currency {
  // color: #0082c1;
}

.cell-no-entry {
  opacity: 0.4;
}
</style>
