<script lang="ts">
import { computed, defineComponent } from 'vue';

export type Instance = InstanceType<
  typeof import('./ModalBackdrop.vue').default
>;

export default defineComponent({ name: 'ModalBackdrop' });
</script>

<script lang="ts" setup>
/** `ModalBackdrop` component properties. */
export interface Props {
  index: number;
  classList: string[];
  animation: boolean;
}

const props = defineProps<Props>();

/** ... */
const zIndex = computed(() => 1040 + props.index + props.index * 10);
/** ... */
const transitionDuration = computed(() => (props.animation ? '0.15s' : '0'));
</script>

<template>
  <Transition appear>
    <div
      :class="['uib-modal-backdrop', ...classList]"
      :style="{ transitionDuration, zIndex }"
    ></div>
  </Transition>
</template>

<style scoped lang="scss">
.uib-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  opacity: 1;
  background-color: #000000d4;
  transition: opacity 0.5s, background-color 0.5s;
  // backdrop-filter: blur(60px) saturate(1.5);
  // backdrop-filter: blur(20px) saturate(1.5);

  &.v-enter,
  &.v-leave-active {
    opacity: 0;
    background-color: transparent;
  }
}
</style>
