<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { isString } from '@tools/type-guards';

declare module 'vue/types/vue' {
  export interface Vue {
    Spacer: ComponentWithProps<Props>;
  }
}

const SPACER_TYPES = [
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'none',
] as const;

/** Type that describes the line quality of spacer. */
export type SpacerType = (typeof SPACER_TYPES)[number];

/**
 * `Spacer` component properties.
 */
export interface Props {
  /** ... */
  type?: SpacerType;
}

export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Spacer',
  props: {
    type: {
      type: String as PropType<SpacerType>,
      default: 'solid',
      validator: (value) => {
        return (
          isString(value) &&
          (SPACER_TYPES as unknown as string[]).includes(value)
        );
      },
    },
  },
});
</script>

<template>
  <div class="spacer" :style="{ borderTopStyle: type }"></div>
</template>

<style scoped lang="scss">
.spacer {
  flex-grow: 1;
  height: 1px;
  margin: 0 20px -1px;
  opacity: 0.3;
  border-top-color: currentColor;
  border-top-width: 2px;
}
</style>
