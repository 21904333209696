interface DataSet {
  data: number[];
}

export interface DataPoint {
  createdAt: string | Date;
  updatedAt: string | Date;
}

interface GraphConfigBaseOptions {
  from: Date;
  to: Date;
}

export interface TransactionDataPoint extends DataPoint {
  status: string;
  total: number;
}

export interface TransactionGraphConfigOptions extends GraphConfigBaseOptions {
  type: 'transactions';
  items: TransactionDataPoint[];
}

export interface SchoolRevenueDataPoint extends DataPoint {
  status: number;
  amount: number;
}

export interface SchoolRevenueGraphConfigOptions
  extends GraphConfigBaseOptions {
  type: 'school-revenue';
  items: SchoolRevenueDataPoint[];
}

export type CreateGraphedDataConfigOptions =
  | TransactionGraphConfigOptions
  | SchoolRevenueGraphConfigOptions;

/**
 * Graph configuration structure that is compatible for use with a graph
 * component based off of `chart.js`.
 */
export interface GraphConfig {
  datasets: DataSet[];
  labels: string[];
  series: string[];
}

/**
 * Creates graph configuration information for use with a graph component based
 * off of `chart.js` from a set of provided data. using `chart.js`
 *
 * @param options Options for creating the graph configuration, including the
 * data the graph should be based on.
 * @returns The graph configuration information.
 */
export function createGraphedDataConfig(
  options: CreateGraphedDataConfigOptions,
) {
  const labels: string[] = [];

  let date = new Date(options.from);

  do {
    labels.push(createDateLabel(date));

    date.setMonth(date.getMonth() + 1);
  } while (date <= options.to);

  let series;

  if (options.type === 'transactions') {
    series = ['Transactions'];
  } else {
    series = ['Institutional Revenue'];
  }

  const datasets: DataSet[] = [{ data: [] }];
  const dataSet: DataSet = { data: [] };

  for (const item of options.items) {
    if (options.type === 'school-revenue' && item.status !== 1) continue;

    let date;
    let value;

    if (options.type === 'transactions') {
      date = item.createdAt;
      value = (item as TransactionDataPoint).total;
    } else {
      date = item.updatedAt;
      value = (item as SchoolRevenueDataPoint).amount;
    }

    const dateLabel = createDateLabel(new Date(date));

    labels.forEach((label, j) => {
      if (label !== dateLabel) return;

      // If it's empty or zero, set it to zero.
      dataSet.data[j] = dataSet.data[j] ?? 0;

      // Increment by total amount.
      dataSet.data[j] += value;
    });
  }

  return { datasets, labels, series } as GraphConfig;
}

//#region Helper Functions

function createDateLabel(date: Date) {
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
}

//#endregion Helper Functions
