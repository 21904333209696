<script lang="ts">
import { computed, watch } from 'vue';
import { TablePanel as TP } from 'table-panel';
import { toString } from '@utils/to-string';
import { dates } from '@utils/dates';

import { TablePanels } from '@store';
import { delay } from '@tools/delay';
import { isString } from '@tools/type-guards';

import { useFlyout } from './flyout';
import { useTable, Columns, Operations, DataItem } from './table';
import { useProgressiveLoader } from './progressive-loader';
import CellOperations from './CellOperations.vue';
import TableOverlay from './TableOverlay.vue';
import Toolbar from './Toolbar.vue';

export default { name: 'TableProgressive' };
</script>

<script setup lang="ts">
export interface TableContext {
  filter: string | null;
}

export interface ProviderResults {
  items: DataItem[];
}

export type DataProvider = (ctx: TableContext) => Promise<ProviderResults>;

/**
 * `TableProgressive` component properties.
 */
export interface Props {
  table: TablePanels.ModuleKey;
  pk: string;
  label: string;
  columns: Columns;
  operations?: Operations;
  createAction?: string;
  deleteAction?: string;
  loading?: boolean;
  tableQueryText?: string;
  filterFields?: string[];
  specialFilters?: TP.SpecialFilter<DataItem>[];
  filterTags?: TP.FilterTag[];
  filterParams?: TP.FilterParam[];
  infoMessage?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  operations: () => [],
  createAction: '',
  deleteAction: '',
  tableQueryText: '',
  filterFields: () => [],
  specialFilters: () => [],
  filterTags: () => [],
  filterParams: () => [],
  infoMessage: null,
});

const {
  queryText,
  maximized,
  tableFilters,
  fullscreen,
  items,
  displayedItems,
  page,
  allRowsSelected,
  customCells,
  operations,
  tableFilterTags,
  tableFilterParams,
  tableLoading,
  ...table
} = useTable(props);

const {
  loadingPage,
  loadProgress,
  loadDeferred,
  loadItems,
  onScroll,
  onAnimationEnd,
} = useProgressiveLoader({
  tableKey: props.table,
  tableFilterTags,
  tableFilterParams,
});

const flyout = useFlyout(operations.value ?? []);

const rowCount = computed(() => items.value.length);

const classList = computed(() => {
  const items: string[] = ['table-panel', 'progressive-table'];

  if (maximized.value) {
    items.push('fullscreen');

    if (tableFilters.value.length) {
      items.push('filter-offset');
    }
  }

  return items;
});

const fields = computed(() => {
  return table.fields.value.map((field) => {
    const value = isString(field) ? { key: field } : { ...field };

    return { ...value, sortable: false };
  });
});

const tableOverlay = computed(() => {
  let text: string;
  let transparent = false;

  if (loadingPage.value) {
    text = 'Loading';
    transparent = true;
  } else if (props.loading || tableLoading.value) {
    text = 'Loading';
  } else if (!rowCount.value) {
    text = 'No Items';
    transparent = true;
  } else {
    return null;
  }

  return { text, transparent };
});

watch(
  () => props.tableQueryText,
  () => {
    void loadItems(true);
  },
);

watch([tableFilterTags, tableFilterParams], async () => {
  if (loadingPage.value) {
    await loadDeferred.value?.promise;

    await delay();
  }

  void loadItems(true);
});

function openFlyout(item: DataItem, _index: number, event: MouseEvent) {
  event.preventDefault();

  flyout.open(item, event.x, event.y);
}

function sortCompare(
  aRow: Record<string, unknown>,
  bRow: Record<string, unknown>,
  key: string,
  sortDesc: boolean,
  compareOptions: unknown,
  compareLocale: unknown,
) {
  /**
   * Don't forget this function's sister function under TableRegular.vue!
   * Also, not sure if this is ever used in a progressive loader table
   */

  /**
   * sortDesc should not be necessary since b-table will handle the direction
   * according to Bootstrap's documentation
   */

  // try to find column and detect if date or dateTime type is given
  const column = props.columns.find((col) => col.key === key);

  let a;
  let b;

  /**
   *  if the column's value is created with a function, we need to call that
   * function to get the proper value. Otherwise we can take the raw value
   * from the row[key]
   */
  if (typeof column?.value === 'function') {
    a = evokeSafely(() => (column.value as GenericFunction)(aRow));
    b = evokeSafely(() => (column.value as GenericFunction)(bRow));
  } else {
    a = aRow[key];
    b = bRow[key];
  }

  // we need to sort dates differently than raw sort
  if (column && (column.type === 'date' || column.type === 'dateTime')) {
    let dateA: DateLike;
    let dateB: DateLike;

    try {
      dateA = dates.toDate(a as string);
      dateB = dates.toDate(b as string);
    } catch {
      return 0;
    }

    if (dates.isBefore(dateA, dateB)) return 1;

    if (dates.isAfter(dateA, dateB)) return -1;

    return 0;
  } else if (column && column.type === 'currency') {
    // we need to sort currency differently than raw sort

    let valueA: number;
    let valueB: number;

    // we could be given a variety of "currency" type with any
    // number of decimals, commas, and denomination symbols
    // so we'll want to strip all of that out to a raw number
    if (typeof a === 'string') {
      valueA = parseFloat(a.replace(/[^0-9.-]+/g, ''));
    } else if (typeof a === 'number') {
      valueA = a;
    } else {
      return 0;
    }

    if (typeof b === 'string') {
      valueB = parseFloat(b.replace(/[^0-9.-]+/g, ''));
    } else if (typeof b === 'number') {
      valueB = b;
    } else {
      return 0;
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  } else if (typeof a === 'number' && typeof b === 'number') {
    // If both compared fields are native numbers or both are native dates
    return a < b ? -1 : a > b ? 1 : 0;
  } else {
    return toString(a).localeCompare(
      toString(b),
      compareLocale,
      compareOptions,
    );
  }
}

function evokeSafely(fn: () => unknown) {
  let value: unknown;

  try {
    value = fn();
  } catch {
    // Ignore any errors.
  }

  return value;
}
</script>

<template>
  <div :class="classList">
    <Toolbar @maximize="fullscreen = true" @minimize="fullscreen = false" />

    <div class="table-container">
      <Transition appear>
        <TableOverlay v-if="tableOverlay" v-bind="tableOverlay" />
      </Transition>

      <div class="table-wrapper">
        <b-table
          v-model="displayedItems"
          :busy.sync="loadingPage"
          :items="items"
          :fields="fields"
          :per-page="0"
          :class="['mb-0', { 'is-busy': loadingPage }]"
          :sort-compare="sortCompare"
          striped
          sticky-header="auto"
          @row-contextmenu="openFlyout"
          @scroll.native="onScroll"
        >
          <!-- Row Selection Checkbox (Header) -->

          <template #head(selector)>
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox v-model="allRowsSelected" size="sm" />
              </div>
            </div>
          </template>

          <!-- Row Operations Flyout Button (Header) -->

          <template #head(operations)="column">
            <div class="text-center">{{ column.label }}</div>
          </template>

          <!-- Row Selection Checkbox -->

          <template #cell(selector)="{ index }">
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox
                  v-if="page[index]"
                  v-model="page[index].selected"
                  size="sm"
                />
              </div>
            </div>
          </template>

          <!-- Custom Cells -->

          <template v-for="cell in customCells" #[`cell(${cell.key})`]="data">
            <component :is="cell.component" :key="cell.key" v-bind="data" />
          </template>

          <!-- Row Operations Flyout Button -->

          <template #cell(operations)="{ item }">
            <CellOperations
              v-if="operations && item"
              v-bind="{ item, operations }"
            />
          </template>
        </b-table>

        <!-- <div class="table-content-placeholder" v-if="">
          No Results Found
        </div> -->

        <Transition>
          <div v-if="loadingPage" class="table-progress-bar">
            <span
              :style="{ right: `${100 - loadProgress}%` }"
              @transitionend="onAnimationEnd"
            ></span>
          </div>
        </Transition>
      </div>
    </div>

    <div class="table-footer p-4">
      <b-row class="justify-content-between align-items-center">
        <b-col class="col-auto"> {{ items.length }} result(s) </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped lang="scss" src="./table-progressive.scss"></style>
