<script lang="ts">
import { ref, computed, watch } from 'vue';
import { BCard } from 'bootstrap-vue';

import { delay } from '@tools/delay';

import Overlay from '@components/Overlay.vue';
import OverlayRenderIssue from '@components/OverlayRenderIssue.vue';
import Spinner from '@components/Spinner.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    CardLoadable: ComponentWithProps<Props>;
  }
}

export default { name: 'CardLoadable' };
</script>

<script setup lang="ts">
/**
 * `CardLoadable` component properties.
 */
export interface Props extends BCard.Props {
  loading?: boolean;
  error?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  error: false,
});

const loadingStateActive = ref(false);
const transitionBufferCompleted = ref(false);

const classList = computed(() => {
  const items = ['card-loadable'];

  if (loadingStateActive.value) {
    items.push('loading');
  } else if (props.error) {
    items.push('error');
  }

  return items;
});

watch(() => props.loading, onLoadingStateChanged, { immediate: true });

function onLoadingStateChanged(value: boolean) {
  if (value) {
    void onLoadingStarted();
  } else {
    onLoadingFinished();
  }
}

async function onLoadingStarted() {
  if (loadingStateActive.value) return;

  loadingStateActive.value = true;
  transitionBufferCompleted.value = false;

  await delay(500);

  transitionBufferCompleted.value = true;

  loadingStateActive.value = props.loading ?? false;
}

function onLoadingFinished() {
  if (transitionBufferCompleted.value) {
    loadingStateActive.value = false;
  }
}
</script>

<template>
  <b-card :class="classList" v-bind="$attrs">
    <slot />

    <Overlay
      v-if="loadingStateActive"
      class="card-loading-overlay"
      background="transparent"
      transition="1"
    >
      <Spinner />
    </Overlay>

    <OverlayRenderIssue v-else-if="error" class="card-error-overlay" />
  </b-card>
</template>

<style scoped lang="scss">
.card-loadable {
  > .card-header,
  > .card-footer,
  > .card-body > * {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.5s, transform 0.5s;
  }

  &.loading,
  &.error {
    min-height: 100px;
    overflow: hidden;
    text-align: unset;

    // > .card-header,
    > .card-footer,
    > .card-body > *:not(.card-loading-overlay) {
      opacity: 0;
    }

    > .card-body > *:not(.card-loading-overlay) {
      transform: scale(0.8);
    }
  }
}

.card-loading-overlay,
.card-error-overlay {
  position: absolute;
  font-size: 2.5rem;
  opacity: 1 !important;
  transition: 0.5s;
  inset: 0;

  @include v-from {
    opacity: 0 !important;
  }

  .b-skeleton-img {
    position: absolute;
    inset: 0;
  }
}
</style>
