<script setup lang="ts">
import { ref, onUnmounted } from 'vue';

import { useRouter } from '@router';
import { delay } from '@tools/delay';

const router = useRouter();

const visible = ref(false);
const progress = ref(0);
const displayProgress = ref(0);

// On before each route.
onUnmounted(
  router.beforeEach((_to, _from, next) => {
    // ...
    void initializeDisplay();

    next();
  }),
);

// On after each route.
onUnmounted(
  router.afterEach(() => {
    progress.value = 100;
  }),
);

/**
 * ...
 */
async function initializeDisplay() {
  progress.value = 50;
  displayProgress.value = 0;

  if (visible.value) return;

  // ...
  visible.value = true;

  // ...
  await updateDisplay();

  // ...
  visible.value = false;
}

/**
 * ...
 */
async function updateDisplay() {
  if (displayProgress.value >= 100) return;

  if (displayProgress.value < progress.value) {
    displayProgress.value = Math.min(progress.value, displayProgress.value + 2);
  }

  // ...
  await delay();

  // ...
  await updateDisplay();
}
</script>

<template>
  <Transition>
    <div v-if="visible" class="route-load-progress-bar">
      <div :style="{ right: `${100 - displayProgress}%` }"></div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.route-load-progress-bar {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2px;
  background: #00000038;
  opacity: 1;
  transition: opacity 0.5s;

  &.v-leave-to {
    opacity: 0;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 50%;
    background: var(--primary);
  }
}
</style>
