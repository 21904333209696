<script lang="ts">
import { computed } from 'vue';

import type { Scene } from '@models';

const TEXT_COLOR_GREEN = '#004546';
const BG_COLOR_GREEN = '#37da88';

const TEXT_COLOR_RED = '#5d0029';
const BG_COLOR_RED = '#ff4f80';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellPrivateStatus: ComponentWithProps<Props>;
  }
}

interface StatusDisplayConfig {
  text: string;
  color: string;
  backgroundColor: string;
}

/** Display config info for certification status label. */
const STATUS_DISPLAY_CONFIGS = {
  PRIVATE: {
    text: 'Private',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
  NOT_PRIVATE: {
    text: 'Public',
    color: TEXT_COLOR_GREEN,
    backgroundColor: BG_COLOR_GREEN,
  },
} satisfies Record<string, StatusDisplayConfig>;
</script>

<script setup lang="ts">
/**
 * `TableCellPrivateStatus` component properties.
 */
export interface Props {
  item: Scene;
}

const props = defineProps<Props>();

const displayConfig = computed(() => {
  return props.item.isPrivate
    ? STATUS_DISPLAY_CONFIGS.PRIVATE
    : STATUS_DISPLAY_CONFIGS.NOT_PRIVATE;
});

const text = computed(() => {
  return displayConfig.value.text;
});

const style = computed(() => {
  return {
    color: displayConfig.value.color,
    backgroundColor: displayConfig.value.backgroundColor,
  };
});
</script>

<template>
  <div class="score-table-cell">
    <span class="label-private-status" :style="style">
      {{ text }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.label-private-status {
  display: inline-block;
  padding: 4px 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  width: 170px;
}
</style>
