<script setup lang="ts">
import { computed } from 'vue';

import { secToHmsFilter } from '@filters/sec-to-hms';
import { isNumber } from '@tools/type-guards';

import CardLoadable from '@components/Cards/CardLoadable.vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardFlightTime: ComponentWithProps<Props>;
  }
}

/**
 * `CardFlightTime` component properties.
 */
export interface Props {
  loading: boolean;
  value?: number | null;
}

const props = defineProps<Props>();

const flightTime = computed(() => {
  return isNumber(props.value) ? secToHmsFilter(props.value) : 'N/A';
});
</script>

<template>
  <CardLoadable v-bind="{ loading: !!loading }" no-body>
    <b-card-body>
      <b-card-title>
        <div class="h6">
          <span class="stat-card-title">Flight Time</span>
          <span class="small">(hh:mm:ss)</span>
        </div>
      </b-card-title>

      <span class="value-display">
        <span class="h1 m-0">{{ flightTime }}</span>
      </span>
    </b-card-body>
  </CardLoadable>
</template>

<style scoped lang="scss">
.card-title {
  display: flex;
  justify-content: space-between;
}

.stat-card-title {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
}

.card-body {
  display: flex;
  flex-direction: column;
}

.value-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-grow: 1;

  .h1 {
    font-size: 4rem;
    font-weight: 300;
  }
}
</style>
