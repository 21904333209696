<script lang="ts">
import InfoCircle from '@components/InfoCircle.vue';

import { faCheck } from '@icons/solid/faCheck';
import { faCircle } from '@icons/solid/faCircle';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    PasswordValidationItem: ComponentWithProps<Props>;
  }
}

export default { name: 'PasswordValidationItem' };
</script>

<script setup lang="ts">
/**
 * `PasswordValidationItem` component properties.
 */
export interface Props {
  description: string;
  condition: boolean;
  tooltip?: string;
}

defineProps<Props>();
</script>

<template>
  <div :class="['password-validation-item', { valid: condition }]">
    <span class="item-bullet">
      <Transition appear>
        <span v-if="condition" class="bullet-valid">
          <Icon :icon="faCheck" />
        </span>
      </Transition>

      <Transition appear>
        <span v-if="!condition" class="bullet-invalid">
          <Icon :icon="faCircle" />
        </span>
      </Transition>
    </span>

    <span class="item-text">{{ description }}</span>

    <InfoCircle v-if="tooltip" :text="tooltip" />
  </div>
</template>

<style scoped lang="scss">
.password-validation-item {
  font-size: 0.8em;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  width: 100%;

  @include app-theme-light {
    color: #7d7d7d;
  }

  @include app-theme-dark {
    color: #70717d;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.item-bullet {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 0.4rem;

  > span {
    position: absolute;
    margin-top: 2px;
    font-size: 1.2em;
    display: inline-block;
    transform: scale(1);
    transition: transform 0.15s;
    color: currentColor;

    @include v-from {
      transform: scale(0);
    }

    @include v-enter-active {
      transition-delay: 0.25s;
      transition-timing-function: cubic-bezier(0, 2.27, 0.58, 1) !important;
    }

    &.bullet-valid {
      color: var(--green) !important;
    }

    &.bullet-invalid {
      font-size: 0.6em;
    }
  }
}

.item-text {
  text-align: left;
  transition: color 0.25s;

  .valid & {
    color: var(--green) !important;
  }
}

.info-circle {
  margin-left: 0.4rem;
}
</style>
