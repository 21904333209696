<script lang="ts">
import { computed } from 'vue';

import type { Certification } from '@models';

const TEXT_COLOR_GRAY = '#363b47';
const BG_COLOR_GRAY = '#9fa5b3';

const TEXT_COLOR_GREEN = '#004546';
const BG_COLOR_GREEN = '#37da88';

const TEXT_COLOR_YELLOW = '#5f4000';
const BG_COLOR_YELLOW = '#ffd800';

const TEXT_COLOR_RED = '#5d0029';
const BG_COLOR_RED = '#ff4f80';

interface StatusDisplayConfig {
  text: string;
  color: string;
  backgroundColor: string;
}

/** Display config info for certification status label. */
export const STATUS_DISPLAY_CONFIGS: Record<
  Certification.Status,
  StatusDisplayConfig
> = {
  NOT_STARTED: {
    text: 'Not Started',
    color: TEXT_COLOR_GRAY,
    backgroundColor: BG_COLOR_GRAY,
  },
  AWAITING_ATTEMPT: {
    text: 'Awaiting Attempt',
    color: TEXT_COLOR_GRAY,
    backgroundColor: BG_COLOR_GRAY,
  },
  AWAITING_REATTEMPT: {
    text: 'Awaiting Reattempt',
    color: TEXT_COLOR_GRAY,
    backgroundColor: BG_COLOR_GRAY,
  },
  AWAITING_REVIEW: {
    text: 'Awaiting Review',
    color: TEXT_COLOR_YELLOW,
    backgroundColor: BG_COLOR_YELLOW,
  },
  REVIEWING: {
    text: 'Reviewing',
    color: TEXT_COLOR_YELLOW,
    backgroundColor: BG_COLOR_YELLOW,
  },
  CURRENT: {
    text: 'Current',
    color: TEXT_COLOR_GREEN,
    backgroundColor: BG_COLOR_GREEN,
  },
  APPROVED: {
    text: 'Approved',
    color: TEXT_COLOR_GREEN,
    backgroundColor: BG_COLOR_GREEN,
  },
  DENIED: {
    text: 'Denied',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
  EXPIRED: {
    text: 'Expired',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
  REVOKED: {
    text: 'Revoked',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
  SUSPENDED: {
    text: 'Suspended',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
};
</script>

<script setup lang="ts">
/**
 * `LabelCertificationStatus` component properties.
 */
export interface Props {
  status: Certification.Status;
  fullwidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fullwidth: false,
});

const displayConfig = computed(() => {
  return STATUS_DISPLAY_CONFIGS[props.status];
});

const classList = computed(() => {
  const classList: string[] = ['label-certification-status'];

  if (props.fullwidth) {
    classList.push('fullwidth');
  }

  return classList;
});

const text = computed(() => {
  return displayConfig.value.text;
});

const style = computed(() => {
  return {
    color: displayConfig.value.color,
    backgroundColor: displayConfig.value.backgroundColor,
  };
});
</script>

<template>
  <span :class="classList" :style="style">
    {{ text }}
  </span>
</template>

<style scoped lang="scss">
.label-certification-status {
  display: inline-block;
  padding: 4px 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;

  &.fullwidth {
    width: 170px;
  }
}
</style>
