<script lang="ts">
import { computed } from 'vue';
import uniqBy from 'lodash/uniqBy';

import { Report } from '@models';

import CardStat from '@components/Cards/CardStat.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    CardModulesCompleted: ComponentWithProps<Props>;
  }
}

export default { name: 'CardModulesCompleted' };
</script>

<script setup lang="ts">
/**
 * `CardModulesCompleted` component properties.
 */
export interface Props {
  loading: boolean;
  reports: Report[] | null;
}

const props = defineProps<Props>();

const modulesCompleted = computed(() => {
  return props.reports ? uniqBy(props.reports || [], 'module.id').length : null;
});
</script>

<template>
  <CardStat
    title="Modules Completed"
    :value="modulesCompleted"
    :loading="loading"
  />
</template>
