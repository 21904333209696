<script lang="ts">
import type { TablePanels } from '@store';

import Icon from '@components/Icon.vue';

import { faPlus } from '@icons/regular/faPlus';
import { faMinus } from '@icons/regular/faMinus';

import { useTableItem } from './table-item';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardTableGroupItem: ComponentWithProps<Props>;
  }
}

export default { name: 'CardTableGroupItem' };
</script>

<script setup lang="ts">
/** `CardTableGroupItem` component props. */
export interface Props {
  tableKey: TablePanels.ModuleKey;
  loaderOptions?: Nullable<GenericObject>;
}

const props = defineProps<Props>();

const { expanded, tablePanel, tableComponent } = useTableItem({
  ...props,
  collapsable: true,
});
</script>

<template>
  <b-card class="card-table-group-item" no-body>
    <b-card-header
      header-tag="header"
      class="p-3 d-flex justify-content-between"
      role="tab"
      @click="expanded = !expanded"
    >
      <span>{{ tablePanel.label }}</span>

      <span class="pull-right">
        <Icon :icon="expanded ? faMinus : faPlus" />
      </span>
    </b-card-header>

    <b-collapse
      v-model="expanded"
      accordion="data-table-accordion"
      role="tabpanel"
    >
      <b-card-body class="p-0">
        <component
          :is="tableComponent"
          v-if="expanded"
          :table="tableKey"
          v-bind="tablePanel"
        />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<style scoped lang="scss">
$border-radius: 10px !important;

.card {
  margin-bottom: 20px;
  background-color: var(--panel-bg) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: $border-radius;
  overflow: hidden;
}

.card-header {
  cursor: default;
  user-select: none;

  &:first-child {
    border-radius: 0 !important;
  }
}

.card-body {
  border-top: 1px solid var(--border-color);
}

header {
  background-color: transparent;
  border-bottom: none;
  transition: background-color 0.25s;
  overflow: hidden;

  @include border-top-radius((9px));

  > .dropdown .dropdown-toggle {
    color: inherit;
  }

  &:hover {
    background-color: var(--panel-header-hover-bg);
  }
}
</style>
