<script lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';

import { delay } from '@tools/delay';
import { randomId } from '@tools/random-id';

import Icon, { IconDefinition } from '@components/Icon.vue';

export default { name: 'FlyoutMenu' };
</script>

<script setup lang="ts">
/**
 * Flyout menu item.
 */
export interface Item {
  label: string;
  icon: IconDefinition;
  variant?: 'danger' | 'info' | 'warning' | 'success';
  fn: () => void;
}

/**
 * `FlyoutMenu` component properties.
 */
export interface Props {
  x: number;
  y: number;
  items: Item[];
}

/** `FlyoutMenu` component emits. */
export type Emits = (event: 'before-remove' | 'after-remove') => void;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

defineExpose({ close });

const el = ref<HTMLDivElement | null>(null);

const active = ref(true);
const xOffset = ref(-10);

const style = computed(() => {
  const x = props.x - xOffset.value;
  const y = props.y;

  return { transform: `translate(${x}px, ${y}px)` };
});

/** Keyed flyout menu items. */
const menuItems = computed(() => {
  return props.items.map((item) => ({ key: randomId(), ...item }));
});

onMounted(() => {
  const flyoutWidth = el.value?.offsetWidth ?? 0;
  const widthDifference = window.innerWidth - props.x - flyoutWidth - 20;

  if (widthDifference < 0) {
    xOffset.value = flyoutWidth + 10;
  }

  delay().finally(() => {
    document.addEventListener('click', onCloseEvent);
    document.addEventListener('wheel', onCloseEvent);
  });
});

onUnmounted(() => {
  document.removeEventListener('click', onCloseEvent);
  document.removeEventListener('wheel', onCloseEvent);
});

/**
 * Execute a menu item action. Emits event `action`.
 */
function execAction(fn: Item['fn']) {
  fn();

  close();
}

/**
 * Manually close the flyout menu.
 */
function close() {
  active.value = false;
}

function onCloseEvent({ target }: Event) {
  if (
    target instanceof Node &&
    (el.value === target || el.value?.contains(target))
  ) {
    return;
  }

  close();
}

function beforeLeave() {
  emit('before-remove');
}

function afterLeave() {
  emit('after-remove');
}
</script>

<template>
  <Transition appear @before-leave="beforeLeave" @after-leave="afterLeave">
    <div v-if="active" ref="el" class="flyout-menu" :style="style">
      <ul>
        <li
          v-for="{ key, icon, label, variant, fn } in menuItems"
          :key="key"
          :class="{
            danger: variant === 'danger',
            info: variant === 'info',
            success: variant === 'success',
            warning: variant === 'warning',
          }"
        >
          <div @click="execAction(fn)">
            <span class="icon-wrapper">
              <Icon v-bind="{ icon }" />
            </span>

            <span class="text-wrapper">{{ label }}</span>
          </div>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
$item-height: 2.8rem;

.flyout-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  min-width: 250px;
  padding: 0;
  overflow: hidden;
  font-size: 110%;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px #00000059;
  transform: translateX(0);
  opacity: 1;
  backdrop-filter: blur(30px);
  transition: opacity 0.25s;

  &.v-enter,
  &.v-leave-active {
    // transform: translateX(20px);
    opacity: 0;
  }

  @include app-theme-light {
    background-color: #ffffff94;
  }

  @include app-theme-dark {
    background-color: #262831;
  }
}

ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
}

li {
  &:not(:last-of-type) {
    border-bottom: 1px solid #00113824;
  }

  > div {
    display: flex;
    align-items: stretch;
    height: $item-height;
    text-decoration: none !important;
    background-color: transparent;
    cursor: pointer;
    opacity: 1;
    transition: 0.15s;

    &:hover {
      color: white;
      background-color: #007bff;
    }

    > span {
      line-height: $item-height;
      user-select: none;
    }
  }

  &.danger {
    color: var(--danger);

    > div:hover {
      background-color: var(--danger);
    }
  }
  &.info {
    color: var(--info);

    > div:hover {
      background-color: var(--info);
    }
  }
  &.success {
    color: var(--success);

    > div:hover {
      background-color: var(--success);
    }
  }
  &.warning {
    color: var(--warning);

    > div:hover {
      background-color: var(--warning);
    }
  }
}

.icon-wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  font-size: 1.3rem;
}

.text-wrapper {
  flex-grow: 1;
  padding-right: 20px;
  font-weight: 400;
  font-size: 0.9rem;
  white-space: nowrap;
}
</style>
