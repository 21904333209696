<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';
import { UnityCatalog } from '@client/models/unity-catalog';
/**
 * `TableCellListDLC` component properties.
 */
export interface Props {
  /** ... */
  value: UnityCatalog.DLC[];
}

const props = defineProps<Props>();
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellListDLC: ComponentWithProps<Props>;
  }
}
export default { name: 'TableCellListDLC' };
</script>

<template>
  <div>
    <div v-for="dlc in props.value" :key="dlc.id" class="mx-1">
      <span>{{ dlc.name }} | {{ dlc.version }}</span>
    </div>
  </div>
</template>
