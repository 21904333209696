<script setup lang="ts">
import { ref, computed } from 'vue';

import { useEventListener } from '@composables';
import { isNumber } from '@tools/type-guards';

export interface Props {
  range: string | number;
}

const props = defineProps<Props>();

const el = ref<HTMLDivElement | null>(null);

const triggered = ref(false);

/** ... */
const rangeValue = computed(() => {
  // ...
  const value = isNumber(props.range) ? props.range : parseFloat(props.range);

  if (value < -1 || value > 1) {
    throw new Error(
      `[ScrollTrigger] provided value "${value}" for "range" was invalid -- must be between -1 and 1.`,
    );
  }

  return value;
});

useEventListener('scroll', () => {
  if (triggered.value || !el.value) return;

  const winCenter = 0.5 * window.innerHeight;

  // ...
  const { top, height } = el.value.getBoundingClientRect();

  // ...
  const center = top + 0.5 * height;
  // ...
  const threshold = Math.min(1, Math.max(-1, (winCenter - center) / winCenter));

  if (threshold >= rangeValue.value) triggered.value = true;
});
</script>

<template>
  <div ref="el" class="scroll-trigger">
    <slot v-bind="{ triggered }" />
  </div>
</template>

<style scoped lang="scss">
.scroll-trigger {
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 50%;
  //   width: 1px;
  //   background: red;
  //   z-index: 99999;
  // }
  //
  // &::after {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 50%;
  //   height: 1px;
  //   background: red;
  //   z-index: 99999;
  // }
}
</style>
