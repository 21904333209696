<template>
  <div class="organization-site-license-type-table-cell">
    <span v-if="billingType === 'SiteLicense'">
      {{ totalLicenses }}
    </span>
    <span v-else>N/A</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import type { Organization } from '@models';

/**
 * `TableCellOrganizationSiteLicenses` component properties.
 */
export interface Props {
  /** ... */
  item: Organization;
}

const props = defineProps<Props>();

const totalLicenses = computed(() => props.item.totalLicenses);
const billingType = computed(() => props.item.billingType);
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellOrganizationSiteLicenses: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellOrganizationSiteLicenses' };
</script>

<style lang="scss" scoped>
.organization-seat-type-table-cell {
  // text-align: right;
  white-space: nowrap;
}
</style>
