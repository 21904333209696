<script lang="ts">
import { computed } from '@vue';

import { dateFilter } from '@filters/date';
import { isNumber, isString, isDate, isOneOf } from '@tools/type-guards';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayTextDate: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayTextDate' };
</script>

<script setup lang="ts">
/**
 * `DisplayTextDate` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: Nullable<string | number | Date>;
  format?: string;
  timezone?: string;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

const props = defineProps<Props>();

const tag = computed(() => props.tag ?? 'span');

const displayValue = computed(() => {
  let value: string;

  if (isOneOf(props.value, isNumber, isString, isDate)) {
    value = dateFilter(
      props.value,
      props.format ?? 'MM/dd/yyyy',
      props.timezone,
    ).toString();
  } else {
    value = '--';
  }

  return value;
});
</script>

<template>
  <component :is="tag">{{ displayValue }}</component>
</template>
