<script setup lang="ts">
import { ref, computed, useSlots } from 'vue';
import type { StyleValue } from 'vue/types/jsx';

export interface Props {
  width?: string;
  height?: string;
  noBodyPadding?: boolean;
}

const props = defineProps<Props>();

const slots = useSlots();

const active = ref(false);

const rootStyle = computed(() => {
  const values: StyleValue = {};

  if (props.width) {
    values.width = props.width;
  }

  if (props.height) {
    values.height = props.height;
  }

  return values;
});

const bodyStyle = computed(() => {
  const values: StyleValue = {};

  if (props.noBodyPadding) {
    values.padding = '0';
  }

  return values;
});

/**
 * Toggle dropdown.
 */
function toggle() {
  if (!active.value) {
    open();
  } else {
    close();
  }
}

/**
 * Open dropdown.
 */
function open() {
  active.value = true;
}

/**
 * Close dropdown.
 */
function close() {
  active.value = false;
}

defineExpose({ toggle });
</script>

<template>
  <Transition appear>
    <div
      v-if="active"
      v-on-clickaway="close"
      class="nav-dropdown"
      :style="rootStyle"
    >
      <div v-if="'header' in slots" class="nav-dropdown-header">
        <slot name="header" />
      </div>

      <div class="nav-dropdown-body" :style="bodyStyle">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.nav-dropdown {
  color: var(--text-primary);
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  font-weight: 700;
  opacity: 1;
  box-shadow: $panel-shadow;
  transform: translateY(0);
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  user-select: none;
  min-width: 300px;

  &.v-enter,
  &.v-enter-to,
  &.v-leave,
  &.v-leave-to {
    pointer-events: none !important;
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: translateY(0);
  }

  &.v-enter {
    opacity: 0;
    transform: translateY(-80px);
  }

  &.v-leave-to {
    opacity: 0;
    transform: scale(0.95);
  }

  &.v-enter-active {
    transition: opacity 0.25s, transform 0.25s cubic-bezier(0, 1.48, 0.3, 1.03);
  }

  &.v-leave-active {
    transition: 0.2s;
  }

  @media (max-width: 990px) {
    left: -50px;
    right: -150px;
    z-index: 1100;
  }
}

.nav-dropdown-header {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
  justify-content: end;
  padding: 0.5rem 0.5rem;
}

.nav-dropdown-body {
  flex-grow: 1;
  position: relative;
  padding: 1.5rem;
}
</style>
