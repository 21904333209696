<script lang="ts">
import { ref, computed, onMounted, defineComponent } from 'vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    HostedField: ComponentWithProps<Props>;
  }
}

export default defineComponent({ name: 'HostedField' });
</script>

<script setup lang="ts">
/**
 * `HostedField` component properties.
 */
export interface Props {
  uid: string;
  label: string;
  error: Error | null;
}

const props = defineProps<Props>();

const emit = defineEmits(['mounted']);

const focused = ref(false);
const showError = ref(false);

/** ... */
const occupied = computed(() => focused.value);

const classList = computed(() => {
  const items = ['form-field', 'form-field-hosted'];

  if (occupied.value) {
    items.push('occupied');
  }

  if (props.error) {
    items.push('error');
  }

  return items;
});

onMounted(() => emit('mounted'));

defineExpose({ showError });
</script>

<template>
  <div :class="classList">
    <div class="input-wrapper">
      <div :id="uid" class="form-control hosted-field-wrapper" />

      <Transition>
        <label :for="uid" class="input-label">{{ label }}</label>
      </Transition>
    </div>

    <p v-if="error" class="error-message">{{ error.message }}</p>
  </div>
</template>

<style scoped lang="scss">
$transition-time: 0.25s;

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.hosted-field-wrapper {
  height: 50px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.input-label {
  position: absolute;
  left: 20px;
  top: 0;
  color: #868686;
  font-weight: 600;
  transform: translateY(-10px) translateX(-7px) scale(0.8);
  transform-origin: left center;
  opacity: 1;
  transition: transform $transition-time, color $transition-time,
    font-weight $transition-time;
  pointer-events: none;

  @include v-from {
    color: #a0a0a0;
    font-weight: initial;
    transform: translateY(0) translateX(-7px) scale(1);
  }
}

.error-message {
  margin: 0;
  color: #ff417d;
  font-weight: 600;
  font-size: 0.8em;
}
</style>
