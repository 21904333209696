<script lang="ts">
import { ref, computed } from 'vue';

import { modals } from '@modals';

export interface Announcement {
  title: string;
  details?: string;
}

export default { name: 'SiteAnnouncement' };
</script>

<script setup lang="ts">
/**
 * `SiteAnnouncement` component properties.
 */
export interface Props {
  announcement: Announcement;
}

const props = defineProps<Props>();

const dismissed = ref(false);

const title = computed(() => {
  return props.announcement?.title ? props.announcement.title : null;
});
const details = computed(() => {
  return props.announcement?.details ? props.announcement.details : null;
});

async function showDetails() {
  if (!details.value || typeof details.value !== 'string') return;

  const options = {
    title: 'Maintenance Nov. 19-20',
    text: details.value,
  };

  await modals.util.generalInfo(options);
}
</script>

<template>
  <div v-if="!dismissed" class="site-announcement">
    <div class="site-announcement-inner">
      {{ title }}

      <b-button v-if="details" @click="showDetails"> Details </b-button>
      <b-button @click="dismissed = true"> Dismiss </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.site-announcement {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgb(240, 65, 42);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  color: white;
  min-height: 50px;
  max-height: 100px;
  position: fixed;
  bottom: 0;

  .site-announcement-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;

    p {
      margin-bottom: 0;
    }

    button {
      margin-left: 5px;
    }
  }

  .site-announcement-details {
    padding: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    max-width: 400px;
    background-color: rgb(240, 65, 42);
    border-radius: 0 0 0 10px;

    &.v-enter-active,
    &.v-leave-active {
      transition: 0.3s;
    }

    &.v-enter-from,
    &.v-leave-to {
      transform: translateX(100px);
      opacity: 0;
    }

    &.v-enter-to,
    &.v-leave-from {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
</style>
