<template>
  <CardStat v-bind="cardProps">
    <span class="percentage-display">
      <RadialProgressBar
        :diameter="100"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        start-color="#ffa93e"
        stop-color="#ff7f42"
        inner-stroke-color="#ffffff29"
        :inner-stroke-width="5"
      />
      <span class="percentage-value h4 mb-0">{{ percentDisplay }}%</span>
    </span>
  </CardStat>
</template>

<script setup lang="ts">
import { ref, computed } from '@vue';

import RadialProgressBar from '@components/RadialProgressBar';

import CardStat, { Props as CardStatProps } from './CardStat.vue';

/**
 * `CardMetric` component properties.
 */
export interface Props {
  title: string;
  subtitle?: string | null;
  percent?: number | null;
  rating?: string | number | null;
  info?: string | null;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  subtitle: null,
  percent: null,
  rating: null,
  info: null,
  loading: false,
});

/** ... */
const totalSteps = ref(100);

/** ... */
const cardProps = computed(() => {
  const cardProps: CardStatProps = {
    title: props.title,
    value: ratingDisplay.value,
    info: props.info,
    loading: props.loading,
  };

  if (props.subtitle) {
    cardProps.subtitle = props.subtitle;
  }

  return cardProps;
});

/** ... */
const metricValue = computed(() => Math.round(props.percent ?? 0));

/** ... */
const percentDisplay = computed(() => metricValue.value);

/** ... */
const completedSteps = computed(() => metricValue.value);

/** ... */
const ratingDisplay = computed(() => {
  return props.rating === 'good'
    ? 'Good'
    : props.rating === 'average'
    ? 'Average'
    : props.rating === 'poor'
    ? 'Poor'
    : 'N/A';
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardMetric: ComponentWithProps<Props>;
  }
}

export default { name: 'CardMetric' };
</script>

<style lang="scss" scoped>
.percentage-display {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.percentage-value {
  position: absolute;
  font-weight: 200;
}
</style>
