<script lang="ts">
import { computed } from 'vue';

import { useRouter } from '@router';
import { Order } from '@models';

import DisplayTextCurrency from '@components/Display/DisplayTextCurrency.vue';
import DisplayTextDate from '@components/Display/DisplayTextDate.vue';

import OrderCardItem from './OrderCardItem.vue';

export default { name: 'OrderCard' };
</script>

<script setup lang="ts">
/**
 * `OrderCard` component properties.
 */
export interface Props {
  order: Order;
  index?: number;
}

const props = defineProps<Props>();

const router = useRouter();

const transitionDelay = computed(() => {
  return typeof props.index !== 'number' ? null : `${0.1 * props.index}s`;
});

// const shippingInfo = computed(() => {
//   if (!props.order.isShipping) return null;

//   return {
//     first: props.order.shippingFirst,
//     last: props.order.shippingLast,
//     country: props.order.shippingCountry,
//     address1: props.order.shippingAddress1,
//     address2: props.order.shippingAddress2,
//     address3: props.order.shippingAddress3,
//     city: props.order.shippingCity,
//     stateProvince: props.order.shippingStateProvince,
//     postalCode: props.order.shippingPostalCode,
//   } as ZephyrWeb.AddressInfo;
// });

function goToOrderPage() {
  void router.push({
    name: 'orderStatus',
    params: { orderId: props.order.id },
  });
}
</script>

<template>
  <Transition appear>
    <b-card no-body class="w-100" :style="{ transitionDelay }">
      <b-card-header>
        <b-row>
          <b-col>
            <div>Order Placed</div>
            <span class="order-date">
              <DisplayTextDate :value="order.createdAt" />
            </span>
          </b-col>

          <b-col>
            <div>Total</div>
            <div class="order-amount">
              <DisplayTextCurrency
                :value="order.total"
                :currency="order.currency"
                unit="smallest"
              />
            </div>
          </b-col>

          <b-col>
            <div>Status</div>
            <div>
              <DisplayText :value="order.status" capitalize />
            </div>
          </b-col>

          <b-col v-if="order.discountCode">
            <div>Discount</div>
            <div class="order-amount">
              <DisplayTextCurrency
                :value="order.discountTotal"
                :currency="order.currency"
                unit="smallest"
              />
            </div>
          </b-col>

          <b-col cols="auto">
            <b-button variant="link" @click="goToOrderPage">
              See Details
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="p-0">
        <OrderCardItem
          v-for="(item, i) in order.cart"
          :key="item.id"
          :cart-item="item"
          :currency="order.currency"
          :class="{ 'border-bottom': i < order.cart.length - 1 }"
        />
      </b-card-body>
    </b-card>
  </Transition>
</template>

<style scoped lang="scss">
.order-card {
  overflow: hidden;
  border: 1px solid #00000021;
  border-radius: 10px;
  transform: translateX(0);
  opacity: 1;
  transition: opacity 0.5s, transform 0.75s;

  @include v-from {
    transform: translateX(-100px);
    opacity: 0;
  }
}

.order-card-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;

  .header-item {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;

    &:last-child {
      align-items: flex-end;
    }
  }
}

.order-id {
  position: absolute;
  top: 5px;
  font-size: 0.8em;
}

.order-date {
  font-weight: 500;
}

.order-amount {
  font-weight: 500;
}

.order-card-content {
  padding: 20px;
  border-top: 1px solid #00000021;
}
</style>
