<template>
  <div class="user-status-table-cell">
    <span
      v-if="blocked"
      v-b-tooltip.hover
      class="blocked"
      title="User has been blocked from logging in for suspicious activity"
    >
      BLOCKED
    </span>

    <span
      v-else
      v-b-tooltip.hover
      title="User account is active and in good standing"
      class="enabled"
      >ACTIVE</span
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { User } from '@models';

export interface Props {
  item: User;
}
const props = defineProps<Props>();

const blocked = computed(() => {
  return !props.item.active;
});
</script>
<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellUserStatus: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellUserEmail' };
</script>

<style scoped lang="scss">
.blocked {
  color: var(--danger);
}
.enabled {
  color: var(--success);
}
</style>
