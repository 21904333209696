<script lang="ts">
import { defineComponent } from 'vue';

import Overlay from '@components/Overlay.vue';
import Spinner from '@components/Spinner.vue';
import AnimatedCheckmark from '@client/components/AnimatedCheckmark.vue';
import AnimatedSuccess from '@client/components/AnimatedSuccess.vue';
import AnimatedError from '@client/components/AnimatedError.vue';
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ModalBody: ComponentWithProps<Props>;
  }
}

export default defineComponent({ name: 'ModalBody' });
</script>

<script setup lang="ts">
/** `ModalBody` component properties. */
export interface Props {
  /**
   * If `true`, a "loading" overlay will display instead of the modal body
   * content.
   */
  loading?: boolean;
  /**
   * If `true`, a "processing" overlay will display over the modal body content.
   */
  processing?: boolean;
  /**
   * If `true`, a green checkmark overlay will display over the modal body content.
   */
  success?: boolean;
  /**
   * If `true`, a red X overlay will display over the modal body content.
   */
  errored?: boolean;
  /**
   * If `true`, the modal body will not include its standard padding.
   */
  noPadding?: boolean;
  /**
   * If `true`, the modal body will be prevented from being scrollable.
   */
  noScroll?: boolean;
}

withDefaults(defineProps<Props>(), {
  loading: false,
  processing: false,
  success: false,
  errored: false,
  noPadding: false,
});
</script>

<template>
  <div
    :class="[
      'modal-body',
      { processing, 'no-padding': noPadding, 'no-scroll': noScroll },
    ]"
  >
    <Overlay v-if="processing" background="transparent">
      <Spinner v-if="!success && !errored" :size="3" unit="rem" />
      <!-- <AnimatedCheckmark v-if="success" color="#00a355" /> -->
      <AnimatedSuccess v-if="success" />

      <AnimatedError v-if="errored" />
    </Overlay>

    <b-skeleton-wrapper v-bind="{ loading: !!loading }">
      <template #loading>
        <b-skeleton width="85%" />
        <b-skeleton width="55%" />
        <b-skeleton width="70%" />

        <br />

        <b-skeleton width="45%" />
        <b-skeleton width="25%" />
        <b-skeleton width="90%" />

        <br />

        <b-skeleton width="25%" />
        <b-skeleton width="65%" />
        <b-skeleton width="40%" />
      </template>

      <div class="modal-body-content">
        <slot />
      </div>

      <slot name="secondary-content" />
    </b-skeleton-wrapper>
  </div>
</template>

<style lang="scss">
$modal-body-padding: 40px;

.modal-body {
  padding: 0 !important;

  &.processing {
    pointer-events: none;
  }
}

.modal-body-content {
  padding: $modal-body-padding;
  position: relative;
  max-height: calc(100vh - 300px);
  overflow: auto;

  .uib-modal-dialog.modal-fullscreen & {
    max-height: none;
    position: absolute;
    inset: 0;
  }

  .modal-body.processing & {
    opacity: 0.25;
  }

  .modal-body.no-padding & {
    padding: 0;
  }

  .modal-body.no-scroll & {
    overflow: hidden;
  }
}

.b-skeleton-wrapper {
  padding: $modal-body-padding;
}
</style>
