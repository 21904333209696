<script lang="ts">
import { ref, computed } from 'vue';

import { useStore } from '@store';

import NavDropdown from './NavDropdown.vue';

/** ... */
const THEME_OPTIONS: ZephyrWeb.OptionItem<ZephyrWeb.AppTheme>[] = [
  { text: 'Light', value: 'light' },
  { text: 'Dark', value: 'dark' },
  { text: 'Auto', value: 'auto' },
];
</script>

<script setup lang="ts">
const store = useStore();

const dropdown = ref<InstanceType<typeof NavDropdown> | null>(null);

const theme = computed({
  get: () => store.state.theme,
  set: (value) => store.commit('SET_THEME', { theme: value }),
});

/**
 * ...
 */
function toggle() {
  dropdown?.value?.toggle();
}

defineExpose({ toggle });
</script>

<template>
  <NavDropdown ref="dropdown">
    <FormSelect
      v-model="theme"
      class="m-0"
      label="Appearance"
      :options="THEME_OPTIONS"
    />
  </NavDropdown>
</template>
