<script lang="ts">
import { ref, computed, defineComponent } from 'vue';
import { BFormInput } from 'bootstrap-vue';
import { uniqueId } from 'lodash';

export interface ColorOption {
  name?: string;
  value: string;
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormColor: ComponentWithProps<Props>;
  }
}

const DEFAULT_COLOR_OPTIONS = [
  { name: 'Blue', value: '#3379F6' },
  { name: 'Purple', value: '#9B55A3' },
  { name: 'Pink', value: '#E45C9C' },
  { name: 'Red', value: '#EC5F5E' },
  { name: 'Orange', value: '#E8883A' },
  { name: 'Yellow', value: '#F7C844' },
  { name: 'Green', value: '#78B856' },
] as const;

export default defineComponent({ name: 'FormColor' });
</script>

<script setup lang="ts">
/**
 * `FormColor` component properties.
 */
export interface Props extends BFormInput.Props {
  value?: string | null;
  label?: string | null;
  options?: ColorOption[];
  allowCustom?: boolean;
  allowNone?: boolean;
}

/** `FormColor` component emits. */
export type Emits = (event: 'input', value: string | null) => void;

interface ColorOptionItem extends ColorOption {
  key: string;
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
  label: null,
  options: () => [...DEFAULT_COLOR_OPTIONS],
  allowCustom: true,
  allowNone: true,
});

const emit = defineEmits<Emits>();

const fieldId = `form-color-${uniqueId()}`;

const customColorInput = ref<[HTMLInputElement] | null>(null);

const customColor = ref<string | null>(null);

const colorOptions = computed(() => {
  const items: ColorOptionItem[] = [];

  if (props.allowNone) {
    items.push({
      key: 'none',
      name: 'None',
      value: 'gray',
    });
  }

  items.push(
    ...props.options.map((item) => ({ ...item, key: `color-${uniqueId()}` })),
  );

  if (props.allowCustom) {
    items.push({
      key: 'custom',
      name: 'Custom',
      value:
        customColor.value ?? 'linear-gradient( 90deg,red, orange, skyblue)',
    });
  }

  return items;
});

function setValue(value: string | null) {
  emit('input', value);
}

function onSelection(event: MouseEvent, option: ColorOptionItem) {
  const inputEl = (
    event.target as HTMLLabelElement
  ).parentElement?.querySelector('input');

  console.log(inputEl);

  if (!inputEl) return;

  event.preventDefault();
  inputEl.checked = true;

  // ...
  if (option.key === 'none') return setValue(null);
  // ...
  if (option.key !== 'custom') return setValue(option.value);

  // ...

  setValue(customColor.value);

  const customColorInputEl = customColorInput.value?.[0];

  if (!customColorInputEl) {
    // eslint-disable-next-line no-console
    return console.warn('Unable to find custom color input element.');
  }

  customColorInputEl.click();
}
</script>

<template>
  <b-form-group class="form-color" v-bind="{ label }" label-cols="auto">
    <div class="options-wrapper">
      <div
        v-for="option in colorOptions"
        :key="option.key"
        class="option-wrapper"
      >
        <b-form-radio
          :class="['color-option', option.key]"
          :style="{ color: `${option.value} !important` }"
          :checked="option.value === value"
          v-bind="{ name: fieldId, value: option.value }"
          @click.native="onSelection($event, option)"
        />

        <div v-if="option.key === 'none'" class="option-label text-muted">
          None
        </div>

        <div
          v-else-if="option.key === 'custom'"
          class="option-label text-muted"
        >
          Custom
        </div>

        <input
          v-if="option.key === 'custom'"
          ref="customColorInput"
          v-model="customColor"
          class="custom-color-input"
          type="color"
          @input="setValue(option.value)"
        />
      </div>
    </div>
  </b-form-group>
</template>

<style scoped lang="scss">
.form-color {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-color:deep(.form-row) {
  width: 100%;

  > .col {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.form-color:deep(.custom-control-input:checked
    ~ .custom-control-label::before) {
  color: unset !important;
}

.form-color:deep(.custom-control-label::before) {
  background: currentColor !important;

  @include app-theme-light {
    border-color: #00000054 !important;
  }

  @include app-theme-dark {
    border-color: #ffffff54 !important;
  }
}

.options-wrapper {
  display: flex;
  align-items: center;
}

.option-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.color-option {
  position: relative;
}

.option-label {
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  top: 100%;
  margin-left: -0.5rem;
}

.custom-color-input {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  bottom: 0;
  right: 0;
}
</style>
