<template>
  <div :class="['panel-pointer', info.className]" :style="info.style"></div>
</template>

<script lang="ts">
import { computed } from 'vue';
import type { StyleValue } from 'vue/types/jsx';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    PanelPointer: ComponentWithProps<Props>;
  }
}

export default { name: 'PanelPointer' };
</script>

<script setup lang="ts">
/** ... */
export type PointerPosition = 'top' | 'right' | 'bottom' | 'left';

/**
 * `PanelPointer` component properties.
 */
export interface Props {
  side?: PointerPosition;
  pos?: number;
  unit?: string;
}

const props = withDefaults(defineProps<Props>(), {
  side: 'right',
  pos: 10,
  unit: 'px',
});

/** ... */
const info = computed(() => {
  const styleProp =
    props.side === 'top' || props.side === 'bottom' ? 'left' : 'top';

  const className = `menu-pointer-${props.side}`;

  const style: StyleValue = { [styleProp]: props.pos.toString() + props.unit };

  return { className, style };
});
</script>

<style scoped lang="scss">
$pointer-size: 25px;
$pointer-width: calc(#{$pointer-size} * 0.6);
$pointer-pos: calc(100%);

.panel-pointer {
  position: absolute;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.menu-pointer-top {
    bottom: $pointer-pos;
    width: $pointer-size;
    height: $pointer-width;

    > div,
    &::after {
      bottom: 0;
    }
  }

  &.menu-pointer-right {
    left: $pointer-pos;
    width: $pointer-width;
    height: $pointer-size;

    > div,
    &::after {
      left: 0;
    }
  }

  &.menu-pointer-bottom {
    top: $pointer-pos;
    width: $pointer-size;
    height: $pointer-width;

    > div,
    &::after {
      top: 0;
    }
  }

  &.menu-pointer-left {
    right: $pointer-pos;
    width: $pointer-width;
    height: $pointer-size;

    > div,
    &::after {
      right: 0;
    }
  }

  > div {
    flex-shrink: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    position: absolute;
    width: 100%;
    height: 100%;

    // @include app-theme-dark {
    //   background-color: #4c535d;
    // }
  }

  &::after {
    content: '';
    flex-shrink: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    // border: 1px solid #ffffff12;
    background-color: #363636;

    // @include app-theme-dark {
    //   background-color: #323840;
    // }
  }
}
</style>
