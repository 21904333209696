<script lang="ts">
import { ref, computed, watch } from 'vue';

import { useStore } from '@store';

import ChartLine from '@components/ChartLine.vue';
import CardLoadable from '@components/Cards/CardLoadable.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    CardReviewerAudit: ComponentWithProps;
  }
}

interface DataSet {
  data: number[];
  label?: string;
}

interface ChartData {
  datasets: DataSet[];
  labels: string[];
  series: string[];
}

export default { name: 'CardReviewerAudit' };
</script>

<script setup lang="ts">
const store = useStore();

const loading = ref(false);
const reviewedData = ref<number[]>([]);
const labels = ref<string[]>([]);
const series = ref<string[]>([]);

const certifications = computed(() => {
  // filter certifications that I've reviewed
  return store.state.certifications.items.filter(
    (c) => c.reviewer?.id === store.state.me.id,
  );
});

watch(certifications, () => {
  reviewedData.value = [];
  labels.value = [];
  series.value = [];

  // create last 6 months of series
  const months = 6;
  const today = new Date();

  let currentMonth = today.getMonth();
  let currentYear = today.getFullYear();

  for (let i = 0; i < months; i++) {
    labels.value.unshift(`${currentMonth + 1}/${currentYear}`);

    const relevantCerts = certifications.value.filter(
      ({ approvedAt, deniedAt }) => {
        const reviewedDate = approvedAt ?? deniedAt;

        if (typeof reviewedDate !== 'string') return false;

        const approvedDate = new Date(reviewedDate);

        return (
          approvedDate.getMonth() === currentMonth &&
          approvedDate.getFullYear() === currentYear
        );
      },
    ).length;

    reviewedData.value.unshift(relevantCerts);

    if (currentMonth - 1 < 0) {
      currentMonth = 11;
      currentYear--;
    } else {
      currentMonth--;
    }
  }
});

const certificationChartData = computed(() => {
  return {
    datasets: [{ label: 'Reviewed Certifications', data: reviewedData.value }],
    labels: labels.value,
    series: series.value,
  } as ChartData;
});
</script>

<template>
  <CardLoadable header="Reviews Completed" v-bind="{ loading }" no-body>
    <b-card-body>
      <ChartLine :chart-data="certificationChartData" />
    </b-card-body>
  </CardLoadable>
</template>
