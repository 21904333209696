<template>
  <CardLoadable :loading="loading" no-body>
    <b-card-header>
      <h6 class="mb-0">Current Courses</h6>
    </b-card-header>

    <b-card-body class="p-0">
      <b-table :fields="fields" :items="currentCourses">
        <!-- Row Operations Flyout Button (Header) -->

        <template #head(operations)="{ label }">
          <div class="text-center">{{ label }}</div>
        </template>

        <!-- Row Operations Flyout Button -->

        <template #cell(operations)="{ item }">
          <span class="icon-button" @click="goToCourse(item)">
            <Icon :icon="faEye" />
          </span>
        </template>
      </b-table>
    </b-card-body>
  </CardLoadable>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { faEye } from '@icons/solid/faEye';

import CardLoadable from '@components/Cards/CardLoadable.vue';

import { dateFilter } from '@filters/date';
import { userLabelFilter } from '@filters/user-label';
import { Course, User } from '@models';
import { useRouter } from '@router';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardCurrentCourses: ComponentWithProps<Props>;
  }
}

/** ... */
const fields = [
  {
    key: 'operations',
    label: 'View',
    stickyColumn: true,
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'startDate',
    label: 'Start Date',
    formatter: (value: string) => dateFilter(value, 'MM/dd/yyyy'),
  },
  {
    key: 'endDate',
    label: 'End Date',
    formatter: (value: string) => dateFilter(value, 'MM/dd/yyyy'),
  },
  {
    key: 'teacher',
    formatter: displayUserLabel,
  },
  {
    key: 'teacherAssistant',
    formatter: displayUserLabel,
  },
];

/**
 * ...
 */
function displayUserLabel(value: User | null) {
  return value ? userLabelFilter(value) : '--';
}

export default defineComponent({ name: 'CardCurrentCourses' });
</script>

<script setup lang="ts">
/**
 * `CardCurrentCourses` component properties.
 */
export interface Props {
  courses: Course[];
  loading?: boolean;
}

const router = useRouter();

const props = defineProps<Props>();

const currentCourses = computed(() => {
  // const today = new Date();

  // return this.courses.filter(({ endDate }) =>
  //   this.$utils.datePassed(endDate, today)
  // );

  return props.courses;
});

function goToCourse(course: Course) {
  const params = {
    organizationId: course.organization.id,
    courseId: course.id,
  };

  void router.push({ name: 'dashboardCourse', params });
}
</script>

<style lang="scss" scoped>
.b-table {
  font-size: 0.8rem;
}

.icon-button {
  display: flex;
  justify-content: center;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
