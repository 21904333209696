<template>
  <div class="license-key-table-cell">
    <span v-b-tooltip.hover.right="'Click to copy'" @click="copyKey">
      {{ licenseKey }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import type { License } from '@models';
import { clipboard } from '@services/clipboard';

/**
 * `TableCellLicenseKey` component properties.
 */
export interface Props {
  /** ... */
  item: License;
}

const props = defineProps<Props>();

const licenseKey = computed(() => props.item.key);

/**
 * Copy license key to clipboard.
 */
async function copyKey() {
  await clipboard.copy(licenseKey.value);
}
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLicenseKey: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLicenseKey' };
</script>

<style lang="scss" scoped>
.license-key-table-cell {
  white-space: nowrap;
  cursor: pointer;
  color: var(--blue);
  transition: color 0.5s;

  &:hover {
    color: $orange;
  }
}
</style>
