<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows';

/**
 * `TableCellListOperatingSystems` component properties.
 */
export interface Props {
  /** ... */
  value: unknown[];
}

const props = defineProps<Props>();

const items = computed(() => {
  return props.value.map((item) => ({ key: uniqueId(), item }));
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellListOperatingSystems: ComponentWithProps<Props>;
  }
}
export default { name: 'TableCellListOperatingSystems' };
</script>

<template>
  <div class="os-cell-container">
    <div v-for="{ key, item } in items" :key="key" class="mx-1">
      <icon v-if="item === 'mac'" :icon="faApple" />
      <icon v-if="item === 'win'" :icon="faWindows" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.os-cell-container {
  display: inline-flex;
}
</style>
