<script lang="ts">
import { ref, computed } from 'vue';

import { useStore } from '@store';
import { isNumber } from '@tools/type-guards';

import type { IconDefinition } from '@icons/core';

export default { name: 'NavItemIcon' };
</script>

<script setup lang="ts">
/**
 * `NavItemIcon` component properties.
 */
export interface Props {
  /**
   * Title of the nav item. Will also be used as the `tooltip` if none is
   * specified.
   */
  title: string;
  /**
   * FontAwesome icon to display.
   */
  icon: IconDefinition;
  /**
   * Text to be displayed when moused over.
   */
  tooltip?: string;
  /**
   * If specified, will display a badge to the bottom-right of the nav item
   * with the given quantity.
   */
  badge?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  // tooltip: (props) => props.title, // `props` undefined for some reason.
  tooltip: '',
  badge: null,
});

const tooltip = computed(() => props.tooltip || props.title);

const emit = defineEmits(['click']);

const store = useStore();

const el = ref<HTMLElement | null>(null);
const wasClicked = ref(false);

const classList = computed(() => {
  const items: string[] = [];

  if (isMobile.value) {
    items.push('nav-item-icon-mobile');
  } else {
    items.push('nav-item', 'nav-item-icon');
  }

  return items;
});

const isMobile = computed(() => store.state.displayMode === 'mobile');
const disableTooltip = computed(() => wasClicked.value || isMobile.value);

function onClick() {
  wasClicked.value = true;

  emit('click');
}

function onMouseLeave() {
  wasClicked.value = false;
}
</script>

<template>
  <a
    ref="el"
    v-bind="{ title }"
    :class="classList"
    @click="onClick"
    @mouseleave="onMouseLeave"
  >
    <Icon v-bind="{ icon }" />

    <template v-if="isNumber(badge) && badge > 0">
      <BadgeCount :key="badge" :value="badge" />
    </template>

    <p v-if="isMobile" class="m-0">{{ tooltip }}</p>

    <b-tooltip v-if="el && !disableTooltip" :target="el" triggers="hover">
      {{ tooltip }}
    </b-tooltip>
  </a>
</template>

<style lang="scss" scoped>
.nav-item-icon {
  display: inline-flex;
  width: 2.5rem;
  font-size: 1.4em;
  padding: 0 !important;
}

.nav-item-icon-mobile {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 1.2em;

  .fa-icon {
    width: 20px;
    margin-right: 15px;
  }

  .badge-count {
    left: unset;
    top: unset;
    right: calc(100% + 10px);
  }
}

@keyframes badgePop {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.badge-count {
  position: absolute;
  top: 55%;
  left: 50%;
  animation: badgePop 0.5s;
}
</style>
