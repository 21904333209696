<template>
  <div class="organization-billing-type-table-cell">
    <span v-if="value === 'BillMeLater'">Billed</span>
    <span v-if="value === 'POT'">Point of Transaction</span>
    <span v-if="value === 'StudentPays'">Student Pays</span>
    <span v-if="value === 'SiteLicense'">Site License</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import type { Organization } from '@models';

/**
 * `TableCellOrganizationBillingType` component properties.
 */
export interface Props {
  /** ... */
  item: Organization;
}

const props = defineProps<Props>();

const value = computed(() => props.item.billingType);
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellOrganizationBillingType: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellOrganizationBillingType' };
</script>

<style lang="scss" scoped>
.organization-billing-type-table-cell {
  // text-align: right;
  white-space: nowrap;
}
</style>
