<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    CountryFlag: ComponentWithProps<Props>;
  }
}

export default { name: 'CountryFlag' };
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { hasFlag } from 'country-flag-icons';

import 'country-flag-icons/3x2/flags.css';

/** `CountryFlag` component properties. */
export interface Props {
  flag: string;
}

const props = defineProps<Props>();

const displayFlag = computed(() => {
  let value: string;

  if (props.flag && hasFlag(props.flag)) {
    value = props.flag;
  } else {
    value = '';
  }

  return value;
});
</script>

<template>
  <span :class="['flag-class', `flag:${displayFlag}`]"></span>
</template>

<style scoped lang="scss">
.flag-class {
  border-radius: 3px;
  height: 1em;
  line-height: 0;
  display: inline-block;
}
</style>
