<script setup lang="ts">
import { computed } from 'vue';

import { modals } from '@modals';
import { useStore, useGetter } from '@store';
import { computeDisplayName } from '@utils/user';
import { auth, RoleId } from '@auth';

import { faArrowCircleDown } from '@icons/solid/faArrowCircleDown';

import profileImage from '@assets/images/portrait_las.png';
import profileBorder from '@assets/images/bronze_rank.png';

import SidebarNav from './SidebarNav.vue';

const store = useStore();

const activeRole = useGetter('activeRole');
const selectableRoles = useGetter('me/selectableRoles');

const me = computed(() => store.state.me);

const organization = computed(() => {
  if (me.value.organization) return me.value.organization;
  return null;
});

const isInstitutionAdmin = computed(() => {
  return auth.isActiveRole(RoleId.InstitutionAdmin);
});

const profileName = computed(() => computeDisplayName(me.value));

const hasMultipleRoles = computed(() => selectableRoles.value.length > 1);

const downloadLinks = computed(() => {
  return store.state.launcherLinks.map((item, key) => ({ ...item, key }));
});

const billingType = computed(() => {
  if (!isInstitutionAdmin?.value || !organization?.value) return null;

  if (organization.value.billingType === 'BillMeLater') return 'Billed';
  if (organization.value.billingType === 'POT') return 'Point of Transaction';
  if (organization.value.billingType === 'StudentPays') return 'Student Pays';
  if (organization.value.billingType === 'SiteLicense') return 'Site License';

  return null;
});

const isK12 = computed(() => {
  return !!auth.user?.isK12;
});

function openUserSettings() {
  void modals.account.settings();
}

function openRoleSelect() {
  void modals.account.selectRole();
}
</script>

<template>
  <Transition appear>
    <div class="sidebar">
      <div class="sidebar-header">
        <div
          v-b-tooltip.top="'User Settings'"
          class="h5 header-item header-button"
          @click="openUserSettings"
        >
          <div class="profile-image">
            <img :src="profileBorder" class="profile-border" />
            <img :src="profileImage" class="profile-icon" />
          </div>

          <div class="profile-name" :title="profileName">{{ profileName }}</div>
        </div>

        <div
          v-if="hasMultipleRoles"
          v-b-tooltip.bottom="'Change Role'"
          class="h6 header-item header-button"
          @click="openRoleSelect"
        >
          {{ activeRole?.name || '--' }}
        </div>

        <div v-else class="h6 header-item">
          {{ activeRole?.name || '--' }}
        </div>

        <div v-if="activeRole?.organization?.id" class="h6 header-item">
          {{ activeRole.organization.name || '--' }}
        </div>

        <div
          v-if="isInstitutionAdmin && billingType"
          class="billing-type header-item"
        >
          <span class="label">Billing Type: </span>
          <span class="value">{{ billingType }}</span>
        </div>

        <div class="header-item">
          <b-button
            v-if="!isK12"
            size="sm"
            class="public-profile-btn"
            variant="outline-primary"
            pill
            :to="{ name: 'publicProfile', params: { userId: me.id } }"
            target="_blank"
          >
            Public Profile
          </b-button>

          <b-button
            v-if="hasMultipleRoles"
            size="sm"
            class="public-profile-btn"
            variant="outline-primary"
            pill
            @click="openRoleSelect"
          >
            Change Role
          </b-button>
        </div>
      </div>

      <div class="sidebar-main">
        <SidebarNav />

        <hr />

        <h6>Downloads</h6>

        <ul class="sidebar-list">
          <li
            v-for="{ key, name, link } in downloadLinks"
            :key="key"
            class="sidebar-list-item"
          >
            <a class="sidebar-link" :href="link">
              <span class="icon-wrapper">
                <Icon :icon="faArrowCircleDown" />
              </span>

              <span>{{ name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
:root {
  --sidebar-width: 350px;
}

.sidebar-list {
  list-style: none;
  margin: 0 -20px;
  padding: 0;
}

.sidebar-list-item {
  overflow: hidden;

  &.coming-soon {
    opacity: 0.8;
    font-style: italic;
  }
}

.sidebar-link {
  cursor: pointer;
  text-decoration: none !important;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  letter-spacing: 3px;
  color: var(--navlink-color) !important;
  opacity: 0.6;
  font-size: 0.9rem;
  user-select: none;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0, 1.1, 0.01, 1.15), opacity 0.25s,
    border-left 0.25s;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    opacity: 1 !important;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  &.v-enter {
    transform: translateX(-100%);
  }

  .icon-wrapper {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    margin-right: 20px;
  }
}

.sidebar-link::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  border-radius: 4px;
  opacity: 0;
  background-color: #ff8935;
  transition: opacity 0.15s, width 0.15s;
}

.sidebar-link.active::before {
  opacity: 1;
  width: 4px;
}
</style>

<style scoped lang="scss">
.sidebar {
  position: fixed;
  width: var(--sidebar-width);
  top: var(--navbar-height);
  bottom: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(0);
  z-index: 100;
  background-color: transparent;
  user-select: none;
  transition: transform 0.75s cubic-bezier(0, 1.15, 0.28, 1.02);

  @include app-dashboard-mode-fullwidth {
    border-right: 1px solid var(--border-color);
  }

  &.v-enter {
    transform: translateX(-300px);
  }

  &.v-leave-active {
    display: none;
  }
}

.public-profile-btn {
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 0.5rem;
  line-height: 1em;
}

.sidebar-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.profile-image {
  display: block;
  position: relative;
  height: 115px;

  img {
    width: 150px;
    position: absolute;
    left: 50%;
    margin-left: -75px;

    &.profile-icon {
      top: -20px;
    }
  }
}

.header-item {
  width: 100%;
  text-align: center;

  &.billing-type {
    font-size: 0.8rem;
    margin-top: -8px;

    .label {
      // color: #ff8935;
      opacity: 0.75;
    }

    .value {
      opacity: 0.75;
    }
  }
}

.header-button {
  cursor: pointer;
  letter-spacing: 2px;
  transition: color 0.25s;
  color: var(--primary);

  &:hover {
    color: #ff8935;
  }
}

.profile-name {
  padding: 0 2rem;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: inline-block;
}

.sidebar-main {
  padding: 0 20px;
}
</style>
