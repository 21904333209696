<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    FloatingLabel: ComponentWithProps<Props>;
  }
}

export default { name: 'FloatingLabel' };
</script>

<script setup lang="ts">
/**
 * `FloatingLabel` component properties.
 */
export interface Props {
  // id?: string;
  label: string;
}

defineProps<Props>();
</script>

<template>
  <b-form-group class="form-floating">
    <slot />

    <label>{{ label }}</label>
  </b-form-group>
</template>

<style lang="scss" scoped>
.form-floating {
  position: relative;
}

.form-floating:deep(div) {
  .form-control {
    padding: 1rem 0.75rem;

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }

    &::placeholder {
      color: transparent;
    }
  }

  .custom-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }

  .form-control,
  .custom-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
  }
}

label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

  @include app-theme-light {
    color: rgba(0, 0, 0, 0.35);
  }

  @include app-theme-dark {
    color: rgba(255, 255, 255, 0.35);
  }
}

.form-floating:deep(div > .form-control:focus ~ label),
.form-floating:deep(div > .form-control:not(:placeholder-shown) ~ label),
.form-floating:deep(div > .custom-select ~ label) {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating:deep(div > .input-group ~ label) {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
</style>
