<script lang="ts">
import { computed } from 'vue';

import { isNullish, isNumber } from '@tools/type-guards';
import type { Currency } from '@values/currencies';

declare module 'vue/types/vue' {
  export interface Vue {
    InputPrice: ComponentWithProps<Props>;
  }
}

export interface PriceInfo {
  amount: number | null;
  currency: Currency | null;
  description: string | null;
}

type CurrencyOption = ZephyrWeb.OptionItem<string>;

const CURRENCY_OPTIONS = [
  { text: 'USD 🇺🇸', value: 'usd' },
  { text: 'EUR 🇪🇺', value: 'eur' },
] as const satisfies CurrencyOption[];

const CURRENCY_SYMBOL_MAP: Record<string, string> = {
  usd: '$',
  eur: '€',
};

export default { name: 'InputPrice' };
</script>

<script setup lang="ts">
/**
 * `InputPrice` component properties.
 */
export interface Props {
  value: PriceInfo;
  placeholder?: string;
  readonly?: boolean;
  descriptionRequired?: boolean;
}

/** `PriceInputPanel` component emits. */
export type Emits = (event: 'input', value: PriceInfo) => void;

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'Price Description',
  readonly: false,
  descriptionRequired: false,
});

const emit = defineEmits<Emits>();

const model = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
});

const currencySymbol = computed(() => {
  return props.value.currency
    ? CURRENCY_SYMBOL_MAP[props.value.currency]
    : null;
});

function sanitizeAmount() {
  const { amount: currentValue } = model.value;

  let sanitizedAmount: number | null;

  if (isNullish(currentValue)) {
    sanitizedAmount = null;
  } else if (isNumber(currentValue) && isFinite(currentValue)) {
    sanitizedAmount = parseFloat(
      (Math.floor(currentValue * 100) / 100).toFixed(2),
    );
  } else {
    sanitizedAmount = 0;
  }

  model.value.amount = sanitizedAmount;
}
</script>

<template>
  <div class="input-price">
    <b-form-group label="Amount*">
      <b-input-group>
        <template v-if="model.currency" #prepend>
          <b-input-group-text class="currency-symbol">
            {{ currencySymbol }}
          </b-input-group-text>
        </template>

        <b-form-input
          v-model="model.amount"
          placeholder="0.00"
          :readonly="props.readonly"
          :min="0"
          type="number"
          number
          @change="sanitizeAmount"
        />

        <b-form-select
          v-model="model.currency"
          class="currency-select"
          :readonly="props.readonly"
          :options="CURRENCY_OPTIONS"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group label="Description">
      <b-form-input
        v-model="model.description"
        :placeholder="props.placeholder"
        :readonly="props.readonly"
      />
    </b-form-group>
  </div>
</template>

<style scoped lang="scss">
.currency-symbol {
  width: 2.5rem;
  justify-content: center;
}

.currency-select {
  flex: 0 1 content;
}
</style>
