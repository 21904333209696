<script lang="ts">
import { computed, defineComponent } from 'vue';

import Icon from '@components/Icon.vue';

import { faCircle } from '@icons/solid/faCircle';

declare module 'vue/types/vue' {
  export interface Vue {
    InputRadio: ComponentWithProps<Props>;
  }
}

export default defineComponent({
  name: 'InputRadio',
  model: { prop: 'inputValue', event: 'input' },
});
</script>

<script setup lang="ts">
/**
 * `InputRadio` component properties.
 */
export interface Props {
  /** ... */
  value: unknown;
  /** ... */
  disabled?: boolean;
  /** ... */
  inputValue?: unknown;
}

/** `InputRadio` component emits. */
export type Emits = (event: 'input', value: unknown) => void;

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  inputValue: () => null,
});

const emit = defineEmits<Emits>();

const checked = computed(() => props.inputValue === props.value);

function onClick() {
  if (!props.disabled) emit('input', props.value);
}
</script>

<template>
  <div
    :class="['v-input-mark', 'input-radio', { checked, disabled }]"
    @click="onClick"
  >
    <input ref="input" type="radio" v-bind="{ value }" />

    <Transition>
      <div v-if="checked" class="input-mark">
        <Icon :icon="faCircle" />
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.v-input-mark {
  &.disabled {
    pointer-events: none;
  }
}

input {
  border: 1px solid rgba(255, 255, 255, 0.1294117647);
}
</style>
