<script setup lang="ts">
import { computed } from 'vue';

import type { Certification } from '@models';

import LabelCertificationStatus from '@components/LabelCertificationStatus.vue';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellCertificationStatus: ComponentWithProps<Props>;
  }
}

/**
 * `TableCellCertificationStatus` component properties.
 */
export interface Props {
  item: Certification;
}

const props = defineProps<Props>();

const status = computed(() => props.item.status);
</script>

<template>
  <div class="score-table-cell">
    <LabelCertificationStatus :status="status" fullwidth />
  </div>
</template>
