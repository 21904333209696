<script lang="ts">
import Overlay from '@components/Overlay.vue';
import Spinner from '@components/Spinner.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    OverlayLoading: ComponentWithProps<Props>;
  }
}

export default { name: 'OverlayLoading' };
</script>

<script setup lang="ts">
/**
 * `OverlayLoading` component properties.
 */
export interface Props {
  /** Message to display with the overlay. */
  message?: string;
  /** ... */
  color?: string;
  /** ... */
  blur?: string;
  /** Background color of the overlay. */
  // eslint-disable-next-line @typescript-eslint/ban-types
  background?: 'none' | (string & {});
  /** ... */
  fullscreen?: boolean;
  /** ... */
  transition?: string | number;
}

defineProps<Props>();
</script>

<template>
  <Overlay class="overlay-loading" v-bind="$props">
    <Spinner />
    <span v-if="message" class="ml-4">{{ message }}</span>
  </Overlay>
</template>

<style scoped lang="scss">
.overlay-loading {
  color: white;
  font-size: 40px;
}
</style>
