<script setup lang="ts">
import { UnityCatalog } from '@client/models/unity-catalog';
/**
 * `TableCellListCatalogEvents` component properties.
 */
export interface Props {
  /** ... */
  value: UnityCatalog.Event[];
}

const props = defineProps<Props>();
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellListCatalogEvents: ComponentWithProps<Props>;
  }
}
export default { name: 'TableCellListCatalogEvents' };
</script>

<template>
  <div>
    <div v-for="event in props.value" :key="event.timestamp" class="mx-1">
      <span>{{ event.user.name }} | {{ event.status }}</span>
    </div>
  </div>
</template>
