<script setup lang="ts">
import { computed } from 'vue';
import { uniqueId } from 'lodash';

import { Certification } from '@models';

import PopoverVerificationInfo from '@components/Popovers/PopoverVerificationInfo.vue';

import { faCheck } from '@icons/solid/faCheck';
import { faBan } from '@icons/solid/faBan';
import { faClock } from '@icons/solid/faClock';
import { faTimes } from '@icons/solid/faTimes';

/**
 * `VerificationStatus` component properties.
 */
export interface Props {
  status: Certification.Status;
  compact?: boolean;
  hoverable?: boolean;
}

interface StatusInfo {
  text: string;
  color: string;
  icon: unknown;
}

const props = withDefaults(defineProps<Props>(), {
  compact: false,
  hoverable: false,
});

const uid = uniqueId();
const target = `status-popover-${uid}`;

const info = computed(() => {
  let value: StatusInfo | null = null;

  if (props.status === 'CURRENT') {
    value = { text: 'Active', color: '#15d246', icon: faCheck };
  } else if (props.status === 'EXPIRED') {
    value = { text: 'Expired', color: '#ff004f', icon: faClock };
  } else if (props.status === 'SUSPENDED') {
    value = { text: 'Suspended', color: '#ff004f', icon: faTimes };
  } else if (props.status === 'REVOKED') {
    value = { text: 'Revoked', color: '#ff004f', icon: faBan };
  }

  return value;
});
</script>

<template>
  <span
    v-if="info"
    :id="target"
    :class="['verification-status-indicator', { compact, hoverable }]"
    :style="{ backgroundColor: info.color }"
  >
    <Icon :icon="info.icon" />

    <span>{{ info.text }}</span>

    <PopoverVerificationInfo v-bind="{ target, status }" />
  </span>
</template>

<style scoped lang="scss">
.verification-status-indicator {
  cursor: default;
  display: inline-flex;
  align-items: center;
  background: gray;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  color: var(--body-bg);
  font-weight: 500;
  opacity: 1;
  transition: 0.25s;

  .fa-icon {
    margin-right: 0.5rem;
  }

  &.compact {
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.25rem 0.75rem;

    .fa-icon {
      margin-right: 0.1rem;
    }
  }

  &.hoverable:hover {
    opacity: 0.5;
  }
}
</style>
