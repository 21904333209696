<script lang="ts">
import { ref, computed, watch, onMounted } from 'vue';

import { repeat } from '@tools/math';

declare module 'vue/types/vue' {
  export interface Vue {
    Spinner: ComponentWithProps<Props>;
  }
}

/**
 * ...
 */
interface SpinnerElement {
  index: number;
  node: HTMLElement;
  rotation: number;
  opacity: number;
}

/** ... */
export type FontSize =
  | 'medium'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'smaller'
  | 'larger'
  | 'initial'
  | 'inherit'
  | string
  | number;

/** ... */
export type SpinnerOffset = 'up' | 'right' | 'down' | 'left';

export default { name: 'Spinner' };
</script>

<script setup lang="ts">
/**
 * `Spinner` properties.
 */
export interface Props {
  /** The size of the spinner. */
  size?: FontSize;
  /** Number of nodes that will comprise the spinner. */
  nodes?: number;
  /** Unit used for size (if size is a number). */
  unit?: string;
  /** Color of the spinner. */
  color?: string;
  /** Offset of the spinner from its initial position on the page. */
  offset?: SpinnerOffset | null;
}

const props = withDefaults(defineProps<Props>(), {
  size: '',
  nodes: 10,
  unit: 'em',
  color: 'currentColor',
  offset: null,
});

const anchorEl = ref<HTMLDivElement | null>(null);

const elements = ref<SpinnerElement[]>([]);
const spin = ref(0);
const intervalId = ref<number | null>(window.setInterval(updateElements));

/** ... */
const rootStyle = computed(() => {
  const styles: Partial<CSSStyleDeclaration> = {};

  if (typeof props.size === 'number' || !isNaN(parseFloat(props.size))) {
    styles.fontSize = props.size.toString() + props.unit;
  } else if (typeof props.size === 'string') {
    styles.fontSize = props.size;
  }

  if (props.offset === 'up') {
    styles.marginTop = '-1em';
  } else if (props.offset === 'right') {
    styles.marginRight = '-1em';
  } else if (props.offset === 'down') {
    styles.marginBottom = '-1em';
  } else if (props.offset === 'left') {
    styles.marginLeft = '-1em';
  }

  return styles as Record<string, string>;
});

onMounted(() => {
  watch(() => props.nodes, createElements, { immediate: true });
});

/**
 * ...
 */
function createElements() {
  if (elements.value.length) {
    elements.value.forEach(({ node }) => node.remove());
    elements.value = [];
  }

  for (let i = 0; i < props.nodes; i++) {
    const rotation = 360 * (i / props.nodes);

    const node = document.createElement('div');
    node.classList.add('spinner-elem');
    node.appendChild(document.createElement('div'));

    const spinnerElement: SpinnerElement = {
      index: i,
      node,
      rotation,
      opacity: 1,
    };

    elements.value.push(spinnerElement);

    anchorEl.value?.appendChild(node);
  }
}

let spinnerEl: HTMLSpanElement | null = null;

/**
 * ...
 */
function updateElements() {
  if (!spinnerEl && anchorEl.value?.parentElement) {
    spinnerEl = anchorEl.value.parentElement;
  }

  if (intervalId.value && spinnerEl?.getRootNode() !== document) {
    return clearInterval(intervalId.value);
  }

  if (spin.value + 0.05 > props.nodes) {
    spin.value = 0;
  } else {
    spin.value += 0.05;
  }

  for (const elem of elements.value) {
    elem.opacity = repeat(elem.index - spin.value, props.nodes) / props.nodes;

    elem.node.style.transform = `rotate(${elem.rotation}deg)`;
    elem.node.style.opacity = elem.opacity.toString();

    const [el] = elem.node.querySelectorAll('div');

    if (el) el.style.backgroundColor = props.color;
  }
}
</script>

<template>
  <span class="spinner" :style="rootStyle">
    <div ref="anchorEl" />
  </span>
</template>

<style lang="scss">
.spinner {
  width: 1em;
  height: 1em;
  font-size: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.spinner-elem {
  width: 0;
  height: 0;
  position: absolute;
  display: inline-flex;
  justify-content: center;

  > div {
    position: absolute;
    width: calc(1em * 0.09);
    height: calc(1em * 0.2);
    border-radius: calc(1em * 0.045);
    top: calc(1em * -0.5);
    background-color: currentColor;

    // @include app-theme-dark {
    //   background-color: $text-color-dark;
    // }
  }
}
</style>
