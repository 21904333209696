<script lang="ts">
import { computed } from 'vue';

import { ListItem } from './SelectionPanel.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    SelectionGroup: ComponentWithProps<Props>;
  }
}

export default { name: 'SelectionGroup' };
</script>

<script setup lang="ts">
/**
 * `SelectionGroup` component properties.
 */
export interface Props<T = unknown> {
  label: string;
  items: ListItem<T>[];
  text: (item: T) => string;
  subtext: (item: T) => string;
}

const props = defineProps<Props>();

const itemsLength = computed(() => props.items.length);

const groupItems = computed(() => {
  return props.items.map(({ id, item }) => {
    return { id, text: props.text(item), subtext: props.subtext(item) };
  });
});
</script>

<template>
  <div class="selector-group">
    <h3 class="text-center">{{ label }}</h3>

    <div class="list-container">
      <ul v-if="!itemsLength">
        <li>
          <h4 class="text-center">No Items</h4>
        </li>
      </ul>

      <ul v-else>
        <li
          v-for="item in groupItems"
          :key="item.id"
          @click="$emit('removed', item.id)"
        >
          <div>
            <div class="item-text">{{ item.text }}</div>
            <div class="item-subtext">{{ item.subtext }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
  margin: 0;
}

li {
  cursor: pointer;
  list-style: none;
  padding: 10px 20px;

  &:nth-child(odd) {
    background-color: #00000012;
  }

  > div {
    width: 100%;
    overflow: hidden;
  }
}

.selector-group {
  //
}

.list-container {
  width: 100%;
  margin: 0;
  background-color: #00000021;
  // border-radius: 20px;
  position: relative;
  height: 70vh;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.item-text {
  //
}

.item-subtext {
  font-size: 0.9rem;
}
</style>
