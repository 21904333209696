<template>
  <div ref="item" class="layout-masonry-item">
    <div class="layout-masonry-item-inner">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

export interface Emits {
  (event: 'item-mounted', data: HTMLDivElement): void;
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  (event: 'item-unmounted', data: HTMLDivElement): void;
}

const emit = defineEmits(['item-mounted', 'item-unmounted']);

const item = ref<HTMLDivElement | null>(null);

onMounted(() => {
  if (item.value) emit('item-mounted', item.value);
});

onUnmounted(() => {
  if (item.value) emit('item-unmounted', item.value);
});
</script>

<style scoped lang="scss">
.layout-masonry-item {
  // ...
}
</style>
