<script lang="ts">
import { computed } from 'vue';

import { useStore } from '@store';
import { BvTableField } from 'bootstrap-vue';
import { filter, find } from 'lodash';

import { faExclamationTriangle } from '@icons/regular/faExclamationTriangle';

import type { Course } from '@models';
import { dates } from '@utils/dates';

declare module 'vue/types/vue' {
  export interface Vue {
    TableCellCourseName: ComponentWithProps<Props>;
  }
}

type Field = BvTableField & { key: string };

const tooltip =
  'Students within course.value need license extensions. Click the Gear Icon > Send Extension Codes.';

export default { name: 'TableCellCourseName' };
</script>

<script setup lang="ts">
/**
 * `TableCellCourseName` component properties.
 */
export interface Props {
  field: Field;
  value: unknown;
  item: Course;
}

const props = defineProps<Props>();

const store = useStore();

const users = computed(() => {
  return store.state.users.items;
});

const courseName = computed(() => {
  return props.item.name;
});

const extensionsRequired = computed(() => {
  // return !!props.item.extensionsRequired;
  //

  return users.value.some((user) => {
    const studentRole = find(user.roles, { id: 3 });

    if (!studentRole) return false;

    if (!dates.isBefore(dates.now, props.item.endDate)) return false;

    for (const license of user.licenses ?? []) {
      if (
        !license.expiration ||
        dates.isAfter(license.expiration, props.item.endDate)
      ) {
        continue;
      }

      if (!user.extensionCodes?.length) return true;

      // user has extension codes sent out already
      const codesUnused = filter(user.extensionCodes, { used: false });

      if (codesUnused.every(({ courseId }) => courseId !== props.item.id)) {
        return true;
      }
    }

    return false;
  });
});
</script>

<template>
  <div class="score-table-cell">
    <span :class="{ important: extensionsRequired }">
      <span>{{ courseName }}</span>
      <span v-if="extensionsRequired" v-b-tooltip="tooltip">
        <Icon :icon="faExclamationTriangle" />
      </span>
    </span>
  </div>
</template>
