<script lang="ts">
import InfoCircle from '@components/InfoCircle.vue';
import Icon from '@components/Icon.vue';

import { faTriangleExclamation } from '@icons/duotone/faTriangleExclamation';

declare module 'vue/types/vue' {
  export interface Vue {
    TableCellProductSaleNotice: ComponentWithProps;
  }
}

const NOTICE_MESSAGE =
  'Product sale functionality is currently not functional as migration to using Stripe continues.';

export default { name: 'TableCellProductSaleNotice' };
</script>

<script setup lang="ts"></script>

<template>
  <div class="product-sale-notice-cell">
    <span class="notice-text">
      <Icon :icon="faTriangleExclamation" />
    </span>

    <InfoCircle :text="NOTICE_MESSAGE" />
  </div>
</template>

<style scoped lang="scss">
.product-sale-notice-cell {
  display: flex;
  align-items: center;
}

.notice-text {
  color: var(--warning);
  margin-right: 0.5rem;
  font-size: 1.2rem;
}
</style>
