import { render, staticRenderFns } from "./CountryFlag.vue?vue&type=template&id=47648628&scoped=true"
import script from "./CountryFlag.vue?vue&type=script&setup=true&lang=ts"
export * from "./CountryFlag.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CountryFlag.vue?vue&type=style&index=0&id=47648628&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47648628",
  null
  
)

export default component.exports