<template>
  <div class="heatmap-table">
    <b-table
      :items="updatedItems"
      :fields="fields"
      tbody-tr-class="rowClass"
      borderless
      fixed
    >
      <template #cell(January)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(February)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(March)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(April)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(May)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(June)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }}<br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(July)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(August)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }}<br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(September)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(October)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: ${{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(November)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div>
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: {{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <template #cell(December)="data">
        <div :id="data.value.id" class="cell-class" :style="computeColor(data)">
          {{ data.value.total }}
        </div>
        <b-tooltip ref="tooltip" :target="data.value.id">
          <div class="heatmap-tooltip">
            <p>
              Approved: {{ data.value.approved }} <br />
              Denied: {{ data.value.denied }} <br />
              Revoked: {{ data.value.revoked }} <br />
              Fee: {{ data.value.reviewerFee }}
            </p>
          </div>
        </b-tooltip>
      </template>
      <!-- <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :$attrs = "v-b-tooltip.hover"
        />
      </template> -->
    </b-table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { groupBy, sumBy, uniqueId, orderBy } from 'lodash';
import { getYear, getMonth, parseISO } from 'date-fns';

export interface Props {
  items: [];
}

const computeColor = (data) => {
  return {
    backgroundColor: `rgb(${(data.value.revoked + data.value.denied) * 20}, ${
      data.value.approved * 20
    }, 0, ${data.value.total / 10})`,
  };
};

const monthsMap = [
  { id: 0, name: 'January' },
  { id: 1, name: 'February' },
  { id: 2, name: 'March' },
  { id: 3, name: 'April' },
  { id: 4, name: 'May' },
  { id: 5, name: 'June' },
  { id: 6, name: 'July' },
  { id: 7, name: 'August' },
  { id: 8, name: 'September' },
  { id: 9, name: 'October' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' },
];

const processToolTipTd = (value, key, item) => {
  return {
    title: 'tooltip test',
  };
};

const processTdClass = (value, key, item) => {
  return {
    border: '1px solid !important',
    'text-align': 'center',
  };
};

const fields = [
  {
    key: 'year',
    label: '',
    tdClass: 'year-col-class',
  },
  {
    key: 'January',
    label: 'January',
    tdClass: 'tdClass',
    thClass: 'thClass',
    // tdAttr: processToolTipTd,
  },
  {
    key: 'February',
    label: 'February',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'March',
    label: 'March',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'April',
    label: 'April',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'May',
    label: 'May',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'June',
    label: 'June',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'July',
    label: 'July',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'August',
    label: 'August',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'September',
    label: 'September',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'October',
    label: 'October',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'November',
    label: 'November',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
  {
    key: 'December',
    label: 'December',
    tdClass: 'tdClass',
    thClass: 'thClass',
  },
];

const props = defineProps<Props>();

const loading = ref(false);

const updatedItems = computed(() => {
  const result = [];
  const years = groupBy(props.items, (i) => getYear(parseISO(i.createdAt)));
  for (const year in years) {
    const months = groupBy(years[year], (y) => getMonth(parseISO(y.createdAt)));
    console.log('In here');
    result.push({
      year: year,
      January: {
        id: uniqueId(),
        year: year,
        total: months[0] ? months[0].length : 0,
        approved: sumBy(months[0], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[0], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[0], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[0], (m) => m.reviewerFee),
      },
      February: {
        id: uniqueId(),
        year: year,
        total: months[1] ? months[1].length : 0,
        approved: sumBy(months[1], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[1], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[1], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[1], (m) => m.reviewerFee),
      },
      March: {
        id: uniqueId(),
        year: year,
        total: months[2] ? months[2].length : 0,
        approved: sumBy(months[2], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[2], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[2], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[2], (m) => m.reviewerFee),
      },
      April: {
        id: uniqueId(),
        year: year,
        total: months[3] ? months[3].length : 0,
        approved: sumBy(months[3], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[3], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[3], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[3], (m) => m.reviewerFee),
      },
      May: {
        id: uniqueId(),
        year: year,
        total: months[4] ? months[4].length : 0,
        approved: sumBy(months[4], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[4], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[4], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[4], (m) => m.reviewerFee),
      },
      June: {
        id: uniqueId(),
        year: year,
        total: months[5] ? months[5].length : 0,
        approved: sumBy(months[5], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[5], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[5], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[5], (m) => m.reviewerFee),
      },
      July: {
        id: uniqueId(),
        year: year,
        total: months[6] ? months[6].length : 0,
        approved: sumBy(months[6], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[6], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[6], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[6], (m) => m.reviewerFee),
      },
      August: {
        id: uniqueId(),
        year: year,
        total: months[7] ? months[7].length : 0,
        approved: sumBy(months[7], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[7], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[7], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[7], (m) => m.reviewerFee),
      },
      September: {
        id: uniqueId(),
        year: year,
        total: months[8] ? months[8].length : 0,
        approved: sumBy(months[8], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[8], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[8], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[8], (m) => m.reviewerFee),
      },
      October: {
        id: uniqueId(),
        year: year,
        total: months[9] ? months[9].length : 0,
        approved: sumBy(months[9], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[9], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[9], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[9], (m) => m.reviewerFee),
      },
      November: {
        id: uniqueId(),
        year: year,
        total: months[10] ? months[10].length : 0,
        approved: sumBy(months[10], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[10], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[10], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[10], (m) => m.reviewerFee),
      },
      December: {
        id: uniqueId(),
        year: year,
        total: months[11] ? months[11].length : 0,
        approved: sumBy(months[11], (m) => m.status === 'CURRENT'),
        denied: sumBy(months[11], (m) => m.status === 'DENIED'),
        revoked: sumBy(months[11], (m) => m.status === 'REVOKED'),
        reviewerFee: sumBy(months[11], (m) => m.reviewerFee),
      },
    });
  }
  return orderBy(result, 'year', 'desc');
});
</script>

<style lang="scss">
// .year-col-class {
//   text-align: right;
// }

// .tdClass {
//   border: 1px solid !important;
//   text-align: center;
// }
// .thClass {
//   text-align: center;
// }
// .rowClass {
//   border: 0px !important;
// }
</style>

<style scoped lang="scss">
.heatmap-table:deep(.tdClass) {
  border: 1px solid !important;
  position: relative;
  padding: 0px;
}
// .heatmap-table:deep(.tdClass:hover) {
//   background-color: lightgrey;
//   opacity: 0.3;
//   color: black;
// }

.heatmap-table:deep(.thClass) {
  text-align: center;
}
.heatmap-table:deep(.year-col-class) {
  text-align: right;
}

.cell-class {
  //   width: 100%;
  //   height: 100%;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //   padding: 0px;
}
.cell-class:hover {
  opacity: 0.3;
}

// .heatmap-tooltip {
//   background-color: #fff;
//   width: 100%;
// }
// .cell-class:hover {
//   background-color: grey;
//   opacity: 0.5;
// }

// .tdClass {
//   //   border: 1px solid !important;
//   border-left: 1px solid #fff;
// }
</style>
