<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import { useRoute, useRouter } from '@router';
import { useStore, useCommit } from '@store';
import { delay } from '@tools/delay';
import { isNumber } from '@tools/type-guards';

import DisplayTextCurrency from '@components/Display/DisplayTextCurrency.vue';

import { faShoppingCart } from '@icons/regular/faShoppingCart';
import { faTimes } from '@icons/solid/faTimes';
import { faTrashCan } from '@icons/solid/faTrashCan';

const store = useStore();
const route = useRoute();
const router = useRouter();

const showCartWidget = ref(false);
const newItemAdded = ref(false);

const cart = computed(() => store.state.cart);

const removeItem = useCommit('cart/REMOVE_ITEM');

watch(
  () => cart.value.items,
  async (newValue, oldValue) => {
    const newItemCount = newValue.length;
    const oldItemCount = oldValue?.length ?? newItemCount;

    // ...
    const wasHidden = !showCartWidget.value;

    // showCartWidget.value = !!newItemCount;
    showCartWidget.value = !!newItemCount;

    if (wasHidden || newItemCount < oldItemCount) return;

    newItemAdded.value = true;

    await delay(200);

    newItemAdded.value = false;
  },
);

const cartLength = computed(() => {
  return cart.value.items?.length;
});

/** ... */
const showCartButton = computed(() => {
  return (
    !route.value.name?.includes('dashboard') &&
    !showCartWidget.value &&
    !!cartLength.value
  );
});

/** ... */
const showBadge = computed(() => {
  return isNumber(cartLength.value) && cartLength.value > 0;
});

/**
 * Remove the product from the shopping cart.
 *
 * @param itemId ID of the item to remove from the cart.
 */
function removeFromCart(itemId: string) {
  removeItem({ itemId });
}

function goToCheckout() {
  void router.push({ name: 'preCheckout' });
}
</script>

<template>
  <div class="cart-widget-wrapper">
    <Transition appear>
      <div
        v-if="showCartButton"
        v-b-tooltip.left="'Show Cart'"
        class="cart-button"
        @click="showCartWidget = !showCartWidget"
      >
        <Icon :icon="faShoppingCart" />
        <BadgeCount v-if="showBadge" :key="cartLength" :value="cartLength" />
      </div>
    </Transition>

    <Transition appear>
      <div
        v-if="showCartWidget"
        id="cart-widget-view"
        :class="{ 'has-new-item': newItemAdded }"
      >
        <h3 class="h5 d-flex justify-content-between align-items-center mb-4">
          <span>
            <Icon class="mr-1" :icon="faShoppingCart" />
            <b>CART</b>
          </span>
          <b-button
            class="dismiss-button"
            variant="secondary"
            @click="showCartWidget = false"
          >
            <Icon :icon="faTimes"
          /></b-button>
        </h3>

        <div class="cart-items-container mb-4">
          <b-container class="flex-column align-items-stretch px-0">
            <b-row
              v-for="item in cart.items"
              :key="item.id"
              class="cart-item py-2"
            >
              <b-col
                col
                class="d-flex justify-content-between align-items-center"
              >
                <small>{{ item.name }}</small>
                <DisplayTextCurrency
                  class="font-weight-bold"
                  :value="item.total"
                />
              </b-col>

              <b-col cols="auto" class="d-inline-flex align-items-center">
                <b-button
                  title="Remove Item"
                  variant="outline-danger"
                  size="sm"
                  @click="removeFromCart(item.id)"
                >
                  <Icon :icon="faTrashCan"
                /></b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <b-button block variant="primary" @click="goToCheckout">
          Proceed to Checkout
        </b-button>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
@mixin widget-transition {
  opacity: 1;
  transform: translateX(0);
  transition: opacity, transform;
  transition-duration: 0.1s;

  @include v-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1.24, 0, 1);
  }

  @include v-enter {
    transform: translateX(520px);
  }

  @include v-leave-active {
    transition-duration: 0.25s;
  }

  @include v-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.cart-button {
  top: 100px;
  right: 15px;
  position: fixed;
  z-index: 1001;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  color: #4478ee;
  background-color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 1.5em;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%),
    0 2px 3px #00134a36;

  @include widget-transition;

  &:hover {
    transform: scale(1.1);
  }

  .fa-icon {
    align-self: center;
  }

  .badge-count {
    position: absolute;
    left: -5px;
    bottom: -5px;
  }

  &:hover {
    background-color: $sales-blue-primary;

    color: $almost-white;
    cursor: pointer;
  }
}

#cart-widget-view {
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
  height: auto;
  padding: 25px;
  border: 1px solid var(--border-color);
  background-color: var(--card-bg);
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  z-index: 1001;
  position: absolute;
  top: 100px;
  right: 20px;
  border-radius: 10px;

  @include widget-transition;

  &.has-new-item {
    transform: scale(1.1);
  }
}

.dismiss-button {
  display: block;
  float: right;
}

.cart-items-container {
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid gray;
  }
}

small {
  font-size: 0.8rem;
}

.cart-item {
  position: relative;

  &::after {
    // border-bottom: 1px solid gray;

    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: var(--border-color);
  }

  &:first-child::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background-color: var(--border-color);
  }
}
</style>
