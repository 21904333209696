<script lang="ts">
import { computed } from 'vue';

import { faCheck } from '@icons/solid/faCheck';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    InputCheckbox: ComponentWithProps<Props>;
  }
}

export default { name: 'InputCheckbox' };
</script>

<script setup lang="ts">
/**
 * `InputCheckbox` component properties.
 */
export interface Props {
  value: boolean;
  size?: string | null;
  disabled?: boolean | 'true' | 'false' | null;
}

/** `InputCheckbox` component emits. */
export type Emits = (event: 'input', value: boolean) => void;

const props = withDefaults(defineProps<Props>(), {
  size: null,
  disabled: false,
});

const emit = defineEmits<Emits>();

const inputDisabled = computed(() => {
  return props.disabled !== false && props.disabled !== 'false';
});

const classList = computed(() => {
  const classList: string[] = [];

  if (props.value) {
    classList.push('checked');
  }

  if (inputDisabled.value) {
    classList.push('disabled');
  }

  if (props.size === 'sm') {
    classList.push('size-small');
  }

  return classList;
});

function toggle() {
  if (inputDisabled.value) return;

  emit('input', !props.value);
}
</script>

<template>
  <div
    :class="['v-input-mark', 'input-checkbox', ...classList]"
    @click="toggle"
  >
    <input v-bind="{ value }" type="checkbox" />

    <Transition>
      <div v-if="value" class="input-mark">
        <span class="input-icon">
          <Icon :icon="faCheck" />
        </span>
      </div>
    </Transition>
  </div>
</template>
