<script lang="ts">
import { defaults } from 'chart.js';

declare module 'vue/types/vue' {
  export interface Vue {
    ChartLine: ComponentWithProps<Props>;
  }
}

const { elements } = defaults.global;

if (elements?.line) {
  Object.assign(elements.line, {
    tension: 0.4,
    backgroundColor: '#007bff33',
    borderWidth: 3,
    borderColor: '#007bff',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    capBezierPoints: true,
    fill: true,
  });
}

export default { name: 'ChartLine' };
</script>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { Line as LineChart } from 'vue-chartjs';

import { useStore } from '@store';

export interface ChartLine {
  $data: {
    _chart: Chart;
  };
  addPlugin: LineChart['addPlugin'];
  renderChart: LineChart['renderChart'];
}

/**
 * `ChartLine` component properties.
 */
export interface Props {
  /**
   * The {@link Chart.ChartData chart data} that will be represented ny the
   * graph.
   */
  chartData: Chart.ChartData | null;
  /**
   * {@link Chart.ChartOptions Chart options} used for configuring
   * visual/organizational aspects of the chart.
   */
  options?: Chart.ChartOptions;
  /**
   * The height of the chart.
   */
  height?: number;
}

const props = withDefaults(defineProps<Props>(), {
  // options: null,
  height: 270,
});

const store = useStore();

const chart = ref<InstanceType<typeof LineChart> | null>(null);

const appTheme = computed(() => store.state.theme);

watch(
  () => props.chartData,
  () => {
    chart.value?.$data._chart.update();
  },
);

watch(appTheme, makeChart);

onMounted(() => {
  makeChart();
});

/**
 * Make the chart.
 */
function makeChart() {
  const isDarkTheme = store.state.theme === 'dark';

  const tickFontColor = isDarkTheme ? '#c8cad2' : '#667381';
  const gridLinesColor = isDarkTheme ? '#c8cad229' : '#0000003d';

  const axesOptions = {
    display: true,
    ticks: {
      fontColor: tickFontColor,
    },
    gridLines: {
      display: true,
      color: gridLinesColor,
      borderDash: [2, 2],
    },
  };

  const options: Chart.ChartOptions = {
    scales: {
      xAxes: [axesOptions],
      yAxes: [axesOptions],
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  chart.value?.renderChart?.(props.chartData ?? {}, options);
}
</script>

<template>
  <LineChart class="chart-line" ref="chart" v-bind="{ height }" />
</template>
