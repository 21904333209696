<script lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

import { auth } from '@auth';
import { modals } from '@modals';
import { useRoute, useRouter } from '@router';
import { useStore, useGetter } from '@store';
import { externalLinks, type ExternalSiteLink } from '@values/external-links';

import CartWidget from '@components/CartWidget.vue';
import SidebarMobile from '@components/SidebarMobile.vue';
import ZephyrLogo from '@components/SVGs/ZephyrLogo.vue';

import { faArrowCircleDown } from '@icons/regular/faArrowCircleDown';
import { faShoppingCart } from '@icons/regular/faShoppingCart';
import { faTachometerFast } from '@icons/regular/faTachometerFast';
import { faUsers } from '@icons/regular/faUsers';
// import { faDisplay } from '@icons/regular/faDisplay';
// import { faSignOut } from '@icons/regular/faSignOut';
import { faBell } from '@icons/regular/faBell';
import { faCog } from '@icons/regular/faCog';
import { faVial } from '@icons/regular/faVial';
// import { faPalette } from '@icons/regular/faPalette';

import AnnouncementsDropdown from './AnnouncementsDropdown.vue';
// import AppearanceDropdown from './AppearanceDropdown.vue';
import MobileMenuButton from './MobileMenuButton.vue';
import NavItemIcon from './NavItemIcon.vue';
// import RouteLoadProgressBar from './RouteLoadProgressBar.vue';
import SiteAnnouncement from './SiteAnnouncement.vue';

type AnnouncementsDropdownRef = InstanceType<typeof AnnouncementsDropdown>;

interface RouteMenuItem {
  id: string;
  title: string;
  route: string;
}

type MenuItem = RouteMenuItem | ExternalSiteLink;

const NAV_MENU_ITEMS: MenuItem[] = [
  externalLinks.faqs,
  // { id: 'about', title: 'About', route: 'about' },
  { id: 'features', title: 'Features', route: 'features' },
  { id: 'individuals', title: 'Individuals', route: 'individuals' },
  { id: 'enterprise', title: 'Enterprise', route: 'enterprise' },
  { id: 'certifications', title: 'Certifications', route: 'certifications' },
  // { id: 'news', title: 'News', route: 'news' },
  { id: 'shop', title: 'Shop', route: 'shop' },
  // { id: 'pricing', title: 'Pricing', route: 'pricing' },
];

const BASE_MENU_ITEM = ['faqs', 'features', 'certifications'];
const AUTH_MENU_ITEMS = [...BASE_MENU_ITEM, 'shop'];
const NO_AUTH_MENU_ITEMS = [...BASE_MENU_ITEM, 'individuals', 'enterprise'];

export default { name: 'AppHeader' };
</script>

<script setup lang="ts">
export interface SiteAnnouncement {
  title: string;
  details?: string;
}

const announcementsDropdown = ref<AnnouncementsDropdownRef | null>(null);
const siteAnnouncement = ref<SiteAnnouncement | null>(null);

const store = useStore();
const router = useRouter();
const route = useRoute();

const collapsed = ref(true);
const atPageTop = ref(window.scrollY <= 0);

const displayMode = computed(() => {
  return store.state.displayMode;
});
const initialNavigationCompleted = computed(() => {
  return store.state.initialNavigationCompleted;
});
const launcherLinks = computed(() => {
  return store.state.launcherLinks;
});
const cart = computed(() => {
  return store.state.cart;
});

const selectableRoles = useGetter('me/selectableRoles');

watch(
  () => route.value.name,
  () => {
    collapsed.value = true;
  },
);

const renderNavItems = computed(() => {
  return initialNavigationCompleted.value;
});

const menu = computed(() => {
  const itemsFilter = auth.user ? AUTH_MENU_ITEMS : NO_AUTH_MENU_ITEMS;

  const items = NAV_MENU_ITEMS.filter(({ id }) => {
    if (!itemsFilter.includes(id)) {
      return false;
    }

    if (id === 'shop' && auth.user?.isK12) {
      return false;
    }

    return true;
  });

  // items.push({ id: 'testing', title: 'Testing', state: 'testing' });

  return items;
});

/** ... */
const launcherLink = computed(() => {
  return launcherLinks.value[0]?.link ?? '';
});

const classList = computed(() => {
  const classList: string[] = [];

  if (
    route.value.name !== 'lasadmin' &&
    route.value.name !== 'admin' &&
    route.value.name !== 'instructor' &&
    route.value.name !== 'student' &&
    route.value.name !== 'subscriber' &&
    route.value.name !== 'independentOperator'
  ) {
    classList.push('pinned');
  }

  if (isTransparent.value) {
    classList.push('transparent');
  }

  if (isMobile.value) {
    classList.push('mobile');

    if (!collapsed.value) {
      classList.push('expanded');
    }
  }

  return classList;
});

/** ... */
const cartItemCount = computed(() => {
  let count = 0;

  for (const item of cart.value.items) {
    count += item.quantity;
  }

  return count;
});

/** ... */
const isMobile = computed(() => {
  return displayMode.value === 'mobile';
});

/** ... */
const canChangeRoles = computed(() => {
  return selectableRoles.value.length > 1;
});

/** ... */
const showDownloadButton = computed(() => {
  return auth.user && !!launcherLink.value;
});

const showCartWidget = computed(() => {
  return !auth.user?.isK12 && route.value.name !== 'preCheckout';
});

/** ... */
const isTransparent = computed(() => {
  // ...
  if (!atPageTop.value) return false;

  // ...
  return ['main', 'mainV2', 'individuals', 'enterprise'].some(
    (name) => name === route.value.name,
  );
});

/** ... */
const isHidden = computed(() => {
  return (
    !auth.initialized ||
    route.value.name === null ||
    route.value.name === 'loginCallback' ||
    route.value.name === 'onboarding'
  );
});

/** ... */
const announcements = computed(() => {
  return store.state.announcements.items;
});

/** ... */
const unreadAnnouncementsCount = computed(() => {
  return announcements.value.filter(({ read }) => !read).length;
});

onMounted(() => {
  // Previously from 'created' hook.
  /* eslint-disable @typescript-eslint/unbound-method */
  window.addEventListener('load', onScreenPosUpdated);
  window.addEventListener('resize', onScreenPosUpdated);
  window.addEventListener('scroll', onScreenPosUpdated);
  /* eslint-enable @typescript-eslint/unbound-method */

  // siteAnnouncement.value = {
  //   title: 'Zephyr will undergo maintenance Nov. 19-20',
  //   details:
  //     'On Saturday, November 19th at 6pm EST to Sunday, November 20th at 6pm EST, Zephyr will undergo scheduled maintenance.<br /><br />During time.value, you may not be able access the Zephyr website or you may see incomplete or missing data. If you experience this, please try again after Sunday November 20th at 6pm EST before reaching out to our support.<br /><br />You may also experience issues submitting report data from the simulator. If you experience this, your reports will be saved locally. The next time you log in to the simulator, they will be automatically resubmitted.',
  // };
});

onUnmounted(() => {
  /* eslint-disable @typescript-eslint/unbound-method */
  window.removeEventListener('load', onScreenPosUpdated);
  window.removeEventListener('resize', onScreenPosUpdated);
  window.removeEventListener('scroll', onScreenPosUpdated);
  /* eslint-enable @typescript-eslint/unbound-method */
});

//#region Route Navigation

function goToRoute(name: string) {
  if (router.currentRoute.name !== name) {
    void router.push({ name });
  }
}

// const goToSignUp = () => goToRoute('checkout');
const goToDashboard = () => goToRoute('dashboard');
const goToIndividuals = () => goToRoute('individuals');
// const goToEnterprise = () => goToRoute('enterprise');
const goToCart = () => goToRoute('cart');
// const goToShop = () => goToRoute('shop');
const goToTesting = () => goToRoute('testing');

//#endregion Route Navigation

//#region Modal Openers

/** Open the role selection modal. */
const openRoleSelect = () => {
  void modals.account.selectRole();
};

// /** Open app settings modal. */
// const openSettingsModal = () => {
//   void modals.settings.appSettings();
// };

/** Open user settings modal. */
const openUserSettingsModal = () => {
  void modals.account.settings();
};

//#endregion Modal Openers

//#region Account/Authorization

const login = () => {
  void auth.loginWithRedirect({
    prompt: 'login',
    returnTo: { name: 'dashboard' },
  });
};

const logout = () => {
  void auth.logout();
};

//#endregion Account/Authorization

const toggleAnnouncementsDropdown = () => {
  announcementsDropdown.value?.toggle();
};

const onScreenPosUpdated = () => {
  atPageTop.value = window.scrollY <= 0;
};
</script>

<template>
  <header id="app-header" ref="rootEl" :class="classList">
    <div v-if="!isHidden" class="navbar">
      <div class="navbar-inner">
        <!-- Navbar Logo -->

        <div class="navbar__header">
          <RouterLink class="navbar-brand" to="/">
            <ZephyrLogo :width="165" colors="currentColor" />
          </RouterLink>

          <MobileMenuButton
            v-if="isMobile"
            :collapsed="collapsed"
            @click="collapsed = !collapsed"
          />
        </div>

        <!-- Navbar Menu -->

        <Transition>
          <div
            v-if="!isMobile || !collapsed"
            :class="[
              'navbar-main',
              {
                'navbar-mobile': isMobile,
                hidden: !renderNavItems,
              },
            ]"
          >
            <nav>
              <!-- Core Menu Items -->
              <template v-for="item in menu">
                <RouterLink
                  v-if="'route' in item"
                  :key="item.id"
                  class="nav-item nav-item-link"
                  :to="{ name: item.route }"
                  active-class="active"
                >
                  {{ item.title }}
                </RouterLink>

                <a
                  v-else
                  :key="item.id"
                  class="nav-item nav-item-link"
                  :href="item.url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ item.title }}
                </a>
              </template>
            </nav>

            <!-- SideNav if Mobile -->

            <div v-if="isMobile" :style="{ width: '100%' }">
              <div class="nav-divider"></div>

              <div class="sidebar-mobile-nav">
                <SidebarMobile />
              </div>
            </div>

            <div class="nav-divider"></div>

            <!-- #region Navbar Side -->

            <div class="navbar-side">
              <NavItemIcon
                v-if="auth.user"
                title="Dashboard"
                :icon="faTachometerFast"
                @click="goToDashboard"
              />

              <NavItemIcon
                v-if="canChangeRoles"
                title="Change Role"
                :icon="faUsers"
                @click="openRoleSelect"
              />

              <NavItemIcon
                v-if="!auth.user?.isK12"
                title="Cart"
                :icon="faShoppingCart"
                :badge="cartItemCount"
                @click="goToCart"
              />

              <div v-if="auth.user" class="position-relative">
                <NavItemIcon
                  title="Announcements"
                  :icon="faBell"
                  :badge="unreadAnnouncementsCount"
                  @click="toggleAnnouncementsDropdown"
                />

                <AnnouncementsDropdown
                  ref="announcementsDropdown"
                  v-bind="{ announcements }"
                />
              </div>

              <NavItemIcon
                v-if="auth.user"
                title="Settings"
                :icon="faCog"
                @click="openUserSettingsModal"
              />

              <!-- <div class="position-relative">
                <NavItemIcon
                  title="Site Appearance"
                  :icon="icons.faDisplay"
                  @click="appearanceDropdown?.toggle()"
                />

                <AppearanceDropdown ref="appearanceDropdown" />
              </div> -->

              <NavItemIcon
                v-if="$env.isDevelopment"
                title="Dev Test Page"
                :icon="faVial"
                @click="goToTesting"
              />

              <div class="nav-divider"></div>

              <!-- Login -->

              <a
                v-if="!auth.user"
                class="nav-item nav-item-action"
                @click="login"
              >
                Log In
              </a>

              <!-- Purchase -->

              <a
                v-if="!auth.user"
                class="nav-item nav-item-action primary"
                @click="goToIndividuals"
              >
                Purchase
              </a>

              <!-- Download Button -->

              <a
                v-if="showDownloadButton"
                id="download-zephyr-button"
                class="nav-item nav-item-button"
                :disabled="!launcherLink"
                :href="launcherLink"
              >
                <Icon :icon="faArrowCircleDown" />
                <span class="button-text">Get Zephyr</span>
              </a>

              <!-- Logout -->

              <a
                v-if="auth.user"
                class="nav-item nav-item-action"
                href="javascript:void(0)"
                @click="logout"
              >
                <!-- <Icon :icon="icons.faSignOut" /> -->
                <span>Logout</span>
              </a>
            </div>

            <!-- #endregion Navbar Side -->
          </div>
        </Transition>

        <!-- <RouteLoadProgressBar /> -->
        <CartWidget v-if="showCartWidget" />
      </div>
    </div>

    <SiteAnnouncement
      v-if="siteAnnouncement"
      :announcement="siteAnnouncement"
    />
  </header>
</template>

<style lang="scss">
:root {
  --navbar-height: 80px;
  --navbar-and-promos-height: 120px;
  --navbar-color-light: #636363;
  --navbar-color-dark: #bbb;

  @include app-theme-light {
    --navbar-color: var(--navbar-color-light);
  }

  @include app-theme-dark {
    --navbar-color: var(--navbar-color-dark);
  }
}
</style>

<style scoped lang="scss">
$navbar-transition: 0.25s;
$navbar-hover-transition: 0.25s;

// ...
@mixin navbar-pinned {
  #app-header.pinned & {
    @content;
  }
}

// ...
@mixin navbar-transparent {
  #app-header.transparent & {
    @content;
  }
}

// ...
@mixin navbar-mobile {
  #app-header.mobile & {
    @content;
  }
}

// ...
@mixin navbar-expanded {
  #app-header.expanded & {
    @content;
  }
}

// ...
@mixin on-route($routes...) {
  #app-header.transparent:not(.expanded) & {
    @each $route in $routes {
      @include app-route($route) {
        @content;
      }
    }
  }
}

// ...
@mixin navbar-promos {
  #app-header.promos & {
    @content;
  }
}

#app-header {
  position: relative;
  z-index: 1000;
  display: block;

  &.pinned {
    margin-bottom: var(--navbar-height);
  }
}

@keyframes navbarEnter {
  from {
    transform: translateY(-80px);
  }

  to {
    transform: translateY(0);
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--navbar-height);
  margin: 0 auto;
  padding: 0 20px;
  color: var(--navbar-color);
  font-size: 0.9rem;
  font-family: 'Rubik';
  background-color: var(--panel-bg);
  border-bottom: 1px solid var(--border-color);
  transition: background-color $navbar-transition;
  animation: navbarEnter 0.5s;

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }

  @include navbar-pinned {
    position: fixed;
    top: 0;
  }

  @include navbar-transparent {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  @include navbar-expanded {
    color: #bcbcbc !important;
  }

  @include on-route('main') {
    color: #d0571c !important;
    // darker color below for ADA compliance contrast
    // color: #793311 !important;
  }

  @include on-route('enterprise', 'individuals') {
    color: white !important;
  }

  a {
    color: inherit;
    text-decoration: none !important;
  }
}

.navbar-inner {
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--navbar-height);
  padding: 7px;
  opacity: 1;
  transition: color $navbar-transition, opacity $navbar-hover-transition;

  &:hover {
    opacity: 0.5;
  }
}

.navbar-main {
  display: flex !important;
  align-items: center;
  padding: 0 20px;
  border: none !important;
  opacity: 1;
  transition: opacity 0.5s;
  //
  width: 900px;
  flex-grow: 1;

  &.hidden {
    opacity: 0;
  }

  @include navbar-mobile {
    width: auto;
    position: fixed;
    inset: 0;
    flex-direction: column;
    padding: var(--navbar-height) 0 20px;
    overflow: auto;
    background-color: #000000e6;
    opacity: 1;

    @include v-from {
      opacity: 0;
    }
  }
}

nav {
  display: inline-flex;
  align-items: center;

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
}

.navbar-side {
  display: inline-flex;
  align-items: center;

  .sidebar-mobile-nav {
    .sidebar {
      position: relative;
    }
  }

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: 0;
    padding: 0 20px;

    .position-relative {
      margin-left: -90px;
    }
  }

  @include navbar-mobile {
    width: auto;
  }
}

.nav-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--navbar-height);
  padding: 0 15px;
  background-color: transparent;
  cursor: pointer;
  transition: color $navbar-transition, opacity $navbar-hover-transition;

  @media (max-width: 990px) {
    width: 100%;
    height: 40px;
  }

  @keyframes scaleIn {
    0% {
      transform: translateY(50px) translateZ(20px);
      opacity: 0;
    }

    20% {
      // opacity: 0;
    }

    100% {
      transform: translateY(0) translateZ(0);
      opacity: 1;
    }
  }

  @media (max-width: 990px) {
    animation: scaleIn 0.75s both;

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: 0.15s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      animation-delay: 0.25s;
    }

    &:nth-child(5) {
      animation-delay: 0.3s;
    }

    &:nth-child(6) {
      animation-delay: 0.35s;
    }
  }

  &.nav-item-link {
    user-select: none;

    @media (max-width: 990px) {
      justify-content: flex-start;
      width: 100%;
      height: 60px;
      padding: 0 40px;
      border-top: 1px solid #ffffff26;

      &:last-child {
        border-bottom: 1px solid #ffffff26;
      }

      &:hover {
        color: white !important;
        background-color: #00000036 !important;
      }
    }
  }

  &.nav-item-button {
    display: inline-flex;
    align-items: center;
    height: 75px;
    user-select: none;
  }

  &.nav-item-action {
    min-width: 110px;
    height: 35px;
    margin: 0 5px;
    border: 2px solid currentColor;
    border-radius: 8px;
    opacity: 1 !important;
    transition: background-color $navbar-hover-transition,
      border-color $navbar-hover-transition, color $navbar-hover-transition;
    user-select: none;

    &:hover {
      color: var(--panel-bg);
      background-color: var(--text-primary);

      @include navbar-transparent {
        background-color: #d0571c;
        border-color: #d0571c;
      }
    }

    &.primary {
      color: #ff8f00;
      font-weight: 500;
      border: 2px solid #ff8f00;

      &:hover {
        color: white;
        background-color: #ff8f00;
      }
    }

    @include on-route('enterprise', 'individuals') {
      color: inherit !important;
      border-color: white !important;

      &:hover {
        color: #ff6417 !important;
        background-color: white !important;
      }
    }

    @include navbar-mobile {
      margin: 5px 0;
    }

    .fa-icon {
      margin-right: 10px;
      font-size: 1.4em;
    }
  }

  &:hover {
    opacity: 0.5;
  }

  &.active {
    color: white !important;
    opacity: 1 !important;

    @include app-theme-light {
      color: #ff9c34 !important;
    }

    @include on-route('main') {
      color: #d0571c !important;
    }

    @include on-route('enterprise', 'individuals') {
      // color: #ffffff9c !important;
      color: #ecff79 !important;
    }

    &:focus {
      // color: $white;
      background-color: transparent;
    }
  }
}

.nav-divider {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2px !important;
  height: 30px !important;
  margin: 0 10px !important;
  // ...
  transition: background-color $navbar-hover-transition;

  @media (max-width: 990px) {
    width: 100% !important;
    height: 2px !important;
    margin: 20px 0 !important;
    opacity: 0;
  }

  &::before {
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 4px;
    opacity: 0.25;
    content: '';

    @media (max-width: 990px) {
      height: 2px !important;
    }
  }
}

#download-zephyr-button {
  position: relative;
  height: 35px;
  margin: 0 5px;
  padding: 0 25px 0 15px;
  overflow: hidden;
  color: #363636;
  font-weight: 500;
  font-size: 0.9em;
  background: orange;
  background: linear-gradient(#ffa93e, #ff7f42);
  border-radius: 8px;
  outline: none !important;
  transition: color 0.15s;

  // @media (max-width: 1300px) {
  //   margin-bottom: 20px;
  //   padding: 10px 25px 10px 25px;
  // }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #9a0000;
    opacity: 0;
    transition: opacity 0.25s;
    content: '';
    pointer-events: none;
  }

  &:hover {
    color: white;

    &::before {
      opacity: 0.35;
    }
  }

  &:active {
    &::before {
      opacity: 0.8;
    }
  }

  &[disabled='disabled'] {
    background: #bbbbbb;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none !important;
  }

  span {
    position: relative;
    z-index: 1;
  }

  .fa-icon {
    margin-right: 5px;
    font-size: 1.5em;

    @media (max-width: 1300px) {
      // margin-right: 0;
      margin-right: 10px;
    }
  }

  .button-text {
    white-space: nowrap;
    @media (max-width: 1300px) {
      // display: none;
    }
  }
}

.navbar-nav {
  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }

  > li {
    &:hover {
      cursor: pointer;
    }

    > a {
      &:hover {
        color: $light-grey;
      }

      @include app-theme-light {
        color: #636363;

        &:hover {
          opacity: 0.5;
        }
      }

      @include app-theme-dark {
        color: #bbb;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .navbar-header {
    display: flex;
    align-items: center;
    float: none;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
    padding: 0 20px;
    background-color: $dark-grey;
    // margin-top: 22px;

    @include box-shadow(0 2px 5px 0 $black);
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    .shopping-cart {
      top: 60px;
    }
  }
}

// TEMP Refactor

.navbar__header {
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  float: left;

  @media (max-width: 990px) {
    float: none;
    width: 100%;
  }
}
</style>
