<script setup lang="ts">
import { ref } from 'vue';

import { faCog } from '@icons/solid/faCog';

import { useFlyout, type FlyoutOperations } from './flyout';
import type { DataItem } from './table';

/**
 * `CellOperations` component properties.
 */
export interface Props<T extends DataItem = DataItem> {
  /** The table row item subject of the flyout. */
  item: T;
  /** The list of operations to enumerate within the flyout. */
  operations: FlyoutOperations<T>;
}

const props = defineProps<Props>();

const el = ref<HTMLDivElement | null>(null);

const flyout = useFlyout(props.operations);

/**
 * Toggle the flyout "on" or "off".
 */
function toggleFlyout() {
  if (flyout.isOpen) return flyout.close();

  if (!el.value) {
    // eslint-disable-next-line no-console
    return console.warn(
      '[CellOperations.toggleFlyout] component element has not been rendered.',
    );
  }

  let { x, y } = el.value.getBoundingClientRect();

  x += 0.5 * el.value.offsetWidth;
  y += 0.5 * el.value.offsetHeight;

  flyout.open(props.item, x, y);
}
</script>

<template>
  <div ref="el" class="table-options-column">
    <div
      v-if="item"
      :class="['column-button', { selected: flyout.isOpen }]"
      @click="toggleFlyout"
    >
      <Icon :icon="faCog" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.table-options-column {
  background-color: #050f3305;
}

.column-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &.selected {
    color: #007bff;
    cursor: default;
    opacity: 1 !important;
  }
}
</style>
