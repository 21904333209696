<script lang="ts">
import { computed, reactive, watch, onMounted } from 'vue';
import { TablePanel as TP } from 'table-panel';

import { api } from '@api';
import { useStore, TablePanels } from '@store';
import { isString } from '@tools/type-guards';
import { nameFormat } from '@utils/name-format';

import FormQuerySelect, {
  type Querier,
} from '@components/Form/FormQuerySelect.vue';
import Icon from '@components/Icon.vue';
import { viewFullLog } from '@store/modules/table-panels/output-log-event';

import { faSync } from '@icons/regular/faSync';
import { faInfo } from '@icons/regular/faInfo';

import { useTable, Columns, Operations, DataItem } from './table';
import { useProgressiveLoader } from './progressive-loader';
import CellOperations from './CellOperations.vue';
import TableOverlay from './TableOverlay.vue';

export default { name: 'TableLogs' };
</script>

<script setup lang="ts">
export interface TableContext {
  filter: string | null;
}

export interface ProviderResults {
  items: DataItem[];
}

export type DataProvider = (ctx: TableContext) => Promise<ProviderResults>;

/**
 * `TableLogs` component properties.
 */
export interface Props {
  table: TablePanels.ModuleKey;
  pk: string;
  label: string;
  columns: Columns;
  operations?: Operations;
  createAction?: string;
  deleteAction?: string;
  loading?: boolean;
  tableQueryText?: string;
  filterFields?: string[];
  specialFilters?: TP.SpecialFilter<DataItem>[];
  filterTags?: TP.FilterTag[];
  infoMessage?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  operations: () => [],
  createAction: '',
  deleteAction: '',
  tableQueryText: '',
  filterFields: () => [],
  specialFilters: () => [],
  filterTags: () => [],
  infoMessage: null,
});

const store = useStore();

const {
  queryText,
  maximized,
  tableFilters,
  fullscreen,
  items,
  displayedItems,
  page,
  allRowsSelected,
  customCells,
  refreshItems,
  ...table
} = useTable(props);

const {
  loadingPage,
  loadProgress,
  allResultsLoaded,
  loadItems,
  onScroll,
  onAnimationEnd,
} = useProgressiveLoader({ tableKey: props.table });

const queryParams = reactive({
  userId: null as string | null,
  // startDate: null as number | null,
  // endDate: null as number | null,
});

const rowCount = computed(() => items.value.length);

const classList = computed(() => {
  const items: string[] = ['table-panel', 'progressive-table'];

  if (maximized.value) {
    items.push('fullscreen');

    if (tableFilters.value.length) {
      items.push('filter-offset');
    }
  }

  return items;
});

const fields = computed(() => {
  return table.fields.value.map((field) => {
    const value = isString(field) ? { key: field } : { ...field };

    return { ...value, sortable: false };
  });
});

const tableOverlay = computed(() => {
  let text: string;
  let transparent = false;

  if (loadingPage.value) {
    text = 'Loading';
    transparent = true;
  } else if (props.loading) {
    text = 'Loading';
  } else if (queryParams.userId === null) {
    text = 'Select a User';
    transparent = true;
  } else if (!rowCount.value) {
    text = 'No logs';
    transparent = true;
  } else {
    return null;
  }

  return { text, transparent };
});

const queryUsers: Querier = async (queryText) => {
  const res = await api.users.search({
    admin: true,
    includeRoles: true,
    includeLicenses: false,
    limit: 25,
    contains: queryText,
  });

  const resultItems = res.items.map((item) => ({
    text: nameFormat(item),
    value: item.id,
  }));

  return resultItems;
};

function loadMore() {
  void loadItems();
}

watch(queryParams, (params) => {
  store.commit(`tablePanels/${props.table}/update`, {
    tableQueryParams: params,
  });

  void loadItems(true);
});

onMounted(() => {
  store.commit(`${props.table}/CLEAR`);
});
</script>

<template>
  <div :class="classList">
    <b-button-toolbar class="table-toolbar p-4" justify>
      <b-input-group class="flex-grow-1 mr-2" prepend="User">
        <FormQuerySelect
          v-model="queryParams.userId"
          placeholder="Select a user"
          :querier="queryUsers"
        />
      </b-input-group>

      <b-button-group class="mr-2">
        <b-button
          v-b-tooltip="'Load More'"
          :disabled="loadingPage || allResultsLoaded"
          @click="loadMore"
        >
          Load More
        </b-button>
      </b-button-group>

      <b-button-group>
        <b-button v-b-tooltip="'Refresh'" @click="refreshItems">
          <Icon :icon="faSync" />
        </b-button>
      </b-button-group>

      <!-- Information Tooltip -->
      <b-button-group v-if="infoMessage" class="ml-2">
        <b-button v-b-tooltip.left="infoMessage">
          <Icon :icon="faInfo" />
        </b-button>
      </b-button-group>
    </b-button-toolbar>

    <div class="table-container">
      <Transition appear>
        <TableOverlay v-if="tableOverlay" v-bind="tableOverlay" />
      </Transition>

      <div class="table-wrapper">
        <b-table
          v-model="displayedItems"
          :busy.sync="loadingPage"
          :items="items"
          :fields="[...fields, 'show']"
          :per-page="0"
          :class="['mb-0', { 'is-busy': loadingPage }]"
          striped
          sticky-header="auto"
          @scroll.native="onScroll"
        >
          <!-- Row Selection Checkbox (Header) -->

          <template #head(selector)>
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox v-model="allRowsSelected" size="sm" />
              </div>
            </div>
          </template>

          <!-- Row Operations Flyout Button (Header) -->

          <template #head(operations)="column">
            <div class="text-center">{{ column.label }}</div>
          </template>

          <template #head(show)>
            <div class="text-center">Full Log</div>
          </template>

          <!-- Row Selection Checkbox -->

          <template #cell(selector)="cell">
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox
                  v-if="page[cell.index]"
                  v-model="page[cell.index].selected"
                  size="sm"
                />
              </div>
            </div>
          </template>

          <!-- Custom Cells -->

          <template v-for="cell in customCells" #[`cell(${cell.key})`]="data">
            <component :is="cell.component" :key="cell.key" v-bind="data" />
          </template>

          <!-- Row Operations Flyout Button -->

          <template #cell(operations)="cell">
            <CellOperations
              v-if="operations && cell.item"
              v-bind="{ item: cell.item, operations }"
            />
          </template>

          <template #cell(show)="cell">
            <div class="text-center">
              <b-button
                class="view-log-btn"
                variant="link"
                @click="viewFullLog(cell.item)"
              >
                View
              </b-button>
            </div>
          </template>
        </b-table>

        <!-- <div class="table-content-placeholder" v-if="">
          No Results Found
        </div> -->

        <Transition>
          <div v-if="loadingPage" class="table-progress-bar">
            <span
              :style="{ right: `${100 - loadProgress}%` }"
              @transitionend="onAnimationEnd"
            ></span>
          </div>
        </Transition>
      </div>
    </div>

    <div class="table-footer p-4">
      <b-row class="justify-content-between align-items-center">
        <b-col class="col-auto"> {{ items.length }} result(s) </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use 'table-progressive';

.view-log-btn {
  text-decoration: none !important;
  padding: 0 !important;
}

.table-toolbar {
  border-bottom: 1px solid var(--border-color) !important;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
