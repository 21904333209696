<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import { StyleValue } from 'vue/types/jsx';

import { VueNotifications } from './plugin';
import { events } from './events';
import { generateUid, listToDirection, Timer } from './util';
import { defaults } from './defaults';
import { parse as parseNumericValue } from './parser';

const STATE = { IDLE: 0, DESTROYED: 2 } as const;

declare module 'vue/types/vue' {
  export interface Vue {
    Notifications: ComponentWithProps<Props>;
  }
}

export interface NotificationItem {
  id: number;
  title: string | null;
  text: string | null;
  type: string | null;
  state: 0 | 2;
  speed: number | null;
  length: number;
  data: unknown;
  timer?: number;
}

export default { name: 'Notifications' };
</script>

<script setup lang="ts">
/**
 * `Notifications` component properties.
 */
export interface Props {
  group?: string;
  width?: number | string;
  reverse?: boolean;
  position?: string | string[];
  classes?: string;
  animation?: typeof defaults.velocityAnimation;
  animationName?: typeof defaults.cssAnimation;
  speed?: number;
  cooldown?: number;
  duration?: number;
  delay?: number;
  max?: number;
  ignoreDuplicates?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
}

/**
 * `Notifications` component default slot properties.
 */
export interface SlotProps {
  item: NotificationItem;
  close: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  group: '',
  width: 300,
  reverse: false,
  position: () => defaults.position,
  classes: 'vue-notification',
  animation: () => defaults.velocityAnimation,
  animationName: defaults.cssAnimation,
  speed: 300,
  cooldown: 0,
  duration: 3_000,
  delay: 0,
  max: Infinity,
  ignoreDuplicates: false,
  closeOnClick: true,
  pauseOnHover: false,
});

const emit = defineEmits(['click', 'destroy']);

const list = ref<NotificationItem[]>([]);
const timerControl = ref<Timer | null>(null);

/** ... */
const actualWidth = computed(() => {
  return parseNumericValue(props.width);
});

/** ... */
const styles = computed(() => {
  const { x, y } = listToDirection(props.position);

  const width = actualWidth.value.value;
  const suffix = actualWidth.value.type;

  const styles: StyleValue = {
    width: width.toString() + suffix,
  };

  if (y) styles[y] = '0px';

  if (x === 'center') {
    styles.left = `calc(50% - ${width / 2}${suffix})`;
  } else if (x) {
    styles[x] = '0px';
  }

  return styles;
});

/** ... */
const active = computed(() => {
  return list.value.filter((v) => v.state !== STATE.DESTROYED);
});

/** ... */
const botToTop = computed(() => {
  return 'bottom' in styles.value;
});

onMounted(() => {
  events.$on('add', addItem);
  events.$on('close', closeItem);
});

/**
 * ...
 *
 * @param item ...
 * @return ...
 */
function destroyIfNecessary(item: NotificationItem) {
  emit('click', item);

  if (props.closeOnClick) destroy(item);
}

/**
 * ...
 */
function pauseTimeout() {
  if (props.pauseOnHover) timerControl.value?.pause();
}

/**
 * ...
 */
function resumeTimeout() {
  if (props.pauseOnHover) timerControl.value?.resume();
}

/**
 * ...
 *
 * @param options ...
 */
function addItem(options: VueNotifications.Options) {
  options.group = options.group ?? '';
  options.data = options.data ?? {};

  if (props.group !== options.group) return;

  if (options.clean) return destroyAll();

  const duration = options.duration ?? props.duration;
  const speed = options.speed ?? props.speed;
  const ignoreDuplicates = options.ignoreDuplicates ?? props.ignoreDuplicates;
  const { title, text, type, data, id } = options;

  const item: NotificationItem = {
    id: id ?? generateUid(),
    title: title ?? null,
    text: text ?? null,
    type: type ?? null,
    state: STATE.IDLE,
    speed: speed ?? null,
    length: duration + 2 * speed,
    data,
  };

  if (duration >= 0) {
    timerControl.value = new Timer(() => destroy(item), item.length, item);
  }

  const direction = props.reverse ? !botToTop.value : botToTop.value;

  let indexToDestroy = -1;

  const isDuplicate = active.value.some((item) => {
    return item.title === options.title && item.text === options.text;
  });

  const canAdd = ignoreDuplicates ? !isDuplicate : true;

  if (!canAdd) return;

  if (direction) {
    list.value.push(item);

    if (active.value.length > props.max) {
      indexToDestroy = 0;
    }
  } else {
    list.value.unshift(item);

    if (active.value.length > props.max) {
      indexToDestroy = active.value.length - 1;
    }
  }

  if (indexToDestroy === -1) return;

  const target = active.value[indexToDestroy];

  target && destroy(target);
}

/**
 * ...
 *
 * @param id ...
 */
function closeItem(id: number) {
  destroyById(id);
}

/**
 * ...
 *
 * @param item ...
 * @return ...
 */
function notifyClass(item: NotificationItem) {
  return ['vue-notification-template', props.classes, item.type];
}

/**
 * ...
 *
 * @param item ...
 * @returns ...
 */
function notifyWrapperStyle(item: NotificationItem) {
  return {
    transition: `all ${item.speed ?? 0}ms`,
  } as StyleValue;
}

/**
 * ...
 *
 * @param item ...
 */
function destroy(item: NotificationItem) {
  window.clearTimeout(item.timer);

  item.state = STATE.DESTROYED;

  clean();

  emit('destroy', item);
}

/**
 * ...
 *
 * @param id ...
 */
function destroyById(id: number) {
  const item = list.value.find((v) => v.id === id);

  item && destroy(item);
}

/**
 * ...
 */
function destroyAll() {
  active.value.forEach(destroy);
}

/**
 * ...
 */
function clean() {
  list.value = list.value.filter((v) => v.state !== STATE.DESTROYED);
}
</script>

<template>
  <div class="vue-notification-group" :style="styles">
    <TransitionGroup :name="animationName" @after-leave="clean">
      <div
        v-for="item in active"
        :key="item.id"
        class="vue-notification-wrapper"
        :style="notifyWrapperStyle(item)"
        :data-id="item.id"
        @mouseenter="pauseTimeout"
        @mouseleave="resumeTimeout"
      >
        <slot
          :class="[classes, item.type]"
          :item="item"
          :close="() => destroy(item)"
        >
          <!-- Default slot template -->
          <div :class="notifyClass(item)" @click="destroyIfNecessary(item)">
            <div
              v-if="item.title"
              class="notification-title"
              v-html="item.title"
            ></div>
            <div class="notification-content" v-html="item.text"></div>
          </div>
        </slot>
      </div>
    </TransitionGroup>
  </div>
</template>

<style>
.vue-notification-group {
  display: block;
  position: fixed;
  z-index: 5000;
}

.vue-notification-wrapper {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.notification-title {
  font-weight: 600;
}

.vue-notification-template {
  display: block;
  box-sizing: border-box;
  background: white;
  text-align: left;
}

.vue-notification {
  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin: 0 5px 5px;
  color: white;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}

.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.vue-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.vue-notification.success {
  background: #68cd86;
  border-left-color: #42a85f;
}

.vn-fade-enter-active,
.vn-fade-leave-active,
.vn-fade-move {
  transition: all 0.5s;
}

.vn-fade-enter,
.vn-fade-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>
