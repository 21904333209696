<script lang="ts">
import { ref, computed, onMounted, onUnmounted, defineComponent } from 'vue';
import uniqueId from 'lodash/uniqueId';

import { ensureArray } from '@tools/ensure-array';

import { stack, type ModalStack } from './stack';
import * as utils from './utils';
import ModalBackdrop from './ModalBackdrop.vue';
import ModalWindow, { ModalEventCallback } from './ModalWindow.vue';
import type * as Backdrop from './ModalBackdrop.vue';
import type * as Window from './ModalWindow.vue';

/** ... */
export const ARIA_HIDDEN_ATTRIBUTE_NAME =
  'data-bootstrap-modal-aria-hidden-count';

/** ... */
const OPENED_MODAL_CLASS = 'modal-open';

/** Modal focus behavior. */
export const TABBABLE_SELECTOR = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([tabindex="-1"])',
  'button:not([disabled]):not([tabindex="-1"])',
  'select:not([disabled]):not([tabindex="-1"])',
  'textarea:not([disabled]):not([tabindex="-1"])',
  'iframe',
  'object',
  'embed',
  '*[tabindex]:not([tabindex="-1"])',
  '*[contenteditable=true]',
].join(', ');

export default defineComponent({ name: 'ModalRoot' });
</script>

<script setup lang="ts">
const backdrop = ref<Backdrop.Instance | null>(null);
const modalWindows = ref<Window.Instance[]>([]);

const openedClasses = new utils.MultiMap<ModalStack.ModalInstance>();

const scrollbarPadding = ref<utils.ScrollbarPadding | null>(null);

/** ... */
const openWindows = computed(() => stack.entries.map(({ value }) => value));

/** ... */
const topModalIndex = computed(() => stack.top?.value.modalProps.index ?? 0);

//#region Backdrop

/** ... */
const backdropClassList = ref<string[]>([]);
/** ... */
const backdropAnimation = ref(true);
/** ... */
const backdropIndex = computed(() => {
  let topBackdropIndex = -1;

  stack.keys.forEach((key, i) => {
    if (stack.get(key)?.value.backdrop) {
      topBackdropIndex = i;
    }
  });

  // If any backdrop exist, ensure that it's index is always
  // right below the top modal
  if (topBackdropIndex > -1 && topBackdropIndex < topModalIndex.value) {
    topBackdropIndex = topModalIndex.value;
  }

  return topBackdropIndex;
});

/** ... */
const backdropProps = computed(() => {
  const index = backdropIndex.value;

  if (index <= -1) return null;

  const classList = backdropClassList.value;
  const animation = backdropAnimation.value;

  return { index, classList, animation };
});

//#endregion Backdrop

/**
 * ...
 */
const onModalMounted: ModalEventCallback = ({ modalElement }) => {
  applyAriaHidden(modalElement);
};

/**
 * ...
 */
const onModalRendered: ModalEventCallback = ({ modalWindow, modalElement }) => {
  // Notify {@link $modalStack} that modal is rendered.
  modalWindow.renderDeferred.resolve();

  // If something within the freshly-opened modal already has focus (perhaps
  // via a directive that causes focus) then there's no need to try to focus
  // anything.
  if (document.activeElement && modalElement.contains(document.activeElement)) {
    return;
  }

  // Auto-focusing of a freshly-opened modal element causes any child
  // elements with the autofocus attribute to lose focus. This is an issue on
  // touch based devices which will show and then hide the onscreen keyboard.
  // Attempts to refocus the autofocus element via JavaScript will not reopen
  // the onscreen keyboard. Fixed by updated the focusing logic to only
  // autofocus the modal element if the modal does not contain an autofocus
  // element.
  const elementToFocus =
    modalElement.querySelector<HTMLElement>('[autofocus]') ?? modalElement;

  elementToFocus?.focus();
};

/**
 * ...
 */
const onModalClosed: ModalEventCallback = ({ modalWindow, modalInstance }) => {
  const modalBodyClass = modalWindow.openedClass ?? OPENED_MODAL_CLASS;
  openedClasses.remove(modalBodyClass, modalInstance);

  const areAnyOpen = openedClasses.hasKey(modalBodyClass);
  document.body.classList.toggle(modalBodyClass, areAnyOpen);

  if (
    !areAnyOpen &&
    scrollbarPadding.value &&
    scrollbarPadding.value.heightOverflow &&
    scrollbarPadding.value.scrollbarWidth
  ) {
    const paddingRight = scrollbarPadding.value.originalRight
      ? `${scrollbarPadding.value.originalRight}px`
      : '';

    document.body.style.paddingRight = paddingRight;

    scrollbarPadding.value = null;
  }

  modalWindow.closedDeferred.resolve();
};

onMounted(() => {
  // stack.clear();

  stack.on('open', open);
  stack.on('close', close);
  stack.on('dismiss', dismiss);

  document.addEventListener('keydown', keydownListener);
});

onUnmounted(() => {
  stack.off('open', open);
  stack.off('close', close);
  stack.off('dismiss', dismiss);

  document.removeEventListener('keydown', keydownListener);
});

// region Public Methods

/**
 * ...
 *
 * @param modalInstance ...
 * @param modal ...
 */
function open(
  modalInstance: ModalStack.ModalInstance,
  modal: ModalStack.ModalData,
) {
  const modalId = uniqueId();
  const modalOpener = document.activeElement as HTMLElement;
  const modalBodyClass = modal.openedClass ?? OPENED_MODAL_CLASS;

  modalInstance.close = (result) => close(modalInstance, result);
  modalInstance.dismiss = (reason) => dismiss(modalInstance, reason);

  openedClasses.put(modalBodyClass, modalInstance);

  const currBackdropIndex = backdropIndex.value;

  if (currBackdropIndex >= 0 && !backdropProps.value) {
    if (modal.backdropClass) {
      backdropClassList.value = Array.isArray(modal.backdropClass)
        ? modal.backdropClass
        : [modal.backdropClass];
    }

    if (modal.animation) {
      backdropAnimation.value = modal.animation;
    }

    if (utils.isScrollable(document.body)) {
      scrollbarPadding.value = utils.scrollbarPadding(document.body);

      if (
        scrollbarPadding.value.heightOverflow &&
        scrollbarPadding.value.scrollbarWidth
      ) {
        document.body.style.paddingRight =
          scrollbarPadding.value.right.toString() + 'px';
      }
    }
  }

  document.body.classList.add(modalBodyClass);

  const windowProps: Window.Props = {
    index: topModalIndex.value + 1,
    size: modal.size ?? 'md',
    classList: [],
    animation: !!modal.animation,
    modalInstance,
    modalComponent: modal.component,
    modalProps: modal.props || {},
    onModalMounted,
    onModalRendered,
    onModalClosed,
  };

  if (modal.windowClass) {
    windowProps.classList = ensureArray(modal.windowClass);
  }

  stack.add(modalInstance, {
    modalId,
    modalOpener,
    modalProps: windowProps,
    deferred: modal.deferred,
    renderDeferred: modal.renderDeferred,
    closedDeferred: modal.closedDeferred,
    backdrop: modal.backdrop,
    keyboard: modal.keyboard,
    openedClass: modal.openedClass,
    animation: modal.animation,
  });
}

/**
 * ...
 *
 * @param modalInstance ...
 * @param result ...
 */
function close(modalInstance: ModalStack.ModalInstance, result?: unknown) {
  const modalWindow = stack.get(modalInstance)?.value;

  unhideBackgroundElements();

  if (!modalWindow) return true;

  const modalComponent = getModalWindowComponent(modalWindow.modalId);

  modalComponent?.scheduleDestruction();
  modalWindow.deferred.resolve(result);

  removeModalWindow(modalInstance, modalWindow.modalOpener);

  return true;
}

/**
 * ...
 *
 * @param modalInstance ...
 * @param reason ...
 */
function dismiss(modalInstance: ModalStack.ModalInstance, reason?: string) {
  const modalWindow = stack.get(modalInstance)?.value;

  unhideBackgroundElements();

  if (!modalWindow) return true;

  const modalComponent = getModalWindowComponent(modalWindow.modalId);

  modalComponent?.scheduleDestruction();
  modalWindow.deferred.reject(reason);

  removeModalWindow(modalInstance, modalWindow.modalOpener);

  return true;
}

/**
 * ...
 *
 * @param reason ...
 */
function dismissAll(reason: string) {
  let topModal = getTop();

  while (topModal && dismiss(topModal.key, reason)) {
    topModal = getTop();
  }
}

/**
 * ...
 */
function getTop() {
  return stack.top;
}

/**
 * ...
 *
 * @param modalId ...
 */
function getModalWindowComponent(modalId: string) {
  const i = stack.entries.findIndex((o) => o.value.modalId === modalId);

  if (i === -1) return null;

  const component = modalWindows.value[i];

  if (!component) return null;

  return component;
}

/**
 * ...
 *
 * @param list ...
 */
function focusFirstFocusableElement(list: HTMLElement[]) {
  if (list.length <= 0) return false;

  list[0]?.focus();

  return true;
}

/**
 * ...
 *
 * @param list ...
 */
function focusLastFocusableElement(list: HTMLElement[]) {
  if (list.length <= 0) return false;

  list[list.length - 1]?.focus();

  return true;
}

/**
 * ...
 *
 * @param evt ...
 * @param modalWindow ...
 */
function isModalFocused(evt: Event, modalWindow: ModalStack.StackItem) {
  const modalComponent = getModalWindowComponent(modalWindow.value.modalId);

  return evt.target === modalComponent?.$el;
}

/**
 * ...
 *
 * @param evt ...
 * @param list ...
 * @returns ...
 */
function isFocusInFirstItem(evt: Event, list: HTMLElement[]) {
  return list.length <= 0 ? false : evt.target === list[0];
}

/**
 * ...
 *
 * @param evt ...
 * @param list ...
 * @returns ...
 */
function isFocusInLastItem(evt: Event, list: HTMLElement[]) {
  return list.length <= 0 ? false : evt.target === list[list.length - 1];
}

/**
 * ...
 *
 * @param modalWindow ...
 * @returns ...
 */
function loadFocusElementList(modalWindow: ModalStack.StackItem) {
  return (
    getModalWindowComponent(
      modalWindow.value.modalId,
    )?.getFocusableElements() ?? []
  );
}

//#endregion Public Methods

//#region Private Methods

/**
 * ...
 *
 * @param modalInstance ...
 * @param elementToReceiveFocus ...
 */
function removeModalWindow(
  modalInstance: ModalStack.ModalInstance,
  elementToReceiveFocus: Nullable<HTMLElement>,
) {
  // Clean up the stack.
  stack.remove(modalInstance);

  // previousTopOpenedModal.value = stack.top ?? null;

  // if (previousTopOpenedModal.value) {
  //   const prevModalComponent = getModalWindowComponent(
  //     previousTopOpenedModal.value.value.modalId,
  //   );

  //   topModalIndex.value = prevModalComponent?.index ?? 0;
  // }

  // Move focus to specified element if available, or else to body
  if (elementToReceiveFocus?.focus) {
    elementToReceiveFocus.focus();
  } else {
    document.body.focus();
  }
}

/**
 * ...
 *
 * @param evt ...
 */
function keydownListener(evt: KeyboardEvent) {
  if (evt.defaultPrevented) return;

  const modal = stack.top;

  if (!modal) return;

  if (evt.code === 'Escape') {
    if (modal.value.keyboard) {
      evt.preventDefault();

      dismiss(modal.key, 'escape key press');
    }

    return;
  }

  if (evt.code === 'Tab') {
    const list = loadFocusElementList(modal) ?? [];

    let focusChanged = false;

    if (evt.shiftKey) {
      focusChanged =
        (isFocusInFirstItem(evt, list) || isModalFocused(evt, modal)) &&
        focusFirstFocusableElement(list);
    } else if (isFocusInLastItem(evt, list)) {
      focusChanged = focusFirstFocusableElement(list);
    }

    if (focusChanged) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }
}

//#endregion Private Methods

//#region Helper Functions

/**
 * ...
 *
 * @param el ...
 * @return ...
 */
function getAriaHiddenAttrCount(el: Element) {
  // ...
  const attrValue = el.getAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME);

  return !attrValue ? 0 : parseInt(attrValue);
}

/**
 * ...
 *
 * @param el ...
 * @return ...
 */
function applyAriaHidden(el: HTMLElement) {
  if (el.tagName === 'BODY') return;

  // ...
  const siblings = [...(el.parentElement?.children ?? [])];

  for (const sibling of siblings) {
    if (sibling === el) continue;

    // ...
    const ariaHiddenCount =
      getAriaHiddenAttrCount(sibling) ||
      sibling.getAttribute('aria-hidden') === 'true'
        ? 1
        : 0;

    // ...
    sibling.setAttribute(
      ARIA_HIDDEN_ATTRIBUTE_NAME,
      (ariaHiddenCount + 1).toString(),
    );

    sibling.setAttribute('aria-hidden', 'true');
  }

  if (el.parentElement) applyAriaHidden(el.parentElement);
}

/**
 * ...
 */
function unhideBackgroundElements() {
  const hiddenElements = document.querySelectorAll(
    '[' + ARIA_HIDDEN_ATTRIBUTE_NAME + ']',
  );

  for (const hiddenEl of hiddenElements) {
    const ariaHiddenCount = getAriaHiddenAttrCount(hiddenEl);

    const newHiddenCount = ariaHiddenCount - 1;

    hiddenEl.setAttribute(
      ARIA_HIDDEN_ATTRIBUTE_NAME,
      newHiddenCount.toString(),
    );

    if (!newHiddenCount) {
      hiddenEl.removeAttribute(ARIA_HIDDEN_ATTRIBUTE_NAME);
      hiddenEl.removeAttribute('aria-hidden');
    }
  }
}

//#endregion Helper Functions
</script>

<template>
  <div class="modal-root">
    <ModalBackdrop v-if="backdropProps" ref="backdrop" v-bind="backdropProps" />

    <ModalWindow
      v-for="{ modalId, modalProps } of openWindows"
      ref="modalWindows"
      :key="modalId"
      v-bind="modalProps"
    />
  </div>
</template>
