<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    AddOnsRack: ComponentWithProps<Props>;
  }
}

export default { name: 'AddOnsRack' };
</script>

<script setup lang="ts">
import type { Shop } from '@store';

import AddOnsRackCard from '@components/AddOnsRackCard.vue';

/**
 * `AddOnsRack` component properties.
 */
export interface Props {
  heading: string;
  description?: string;
  products: Shop.Item[];
}

defineProps<Props>();
</script>

<template>
  <b-container class="add-ons-rack mt-4 mb-4">
    <b-row>
      <b-col class="mb-4 text-center">
        <h2>{{ heading }}</h2>
        <p v-if="description">{{ description }}</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="product in products"
        :key="product.id"
        class="card-col"
        lg="4"
      >
        <AddOnsRackCard v-bind="{ product }" />
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
.card-col {
  margin: 15px 0;
}
</style>
