<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    DisplayAddress: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayAddress' };
</script>

<script setup lang="ts">
/**
 * `DisplayAddress` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: ZephyrWeb.AddressInfo;
  /**
   * If `true`, the address will be formatted as a single line.
   */
  singleLine?: boolean;
}

defineProps<Props>();
</script>

<template>
  <div
    class="display-address"
    :style="{ flexDirection: singleLine ? 'row' : 'column' }"
  >
    <span v-if="value.first || value.last">
      <span v-if="value.first">{{ value.first }}</span>
      <span v-if="value.last">{{ value.last }}</span>
    </span>

    <span>{{ value.address1 }}</span>
    <span v-if="value.address2">{{ value.address2 }}</span>
    <span v-if="value.address3">{{ value.address3 }}</span>

    <span>
      <span v-if="value.stateProvince">{{ value.stateProvince }}</span>
      <span v-if="value.city">{{ value.city }}</span>
      <span v-if="value.postalCode">{{ value.postalCode }}</span>
    </span>

    <span>{{ value.country }}</span>
  </div>
</template>

<style scoped lang="scss">
.display-address {
  display: inline-flex;
  justify-content: right;

  > span {
    text-align: right;
    display: inline-block;
  }
}
</style>
