<script lang="ts">
import { computed } from 'vue';

import type { Invoice } from '@models';

import { faCircle } from '@icons/regular/faCircle';
import { faCheck } from '@icons/regular/faCheck';
import { faFilePen } from '@icons/regular/faFilePen';
import { faExclamationTriangle } from '@icons/regular/faExclamationTriangle';
import { faBan } from '@icons/regular/faBan';

declare module 'vue/types/vue' {
  export interface Vue {
    TableCellInvoiceStatus: ComponentWithProps<Props>;
  }
}

const STATUS_MAP = {
  draft: {
    text: 'Draft',
    color: 'secondary',
    icon: faFilePen,
  },
  open: {
    text: 'Open',
    color: 'primary',
    icon: faCircle,
  },
  paid: {
    text: 'Paid',
    color: 'success',
    icon: faCheck,
  },
  uncollectible: {
    text: 'Uncollectible',
    color: 'danger',
    icon: faExclamationTriangle,
  },
  void: {
    text: 'Void',
    color: 'primary',
    icon: faBan,
  },
} as const satisfies Record<Invoice.Status, object>;

export default { name: 'TableCellInvoiceStatus' };
</script>

<script setup lang="ts">
/**
 * `TableCellInvoiceStatus` component properties.
 */
export interface Props {
  /** `Invoice` data object. */
  item: Invoice;
}

const props = defineProps<Props>();

const status = computed(() => {
  try {
    return STATUS_MAP[props.item.status];
  } catch {
    return null;
  }
});
</script>

<template>
  <div class="score-table-cell">
    <span v-if="status" :class="[status.color]">
      <Icon :icon="status.icon" />
      <span>{{ status.text }}</span>
    </span>

    <span v-else>--</span>
  </div>
</template>
