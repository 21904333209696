<template>
  <div class="score-table-cell">
    {{ displayValue }}
  </div>
</template>

<script setup lang="ts">
import { computed } from '@vue';

import { dateFilter } from '@filters/date';
import type { Certification } from '@models';

/**
 * `TableCellCertificationLastAction` component properties.
 */
export interface Props {
  /** ... */
  item: Certification;
}

const props = defineProps<Props>();

const displayValue = computed(() => {
  const { item } = props;

  if (item.approvedAt) {
    return `Approved on ${formateDate(item.approvedAt)}`;
  }

  if (item.reattemptPromptAt) {
    return `Reattempt prompted on ${formateDate(item.reattemptPromptAt)}`;
  }

  if (item.deniedAt) {
    return `Denied on ${formateDate(item.deniedAt)}`;
  }

  return 'N/A';
});

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function formateDate(value: string) {
  return dateFilter(value, 'MM/dd/yyyy').toString();
}
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellCertificationLastAction: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellCertificationLastAction' };
</script>
