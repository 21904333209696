<script lang="ts">
import { computed } from 'vue';

import { secToHmsFilter } from '@filters/sec-to-hms';
import { isNumber, isString, isOneOf } from '@tools/type-guards';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayTextSecToHms: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayTextSecToHms' };
</script>

<script setup lang="ts">
/**
 * `DisplayTextSecToHms` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: Nullable<string | number>;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
});

const displayValue = computed(() => {
  let value: string;

  if (isOneOf(props.value, isNumber, isString)) {
    value = secToHmsFilter(props.value);
  } else {
    value = '--';
  }

  return value;
});
</script>

<template>
  <component :is="tag">{{ displayValue }}</component>
</template>
