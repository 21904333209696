<script lang="ts">
import { computed } from 'vue';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import uniqueId from 'lodash/uniqueId';

import Icon from '@components/Icon.vue';

declare module 'vue/types/vue' {
  export interface Vue {
    PostList: ComponentWithProps<Props>;
  }
}

export interface Post {
  title: string;
  date: string;
  link: string;
  icon: IconDefinition;
}

export default { name: 'PostList' };
</script>

<script setup lang="ts">
/**
 * `PostList` component properties.
 */
export interface Props {
  posts: Post[];
}

const props = defineProps<Props>();

const items = computed(() => {
  return props.posts.map((post) => ({ ...post, key: uniqueId() }));
});
</script>

<template>
  <div class="post-list">
    <TransitionGroup tag="ul" appear>
      <li
        v-for="({ key, title, date, link, icon }, index) in items"
        :key="key"
        v-transition-delay.group="{ index }"
      >
        <a :href="link" target="__blank">
          <div>
            <span class="circle-icon small">
              <Icon :icon="icon" />
            </span>

            <div
              class="py-4 position-relative d-flex flex-column flex-grow-1 aligin-items-center"
            >
              <span class="post-description mb-1">{{ title }}</span>
              <span class="post-date text-muted">{{ date }}</span>
              <hr class="post-border m-0" />
            </div>
          </div>
        </a>
      </li>
    </TransitionGroup>
  </div>
</template>

<style scoped lang="scss">
ul {
  text-align: left;
}

li {
  list-style: none;
  opacity: 1;
  transform: translateY(0);
  transition: 0.5s;

  @include v-from {
    opacity: 0;
    transform: translateY(40px);
  }
}

a {
  color: var(--text-primary) !important;
  text-decoration: none !important;

  > div {
    display: flex;
    align-items: center;
  }
}

.post-description {
  display: inline-block;

  a:hover & {
    text-decoration: underline;
  }
}

.post-date {
  font-size: 0.9rem;
}

.post-border {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
