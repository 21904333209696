<script lang="ts">
import { computed } from 'vue';

import { faExclamationCircle } from '@icons/solid/faExclamationCircle';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    OverlayRenderIssue: ComponentWithProps<Props>;
  }
}

export default { name: 'OverlayRenderIssue' };
</script>

<script setup lang="ts">
/**
 * `OverlayRenderIssue` component properties.
 */
export interface Props {
  /** Optional message to display within the overlay. */
  message?: string;
  /** If `true`, the overlay will be rendered with a border. */
  bordered?: boolean;
  /**
   * If `true`, the user will be able to try re-running the process that led to
   * the initial issue. If set to a string, the same button will be displayed,
   * but with its text content set to the value of the string.
   *
   * Note: when the "Retry" button is clicked, the `retry-requested` event is
   * emitted. This can be listened for and used to trigger the corresponding
   * process.
   */
  retriable?: boolean | string;
}

export interface Emits {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  (event: 'retry-requested'): void;
}

const prop = defineProps<Props>();

defineEmits<Emits>();

const retryButton = computed(() => {
  if (!prop.retriable) return null;

  const text = typeof prop.retriable === 'string' ? prop.retriable : 'Retry';

  return { text };
});
</script>

<template>
  <Overlay
    :class="['overlay-render-issue', { bordered }]"
    background="transparent"
  >
    <div class="overlay-inner">
      <Icon :icon="faExclamationCircle" />

      <div v-if="message" class="issue-message">{{ message }}</div>

      <b-button
        v-if="retryButton"
        class="px-5"
        size="lg"
        pill
        @click="$emit('retry-requested')"
      >
        {{ retryButton.text }}
      </b-button>
    </div>
  </Overlay>
</template>

<style scoped lang="scss">
.overlay-render-issue {
  opacity: 0.5;

  &.bordered::after {
    content: '';
    position: absolute;
    inset: 2rem;
    border-radius: 20px;
    border: 2px solid currentColor;
    z-index: 0;
    opacity: 0.5;
  }
}

.overlay-inner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  z-index: 1;
}

.fa-icon {
  font-size: 4rem;
}

.issue-message {
  font-size: 1.5em;
  margin: 1em 0;
}
</style>
