<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    FormFileDrop: ComponentWithProps<Props>;
  }
}

export default { name: 'FormFileDrop' };
</script>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import { alert } from '@services/alert';
import { generateDataUrl } from '@utils/generate-data-url';

import Spinner from '@components/Spinner.vue';

import { faTimes } from '@icons/regular/faTimes';
import { faImage } from '@icons/duotone/faImage';

/**
 * `FormFileDrop` component properties.
 */
export interface Props {
  value: File | null;
}

/**
 * `FormFileDrop` component emits.
 */
export type Emits = (event: 'input', value: File | null) => void;

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const loading = ref(false);
const dragging = ref(false);

const dataUrl = ref<string | null>(null);

watch(
  () => props.value,
  async (value: File | null) => {
    if (!value) {
      dataUrl.value = null;

      return;
    }

    loading.value = true;

    dataUrl.value = await generateDataUrl(value);

    loading.value = false;
  },
);

const showPreview = computed(() => {
  return !!dataUrl.value || loading.value;
});

function removeImage() {
  emit('input', null);
}

async function addFile() {
  const file = await addImageDialog();

  if (!file) return;

  emit('input', file);
}

async function onDrop({ dataTransfer }: DragEvent) {
  dragging.value = false;

  const file = dataTransfer?.files.item(0) ?? null;

  if (!file) return;

  console.log(file.type);

  if (!/^image\/(jpg|jpeg|png|gif|tiff)$/.test(file.type)) {
    return alert.error('The file you tried to add was not an image.');
  }

  emit('input', file);
}

//#region Helper Functions

/**
 * Prompt the user for an image file using the native file system dialog.
 *
 * @returns Image file data, if an image file was selected.
 */
async function addImageDialog() {
  const el = document.createElement('input');
  el.setAttribute('type', 'file');
  el.setAttribute('hidden', 'true');
  el.setAttribute('accept', 'image/png, image/jpeg, image/gif, image/tiff');

  document.body.append(el);

  const target = await new Promise<unknown>((resolve) => {
    el.oninput = ({ target }) => resolve(target);

    el.click();
  });

  el.remove();

  return target instanceof HTMLInputElement
    ? target.files?.item(0) ?? null
    : null;
}

//#endregion Helper Functions
</script>

<template>
  <div class="form-file-drop py-3">
    <div>
      <Transition>
        <div v-if="showPreview" class="preview-wrapper">
          <div class="preview-bg">
            <Spinner v-if="loading" />

            <Transition appear>
              <div
                v-if="!loading"
                class="preview-bg-image"
                :style="{ backgroundImage: `url(${dataUrl})` }"
              ></div>
            </Transition>
          </div>

          <b-button v-if="!loading" variant="danger" @click="removeImage">
            <Icon :icon="faTimes" />
          </b-button>
        </div>
      </Transition>

      <Transition>
        <div
          v-if="!showPreview"
          :class="['drag-area', { dragging }]"
          @dragover.prevent
          @dragenter.prevent="dragging = true"
          @dragleave.prevent="dragging = false"
          @drop.prevent="onDrop"
          @click="addFile"
          title="Click to add an image"
        >
          <div class="drag-area-bg">
            <div class="drag-area-bg-inner">
              <div class="drag-area-bg-icon mb-3">
                <Icon :icon="faImage" />
              </div>
              <div class="drag-area-bg-label">
                Drag &amp; Drop your image here
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
// $border-radius: 0.25rem;
// @import '~@styles/bootstrap/custom-vars.scss';
@import '@styles/bootstrap/custom-vars.scss';

$preview-btn-size: 2rem;

@keyframes iconHover {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.form-file-drop {
  > div {
    position: relative;
    display: flex;
    flex-basis: 70%;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
}

.drag-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  cursor: pointer;
  opacity: 1;
  transition: 0.25s;

  @include v-from {
    transform: scale(0);
    opacity: 0;
  }
}

.drag-area-bg {
  width: 100%;
  height: 100%;
  color: inherit;
  background-color: #00000005;
  border: 2px dashed var(--input-border-color);
  border-radius: $border-radius;
  transition: 0.25s;
  pointer-events: none;

  .drag-area.dragging & {
    color: #4597ff;
    background-color: #2673c52b;
    border-color: #4597ff;
  }
}

.drag-area-bg-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: scale(1);
  opacity: 0.3;
  transition: 0.15s;

  .drag-area.dragging & {
    opacity: 0.8;
  }
}

.drag-area-bg-icon {
  transform: scale(1);
  transition: 0.25s;

  .drag-area.dragging & {
    transform: scale(1.2);
  }
}

.drag-area-bg-icon > .fa-icon {
  font-size: 5rem;

  .drag-area.dragging & {
    animation-name: iconHover;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.drag-area-bg-label {
  margin: 0;
  // color: white;
  font-weight: 400;
  font-size: 1.1rem;
  transform: scale(1);
  transition: 0.25s;

  .drag-area.dragging & {
    transform: scale(1.1);
  }
}

.preview-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  opacity: 1;
  transition: 0.25s;

  @include v-from {
    transform: scale(0);
    opacity: 0;
  }

  .btn {
    position: absolute;
    top: calc(-#{$preview-btn-size} * 0.3);
    right: calc(-#{$preview-btn-size} * 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: $preview-btn-size;
    height: $preview-btn-size;
    margin: 0;
    padding: 0;
    // color: black;
    font-size: 1rem;
    border-radius: 100%;
  }
}

.preview-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 2rem;
  // background-color: #80808014;
  background-color: #474c58;
  border: 1px solid var(--input-border-color);
  border-radius: $border-radius;
}

.preview-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 1;
  transition: 0.25s;

  @include v-from {
    opacity: 0;
  }
}
</style>
