<script lang="ts">
import { ref } from 'vue';

import Icon from '@components/Icon.vue';

import { faPlus } from '@icons/regular/faPlus';
import { faMinus } from '@icons/regular/faMinus';

declare module 'vue/types/vue' {
  export interface Vue {
    CardExpandable: ComponentWithProps<Props>;
  }
}

export default { name: 'CardExpandable' };
</script>

<script setup lang="ts">
/**
 * `CardExpandable` component properties.
 */
export interface Props {
  label?: string;
}

defineProps<Props>();

const expanded = ref(false);
</script>

<template>
  <b-card class="card-expandable" no-body>
    <b-card-header
      header-tag="header"
      class="p-3 d-flex justify-content-between"
      role="tab"
      @click="expanded = !expanded"
    >
      <span>{{ label }}</span>

      <span class="pull-right">
        <Icon :icon="expanded ? faMinus : faPlus" />
      </span>
    </b-card-header>

    <b-collapse v-model="expanded" role="tabpanel">
      <b-card-body class="p-0">
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<style scoped lang="scss">
.card {
  border: 1px solid var(--border-color) !important;
  overflow: hidden;
  box-shadow: none !important;
}

.card-body {
  border-top: 1px solid var(--border-color);
}

header {
  background-color: transparent;
  border-bottom: none;
  transition: background-color 0.25s;
  overflow: hidden;

  &:hover {
    background-color: var(--panel-header-hover-bg);
  }
}
</style>
