<script lang="ts">
import { computed } from 'vue';

import type { Invite } from '@models';

const TEXT_COLOR_GRAY = '#363b47';
const BG_COLOR_GRAY = '#9fa5b3';

const TEXT_COLOR_GREEN = '#004546';
const BG_COLOR_GREEN = '#37da88';

const TEXT_COLOR_RED = '#5d0029';
const BG_COLOR_RED = '#ff4f80';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellInviteStatus: ComponentWithProps<Props>;
  }
}

interface StatusDisplayConfig {
  text: string;
  color: string;
  backgroundColor: string;
}

/** Display config info for certification status label. */
const STATUS_DISPLAY_CONFIGS = {
  AWAITING_RESPONSE: {
    text: 'Awaiting Response',
    color: TEXT_COLOR_GRAY,
    backgroundColor: BG_COLOR_GRAY,
  },
  DECLINED: {
    text: 'Declined',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
  ACCEPTED: {
    text: 'Accepted',
    color: TEXT_COLOR_GREEN,
    backgroundColor: BG_COLOR_GREEN,
  },
  EXPIRED: {
    text: 'Expired',
    color: TEXT_COLOR_RED,
    backgroundColor: BG_COLOR_RED,
  },
} satisfies Record<string, StatusDisplayConfig>;
</script>

<script setup lang="ts">
/**
 * `TableCellInviteStatus` component properties.
 */
export interface Props {
  item: Invite;
}

const props = defineProps<Props>();

const displayConfig = computed(() => {
  if (
    !props.item.accepted &&
    !props.item.used &&
    new Date() > new Date(props.item.expirationDate)
  ) {
    return STATUS_DISPLAY_CONFIGS.EXPIRED;
  }

  return props.item.accepted
    ? STATUS_DISPLAY_CONFIGS.ACCEPTED
    : props.item.used
    ? STATUS_DISPLAY_CONFIGS.DECLINED
    : STATUS_DISPLAY_CONFIGS.AWAITING_RESPONSE;
});

const text = computed(() => {
  return displayConfig.value.text;
});

const style = computed(() => {
  return {
    color: displayConfig.value.color,
    backgroundColor: displayConfig.value.backgroundColor,
  };
});
</script>

<template>
  <div class="score-table-cell">
    <span class="label-invite-status" :style="style">
      {{ text }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.label-invite-status {
  display: inline-block;
  padding: 4px 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 10px;
  width: 170px;
}
</style>
