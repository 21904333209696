<script lang="ts">
import { ref, watch, Ref } from 'vue';
import startCase from 'lodash/startCase';

import { dateFilter } from '@filters/date';

import Icon from '@components/Icon.vue';

import { faCaretRight } from '@icons/solid/faCaretRight';
import { faCaretDown } from '@icons/solid/faCaretDown';

declare module 'vue/types/vue' {
  export interface Vue {
    DisplayObject: ComponentWithProps<Props>;
  }
}

interface PropertyBaseInfo {
  key: string;
  label: string;
  blank: boolean;
  value: unknown;
  expanded: boolean;
}

interface GeneralPropertyInfo extends PropertyBaseInfo {
  type:
    | 'string'
    | 'number'
    | 'boolean'
    | 'bigint'
    | 'symbol'
    | 'date'
    | 'null'
    | 'undefined';
  value: unknown;
}

interface ObjectPropertyInfo extends PropertyBaseInfo {
  type: 'object';
  value: object;
}

type PropertyInfo = GeneralPropertyInfo | ObjectPropertyInfo;

export default { name: 'DisplayObject' };
</script>

<script setup lang="ts">
/**
 * `DisplayObject` component properties.
 */
export interface Props {
  object: object;
  include?: string[] | null;
  expanded?: boolean;
  appearance?: 'regular' | 'json';
}

const props = withDefaults(defineProps<Props>(), {
  include: null,
  expanded: false,
  appearance: 'regular',
});

const properties: Ref<PropertyInfo[]> = ref([]);

watch(
  [() => props.object, () => props.include],
  ([obj, include]) => {
    let entries = Object.entries(obj);

    if (include) {
      entries = entries.filter(([key]) => include.includes(key));
    }

    properties.value = entries.map(mapObjectProperty);
  },
  { immediate: true },
);

function onClick(prop: PropertyInfo) {
  if (prop.type === 'object') {
    prop.expanded = !prop.expanded;
  }
}

function mapObjectProperty(entry: [string, unknown]) {
  let [key, value] = entry;

  const label = props.appearance === 'json' ? key : startCase(key);

  const blank = value === null || value === undefined || value === '';

  let type: string;

  if (value === null) {
    type = 'null';
  } else if (value === undefined) {
    type = 'undefined';
  } else if (value instanceof Date) {
    type = 'date';
  } else {
    type = typeof value;
  }

  if (value instanceof Date) {
    value = dateFilter(value, 'MM/dd/yyyy');
  }

  const prop = {
    key,
    label,
    blank,
    value,
    type,
    expanded: props.expanded,
  };

  return prop as PropertyInfo;
}
</script>

<template>
  <div :class="['display-object', appearance]">
    <div v-for="prop in properties" :key="prop.key" class="object-property">
      <span
        :class="['property-key', { expandable: prop.type === 'object' }]"
        @click="onClick(prop)"
      >
        <span class="property-gutter">
          <span v-if="prop.type === 'object'" class="property-expander">
            <Icon :icon="prop.expanded ? faCaretDown : faCaretRight" />
          </span>
        </span>

        <span class="property-label">{{ prop.label }}</span>

        <span class="mr-2">:</span>

        <span v-if="prop.type === 'object' && !prop.expanded">{ ... }</span>
      </span>

      <span :class="['property-value-container', `type-${prop.type}`]">
        <span v-if="prop.blank" class="property-blank">--</span>

        <span v-else-if="prop.type === 'object'" class="w-100">
          <b-collapse v-model="prop.expanded">
            <DisplayObject
              :object="prop.value"
              v-bind="{ appearance: appearance ?? 'regular' }"
            />
          </b-collapse>
        </span>

        <span v-else class="property-value">
          <template v-if="appearance === 'json' && prop.type === 'string'">
            "{{ prop.value }}"
          </template>

          <template v-else>
            {{ prop.value }}
          </template>
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.display-object {
  display: flex;
  flex-direction: column;
}

.object-property {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.property-key {
  display: flex;
  align-items: center;

  &.expandable {
    cursor: pointer;
  }
}

.property-gutter {
  width: 30px;
}

.property-expander {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-label {
  // color: #ff76e7;
  // color: #29bebf;
  color: #30a7d6;

  .json & {
    color: #d94f62;
  }
}

.property-value-container {
  // ...
}

.property-value {
  color: #ff8942;

  .json & {
    color: #90ba6b;
  }
}

.type-object {
  width: 100%;
  padding-left: 1rem;
  color: inherit;
}

.type-string {
  // color: #ffbe44;
}

.type-number,
.type-boolean {
  // color: #8544ff;
}

.property-blank {
  color: var(--text-primary);
  opacity: 0.25;
}
</style>
