<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    DisplayText: ComponentWithProps<Props>;
  }
}

export default { name: 'DisplayValueText' };
</script>

<script setup lang="ts">
/**
 * `DisplayText` component properties.
 */
export interface Props {
  /**
   * Value to display.
   */
  value: unknown;
  /**
   * If `true`, the rendered text will be bolded.
   */
  bold?: boolean;
  /**
   * HTML tag name to use for rendered DOM element.
   */
  tag?: string;
}

withDefaults(defineProps<Props>(), {
  bold: false,
  tag: 'span',
});
</script>

<template>
  <component :is="tag" :class="['display-text', { bold }]">
    {{ value }}
  </component>
</template>

<style scoped lang="scss">
.display-text {
  display: flex;
  flex-direction: column;

  &.bold {
    font-weight: bold;
  }
}
</style>
