<script lang="ts">
import { defineComponent } from 'vue';

import Icon from '@components/Icon.vue';

import { faChevronLeft } from '@icons/solid/faChevronLeft';

export default defineComponent({ name: 'SplitViewPage' });
</script>

<script setup lang="ts">
/**
 * `SplitViewPage` component properties.
 */
export interface Props {
  open: boolean;
  title?: string;
}

defineProps<Props>();
</script>

<template>
  <Transition>
    <div v-if="open" class="split-view-page">
      <div class="page-header">
        <b-button @click="$emit('closed')">
          <Icon :icon="faChevronLeft" />
          <span>Back</span>
        </b-button>

        <span v-if="title" class="header-item header-title">{{ title }}</span>

        <span></span>
      </div>

      <div class="page-content">
        <div>
          <div class="content-wrapper">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.split-view-page {
  position: absolute;
  inset: 0;
  background-color: var(--modal-bg);
  transform: translateX(0);
  transition: 0.25s;
  z-index: 9;
  display: flex;
  flex-direction: column;

  @include v-from {
    transform: translateX(100%);
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: var(--modal-header-bg);
  border-bottom: 1px solid var(--modal-header-border-color);

  .btn:not(:hover) {
    text-decoration: none !important;
    background-color: transparent;
    border-color: transparent;
  }
}

.header-title {
  margin-left: -58.95px;
}

.page-content {
  width: 100%;
  flex-grow: 1;
  position: relative;

  > div {
    position: absolute;
    inset: 0;
    overflow: auto;
  }
}

.content-wrapper {
  padding: 40px;
}
</style>
