<script lang="ts">
import { ref, computed, watch } from 'vue';
import * as BootstrapVue from 'bootstrap-vue';
import { TablePanel as TP } from 'table-panel';
import { toString } from '@utils/to-string';
import { dates } from '@utils/dates';

import { useStore } from '@store';

import InputCheckbox from '@components/Inputs/InputCheckbox.vue';
import TableOverlay from '@components/TablePanel/TableOverlay.vue';

import { useFlyout } from './flyout';
import { useTable, type DataItem } from './table';
import CellOperations from './CellOperations.vue';
import Toolbar from './Toolbar.vue';

export default { name: 'TableRegular' };
</script>

<script setup lang="ts">
/** ... */
export type PageSize = 5 | 10 | 15 | 25 | 50;

/**
 * `TableRegular` component properties.
 */
export interface Props {
  table: string;
  // pk: keyof T;
  pk: string;
  label: string;
  columns: TP.Column<DataItem>[];
  operations?: TP.Row.Operation<DataItem>[];
  createAction?: string;
  deleteAction?: string;
  loading?: boolean;
  tableQueryText?: string;
  tablePageSize?: PageSize;
  tablePageNumber?: number;
  tableSortType?: string;
  tableSortReverse?: boolean;
  filterFields?: string[];
  specialFilters?: TP.SpecialFilter<DataItem>[];
  filterTags?: TP.FilterTag[];
  infoMessage?: string | null;
}

/** ... */
const PAGE_SIZE_OPTIONS = [5, 10, 15, 25, 50].map((value) => ({
  text: value.toString(),
  value,
}));

const props = withDefaults(defineProps<Props>(), {
  pk: 'id',
  operations: () => [],
  createAction: '',
  deleteAction: '',
  loading: false,
  tableQueryText: '',
  tablePageSize: 5,
  tablePageNumber: 1,
  tableSortType: '',
  tableSortReverse: false,
  filterFields: () => [],
  specialFilters: () => [],
  filterTags: () => [],
  infoMessage: null,
});

const store = useStore();

const {
  path,
  maximized,
  tableFilters,
  fullscreen,
  fields,
  items,
  displayedItems,
  page,
  allRowsSelected,
  customCells,
  operations,
} = useTable(props);

const flyout = useFlyout(operations.value ?? []);

/** ... */
const pageSizeOptions = ref(PAGE_SIZE_OPTIONS);

type UpdateStoreCommit = (path: string, options: TP.UpdateOptions) => void;

/**
 * Update a property of the current table store.
 *
 * @param key Property key.
 * @param value Property value.
 */
function setTableProp<T extends keyof TP.UpdateOptions>(
  key: T,
  value: TP.UpdateOptions[T],
) {
  (store.commit as UpdateStoreCommit)(`${path.value}/update`, { [key]: value });
}

/** List of fields that are included in filter function. */
const filterIncludedFields = computed(() => props.filterFields ?? []);

const filteredRowCount = ref(0);

watch(items, (value) => {
  filteredRowCount.value = value.length;
});

function onTableFiltered(_items: DataItem[], count: number) {
  filteredRowCount.value = count;
}

const tableClassList = computed(() => {
  const items = ['table-panel'];

  if (maximized.value) {
    items.push('fullscreen');
  }

  if (maximized.value && tableFilters.value.length) {
    items.push('filter-offset');
  }

  return items;
});

/** ... */
const pageNumber = computed({
  get: () => props.tablePageNumber,
  set: (value) => setTableProp('tablePageNumber', value),
});

/** ... */
const itemsPerPage = computed({
  get: () => props.tablePageSize,
  set: (value) => setTableProp('tablePageSize', value),
});

const tableOverlay = computed(() => {
  let text: string;
  let transparent = false;

  if (props.loading) {
    text = 'Loading';
  } else if (!filteredRowCount.value) {
    text = 'No Items';
    transparent = true;
  } else {
    return null;
  }

  return { text, transparent };
});

/** `BTable` props data object. */
const bTableProps = computed(() => {
  const value: BootstrapVue.BTable.Props = {
    fields: fields.value,
    items: items.value,
    perPage: itemsPerPage.value,
    currentPage: pageNumber.value,
    filter: props.tableQueryText,
    filterIncludedFields: filterIncludedFields.value,
  };

  if (props.pk) {
    value.primaryKey = props.pk;
  }

  return value;
});

function openFlyout(item: DataItem, _index: number, event: MouseEvent) {
  event.preventDefault();

  flyout.open(item, event.x, event.y);
}

function sortCompare(
  aRow: Record<string, unknown>,
  bRow: Record<string, unknown>,
  key: string,
  sortDesc: boolean,
  compareOptions: unknown,
  compareLocale: unknown,
) {
  /**
   * Don't forget this function's sister function under TableProgressive.vue!
   */

  /**
   * sortDesc should not be necessary since b-table will handle the direction
   * according to Bootstrap's documentation
   */

  // try to find column and detect if date or dateTime type is given
  const column = props.columns.find((col) => col.key === key);

  let a;
  let b;

  /**
   *  if the column's value is created with a function, we need to call that
   * function to get the proper value. Otherwise we can take the raw value
   * from the row[key]
   */
  if (typeof column?.value === 'function') {
    a = evokeSafely(() => (column.value as GenericFunction)(aRow));
    b = evokeSafely(() => (column.value as GenericFunction)(bRow));
  } else {
    a = aRow[key];
    b = bRow[key];
  }

  // we need to sort dates differently than raw sort
  if (column && (column.type === 'date' || column.type === 'dateTime')) {
    let dateA: DateLike;
    let dateB: DateLike;

    try {
      dateA = dates.toDate(a as string);
      dateB = dates.toDate(b as string);
    } catch {
      return 0;
    }

    if (dates.isBefore(dateA, dateB)) return 1;

    if (dates.isAfter(dateA, dateB)) return -1;

    return 0;
  } else if (column && column.type === 'currency') {
    // we need to sort currency differently than raw sort

    let valueA: number;
    let valueB: number;

    // we could be given a variety of "currency" type with any
    // number of decimals, commas, and denomination symbols
    // so we'll want to strip all of that out to a raw number
    if (typeof a === 'string') {
      valueA = parseFloat(a.replace(/[^0-9.-]+/g, ''));
    } else if (typeof a === 'number') {
      valueA = a;
    } else {
      return 0;
    }

    if (typeof b === 'string') {
      valueB = parseFloat(b.replace(/[^0-9.-]+/g, ''));
    } else if (typeof b === 'number') {
      valueB = b;
    } else {
      return 0;
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  } else if (typeof a === 'number' && typeof b === 'number') {
    // If both compared fields are native numbers or both are native dates
    return a < b ? -1 : a > b ? 1 : 0;
  } else {
    return toString(a).localeCompare(
      toString(b),
      compareLocale,
      compareOptions,
    );
  }
}

function evokeSafely(fn: () => unknown) {
  let value: unknown;

  try {
    value = fn();
  } catch {
    // Ignore any errors.
  }

  return value;
}
</script>

<template>
  <div :class="tableClassList">
    <Toolbar @maximize="fullscreen = true" @minimize="fullscreen = false" />

    <div class="table-container">
      <Transition appear>
        <TableOverlay v-if="tableOverlay" v-bind="tableOverlay" />
      </Transition>

      <div class="table-wrapper">
        <b-table
          v-model="displayedItems"
          :sort-compare="sortCompare"
          class="mb-0"
          responsive
          striped
          v-bind="bTableProps"
          @filtered="onTableFiltered"
          @row-contextmenu="openFlyout"
        >
          <!-- Row Selection Checkbox (Header) -->

          <template #head(selector)>
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox v-model="allRowsSelected" size="sm" />
              </div>
            </div>
          </template>

          <!-- Row Operations Flyout Button (Header) -->

          <template #head(operations)="header">
            <div class="text-center">{{ header.label }}</div>
          </template>

          <!-- Row Selection Checkbox -->

          <template #cell(selector)="{ index }">
            <div class="table-cell-checkbox">
              <div>
                <InputCheckbox
                  v-if="page[index]"
                  v-model="page[index].selected"
                  size="sm"
                />
              </div>
            </div>
          </template>

          <!-- Custom Cells -->

          <template v-for="cell in customCells" #[`cell(${cell.key})`]="data">
            <component :is="cell.component" :key="cell.key" v-bind="data" />
          </template>

          <!-- Row Operations Flyout Button -->

          <template #cell(operations)="cell">
            <CellOperations
              v-if="operations && cell.item"
              v-bind="{ item: cell.item, operations }"
            />
          </template>
        </b-table>

        <!-- <div class="table-content-placeholder" v-if="">
          No Results Found
        </div> -->
      </div>
    </div>

    <div class="table-footer p-4">
      <b-row class="justify-content-between align-items-center">
        <b-col class="col-auto">
          <b-input-group class="mr-2" prepend="Show" append="entries">
            <b-form-select v-model="itemsPerPage" :options="pageSizeOptions" />
          </b-input-group>
        </b-col>

        <b-col class="col-auto">
          <b-pagination
            v-model="pageNumber"
            class="mb-0"
            :total-rows="filteredRowCount"
            :per-page="itemsPerPage"
            aria-controls="my-table"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
table {
  width: auto;
  min-width: 100%;
  margin-bottom: 0 !important;
  font-size: 0.8rem;
  border: none;
}

thead {
  background-color: var(--table-header-bg) important;
}

tbody {
  margin: 0 !important;
}

tr {
  min-height: 36px;

  tbody & {
    background: transparent !important;

    &:nth-child(even) {
      background: var(--table-row-even-bg) !important;
    }
  }
}

td {
  position: relative;
  padding: 0.5rem 0.8rem !important;
  text-align: left !important;
  border: none !important;
  border-top: 1px solid var(--table-row-border-color) !important;

  thead & {
    white-space: nowrap;

    a {
      color: var(--text-primary);
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.table-header {
  // display: flex;
  align-items: center;
  padding: 20px;
}

.header-group {
  display: flex;
  align-items: center;
  padding: 0 20px;

  &.search-bar {
    flex-grow: 1;
  }
}

.table-input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  //
  background-color: #424242;
  border: 1px solid #ffffff14 !important;
  border-radius: 5px;
  outline: none !important;

  &:focus {
    border-color: #009eff !important;
    box-shadow: 0 0 1px 1px #009eff, 0 0 10px #009eff85;
  }
}

.table-cell-placeholder {
  height: 0.8rem;
}

.table-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 34px;
  margin: 0 5px;
  padding: 0.2em 1em;
  color: inherit;
  font-size: 1.2em;
  background-color: #424242;
  border: 1px solid #ffffff14 !important;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.15s;

  &:hover {
    background-color: #0000002e;
  }
}

.table-container {
  // border-top: 1px solid var(--border-color) !important;
  font-size: 0.9em;
  //
  min-height: 300px;
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

.column-button {
  display: flex;
  align-items: center;
  justify-content: center;
  // font-size: 1.5em;
  font-size: 1rem;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.5;
  }

  &.selected {
    color: #00b7ff;
    cursor: default;
    opacity: 1 !important;
  }
}

.table-footer {
  border-top: 1px solid var(--border-color) !important;
}
</style>

<style lang="scss" src="./table-panel.scss"></style>
