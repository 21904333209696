<template>
  <div class="user-certifications-table-cell">
    <template v-if="certifications.length">
      <div v-for="certification in certifications" :key="certification.id">
        <span>•</span>
        <span class="certification-name pr-1">{{
          certification.product.name
        }}</span>

        <span class="certification-status">
          <!-- <span class="sub-label">Status:</span> -->
          <span
            :class="{
              danger:
                certification.status === 'REVOKED' ||
                certification.status === 'DENIED' ||
                certification.status === 'SUSPENDED' ||
                certification.status === 'EXPIRED',
              success:
                certification.status === 'CURRENT' ||
                certification.status === 'APPROVED',
              warning: certification.status === 'AWAITING_ATTEMPT',
              'sub-value': 'sub-value',
            }"
            >{{ certification.status }}</span
          >
        </span>
      </div>
    </template>

    <span v-else> ... </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
// import { useStore } from '@store';
import type { User } from '@models';

export interface Props {
  /** ... */
  item: User;
}

// const store = useStore();

const props = defineProps<Props>();

const certifications = computed(() => {
  const items = props.item.certifications ?? [];
  return items;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellUserCertifications: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellUserCertifications' };
</script>
<style scoped lang="scss">
.user-certifications-table-cell {
  white-space: nowrap;
}

.certification-status {
  font-size: 0.8em;
}

.certification-name {
  color: $orange;
}

.sub-label {
  color: #ff8935;
}

.info {
  color: var(--info);
}
.success {
  color: var(--success);
}
.danger {
  color: var(--danger);
}
.warning {
  color: var(--warning);
}

.sub-value {
  opacity: 0.75;
}
</style>
