<script lang="ts">
declare module 'vue/types/vue' {
  export interface Vue {
    CardActiveLicenses: ComponentWithProps;
  }
}

export default { name: 'CardActiveLicenses' };
</script>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';

import { useMe } from '@composables';
import { modals } from '@modals';
import { License, Product } from '@models';
import { alert } from '@services/alert';
import { useGetter, useCommit, Shop } from '@store';
import { ensureError } from '@tools/ensure-error';
import { move } from '@utils/array';
import * as currencies from '@utils/currencies';

import DisplayTextDate from '@components/Display/DisplayTextDate.vue';
import DisplayTextCurrency from '@components/Display/DisplayTextCurrency.vue';
import Icon from '@components/Icon.vue';

import { faIdCard } from '@fortawesome/pro-solid-svg-icons/faIdCard';

const me = useMe();

const offerings = useGetter('shop/existingUserOfferings');
const addItem = useCommit('cart/ADD_ITEM');

const focusedLicenseId = ref<License['id'] | null>(null);

interface ProductInfo {
  id: string;
  name: string;
  onSale: boolean;
  price: number;
  salePrice: number;
}

const products = computed(() => {
  const items: ProductInfo[] = [];

  for (const offering of offerings.value ?? []) {
    // NOTE: Price currently assumed to be first `price` in the `prices` array
    // with a currency in USD.
    const price = currencies.getProductPrice(offering.prices);

    // NOTE: Sale price usage is currently disabled due to Stripe pricing
    // migration.
    // const salePrice = offering.salePrice;
    const salePrice = price;

    items.push({
      id: offering.id,
      name: offering.name,
      onSale: offering.onSale,
      price,
      salePrice,
    });
  }

  return items;
});

// const licenses = computed(() => {
//   return (me.licenses ?? []).map((license) => {
//     return reactive({ ...license, show: false });
//   });
// });

function createLicenseListElement(license: License) {
  const id = license.id;
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const displayName = license.productName || license.productId;
  const expiration = license.expiration;
  const scenes = license.scenes ?? [];

  const highlighted = computed(() => id === focusedLicenseId.value);

  return reactive({
    id,
    displayName,
    expiration,
    scenes,
    highlighted,
  });
}

const licenses = computed(() => {
  return (me.licenses ?? []).map(createLicenseListElement);
});

function createScenarioListElement(scenario: Product.SceneInfo) {
  const id = scenario.id;
  const displayName = scenario.name;

  const highlighted = computed(() => {
    if (!focusedLicenseId.value) return false;

    const license = licenses.value.find((l) => l.id === focusedLicenseId.value);

    return !!find(license?.scenes ?? [], { id });
  });

  return reactive({
    id,
    displayName,
    highlighted,
  });
}

const scenarios = computed(() => {
  return uniqBy(
    licenses.value.flatMap(({ scenes }) =>
      scenes.map(createScenarioListElement),
    ),
    'id',
  );
});

const unownedProducts = computed(() => {
  if (!products.value?.length) return [];

  let items = [...products.value];

  const cheapItemIndex = findIndex(items, ({ price }) => price <= 20);

  if (cheapItemIndex >= 0) {
    // Shift cheaper product to front.
    items = move(items, cheapItemIndex, 0);
  }

  return items.slice(0, 2);
});

function setFocusedLicenseId(licenseId?: License['id']) {
  focusedLicenseId.value = licenseId ?? null;
}

function addToCart(productId: Shop.Item['id']) {
  const item = find(offerings.value ?? [], { id: productId });

  if (!item) {
    throw new Error(
      `[addToCart] could not find cart item with product ID "${productId}".`,
    );
  }

  let error: ErrorOrNullish = null;

  try {
    addItem({ item });
  } catch (err) {
    error = ensureError(err);
  }

  if (error?.code === 'DUPLICATE_ITEM_SCENES') {
    alert.warning(error.message);
  }
}

function showDetails(productId: string) {
  void modals.shop.productDetails({ productId });
}
</script>

<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h6 class="mb-0">My Content</h6>
    </b-card-header>

    <b-card-body class="p-0">
      <div class="separator-title">My Scenarios</div>

      <div class="scenes-list">
        <div
          v-for="scenario in scenarios"
          :key="scenario.id"
          :class="['list-item', { highlighted: scenario.highlighted }]"
        >
          {{ scenario.displayName }}
        </div>
      </div>

      <div class="separator-title">My Licenses</div>

      <div class="scenes-list">
        <div
          v-for="license in licenses"
          :key="license.id"
          :class="[
            'list-item',
            'list-item-large',
            { highlighted: license.highlighted },
          ]"
          @mouseenter="setFocusedLicenseId(license.id)"
          @mouseleave="setFocusedLicenseId()"
        >
          <span
            class="d-flex flex-column justify-content-between px-2 license-name"
          >
            <div class="m-0">
              <Icon :icon="faIdCard" /> {{ license.displayName }}
            </div>

            <small class="text-muted">
              <span v-if="license.expiration">
                Expires on
                <DisplayTextDate
                  :value="license.expiration"
                  format="MM/dd/yyyy"
                />
              </span>

              <span v-else>Does not expire</span>
            </small>
          </span>
          <!-- <b-button size="sm" variant="link" @click="copyKey(license.key)">
          Copy Key
        </b-button> -->
        </div>
      </div>

      <!-- Suggested Products -->
      <div v-if="!me.isK12 && unownedProducts.length">
        <div class="separator-title recommendations">
          Purchase Recommendations
        </div>

        <TransitionGroup appear>
          <div
            v-for="(product, i) in unownedProducts"
            :key="product.id"
            :class="[
              'py-3',
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'product-container',
              { 'border-bottom': i < unownedProducts.length - 1 },
            ]"
          >
            <span class="card-product px-3">
              <div class="h6 m-0 product-name">
                {{ product.name || product.id }}
              </div>

              <span class="connector"></span>

              <div>
                <DisplayTextCurrency
                  :class="{ strikethrough: product.onSale }"
                  :value="product.price"
                />

                <DisplayTextCurrency
                  v-if="product.onSale"
                  :value="product.salePrice"
                />
              </div>

              <b-button size="sm" @click="showDetails(product.id)">
                Details
              </b-button>

              <b-button
                size="sm"
                variant="primary"
                @click="addToCart(product.id)"
              >
                Add
              </b-button>
            </span>
          </div>
        </TransitionGroup>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped lang="scss">
.product-name {
  position: relative;

  .fa-icon {
    position: absolute;
    left: -15px;
    top: -15px;
  }
}

.license-name {
  .fa-icon {
    opacity: 0.5;
    margin-right: 10px;
  }
}

.license-btn {
  cursor: pointer;
}

.separator-title {
  font-size: 0.8em;
  padding: 0.5rem 1rem;
  background: linear-gradient(-90deg, #b7b7b7, #eaeaea);

  &.recommendations {
    color: $white;
    background: linear-gradient(
      -90deg,
      $sales-blue-secondary,
      $sales-blue-tertiary
    );

    @include app-theme-dark {
      background: linear-gradient(
        -90deg,
        $sales-blue-secondary,
        $sales-blue-tertiary
      );
    }
  }

  @include app-theme-dark {
    background: linear-gradient(-90deg, #2f323a, #343741);
  }
}

.product-container {
  opacity: 1;
  transform: translateX(0);

  &.v-enter {
    opacity: 0;
    transform: translateX(20px);
  }

  &.v-enter-active {
    transition: 0.5s;
  }

  &.v-leave,
  &.v-leave-active {
    display: none;
    transition: 0s;
  }
}

.card-product {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div,
  > button {
    margin: 0 10px;
  }

  .connector {
    margin-left: 10px;
    border: none;
    border-top: 1px dashed grey;
    align-self: center;
    opacity: 0.5;
    flex-grow: 1;
  }
}

.scenes-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  align-items: baseline;
  position: relative;
  // margin: 0.5rem 0;
  padding: 1rem;
  gap: 0.5rem;

  font-size: 0.8em;

  // > div {
  //   margin: 3px;
  //   padding: 3px 10px;
  //   background-color: $almost-white;
  //   border-radius: 10px;
  //   transition: 200ms ease;

  //   &.highlighted {
  //     color: $white;
  //     background-color: $sales-blue-primary;
  //   }

  //   &:hover {
  //     cursor: pointer;
  //   }

  //   @include app-theme-dark {
  //     background-color: $dark-grey;

  //     &.highlighted {
  //       background-color: $sales-blue-primary;
  //     }
  //   }
  // }
}

.list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  text-align: center;
  padding: 4px 10px;
  background-color: $almost-white;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  transition: 200ms ease;

  &.list-item-large {
    //
  }

  &.highlighted {
    color: $white;
    background-color: $sales-blue-primary;
  }

  &:hover {
    cursor: pointer;
  }

  @include app-theme-dark {
    background-color: $dark-grey;

    &.highlighted {
      background-color: $sales-blue-primary;
    }
  }
}
</style>
