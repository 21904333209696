<script setup lang="ts">
import { type TablePanels } from '@store';

import { useTableItem } from './table-item';

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CardTableGroupView: ComponentWithProps<Props>;
  }
}

/** `CardTableGroupView` component properties. */
export interface Props {
  tableKey: TablePanels.ModuleKey;
  loaderOptions?: Nullable<GenericObject>;
  collapsable?: boolean;
}

const props = defineProps<Props>();

const { tablePanel, tableComponent, visible } = useTableItem(props);
</script>

<template>
  <b-collapse
    v-bind="{ visible }"
    accordion="data-table-accordion"
    role="tabpanel"
  >
    <b-card-body class="p-0">
      <component :is="tableComponent" :table="tableKey" v-bind="tablePanel" />
    </b-card-body>
  </b-collapse>
</template>
