<template>
  <div class="graphic-chevrons" :style="graphicStyle">
    <div class="d-flex">
      <div
        v-for="item in elementItems"
        :key="item.index"
        class="graphic-element"
      >
        <div class="chevron-piece chevron-top"></div>
        <div class="chevron-piece chevron-bottom"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { StyleValue } from 'vue/types/jsx';

import { isString } from '@tools/type-guards';

/**
 * ...
 */
export interface Element {
  index: number;
}

/**
 * `GraphicChevrons` component properties.
 */
export interface Props {
  color?: string;
  elements?: number;
  x?: string | number;
  y?: string | number;
  rotation?: number;
}

const props = withDefaults(defineProps<Props>(), {
  color: '#48e5c240',
  elements: 6,
  x: 50,
  y: 50,
  rotation: -45,
});

/** ... */
const graphicStyle = computed(() => {
  const style: StyleValue = {};

  style.left = isString(props.x) ? props.x : `${props.x.toString()}%`;

  style.top = isString(props.y) ? props.y : `${props.y.toString()}%`;

  style.color = props.color;

  style.transform = `rotateZ(${props.rotation}deg)`;

  return style;
});

/** ... */
const elementItems = computed(() => {
  const items: Element[] = [];

  for (let i = 0; i <= props.elements - 1; i++) {
    items.push({ index: i });
  }

  return items;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    GraphicChevrons: ComponentWithProps<Props>;
  }
}

export default { name: 'GraphicChevrons' };
</script>

<style lang="scss" scoped>
.graphic-chevrons {
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graphic-element {
  &:not(:last-of-type) {
    margin-right: 45px;
  }
}

.chevron-piece {
  width: 45px;
  height: 65px;
  background-color: currentColor;

  &.chevron-top {
    transform: skewX(30deg);
  }

  &.chevron-bottom {
    transform: skewX(-30deg);
  }
}
</style>
