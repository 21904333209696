<script lang="ts">
const INTERVAL_DURATION = 8_000;

export default { name: 'SectionCustomerStories' };
</script>

<script setup lang="ts">
import { useAutoCycleList } from '@composables';

import Icon from '@components/Icon.vue';

import { faQuoteLeft } from '@icons/solid/faQuoteLeft';
import { faQuoteRight } from '@icons/solid/faQuoteRight';
import { faUser } from '@icons/solid/faUser';

export interface CustomerStory {
  id: string;
  body: string;
  author: string;
  image: string;
}

/** `SectionCustomerStories` component properties. */
export interface Props {
  stories: CustomerStory[];
}

const props = defineProps<Props>();

const { state, paused, start, stop, select } = useAutoCycleList(
  props.stories,
  INTERVAL_DURATION,
);

const animationDuration = (INTERVAL_DURATION * 0.001).toString() + 's';
</script>

<template>
  <b-container
    class="section-customer-stories"
    @mouseover="stop"
    @mouseleave="start"
  >
    <b-row class="mb-5 justify-content-md-center">
      <b-col lg="6" class="mx-auto">
        <h2>Customer Stories</h2>
      </b-col>
    </b-row>

    <b-row v-if="state" class="justify-content-center mb-4">
      <b-col lg="6">
        <div :key="state.id" class="story-body">
          <div class="story-content">
            <div class="quote-block quote-block-left">
              <Icon :icon="faQuoteLeft" />
            </div>

            <pre>{{ state.body }}</pre>

            <div class="quote-block quote-block-right">
              <Icon :icon="faQuoteRight" />
            </div>
          </div>

          <div class="story-footer">
            <span class="story-thumbnail">
              <Icon :icon="faUser" />
            </span>

            <span class="story-author">- {{ state.author }}</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="section-footer">
          <div class="pagination-btns">
            <div
              v-for="(story, i) in stories"
              :key="story.id"
              :class="['pagination-btn', { active: story === state }]"
              @click="select(i)"
            ></div>
          </div>

          <div
            v-if="!paused"
            :key="state.id"
            class="progress-bar"
            :style="{ animationDuration }"
          ></div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped lang="scss">
@keyframes storyContent {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes quoteBlocks {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(2) rotateZ(-10deg);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes storyAuthor {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.section-footer {
  position: relative;
  display: inline-flex;
  margin: 0 auto;
}

.progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.15);
  margin: 0 10px;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: gray;
    animation: progressBar linear 8s;
  }
}

.story-panel {
  display: flex;
  align-items: stretch;
  margin-bottom: 2rem;
  opacity: 1;
  transform: translateY(0);
  transition: 1s;

  &.v-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  &.v-leave-active {
    transition: 0;
    display: none;
  }
}

$quote-y: -20px;
$quote-x: -15px;

.quote-block {
  font-size: 3rem;
  display: inline-block;
  position: absolute;
  color: #ff7c00;
  animation: quoteBlocks 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;

  &.quote-block-left {
    text-align: right;
    top: $quote-y;
    left: $quote-x;
    animation-delay: 0.1s;
  }

  &.quote-block-right {
    text-align: right;
    bottom: $quote-y;
    right: $quote-x;
    animation-delay: 0.2s;
  }
}

.story-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 500px;
  justify-content: center;
}

.story-content {
  text-align: left;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  animation: storyContent 0.5s;
  animation-fill-mode: both;
  box-shadow: var(--panel-box-shadow);
  border-radius: 40px;
  padding: 2rem;

  @include app-theme-light {
    background-color: white;
  }

  @include app-theme-dark {
    background: #2d313b;
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: 'Rubik';
    color: inherit;
    font-size: 1rem;
    padding: 1rem;
  }
}

.story-image {
  width: 300px;
  height: 400px;
  margin-right: 80px;
  background-color: #0000004d;
  border-radius: 0.5rem;
  flex-shrink: 0;
  opacity: 1;
  transform: translateY(0);
  transition: 1s;

  &.v-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  @include app-mobile {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.story-footer {
  display: flex;
  align-items: center;
}

.story-thumbnail {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 1rem;
  overflow: hidden;
  animation: storyContent 0.5s;
  animation-fill-mode: both;
  animation-delay: 0.2s;

  @include app-theme-light {
    background-color: #dbdbdb;
  }

  @include app-theme-dark {
    background-color: #3d414d;
  }

  .fa-icon {
    font-size: 3.5rem;
    margin-bottom: -1.6rem;

    @include app-theme-light {
      color: #0000004d;
    }

    @include app-theme-dark {
      color: #999999;
    }
  }
}

.story-author {
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  animation: storyAuthor 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.pagination-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.pagination-btn {
  width: 100px;
  height: 10px;
  background: #ffd400;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  opacity: 0.25;

  &.active {
    opacity: 1;
  }
}
</style>
