// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/images/orqa-promo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orqa-bg[data-v-4039ed2e]{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-position:center;background-size:cover;border-radius:0 0 15px 15px;background-color:#191919;position:relative;color:#fff;text-align:center}.orqa-bg .overlay[data-v-4039ed2e]{position:absolute;top:0;left:0;width:100%;height:100%;display:block;background-color:rgba(25,25,25,.4823529412);border-radius:0 0 15px 15px}`, "",{"version":3,"sources":["webpack://./client/components/Cards/CardOrqaController.vue"],"names":[],"mappings":"AAGA,0BACE,wDAAA,CACA,0BAAA,CACA,qBAAA,CACA,2BAAA,CACA,wBAAA,CACA,iBAAA,CACA,UAAA,CACA,iBAAA,CAEA,mCACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,2CAAA,CACA,2BAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
